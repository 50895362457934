import { useCallback, useState } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { ContentCopyExtensionProps } from './ContentCopyExtension.interface';

const copyWrapperSx = {
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
};

export const ContentCopyExtension: React.FC<ContentCopyExtensionProps> = ({ children, value }) => {
  const [mouseOver, setMouseOver] = useState(false);

  const onMouseOver = useCallback(() => {
    setMouseOver(true);
  }, []);

  const onMouseOut = useCallback(() => {
    setMouseOver(false);
  }, []);

  const onCopyClick = useCallback(
    (event: any) => {
      event.stopPropagation();
      navigator.clipboard.writeText(value);
    },
    [value],
  );

  return (
    <Box
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      data-testid="copy wrapper"
      sx={copyWrapperSx}
    >
      {children}
      {value !== '' ? (
        <IconButton
          data-testid="Copy value"
          onClick={onCopyClick}
          sx={{ p: 0, px: 1, opacity: mouseOver ? 1 : 0 }}
        >
          <ContentCopyIcon sx={{ fontSize: '14px', lineHeight: '21px' }} />
        </IconButton>
      ) : null}
    </Box>
  );
};
