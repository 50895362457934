import {
  BadgeStatus,
  StatusBadge,
  BADGE_STATUSES,
} from 'common/components/StatusBadge/StatusBadge';
import { TFunction, useTranslation } from 'react-i18next';
import {
  BILLING_STATUS,
  BillingStatus,
} from 'simInventory/SimInventoryApi/simInventoryApi.interface';

const getBadgeStatusBaseOnBillingStatus = (billingStatus: BillingStatus): BadgeStatus => {
  switch (billingStatus) {
    case BILLING_STATUS.BILLED: {
      return BADGE_STATUSES.SUCCESS;
    }
    case BILLING_STATUS.NON_BILLED: {
      return BADGE_STATUSES.INACTIVE;
    }
    default: {
      return BADGE_STATUSES.UNKNOWN;
    }
  }
};

const getLabelBaseOnBillingStatus = (
  billingStatus: BillingStatus,
  t: TFunction<'translation', undefined>,
): string => {
  switch (billingStatus) {
    case BILLING_STATUS.BILLED: {
      return t('simInventory.billingStatuses.billed');
    }
    case BILLING_STATUS.NON_BILLED: {
      return t('simInventory.billingStatuses.nonBilled');
    }
    default: {
      return t('common.unknown');
    }
  }
};

export type BillingBadgeProps = {
  billingStatus: BillingStatus;
};

export const BillingBadge = ({ billingStatus }: BillingBadgeProps) => {
  const { t } = useTranslation();
  return (
    <StatusBadge
      type="text"
      status={getBadgeStatusBaseOnBillingStatus(billingStatus)}
      label={getLabelBaseOnBillingStatus(billingStatus, t)}
    />
  );
};
