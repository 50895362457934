import { ErrorCodes } from 'common/errorCodes';

export class AuthError extends Error {
  constructor() {
    super(ErrorCodes.AccessDenied);

    Object.setPrototypeOf(this, AuthError.prototype);
  }
}

export const authFetch = async (input: RequestInfo | URL, init?: RequestInit) => {
  const response = await fetch(input, { ...init, credentials: 'include' });

  const status = response.status.toString();
  if (!response.ok && status === ErrorCodes.AccessDenied) {
    throw new AuthError();
  }

  return response;
};
