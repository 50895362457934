import React from 'react';
import Chip, { ChipProps } from '@mui/material/Chip';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';

import { ReactComponent as LargeCheckIcon } from 'assets/images/BulkStatus/LargeCheck.svg';
import { ReactComponent as LargeXIcon } from 'assets/images/BulkStatus/LargeX.svg';
import { ReactComponent as PendingIcon } from 'assets/images/BulkStatus/Pending.svg';
import { PROVISIONING_STATUS_LABEL } from 'bulkProvisioning/BulkProvisioning.constants';
import { ProvisioningStatusLabel } from 'bulkProvisioning/BulkProvisioning.interface';

type BulkProvisioningStatusChipProps = {
  status?: ProvisioningStatusLabel;
};

export const BulkProvisioningStatusChip: React.FC<BulkProvisioningStatusChipProps> = ({
  status
}) => {
  let color: ChipProps['color'] = 'default';
  let icon = <HistoryToggleOffIcon />;

  if (
    status === PROVISIONING_STATUS_LABEL.DATA_EXTRACTION_FAILED ||
    status === PROVISIONING_STATUS_LABEL.PROCESSING_FAILED
  ) {
    color = 'failed';
    icon = <LargeXIcon width={'24px'} height="24px" />;
  }

  if (status === PROVISIONING_STATUS_LABEL.PROCESSING_SUCCEED) {
    color = 'success';
    icon = <LargeCheckIcon width={'24px'} height="24px" />;
  }

  if (
    status === PROVISIONING_STATUS_LABEL.PROCESSING_IN_PROGRESS ||
    status === PROVISIONING_STATUS_LABEL.DATA_EXTRACTION_IN_PROGRESS ||
    status === PROVISIONING_STATUS_LABEL.DATA_EXTRACTION_PENDING
  ) {
    color = 'info';
    icon = <PendingIcon width={'24px'} height="24px" />;
  }

  return (
    <Chip
      color={color}
      icon={icon}
      label={status}
      sx={{
        height: '28px',
        '& .MuiChip-icon': {
          ml: 1.5,
          borderRadius: 3,
          mr: 0
        },
        '& .MuiChip-label': {
          pl: 1,
          pr: 2
        }
      }}
    />
  );
};
