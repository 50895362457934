import { Suspense, useCallback, useEffect, useState } from 'react';
import { FiltersButton } from 'common/components/Filters/FiltersButton';

import { simFiltersAtom } from 'atoms/filters/sim';
import { useSetAtom } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { syncHintsAtom, updateHintsAtom } from 'atoms/hints/sim';
import { SimFiltersDialog } from './SimFiltersDialog';
import { SimFiltersDialogAtomInitializator } from './Components/Atoms/SimFiltersDialogAtomInitializator';
import Box from '@mui/material/Box';
import { IdSearchInput } from './Components/Inputs/IdSearchInput';
import {
  TabBar,
  TabBarActions,
  TabBarChips,
  TabBarFilters,
} from 'common/components/AppLayout/TabBar/TabBar';
import { simsInventorySearchDataTestId } from 'simInventory/SimInventoryDataTestIds';
import Grid from '@mui/material/Grid2';
import { RefetchQueryButton } from 'common/components/Queries/RefetchQueryButton';
import { Actions, Subjects } from 'permissions/ability';
import { SimInventoryCan } from 'permissions/PermissionProvider';
import { SimProfileDownloadReportsExtendedFiltersButton } from 'simInventory/Reports/SimProfileDownloadReportsExtendedFiltersButton';
import { SimProfileDownloadReportsExtendedFiltersButtonLargeExport } from 'simInventory/Reports/SimProfileDownloadReportsExtendedFiltersButtonLargeExport';
import { SimInventoryFilterChips } from './Components/Chips/SimInventoryFilterChips';
import { useHasActiveFilters } from './hooks/useHasActiveFilters';
import { useHasFeatureFlag } from 'featureFlags/useHasFeatureFlag';
import { useIsFetching } from '@tanstack/react-query';

export const SimFilters = () => {
  const largeReportsEnabled = useHasFeatureFlag('LargeReports');
  const [open, setOpen] = useState(false);

  const setFilters = useSetAtom(simFiltersAtom);
  const resetFilters = useResetAtom(simFiltersAtom);

  const setHints = useSetAtom(updateHintsAtom);
  const syncHints = useSetAtom(syncHintsAtom);

  const onApply = useCallback((filters: any) => {
    setFilters(filters);
    setHints(filters);
    setOpen(false);
  }, []);

  const onClose = useCallback(() => {
    syncHints();
    setOpen(false);
  }, []);

  const isFetching = useIsFetching({ queryKey: ['SimInventoryConfigurable'] });
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setIsLoading(!!isFetching);
  }, [isFetching]);

  const hasChips = useHasActiveFilters();
  return (
    <TabBar>
      <Grid container rowGap={4} sx={{ mt: 0, width: '100%' }} justifyContent={'space-between'}>
        <TabBarFilters>
          <Box sx={{ flex: 1, maxWidth: 360, mr: 4 }}>
            <IdSearchInput />
          </Box>
          <Suspense>
            <div data-testid={simsInventorySearchDataTestId}>
              <FiltersButton
                onClick={() => {
                  setOpen(true);
                }}
                active={false}
              />
              {open && (
                <SimFiltersDialogAtomInitializator>
                  <SimFiltersDialog
                    onApply={onApply}
                    onCancel={onClose}
                    onClear={resetFilters}
                    onClose={onClose}
                  />
                </SimFiltersDialogAtomInitializator>
              )}
            </div>
          </Suspense>
        </TabBarFilters>
        <TabBarActions>
          <RefetchQueryButton queryKey={['SimInventoryConfigurable']} />
          <SimInventoryCan I={Actions.export} a={Subjects.simsProfiles}>
            {largeReportsEnabled ? (
              <SimProfileDownloadReportsExtendedFiltersButtonLargeExport disabled={isLoading} />
            ) : (
              <SimProfileDownloadReportsExtendedFiltersButton disabled={isFetching > 0} />
            )}
          </SimInventoryCan>
        </TabBarActions>
      </Grid>
      <TabBarChips>{hasChips ? <SimInventoryFilterChips /> : null}</TabBarChips>
    </TabBar>
  );
};
