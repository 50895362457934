import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { Suspense } from 'react';
import { useParams } from 'react-router-dom';
import { useSessionsEntry } from '../Components/useSessionsEntry';
import { Sessions } from './Sessions';
import { ControlPanel } from 'common/components/Graphs/ControlPanel';
import { ScrollArea } from 'common/components/ScrollArea';
import { GraphSessionRangeProvider } from 'common/components/Graphs/Sessions/GraphSessionRangeProvider';

const HistoryAnalysisContent = () => {
  const { iccid } = useParams();

  const { data: sessionEntry } = useSessionsEntry(iccid!);

  return sessionEntry && sessionEntry.imsi ? (
    <GraphSessionRangeProvider>
      <Box sx={{ border: '1px solid #E5E5E5', borderRadius: '12px', height: '100%', pt: 2 }}>
        <ScrollArea>
          <Box
            sx={{
              pt: 2,
              pb: 2,
              px: 4,
              borderBottom: (theme) => `1px solid ${theme.palette.border}`,
            }}
          >
            <ControlPanel sliderSx={{ px: 6 }} />
          </Box>
          <Box sx={{ pt: 2 }}>
            <Sessions imsi={sessionEntry.imsi} />
          </Box>
        </ScrollArea>
      </Box>
    </GraphSessionRangeProvider>
  ) : null;
};

export const HistoryAnalysis = () => {
  return (
    <Suspense
      fallback={
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          <CircularProgress />
        </Box>
      }
    >
      <HistoryAnalysisContent />
    </Suspense>
  );
};
