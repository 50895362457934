import { useContext, useMemo } from 'react';
import { InterimsTimelineContext } from './InterimsTimelineProvider';
import { mapInterimsTimelineToUsage } from './utils/interimUtils';
import { UsageGraph } from '../common/Graphs/UsageGraph';
import { createZeroUsageInterimRenderer } from './utils/graphPlugins';
import Box from '@mui/material/Box';
import { ZOOM_VALUES_IN_MINUTES } from './utils/range';

import CircularProgress from '@mui/material/CircularProgress';
import { FloatingGraphBox } from '../common/Graphs/components/FloatingGraphBox';
import { useInterimsTimeRange } from './hooks/useInterimsTimeRange';

export const InterimsUsageGraph = () => {
  const { selectedRange, availableRange, moveVisibleRangeTo } = useInterimsTimeRange();

  const {
    fetchingInterims,
    interimsTimeline,
    markedInterimStart,
    markedInterimStop,
    selectInterimById,
    unselectInterim,
    markSelectedInterimAsDefault,
  } = useContext(InterimsTimelineContext);

  const usageDatasets = useMemo(() => {
    return mapInterimsTimelineToUsage(interimsTimeline);
  }, [interimsTimeline]);

  const zeroUsageInterimRendererPlugin = useMemo(
    () => createZeroUsageInterimRenderer(usageDatasets),
    [usageDatasets],
  );

  return (
    <Box sx={{ position: 'relative', height: '143px' }}>
      <UsageGraph
        highlightFrom={markedInterimStart}
        highlightTo={markedInterimStop}
        zoomBoundaryStart={availableRange.from}
        zoomBoundaryStop={availableRange.to}
        entries={usageDatasets}
        selectedRangeStart={selectedRange.from}
        selectedRangeStop={selectedRange.to}
        onClick={() => {
          markSelectedInterimAsDefault();
        }}
        onZoomComplete={([min, max]) => {
          moveVisibleRangeTo(min, max);
        }}
        tooltip={(_positionX, value) => {
          if (value === null) {
            unselectInterim();
          } else {
            const interimId = interimsTimeline[value]?.id;
            selectInterimById(interimId);
          }
        }}
        plugins={[zeroUsageInterimRendererPlugin]}
        maxZoomInMS={ZOOM_VALUES_IN_MINUTES.DAY * 60 * 1000}
        onPan={moveVisibleRangeTo}
      />
      {fetchingInterims ? (
        <FloatingGraphBox>
          <CircularProgress />
        </FloatingGraphBox>
      ) : null}
    </Box>
  );
};
