import { authFetch } from '../../../auth/utils';
import saveAs from 'file-saver';

export const uploadRSimManufacturingFile = async (file: File) => {
  const data = new FormData();
  data.append('file', file, file.name);
  const response = await authFetch(
    `${process.env.REACT_APP_SIM_INVENTORY_RSIMS_PROFILE_API}/import`,
    {
      method: 'POST',
      body: data,
    },
  );

  if (response.ok) {
    return await response.text().then((text) => {
      return JSON.parse(text);
    });
  }

  if (!response.ok) {
    await response.text().then((text) => {
      throw new Error(text);
    });
  }

  return response;
};

export const downloadOutputForRSimImport = async (id: string, filename?: string) => {
  const response = await downloadFile(
    `${process.env.REACT_APP_SIM_INVENTORY_RSIMS_PROFILE_API}/import/${id}`,
    filename,
  );

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  return response;
};

const downloadFile = async (filePath: string, originalFilename?: string) => {
  const fileResponse = await authFetch(filePath);
  const blob = await fileResponse.blob();

  saveAs(
    blob,
    `${originalFilename?.split('.')[0]}-results-${new Date().toISOString().split('.')[0]}`,
    {
      autoBom: false,
    },
  );

  return fileResponse;
};
