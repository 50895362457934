import { Components } from '@mui/material';

export const MuiAutocomplete: Components['MuiAutocomplete'] = {
  styleOverrides: {
    root: {
      '& .MuiAutocomplete-inputRoot': {
        padding: '5px 65px 5px 9px !important',
      },
      '& .MuiInputBase-input': {
        paddingLeft: '3px !important',
      }
    },
    paper: {
      '&:has(.MuiAutocomplete-listbox:empty)': {
        display: 'none',
      },
    },
  },
};
