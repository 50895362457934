import { useSuspenseQuery, UseSuspenseQueryResult } from '@tanstack/react-query';
import { usePageReset } from './usePageReset';
import { usePaginationWithUrlParams } from './usePaginationWithUrlParams';

interface IPagination {
  page: number;
  rowsPerPage: number;
  setPage: (page: number) => void;
  setRowsPerPage: (rowsPerPage: number) => void;
}

type PaginatedQueryContext = {
  signal?: AbortSignal;
  page: number;
  rowsPerPage: number;
};

type PaginatedFunction = (paginatedQueryContext: PaginatedQueryContext) => void;

export const usePaginatedQuery = <TQueryResults = unknown, TQueryKeys = unknown>(
  queryKey: TQueryKeys[],
  queryFn: PaginatedFunction,
): {
  pagination: IPagination;
  queryResult: UseSuspenseQueryResult<TQueryResults>;
} => {
  const { page, setPage, rowsPerPage, setRowsPerPage } = usePaginationWithUrlParams();

  const queryResult = useSuspenseQuery({
    queryKey: [...queryKey, page, rowsPerPage],
    queryFn: ({ signal }) => queryFn({ page, rowsPerPage, signal }),
  });

  usePageReset([...queryKey, rowsPerPage]);

  return {
    pagination: {
      page,
      rowsPerPage,
      setPage,
      setRowsPerPage,
    },
    queryResult: queryResult as unknown as UseSuspenseQueryResult<TQueryResults, Error>,
  };
};
