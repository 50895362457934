import Stack from '@mui/material/Stack';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Box from '@mui/material/Box';
import { LastMonthTimeRangeSlider } from '../TimeRangeSlider/LastMonthTimeRangeSlider';
import { useMemo } from 'react';
import { DateRangeTimePicker } from '../Inputs/DateTimeRangePickerMui/DateRangeTimePicker';
import { ToggleButton } from '../Inputs/ToggleGroup/ToggleButton';
import { changeRangeBaseOnDays } from './utils/utils';
import { differenceInMinutes } from 'date-fns';
import { RefetchQueryButton } from '../Queries/RefetchQueryButton';
import { SxProps, Theme } from '@mui/material';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { useTranslation } from 'react-i18next';
import { useGraphSessionRange } from './Sessions/hooks/useGraphSessionRange';
import { LastHourButton } from './Sessions/components/LastHourButton';
import { basicErrorHandling } from '../Inputs/DateRangePickerMui/DateRangePickerMui.util';

type ControlPanelProps = {
  sliderSx?: SxProps<Theme>;
};

const ZOOM_VALUES_IN_MINUTES = {
  DAY: 24 * 60,
  THREE_DAYS: 3 * 24 * 60,
  SEVEN_DAYS: 7 * 24 * 60,
  THIRTY_DAYS: 30 * 24 * 60,
};

export const ControlPanel: React.FC<ControlPanelProps> = ({ sliderSx }) => {
  const { validRange, visibleRange, selectRange } = useGraphSessionRange();

  const timestampsArrays: [number, number] = useMemo(() => {
    const { from, to } = visibleRange;
    return [from.getTime(), to.getTime()];
  }, [visibleRange]);

  const zoom = differenceInMinutes(visibleRange.from, visibleRange.to);
  const { t } = useTranslation();

  return (
    <Stack spacing={2}>
      <Stack direction="row">
        <DateRangeTimePicker
          onChange={(value) => {
            if (value) {
              //@ts-ignore
              selectRange(new Date(value[0]!), new Date(value[1]!));
            }
          }}
          value={[visibleRange.from, visibleRange.to]}
          minDate={validRange.from}
          disableFuture={true}
          onError={(err) => {
            const range = basicErrorHandling(
              [visibleRange.from, visibleRange.to],
              err,
              validRange.from,
              validRange.to,
            );

            if (range[0] && range[1]) {
              selectRange(range[0], range[1]);
            }
          }}
        />
        <Stack
          direction="row"
          sx={{
            marginLeft: 'auto',
          }}
          spacing={4}
          alignItems="center"
          justifyContent="center"
        >
          <RefetchQueryButton queryKey={['SessionHistory']} variant="tertiary" />
          <Divider orientation="vertical" flexItem />
          <Typography variant="text4" color="text.secondary">
            {t('common.zoom')}
          </Typography>
          <ToggleButtonGroup
            color="primary"
            value={zoom}
            exclusive
            onChange={(_event, value) => {
              if (!value) {
                return;
              }

              const days = value / ZOOM_VALUES_IN_MINUTES.DAY;
              const newDateRange = changeRangeBaseOnDays(
                { min: visibleRange.from, max: visibleRange.to },
                days,
                {
                  min: validRange.from,
                  max: validRange.to,
                },
              );
              selectRange(newDateRange.min, newDateRange.max);
            }}
            aria-label="Time range"
          >
            <ToggleButton value={ZOOM_VALUES_IN_MINUTES.THIRTY_DAYS}>30 days</ToggleButton>
            <ToggleButton value={ZOOM_VALUES_IN_MINUTES.SEVEN_DAYS}>7 days</ToggleButton>
            <ToggleButton value={ZOOM_VALUES_IN_MINUTES.THREE_DAYS}>3 days</ToggleButton>
            <ToggleButton value={ZOOM_VALUES_IN_MINUTES.DAY}>1 day</ToggleButton>
          </ToggleButtonGroup>
          <LastHourButton />
        </Stack>
      </Stack>
      <Box sx={sliderSx}>
        <LastMonthTimeRangeSlider
          value={timestampsArrays}
          onChange={(values) => {
            const [min, max] = values;
            selectRange(new Date(min), new Date(max));
          }}
        />
      </Box>
    </Stack>
  );
};
