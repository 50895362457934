import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import React, { useState } from 'react';
import {
  fetchSimInventoryPagination,
  MaxSimProfilesCountError,
  ReportAlreadyScheduledError,
} from 'simInventory/SimInventoryApi/simInventoryApi';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Trans, useTranslation } from 'react-i18next';
import { AuthError } from 'auth/utils';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  checkIsLargeReport,
  downloadSimProfilesReportWithBackgroundSupport,
} from './api/simProfileReportsApi';
import { useAtomValue } from 'jotai';
import { simFiltersAtom, simSortingAtomWithStorage } from 'atoms/filters/sim';
import { LargeExportPrompt } from 'common/components/Export/LargeExportPrompt';
import { ExportCardsSnackbars } from 'common/components/Export/ExportCardsSnackbars';
import { getCustomFieldValuesForQueryKey } from 'common/components/Filters/CustomFieldsFilter/customFields.utils';
import { useDebounce } from 'usehooks-ts';
import { getSearchIds } from '../Components/Inputs/SimIdSearchInput.util';

type ExportError = {
  title: string;
  description: string[];
};

export const SimProfileDownloadReportsExtendedFiltersButtonLargeExport: React.FC<{
  disabled?: boolean;
}> = ({ disabled }) => {
  const filters = useAtomValue(simFiltersAtom);
  const { tags, searchText, chosenIdType, customFields, ...otherFilters } = filters;
  const debouncedSearchText = useDebounce(searchText || '', 500);
  const minLengthText = debouncedSearchText.length < 3 ? '' : debouncedSearchText;

  const sorting = useAtomValue(simSortingAtomWithStorage);

  const [confirmationRequire, setConfirmationRequire] = useState(false);
  const [largeExport, setLargeExport] = useState(false);

  const query = useQuery({
    queryKey: [
      'SimInventoryConfigurable',
      minLengthText,
      chosenIdType,
      sorting,
      ...Object.values(otherFilters),
      getCustomFieldValuesForQueryKey(customFields),
      tags,
    ],
    queryFn: async ({ signal }) => {
      const data = await fetchSimInventoryPagination(
        0,
        100,
        {
          searchText: getSearchIds(minLengthText),
          chosenIdType: chosenIdType,
          tags,
          customFields,
          ...otherFilters,
        },
        signal,
      );
      return data.totalNumberOfItems || 0;
    },
  });

  const { mutate, isPending } = useMutation({
    mutationKey: [
      'sim-inventory',
      'reports',
      'download',
      {
        searchText: getSearchIds(minLengthText),
        chosenIdType: chosenIdType,
        tags,
        customFields,
        ...otherFilters,
      },
      sorting,
    ],
    mutationFn: async () => {
      await downloadSimProfilesReportWithBackgroundSupport(
        {
          searchText: getSearchIds(minLengthText),
          chosenIdType: chosenIdType,
          tags,
          customFields,
          ...otherFilters,
        },
        sorting,
      );
    },
    retry: 0,
    onError: (error) => {
      if (error instanceof AuthError) {
        throw error;
      }

      if (error instanceof MaxSimProfilesCountError) {
        setExportError({
          title: t('common.exportFailed'),
          description: [
            t('simInventory.maxSimProfilesCountError', {
              maxSimProfiles: error.maxSimProfilesCount,
            }),
          ],
        });
      } else if (error instanceof ReportAlreadyScheduledError) {
        setExportError({
          title: t('common.exportFailed'),
          description: [
            t('simInventory.anotherBackgroundExportIsStillInProgress'),
            t('simInventory.pleaseTryAgainLater'),
          ],
        });
      } else {
        setExportError({
          title: t('common.requestFailed'),
          description: [t('common.somethingWrong')],
        });
      }
    },
    onSuccess: () => {
      setDownloadingSnackbarOpened(true);
    },
  });

  const exportReport = async () => {
    const largeExport = await checkIsLargeReport({
      searchText: getSearchIds(minLengthText),
      chosenIdType: chosenIdType,
      tags,
      customFields,
      ...otherFilters,
    });

    setLargeExport(largeExport);
    setConfirmationRequire(largeExport);
    if (!largeExport) {
      mutate();
    }
  };

  const [downloadingSnackbarOpened, setDownloadingSnackbarOpened] = useState(false);

  const { t } = useTranslation();

  const [exportError, setExportError] = useState<ExportError | null>(null);

  return (
    <>
      <Stack>
        <Button
          startIcon={<FileDownloadIcon />}
          disabled={disabled || isPending}
          color="secondary"
          onClick={exportReport}
        >
          {t('common.export')}
        </Button>
        <LargeExportPrompt
          open={confirmationRequire}
          onConfirm={async () => {
            mutate();
            setConfirmationRequire(false);
          }}
          onClose={() => {
            setConfirmationRequire(false);
            setLargeExport(false);
          }}
          title={t('simInventory.simInventoryExport')}
          message={
            <>
              <Trans
                i18nKey={'simInventory.requestOfNSimsExport'}
                values={{ numberOfCards: query.data || 0 }}
                components={{ 1: <strong /> }}
              />
              <div>{t('common.emailWithDownloadLink')}</div>
            </>
          }
        />
        <ExportCardsSnackbars
          opened={downloadingSnackbarOpened}
          largeExportSuccessTitle={t('simInventory.exportingNSims', {
            numberOfCards: query.data || 0,
          })}
          largeExport={largeExport}
          setExportError={setExportError}
          setDownloadingSnackbarOpened={setDownloadingSnackbarOpened}
          setLargeExport={setLargeExport}
          exportError={exportError}
        />
      </Stack>
    </>
  );
};
