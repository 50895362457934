import React, { PropsWithChildren } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { typography } from './typography';
import { palette } from './palette';
import { components } from './components';
import { mixins } from './mixins';

let theme = createTheme({
  //@ts-ignore
  mixins,
  palette,
  //@ts-ignore
  typography,
  spacing: 4,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1350,
      xl: 1700,
    },
  },
});

const shadows = theme.shadows;
//Temporary solution wa
shadows[4] = '0px 0px 5px 0px #00000014, 0px 1px 2px 0px #00000014';

theme = createTheme(theme, { components, shadows });

export const defaultTheme = theme;

export const DefaultThemeProvider: React.FC<PropsWithChildren> = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);
