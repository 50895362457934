import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { AutocompleteSelect } from 'common/components/Inputs/AutocompleteSelect/AutocompleteSelect';
import { SimInventoryCan } from 'permissions/PermissionProvider';

import React from 'react';
import { EditDialog } from 'common/components/Dialog/EditDialog';
import { useTagsAutocompleteSelect } from 'tags/hooks/useTagsAutocompleteSelect';
import Grid from '@mui/material/Grid2';
import { styled } from '@mui/material';
import { useRefetchQuery } from 'common/useRefetchQuery';
import { Actions, Subjects } from 'permissions/ability';
import { useAbility } from 'permissions/hooks/useAbility';
import { useSimDetails } from 'simInventory/hooks/useSimDetails';

export type SimProductsEditDialogProps = {
  open: boolean;
  onClose: () => void;
  data: any;
  slotProps: {
    onTagsUpdate?: (iccid: string, tags: any) => void;
    queryKey?: string;
  };
};

const GridLabelStyled = styled(Grid)({
  display: 'flex',
  alignItems: 'center',
});

export const SimProductsEditDialog = React.memo(
  ({ open, data, onClose, slotProps: { onTagsUpdate, queryKey } }: SimProductsEditDialogProps) => {
    const ability = useAbility();

    const {
      isLoading,
      tags,
      assignedSimTags,

      addNewTags,
      selectTags,
      save: saveTags,
      refresh: refreshTags,

      changed,
      setChanges,
      searchValue,
      setSearchValue,
      errors,
      validateTagLength,
    } = useTagsAutocompleteSelect({
      id: data?.iccid,
      onTagsUpdate,
      enabled: ability.can(Actions.edit, Subjects.tags),
    });

    const { refetch: refetchSimData } = useSimDetails(data?.iccid!);

    const refetchByQuery = useRefetchQuery([queryKey || '']);

    const refetch = () => {
      if (queryKey) {
        refetchByQuery();
      }
      refetchSimData();
    };

    return (
      <EditDialog
        isLoading={isLoading}
        subtitle={`SIM ${data?.iccid}`}
        unsavedChanges={changed}
        onApplyChangesClick={async () => {
          if (ability.can(Actions.edit, Subjects.tags)) {
            await saveTags();
            refetch();
          }

          onClose();
        }}
        onCancelClick={onClose}
        onClearChangesClick={() => {
          refreshTags();
          setChanges(false);
        }}
        open={open}
      >
        <Stack
          direction="row"
          gap={12}
          alignItems="center"
          sx={{ borderLeft: (theme) => `1px solid ${theme.palette.text.tertiary}` }}
        >
          <Grid container columnSpacing={10} rowSpacing={4} flexGrow={1}>
            <SimInventoryCan I={Actions.edit} a={Subjects.tags}>
              <GridLabelStyled size={4}>
                <Typography variant="text2" color="text.secondary" sx={{ ml: 4 }}>
                  Tags
                </Typography>
              </GridLabelStyled>
              <Grid size={8}>
                <AutocompleteSelect
                  errors={errors}
                  values={assignedSimTags!}
                  options={tags || []}
                  onChange={(values) => {
                    if (values.length <= 20) {
                      selectTags(values.map((value) => value.id));
                      setChanges(true);
                    }
                  }}
                  onAdd={(newValues) => {
                    addNewTags(newValues);
                  }}
                  inputValue={searchValue}
                  onTextInputChange={(value) => {
                    if (validateTagLength(value)) {
                      setSearchValue(value);
                    }
                  }}
                  testId="tags-multiselect"
                />
              </Grid>
            </SimInventoryCan>
          </Grid>
        </Stack>
      </EditDialog>
    );
  },
);
