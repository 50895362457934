import { isAfter, isBefore, isEqual } from 'date-fns';

export const toLocaleDateTime = (date?: Date | null, nullDefault: string = '') => {
  if (!date) {
    return nullDefault;
  }

  return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
};

export const isAfterOrEqual: typeof isAfter = (date, dateToCompare) => {
  return isAfter(date, dateToCompare) || isEqual(date, dateToCompare);
};

export const isBeforeOrEqual: typeof isBefore = (date, dateToCompare) => {
  return isBefore(date, dateToCompare) || isEqual(date, dateToCompare);
};

export const isBetween = (
  date: Date,
  from: Date,
  to: Date,
  openLeft: boolean = true,
  openRight: boolean = false
) => {
  const isAfterComparer = openLeft ? isAfterOrEqual : isAfter;
  const isBeforeComparer = openRight ? isBeforeOrEqual : isBefore;

  return isAfterComparer(date, from) && isBeforeComparer(date, to);
};
