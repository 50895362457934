import {
  AuditTrailsPermissions,
  BulkProvisioningPermissions,
  SimInventoryPermissions,
} from 'permissions/Permissions.model';
import { authFetch } from '../../auth/utils';

export const fetchSimInventoryPermissions = async (signal?: AbortSignal) => {
  const permissionsResponse = await authFetch(
    `${process.env.REACT_APP_SIM_INVENTORY_PERMISSIONS_API}`,
    { signal },
  );

  const permissions: [{ name: SimInventoryPermissions }] = await permissionsResponse.json();

  return permissions.map((item) => item.name);
};

export const fetchBulkProvisioninPermissions = async (signal?: AbortSignal) => {
  const permissionsResponse = await authFetch(
    `${process.env.REACT_APP_BULK_PROVISIONING_PERMISSIONS_API}`,
    { signal },
  );

  const permissions: [{ name: BulkProvisioningPermissions }] = await permissionsResponse.json();

  return permissions.map((item) => item.name);
};

export const fetchAuditTrailsPermissions = async (signal?: AbortSignal) => {
  const permissionsResponse = await authFetch(
    `${process.env.REACT_APP_AUDIT_TRAIL_PERMISSIONS_API}`,
    { signal },
  );

  const permissions: [{ name: AuditTrailsPermissions }] = await permissionsResponse.json();

  return permissions.map((item) => item.name);
};
