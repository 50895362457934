import { authFetch } from 'auth/utils';
import { Account, AccountData } from './accountsApi.types';

export const fetchAccounts = async (name?: string, signal?: AbortSignal) => {
  const urlQuery = new URLSearchParams({
    ...(name ? { Search: name } : {}),
    PageSize: '100',
  });
  const accountsResponse = await authFetch(
    `${process.env.REACT_APP_SIM_INVENTORY_ACCOUNTS_API}?${urlQuery.toString()}`,
    { signal },
  );

  const accounts: AccountData = await accountsResponse.json();
  return accounts.items;
};

export const fetchAccount = async (id?: string, signal?: AbortSignal) => {
  const accountResponse = await authFetch(
    `${process.env.REACT_APP_SIM_INVENTORY_ACCOUNTS_API}/${id}`,
    { signal },
  );

  const account: Account = await accountResponse.json();
  return account;
};
