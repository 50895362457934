import { Components } from '@mui/material';
import { palette } from 'theme/palette';

import inputCloseImage from 'assets/images/inputClose.svg';

export const MuiTextField: Components['MuiTextField'] = {
  styleOverrides: {
    root: () => {
      return {
        '& fieldset': {
          borderRadius: '12px',
        },
        '& .MuiInputBase-adornedStart': {
          padding: 0,
        },
        '& label.Mui-focused': {
          color: palette.grey[600],
        },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: palette.text.tertiary,
          },

          '&:hover fieldset': {
            borderColor: palette.text.secondary,
          },
          '&.Mui-focused fieldset': {
            borderColor: palette.grey[600],
          },
        },
        '& .MuiInputBase-input::-webkit-search-cancel-button': {
          appearance: 'none',
          height: '12px',
          width: '12px',
          backgroundImage: `url(${inputCloseImage})`,
          m: 0,
        },
      };
    },
  },
};
