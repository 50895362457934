import { BoxTwoValueCell } from 'common/components/Table/BoxTwoValueCell';
import { TwoValueCellProps } from 'common/components/Table/TwoValueCell.interface';

export type SessionHistoryCellProps = Pick<
  TwoValueCellProps,
  'primaryText' | 'secondaryText' | 'sx'
>;

export const SessionHistoryCell: React.FC<SessionHistoryCellProps> = ({
  primaryText,
  secondaryText,
  sx,
}) => {
  return (
    <BoxTwoValueCell
      primaryText={primaryText}
      secondaryText={secondaryText}
      primaryVariant="text1"
      primaryColor="text.primary"
      secondaryVariant="text5"
      secondaryColor="text.secondary"
      sx={sx}
    />
  );
};
