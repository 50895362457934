import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { AdditionalMenu } from '../../AdditionalMenu';
import { Tooltip } from '../../Tooltip/Tooltip';
import MuiSwitch from '@mui/material/Switch';
import { ReactComponent as RevertIcon } from 'assets/images/revert.svg';
import { useTranslation } from 'react-i18next';
import { ScrollArea } from '../../ScrollArea';
import { TableSettingsItem } from './TableSettingsItem';
import {
  ColumnConfiguration,
  TABLE_VARIANT,
  TableVariant,
} from '../../../../simInventory/SimInventoryApi/simInventoryTableConfigurationApi.interface';

type TableSettingsProps = {
  columnConfig: ColumnConfiguration[];
  handleWidthChange: (columnName: string, width: number | 'auto', isCustomField: boolean) => void;
  handleVariantChange: (isChecked: boolean) => void;
  handleRevert: () => void;
  handleDrop: (oldIndex: number, newIndex: number) => void;
  variant: TableVariant;
  idLabelMap: Record<string, string>;
};

export const TableSettings: React.FC<TableSettingsProps> = ({
  columnConfig,
  handleWidthChange,
  handleVariantChange,
  handleRevert,
  handleDrop,
  variant,
  idLabelMap,
}) => {
  const { t } = useTranslation();
  return (
    <Box data-testid="configurable-table-settings-button">
      <AdditionalMenu
        children={({ onClose }) => (
          <Box
            className="table-menu-box"
            sx={{ width: '256px' }}
            data-testid="configurable-table-settings-popup"
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
              <Box style={{ maxHeight: '755px', height: '80vh' }}>
                <ScrollArea>
                  {columnConfig.map((column, index) => {
                    return (
                      <React.Fragment key={column.name + index}>
                        <TableSettingsItem
                          index={index}
                          column={column}
                          handleWidthChange={handleWidthChange}
                          handleDrop={handleDrop}
                          variant={variant}
                          idLabelMap={idLabelMap}
                        />
                      </React.Fragment>
                    );
                  })}
                </ScrollArea>
              </Box>
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: '3fr 1fr',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  padding: '0 4px',
                  '& .revert-icon:hover': { backgroundColor: 'rgba(23,118,218,0.04)' },
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="text1" sx={{ mt: 0.5 }}>
                    {t('common.combinedRows')}
                  </Typography>
                  <MuiSwitch
                    onChange={(e) => {
                      handleVariantChange(e.target.checked);
                      onClose();
                    }}
                    checked={variant === TABLE_VARIANT.Combined}
                    data-testid="configurable-table-settings-variant-switch"
                  />
                </Box>
                <Box
                  className="revert-icon"
                  sx={{
                    width: '30px',
                    height: '30px',
                    borderRadius: '50px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    justifySelf: 'end',
                  }}
                >
                  <Tooltip title={t('common.revertToDefault')}>
                    <RevertIcon
                      style={{ cursor: 'pointer' }}
                      width="21px"
                      height="18px"
                      role="img"
                      data-testid="revert-icon"
                      onClick={() => {
                        handleRevert();
                        onClose();
                      }}
                    />
                  </Tooltip>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      ></AdditionalMenu>
    </Box>
  );
};
