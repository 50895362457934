import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import React from 'react';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

type SectionGridRowProps = {
  title?: string;
  children: React.ReactElement;
  sx?: SxProps<Theme>;
  hideTopBorder?: boolean;
  'data-testid'?: string;
};

export const SectionGridRow: React.FC<SectionGridRowProps> = ({
  title,
  children,
  sx,
  hideTopBorder,
  ...props
}) => {
  return (
    <Grid
      {...props}
      container
      direction="column"
      sx={{
        pt: 3,
        pl: !hideTopBorder ? 3 : 0,
        borderTop: ({ palette }) => (!hideTopBorder ? `1px solid ${palette.border}` : ''),
        ...sx,
      }}
    >
      {title && (
        <Grid>
          <Typography variant="h4">{title}</Typography>
        </Grid>
      )}
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        sx={{
          pb: !hideTopBorder ? 3 : 0,
        }}
      >
        {children}
      </Grid>
    </Grid>
  );
};
