import React, { createContext, useMemo } from 'react';
import { createContextualCan } from '@casl/react';

import { AnyAbility, defineAbility } from '@casl/ability';
import { useQuery } from '@tanstack/react-query';
import {
  fetchAuditTrailsPermissions,
  fetchBulkProvisioninPermissions,
  fetchSimInventoryPermissions,
} from './api/permissionsApi';
import {
  createAuditTrailsPermissionsFromRules,
  createBulkProvisioningPermissionsFromRules,
  createSimInventoryPermissionsFromRules,
} from './ability';
import {
  AuditTrailsPermissions,
  BulkProvisioningPermissions,
  SimInventoryPermissions,
} from './Permissions.model';

export const AbilityContext = createContext<AnyAbility>({} as AnyAbility);

export const AbilityContextProvider = ({ children }: { children?: React.ReactNode }) => {
  const { data: permissions, isFetched } = useQuery({
    queryKey: ['permissions'],
    queryFn: async () => {
      let simInventoryPermissions: SimInventoryPermissions[] = [];
      try {
        simInventoryPermissions = await fetchSimInventoryPermissions();
      } catch (err) {
        simInventoryPermissions = [];
      }

      let bulkPermissions: BulkProvisioningPermissions[] = [];
      try {
        bulkPermissions = await fetchBulkProvisioninPermissions();
      } catch (err) {
        bulkPermissions = [];
      }

      let auditTrails: AuditTrailsPermissions[] = [];
      try {
        auditTrails = await fetchAuditTrailsPermissions();
      } catch (err) {
        auditTrails = [];
      }

      return {
        simInventoryPermissions,
        bulkPermissions,
        auditTrails,
      };
    },
  });

  const ability = useMemo(() => {
    if (permissions) {
      //@ts-ignore
      return defineAbility((can) => {
        createSimInventoryPermissionsFromRules(permissions.simInventoryPermissions, can);
        createBulkProvisioningPermissionsFromRules(permissions.bulkPermissions, can);
        createAuditTrailsPermissionsFromRules(permissions.auditTrails, can);
      });
    } else {
      return defineAbility(() => {});
    }
  }, [permissions]);

  return isFetched ? (
    <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>
  ) : null;
};

export const SimInventoryCan = createContextualCan(AbilityContext.Consumer);
