import { Outlet, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Paper from '@mui/material/Paper';
import { NoAccessBoundary } from '../NoAccessBoundary';

import { mainPaddingBottom, paperStyle, menuWidthSmall } from './AppLayout.styles';

type TabAppLayoutProps = {
  children: React.ReactNode | React.ReactNode[];
  firstTabHighlited?: boolean;
};

export const TabAppLayout: React.FC<TabAppLayoutProps> = ({
  children,
  firstTabHighlited = false,
}) => {
  const location = useLocation();

  return (
    <>
      <Box
        component="main"
        sx={{
          marginLeft: menuWidthSmall,
          paddingBottom: mainPaddingBottom,
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            marginLeft: '20px',
            marginRight: '22px',
            height: '100%',
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box sx={{ zIndex: 1 }}>
            <Tabs value={location.pathname}>{children}</Tabs>
          </Box>

          <NoAccessBoundary>
            <Paper
              sx={{
                ...paperStyle,
                borderTopLeftRadius: firstTabHighlited ? 0 : '8px',
              }}
              elevation={0}
            >
              <div style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
                <Outlet />
              </div>
            </Paper>
          </NoAccessBoundary>
        </Box>
      </Box>
    </>
  );
};
