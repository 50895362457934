import Box from '@mui/material/Box';
import React from 'react';
import { QueryKey, useIsFetching } from '@tanstack/react-query';

type SuspenseLoadingViewProps = {
  children: React.ReactNode;
  loadFallback: React.ReactElement;
  queryKey?: QueryKey;
  fullWidth?: boolean;
};

export const SuspenseLoadingView: React.FC<SuspenseLoadingViewProps> = ({
  children,
  loadFallback,
  queryKey,
  fullWidth = false,
}) => {
  const numberOfFetches = useIsFetching({
    queryKey,
    predicate: (query) => {
      if (Array.isArray(query.queryKey)) {
        return !query.queryKey?.some(
          (key: string) => key === 'Pagination' || key === 'sims' || key === 'Enability',
        );
      }

      return true;
    },
  });

  return (
    <React.Suspense fallback={loadFallback}>
      {numberOfFetches > 0 ? React.cloneElement(loadFallback) : null}
      <Box
        sx={{
          display: numberOfFetches === 0 ? 'block' : 'none',
          height: numberOfFetches === 0 ? '100%' : '0',
          ...(fullWidth ? { width: '100%' } : {}),
        }}
      >
        {' '}
        {children}
      </Box>
    </React.Suspense>
  );
};
