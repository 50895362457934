import { isEmpty, uniq } from 'lodash';

export const addPrimitiveHintValue = <T extends string | number>(value: T, currentHints: T[]) => {
  if (!value || currentHints.includes(value)) {
    return currentHints;
  }

  return [...currentHints, value];
};

export const addMultipeHintsValue = <T extends string[] | number[]>(value: T, currentHints: T) => {
  if (isEmpty(value)) {
    return currentHints;
  }

  return uniq([...currentHints, ...value]);
};
