import { ErrorBoundary } from 'react-error-boundary';
import { NoAccessView } from './NoAcessView';

import { ErrorCodes } from 'common/errorCodes';

type NoAccessBoundaryProps = {
  children: React.ReactElement;
};

export const NoAccessBoundary: React.FC<NoAccessBoundaryProps> = ({ children }) => (
  <ErrorBoundary
    FallbackComponent={NoAccessView}
    onError={(error) => {
      if (error.message !== ErrorCodes.Forbidden) {
        throw error;
      }
    }}
  >
    {children}
  </ErrorBoundary>
);
