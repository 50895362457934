export const cleanFalsyEntries = (value: Record<any, any>) => {
  const cleanedValue = { ...value };
  Object.keys(cleanedValue).forEach((key) => {
    const value = cleanedValue[key as keyof typeof cleanedValue];
    if (value !== 0 && !cleanedValue[key as keyof typeof cleanedValue]) {
      delete cleanedValue[key as keyof typeof cleanedValue];
    }
  });

  return cleanedValue;
};
