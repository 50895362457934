import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { getHuminizeDurationFunction } from 'i18n/dateLocale';

type DurationCellProps = {
  duration?: number;
};

export const DurationBox: React.FC<DurationCellProps> = ({ duration }) => {
  const humanize = getHuminizeDurationFunction();
  return (
    <Box>
      <Box sx={{ textAlign: 'right' }}>
        <Typography variant="text1" color="text.primary" data-testid="primaryText">
          {duration ? humanize(duration) : ''}
        </Typography>
      </Box>
    </Box>
  );
};
