import { Components } from '@mui/material';
import { fontFamily } from 'theme/typography';

export const MuiDialogActions: Components['MuiDialogActions'] = {
  styleOverrides: {
    root: {
      fontFamily,
      padding: '8px 16px 16px 16px',
    },
  },
};
