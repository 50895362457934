import MuiTooltip, { TooltipProps as MuiTooltipProps } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import React from 'react';
import { styled } from '@mui/material';

export type TooltipVariant = 'light' | 'dark';

const StyledMuiTooltip = styled(({ className, ...other }: MuiTooltipProps) => {
  return <MuiTooltip classes={{ popper: className }} {...other} />;
})<{ variant: TooltipVariant }>(({ variant, theme }) => {
  let sharedProps = {
    padding: 8,
    paddingTop: '4px',
    paddingBottom: '3px',
    margin: 0,
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.08), 0px 0px 5px rgba(0, 0, 0, 0.08)',
    borderRadius: '12px',
    color: theme.palette.text.primary,
    textTransform: 'capitalize',

    '& .MuiTooltip-arrow::before': {
      backgroundColor: theme.palette.background.paper,
      boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.08), 0px 0px 5px rgba(0, 0, 0, 0.08)',
    },
  };

  if (variant === 'dark') {
    sharedProps = {
      ...sharedProps,
      //@ts-ignore
      backgroundColor: theme.palette.text.secondary,
      color: theme.palette.text.white,
      boxShadow: 'none',
      opacity: 0.9,
      '& .MuiTooltip-arrow::before': {
        backgroundColor: theme.palette.text.secondary,
        boxShadow: 'none',
      },
    };
  }

  return {
    '& .MuiTooltip-tooltip': sharedProps,
  };
});

const DescriptionRows = styled('div')<{ variant: TooltipVariant }>(({ variant, theme }) => {
  let sharedProps = {
    color: theme.palette.text.secondary,
  };

  if (variant === 'dark') {
    sharedProps = {
      ...sharedProps,
      color: theme.palette.text.white,
    };
  }

  return sharedProps;
});
type TooltipProps = Pick<MuiTooltipProps, 'children'> & {
  title: string;
  description?: string | string[];
  component?: React.ReactNode;
  showArrow?: boolean;
  enterDelay?: number;
  enterTouchDelay?: number;
  enterNextDelay?: number;
  placement?: 'top' | 'bottom' | 'left' | 'right';
  variant?: TooltipVariant;
  open?: boolean;
};

export const Tooltip: React.FC<TooltipProps> = React.memo(
  ({
    children,
    title,
    description,
    component,
    showArrow = false,
    enterDelay = 100,
    enterTouchDelay = 700,
    enterNextDelay = 0,
    variant = 'light',
    placement = 'top',
    open,
  }) => {
    let descriptionRows: string[] = [];

    if (Array.isArray(description)) {
      descriptionRows = description;
    } else if (typeof description === 'string') {
      descriptionRows = [description];
    }
    return (
      <StyledMuiTooltip
        variant={variant}
        arrow={showArrow}
        placement={placement}
        enterDelay={enterDelay}
        enterTouchDelay={enterTouchDelay}
        enterNextDelay={enterNextDelay}
        key={`tooltip-${title}`}
        open={open}
        title={
          <>
            {title && (
              <Typography variant="text3" component="div">
                {title}
              </Typography>
            )}

            <DescriptionRows variant={variant}>
              {descriptionRows.map((text, index) => (
                <Typography key={index} variant="text5" component="div">
                  {text}
                </Typography>
              ))}
            </DescriptionRows>

            {component}
          </>
        }
      >
        {children}
      </StyledMuiTooltip>
    );
  },
);
