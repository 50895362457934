import MenuItem, { MenuItemProps } from '@mui/material/MenuItem';

type AdditionalMenuItemProps = Pick<MenuItemProps, 'onClick' | 'children' | 'disabled'>;

export const AdditionalMenuItem: React.FC<AdditionalMenuItemProps> = ({
  children,
  disabled,
  onClick
}) => {
  return (
    <MenuItem onClick={onClick} disabled={disabled}>
      {children}
    </MenuItem>
  );
};
