import { ReactComponent as CommonErrorImage } from 'assets/images/commonErrorBG.svg';

import { centerContentHorizontalAndVertical, fillParentHeight } from 'theme/utility/utility.styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

type ErrorFallbackProps = {
  message: string;
};

export const ErrorFallback: React.FC<ErrorFallbackProps> = ({ message }) => {
  return (
    <Box sx={{ ...centerContentHorizontalAndVertical, ...fillParentHeight }}>
      <CommonErrorImage />
      <Typography variant="h1">{message}</Typography>
    </Box>
  );
};
