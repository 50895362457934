import { BillingBadge } from 'common/components/ConfigurableTable/Components/Badges/BillingBadge';
import { BillingStatus } from 'simInventory/SimInventoryApi/simInventoryApi.interface';

export type BillingStatusCellProps = {
  billingStatus: BillingStatus;
};

export const BillingStatusCell = ({ billingStatus }: BillingStatusCellProps) => {
  return (
    <>
      <BillingBadge billingStatus={billingStatus} />
    </>
  );
};
