import { styled } from '@mui/material';
import { GridRowProps as MuiGridRowProps, GridRow as MuiGridRow } from '@mui/x-data-grid-pro';
import { StickyBorderBox } from '../Common/StickyBorderBox';

const RowRoot = styled('div')({
  position: 'relative',
});

const BorderContainer = styled('div')({
  position: 'absolute',
  left: 0,
  right: 0,
  zIndex: 99999,
  pointerEvents: 'none',
});

export const GridRow = (props: MuiGridRowProps) => {
  return (
    <RowRoot>
      <BorderContainer>
        <StickyBorderBox />
      </BorderContainer>
      <MuiGridRow {...props} />
    </RowRoot>
  );
};
