import { accountsFilterAtom } from 'atoms/filters';
import { accountsHintsAtom } from 'atoms/hints/rsim-products';
import { AccountSearchFilter } from 'common/components/Filters/Inputs/AccountSearchFilter';

export const AccountSearchInput = () => {
  return (
    <AccountSearchFilter
      accountsFilterAtom={accountsFilterAtom}
      accountsHintsAtom={accountsHintsAtom}
    />
  );
};
