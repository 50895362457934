import Box from '@mui/material/Box';

export type SessionDetailsBoxProps = {
  children: React.ReactNode;
};

export const SessionDetailsBox: React.FC<SessionDetailsBoxProps> = ({ children }) => {
  return (
    <Box
      sx={{
        pb: 3,
        borderTop: (theme) => `1px solid ${theme.palette.border}`,
        borderBottom: (theme) => `1px solid ${theme.palette.border}`,
      }}
    >
      {children}
    </Box>
  );
};
