import { SimInventoryTable } from './Components/SimInventoryTable';
import { TableSkeleton } from 'common/components/Table/TableSkeleton';
import { SuspenseLoadingView } from 'common/components/SuspenseLoadingView';
import { SimFilters } from './Filters/SimFilters';

import { ContentBox } from 'common/components/AppLayout/ContentBox';
import { TableWithFiltersLayout } from 'common/components/AppLayout/TableWithFiltersLayout';
import { TagProvider } from 'tags/TagProvider';
import { useCustomFieldsFiltersInitialization } from './Filters/hooks/useCustomFieldsFiltersInit';
import { simFiltersAtom } from 'atoms/filters/sim';
import { useFilterToUrlParser } from 'common/components/Filters/hooks/useFilterToUrlParser';
import { mapSimFiltersToURLSearchParams } from './Filters/utils/filtersToURLSearcParamsMapper';

export const SimInventoryTab = () => {
  useCustomFieldsFiltersInitialization();
  useFilterToUrlParser({
    atom: simFiltersAtom,
    mapperFunction: mapSimFiltersToURLSearchParams,
  });

  return (
    <TagProvider type="sim">
      <TableWithFiltersLayout filters={<SimFilters />}>
        <SuspenseLoadingView
          loadFallback={
            <ContentBox>
              <TableSkeleton showStartSkeleton showEndSkeleton numberOfRows={20} />
            </ContentBox>
          }
          queryKey={['SimInventoryConfigurable']}
        >
          <SimInventoryTable />
        </SuspenseLoadingView>
      </TableWithFiltersLayout>
    </TagProvider>
  );
};
