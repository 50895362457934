import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import {
  fetchAuditTrailByIccid,
  fetchFieldsAuditTrail,
  fetchUsersAuditTrail,
} from '../auditTrailApi/auditTrailApi';
import { AuditTrail, AuditTrailPagination } from '../AuditTrail.models';
import { setMinimalPromiseTime } from 'common/utils/setMinimalPromiseTime';

export const useAuditTrail = (
  iccid: string,
  userName?: string,
  dateFrom?: string | null,
  dateTo?: string | null,
  fields?: string[],
): { data: AuditTrail[]; pagination: AuditTrailPagination } => {
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(25);

  useEffect(() => {
    setPage(0);
  }, [userName, dateTo, dateFrom, fields]);

  const { data } = useSuspenseQuery({
    queryKey: ['AuditTrail', page, perPage, userName, dateFrom, dateTo, fields],
    queryFn: async () => {
      return fetchAuditTrailByIccid(iccid, page, perPage, userName, dateFrom, dateTo, fields);
    },
  });

  const onPageChange = (page: number) => {
    setPage(page);
  };

  const onRowsPerPageChange = (perPage: number) => {
    setPerPage(perPage);
  };

  return {
    data: data.items,
    pagination: {
      page,
      onPageChange,
      perPage,
      onRowsPerPageChange,
      totalNumberOfItems: data.totalCount,
    },
  };
};

export const useUsersAuditTrail = (userName?: string, iccid?: string) => {
  const { data: users } = useQuery({
    queryKey: ['UsersAuditTrail', userName, iccid],
    queryFn: () => setMinimalPromiseTime(fetchUsersAuditTrail(userName, iccid)),
  });

  return { users };
};

export const useFieldsAuditTrail = () => {
  const { data: fields } = useQuery({
    queryKey: ['FieldsAuditTrail'],
    queryFn: () => fetchFieldsAuditTrail(),
  });

  return { fields };
};
