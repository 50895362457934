import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as ExpandCustomIcon } from 'assets/images/Zooming/expandCustom.svg';
import { ReactComponent as MinusCustomIcon } from 'assets/images/Zooming/minusCustom.svg';
import { ReactComponent as PlusCustomIcon } from 'assets/images/Zooming/plusCustom.svg';
import { useInterimsTimeRange } from './hooks/useInterimsTimeRange';
import { styled } from '@mui/material';
import { GraphTooltip } from './components/GraphTooltip';
import { useTranslation } from 'react-i18next';

const ZoomingIconButton = styled(IconButton)({
  width: '36px',
  height: '22px'
});

export const InterimZoomSelect = () => {
  const { zoomIn, zoomOut, zoomExpand } = useInterimsTimeRange();
  const { t } = useTranslation();
  return (
    <Stack direction="row" alignItems="center" justifyContent="center" gap={2}>
      <Typography variant="text3" color="text.secondary">
        Zoom:
      </Typography>
      <GraphTooltip title={t('common.zoomIn')}>
        <ZoomingIconButton onClick={() => zoomIn(0.1)} aria-label="zoom in">
          <PlusCustomIcon width={16} height={16} />
        </ZoomingIconButton>
      </GraphTooltip>
      <GraphTooltip title={t('common.zoomOut')}>
        <ZoomingIconButton onClick={() => zoomOut(0.1)} aria-label="zoom out">
          <MinusCustomIcon width={16} height={16} />
        </ZoomingIconButton>
      </GraphTooltip>
      <GraphTooltip title={t('common.zoomOutMax')}>
        <ZoomingIconButton onClick={() => zoomExpand()} aria-label="max zoom out">
          <ExpandCustomIcon width={16} height={16} />
        </ZoomingIconButton>
      </GraphTooltip>
    </Stack>
  );
};
