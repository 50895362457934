import {
  CreateCustomField,
  CustomField,
  CustomFieldResponse,
  CustomFieldSelectedOption,
  EditCustomField,
} from '../entities/customFields.entity';
import { authFetch } from '../../../auth/utils';

export const fetchCustomField = async (fieldId: number, signal?: AbortSignal) => {
  const customFieldResponse = await authFetch(
    `${process.env.REACT_APP_SIM_INVENTORY_SIMS_PROFILE_API}/custom-fields/${fieldId}`,
    { signal },
  );
  const customFieldResponseData: CustomFieldResponse = await customFieldResponse.json();

  const customField: CustomField = {
    ...customFieldResponseData,
    selectionOptions: customFieldResponseData.selectionOptions || [],
  };

  return customField;
};

export const fetchCustomFields = async (signal?: AbortSignal) => {
  const customFieldsResponse = await authFetch(
    `${process.env.REACT_APP_SIM_INVENTORY_SIMS_PROFILE_API}/custom-fields`,
    { signal },
  );

  const customFieldsResponseData: CustomFieldResponse[] = await customFieldsResponse.json();

  const customFields: CustomField[] = customFieldsResponseData.map((customFieldResponse) => ({
    ...customFieldResponse,
    selectionOptions: customFieldResponse.selectionOptions || [],
  }));

  return customFields;
};

export const createCustomField = async (field: CreateCustomField, signal?: AbortSignal) => {
  return await authFetch(`${process.env.REACT_APP_SIM_INVENTORY_SIMS_PROFILE_API}/custom-fields`, {
    signal,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(field),
  });
};

export const editCustomField = async (field: EditCustomField, signal?: AbortSignal) => {
  return await authFetch(
    `${process.env.REACT_APP_SIM_INVENTORY_SIMS_PROFILE_API}/custom-fields/${field.id}`,
    {
      signal,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ label: field.label, enabled: field.enabled }),
    },
  );
};

export const deleteCustomField = async (fieldId: number, signal?: AbortSignal) => {
  return await authFetch(
    `${process.env.REACT_APP_SIM_INVENTORY_SIMS_PROFILE_API}/custom-fields/${fieldId}`,
    {
      signal,
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
};

export const saveSelectionOptions = async (
  fieldId: number,
  options: CustomFieldSelectedOption[],
  signal?: AbortSignal,
) => {
  return await authFetch(
    `${process.env.REACT_APP_SIM_INVENTORY_SIMS_PROFILE_API}/custom-fields/${fieldId}/selection-options`,
    {
      signal,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(options),
    },
  );
};

export const editSimCustomFieldsData = async (
  iccid: string,
  customFields: {
    id: number;
    value: string;
  }[],
  signal?: AbortSignal,
) => {
  return await authFetch(
    `${process.env.REACT_APP_SIM_INVENTORY_SIMS_PROFILE_API}/${iccid}/custom-fields`,
    {
      signal,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ customFields }),
    },
  );
};
