import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { TagContext } from './TagProvider';

export const useAssignedTags = (id: string) => {
  const { fetchAssignedTags } = useContext(TagContext);
  return useQuery({
    queryKey: ['tags', id],
    queryFn: async () => {
      const tags = await fetchAssignedTags(id);
      return tags.map(({ name }) => name);
    },
    enabled: false,
    refetchOnMount: false,
  });
};
