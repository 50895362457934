import { useMutation, useQueryClient } from '@tanstack/react-query';
import { uploadBulkProvisioningFile } from '../BulkProvisioningApi/bulkProvisioningApi';
import { useState } from 'react';
import { AuthError } from '../../auth/utils';
import { useTranslation } from 'react-i18next';

export type Error = {
  title: string;
  description: string[];
};

export const useBulkProvisioningUpload = () => {
  const [file, setFile] = useState<File | null>(null);
  const queryClient = useQueryClient();

  const [importError, setImportError] = useState<Error | null>(null);

  const { t } = useTranslation();

  const { mutate: uploadFileMutation, isPending } = useMutation({
    mutationFn: async (file: File) => {
      if (!isPending) {
        await uploadBulkProvisioningFile(file);
      }
    },
    onSuccess: () => {
      setFile(null);
      queryClient.invalidateQueries({ queryKey: ['BulkProvisioningJobs'] });
    },
    onError: (error) => {
      if (error instanceof AuthError) {
        throw error;
      } else {
        // @ts-ignore
        const parsedError = JSON.parse(error.message);
        if (parsedError?.validationErrors) {
          const messages: string[] = [];
          for (const key in parsedError.validationErrors) {
            messages.push(parsedError.validationErrors[key][0]?.message);
          }
          if (messages.length > 0) {
            return setImportError({
              title: t('bulkProvisioning.bulkProvisioningFailed'),
              description: messages,
            });
          }
        }

        setImportError({
          title: t('bulkProvisioning.bulkProvisioningFailed'),
          description: [t('common.somethingWrong')],
        });
      }
    },
  });

  const uploadFile = () => {
    if (file) {
      uploadFileMutation(file);
    }
  };

  return { selectedFile: file, selectFile: setFile, uploadFile, importError, setImportError };
};
