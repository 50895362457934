import { useCallback, useState } from 'react';
import List from '@mui/material/List';
import { useTranslation } from 'react-i18next';
import { FilterSection } from 'common/components/Filters/FilterSection';
import { FiltersDialog, FiltersDialogProps } from 'common/components/Filters/FiltersDialog';
import { FilterListItem } from 'common/components/Filters/FilterListItem';
import Box from '@mui/material/Box';

import { FilterBox } from 'common/components/Filters/FilterBox';
import { Actions, Subjects } from 'permissions/ability';
import { SimInventoryCan } from 'permissions/PermissionProvider';
import { AccountSearchInput } from './Components/Inputs/AccountSearchInput';
import { TagsSearchInput } from './Components/Inputs/TagsSearchInput';
import { ConnectionIdSearchInput } from './Components/Inputs/ConnectionIdSearchInput';
import { OrderNumberSearchInput } from './Components/Inputs/OrderNumberSearchInput';
import { MnoSearchInput } from './Components/Inputs/MNOSearchInput';
import { SessionStatusInput } from './Components/Inputs/SessionStatusInput';
import { LabelSearchInput } from './Components/Inputs/LabelSearchInput';
import { IdSearchInput } from './Components/Inputs/IdSearchInput';
import { RadioSection } from 'common/components/Filters/IdFilterSelector/RadioSection';
import { IccidRangeSearch } from './Components/Inputs/IccidRangeSearchInput';
import { simFiltersAtom } from 'atoms/filters/sim';
import { useAtomValue } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { CustomFieldInputs } from './Components/Inputs/CutomFieldInputs';
import { useQuery } from '@tanstack/react-query';
import { useAbility } from 'permissions/hooks/useAbility';

type SimFiltersDialogProps = Pick<FiltersDialogProps, 'onCancel' | 'onClear' | 'onClose'> & {
  onApply: (filters: any) => void;
};

export const SimFiltersDialog = ({ onApply, onClear, ...props }: SimFiltersDialogProps) => {
  const { t } = useTranslation();

  const filters = useAtomValue(simFiltersAtom);
  const resetFilters = useResetAtom(simFiltersAtom);

  const [selectedSearch, setSelectedSearch] = useState<'search' | 'iccidRange'>(() => {
    if (filters.iccidFrom !== '' || filters.iccidTo !== '') {
      return 'iccidRange';
    }

    return 'search';
  });

  const onClearClicked = useCallback(() => {
    resetFilters();
    onClear();
  }, []);

  const onApplyClicked = useCallback(() => {
    if (parseInt(filters.iccidFrom) > parseInt(filters.iccidTo)) {
      onApply({ ...filters, iccidFrom: '', iccidTo: '' });
    } else {
      onApply(filters);
    }
  }, [filters]);

  const ability = useAbility();

  const customFieldsEnabled = ability.can(Actions.edit, Subjects.customFields);

  const { data: customFieldsData } = useQuery<any>({
    queryKey: ['customFields'],
    enabled: false, //It will use previous fetched custom fields
  });
  const customFieldsAvailable =
    customFieldsEnabled &&
    customFieldsData?.length > 0 &&
    ability.can(Actions.edit, Subjects.customFields);

  return (
    <FiltersDialog
      {...props}
      open={true}
      onApply={onApplyClicked}
      onClear={onClearClicked}
      oneColumn={!customFieldsAvailable}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
        <FilterSection title={t('common.idSearch')}>
          <FilterBox>
            <RadioSection
              options={['search', 'iccidRange']}
              value={selectedSearch}
              onValueChange={(newValue) => setSelectedSearch(newValue as 'search' | 'iccidRange')}
              labels={{
                search: 'Search',
                iccidRange: 'ICCID Range',
              }}
            >
              {(value) => {
                return (
                  <Box>
                    <Box
                      data-selector="search"
                      sx={{ opacity: value === 'search' ? 1 : 0 }}
                      aria-hidden={value !== 'search'}
                    >
                      <IdSearchInput />
                    </Box>
                    <Box
                      data-selector="iccidRange"
                      sx={{ opacity: value === 'iccidRange' ? 1 : 0 }}
                      aria-hidden={value !== 'iccidRange'}
                    >
                      <IccidRangeSearch />
                    </Box>
                  </Box>
                );
              }}
            </RadioSection>
          </FilterBox>
        </FilterSection>
        <FilterSection title={t('simInventory.sim')}>
          <List>
            <FilterListItem label={t('simInventory.sessionStatus')} htmlFor="select status">
              <Box
                sx={{
                  display: 'flex',
                }}
              >
                <SessionStatusInput />
              </Box>
            </FilterListItem>
            <SimInventoryCan I={Actions.filter} a={Subjects.internalData}>
              <FilterListItem label="Account" htmlFor="account">
                <AccountSearchInput />
              </FilterListItem>
            </SimInventoryCan>
            <FilterListItem
              label={t('simInventory.mobileNetworkOperator')}
              htmlFor="mobileNetworkOperator"
            >
              <MnoSearchInput />
            </FilterListItem>
            <FilterListItem label={t('common.label')} htmlFor="label">
              <LabelSearchInput />
            </FilterListItem>
            <FilterListItem label={'Tag'} htmlFor="tag">
              <TagsSearchInput />
            </FilterListItem>
          </List>
        </FilterSection>
        <FilterSection title={t('common.order')}>
          <List>
            <FilterListItem label={t('common.connectionId')} htmlFor="connectionId">
              <ConnectionIdSearchInput />
            </FilterListItem>
            <FilterListItem label={t('simInventory.orderNumber')} htmlFor="orderNumber">
              <OrderNumberSearchInput />
            </FilterListItem>
          </List>
        </FilterSection>
      </Box>
      {customFieldsAvailable && (
        <FilterSection title={t('customFields.customFields')}>
          <List>
            <CustomFieldInputs />
          </List>
        </FilterSection>
      )}
    </FiltersDialog>
  );
};
