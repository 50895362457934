import { ChartEntryData } from 'common/components/Graphs/Sessions/SingleSessionGraphRange';
import { UsageChartEntryData } from 'common/components/Graphs/SingleUsageGraph';
import { SessionHistoryTimeline } from 'common/components/SessionHistory/services/SessionHistoryApi';

export const convertSessionHistoryToGraphEntries = (
  sessionHistory: SessionHistoryTimeline
): ChartEntryData[] => {
  return sessionHistory.map((sessionHistoryEntry, index) => ({
    x: [sessionHistoryEntry?.sessionStart, sessionHistoryEntry?.finalizationDate],
    y: sessionHistoryEntry.sessionType,
    dataIndex: index
  }));
};

export const convertSessionHistoryToGraphUsageEntries = (
  sessionHistory: SessionHistoryTimeline,
  label: string
): UsageChartEntryData[] => {
  return sessionHistory.map((sessionHistoryEntry, index) => ({
    x: [sessionHistoryEntry?.sessionStart, sessionHistoryEntry?.finalizationDate],
    inputUsage: sessionHistoryEntry.cumulativeInputUsage || 0,
    outputUsage: sessionHistoryEntry.cumulativeOutputUsage || 0,
    tooltipData: {
      label,
      dataIndex: index
    }
  }));
};
