import React from 'react';
import Box from "@mui/material/Box";
import NotFoundSmall from 'assets/images/notFoundSmall.png';

type EmptyTableMessageProps = {
  message: string | React.ReactElement;
};

export const EmptyTableMessage: React.FC<EmptyTableMessageProps> = ({ message }) => (
  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <Box m={4}>
      <img src={NotFoundSmall} alt="" />
    </Box>
    <Box m={4} mt={0}>
      {message}
    </Box>
  </Box>
);
