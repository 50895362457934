import {
  ProvisioningSourceFilter,
  ProvisioningStatusFilter,
  provisioningSourceFilter,
  provisioningStatusFilter,
} from './BulkProvisioningOLD.interface';

export const parseStatusFilter = (status: string | null): ProvisioningStatusFilter => {
  if (!status) {
    return '';
  }

  const statusParams = parseInt(status);

  try {
    const parsedStatus = provisioningStatusFilter.parse(statusParams);
    return parsedStatus;
  } catch (err) {
    return '';
  }
};

export const parseSourceFilter = (source: string | null): ProvisioningSourceFilter => {
  if (!source) {
    return '';
  }

  const sourceParams = parseInt(source);

  try {
    const parcedSource = provisioningSourceFilter.parse(sourceParams);
    return parcedSource;
  } catch (err) {
    return '';
  }
};
