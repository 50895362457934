import { initHints } from './utils/hintsFactory';

const STORAGE_VERSION = 'v1';
const STORAGE_NAME = `sim-hints`;

const INITIAL_VALUES = {
  accounts: [],
  tags: [],
  connectionId: [],
  orderNumber: [],
  label: [],
  mobileNetworkOperator: [],
};

const { storageAtom, syncAtom, updateAllAtom, gettersAndRemovers } = initHints({
  storageName: STORAGE_NAME,
  storageVersion: STORAGE_VERSION,
  intialValues: INITIAL_VALUES,
});

const { accounts, tags, connectionId, orderNumber, label, mobileNetworkOperator } =
  gettersAndRemovers as {
    accounts: any;
    tags: any;
    connectionId: any;
    orderNumber: any;
    label: any;
    mobileNetworkOperator: any;
  };

export const simHintsAtomWithStorage = storageAtom;
export const syncHintsAtom = syncAtom;
export const updateHintsAtom = updateAllAtom;
export const connectionIdHintsAtom = connectionId;
export const orderNumberHintsAtom = orderNumber;
export const labelHintsAtom = label;
export const simTagsHintsAtom = tags;
export const simAccountsHintsAtom = accounts;
export const mnoHintsAtom = mobileNetworkOperator;
