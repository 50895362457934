import { useAbility } from 'permissions/hooks/useAbility';
import { useCallback, useMemo, useState } from 'react';
import { Action } from '../models/configurableTable';

type UseDataGridDialogsProps = {
  actions?: Action[];
};

export const useDataGridDialogs = ({ actions }: UseDataGridDialogsProps) => {
  const ability = useAbility();

  const dialogActions = useMemo(() => {
    if (!actions) {
      return [];
    }
    const availableActions = actions?.filter((action: any) => {
      if (action.type !== 'dialog') {
        return false;
      }

      if (action.permision && ability.cannot(action.permision.action, action.permision.subject)) {
        return false;
      }

      return true;
    });

    return availableActions;
  }, []);

  const [dialogs, setDialogs] = useState(() => {
    return dialogActions?.reduce((dialogs: any, currentValue: any) => {
      return {
        ...dialogs,
        [currentValue.id]: null,
      };
    }, {});
  });

  const selectDialog = useCallback(
    (dialogId: string, row: any) => {
      setDialogs((currentDialogs: any) => ({
        ...currentDialogs,
        [dialogId]: row,
      }));
    },
    [setDialogs],
  );

  const unselectDialog = useCallback(
    (dialogId: string) => {
      setDialogs((currentDialogs: any) => ({
        ...currentDialogs,
        [dialogId]: null,
      }));
    },
    [setDialogs],
  );

  return {
    dialogActions,
    dialogs,
    selectDialog,
    unselectDialog,
  };
};
