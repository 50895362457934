import Tab from '@mui/material/Tab';
import { tabStyle } from 'common/components/AppLayout/AppLayout.styles';
import { usePreviousPath } from 'common/components/AppLayout/AppNavigation/usePreviousPath';
import { TabAppLayout } from 'common/components/AppLayout/TabAppLayout';
import { useHasFeatureFlag } from 'featureFlags/useHasFeatureFlag';
import { Link, useParams } from 'react-router-dom';
import { Routes } from 'Routes.constants';

export const RSimInventoryTabsLayout = () => {
  const { euicc } = useParams();
  const previousPath = usePreviousPath();

  const sessionHistoryEnabled = useHasFeatureFlag('SimCardSessionsHistory');
  const productsViewEnabled = useHasFeatureFlag('ProductsView');
  const baseRoute = productsViewEnabled ? Routes.productsRsim : Routes.rSimInventory;

  return (
    <TabAppLayout>
      <Tab
        value={`${baseRoute}/${euicc}`}
        label="General"
        component={Link}
        to={`${baseRoute}/${euicc}`}
        state={{ previousPath }}
        sx={tabStyle}
        replace
      />
      {sessionHistoryEnabled ? (
        <Tab
          value={`${baseRoute}/${euicc}/session-history`}
          label="Session History"
          component={Link}
          to={`${baseRoute}/${euicc}/session-history`}
          state={{ previousPath }}
          sx={tabStyle}
          replace
        />
      ) : null}
      {sessionHistoryEnabled ? (
        <Tab
          value={`${baseRoute}/${euicc}/history-analysis`}
          label="History Analysis"
          component={Link}
          to={`${baseRoute}/${euicc}/history-analysis`}
          state={{ previousPath }}
          sx={tabStyle}
          replace
        />
      ) : null}
    </TabAppLayout>
  );
};
