import { palette } from 'theme/palette';
import {
  scrollbarHorizontalSX,
  scrollBarsSX,
  scrollbarVerticalSX,
} from 'theme/utility/utility.styles';

export const gridStyles = {
  '& .MuiDataGrid-scrollbarFiller': {
    backgroundColor: 'white !important',
  },
  '& .MuiDataGrid-scrollbarFiller--header': {
    backgroundColor: `${palette.grey[100]} !important`,
  },
  '& .MuiDataGrid-row': {
    '& .MuiDataGrid-cell--pinnedRight': {
      backgroundColor: 'transparent ',
    },
    '&.Mui-selected': {
      backgroundColor: palette.grey[200],
      '& .MuiDataGrid-cell--pinnedRight': {
        backgroundColor: 'transparent !important',
      },
      '&.Mui-hovered': {
        backgroundColor: palette.grey[100],
      },
    },
  },
  '& .MuiDataGrid-row.Mui-selected': {
    '&:hover': {
      backgroundColor: `${palette.grey[200]} !important`,
      '& .MuiDataGrid-cell--pinnedRight': {
        backgroundColor: `${palette.grey[200]} !important`,
        '#additionalMenuActionsButton': {
          visibility: 'visible',
        },
      },
    },
  },
  '& .header-sticky': {
    borderLeft: 'none !important',
    maxWidth: '50px !important',
    minWidth: '50px !important',
    padding: '0 5px',
  },
  '& .MuiDataGrid-cell--withLeftBorder': {
    borderLeft: 'none',
  },
  '& .MuiDataGrid-pinnedColumnHeaders--right': {
    borderLeft: 'none',
  },

  '& .MuiDataGrid-scrollbar--vertical::-webkit-scrollbar': {
    width: '14px',
  },

  '& .MuiDataGrid-scrollbar--horizontal': {
    ...scrollbarHorizontalSX,
    ...scrollBarsSX,
  },

  '& .MuiDataGrid-scrollbar--vertical': {
    ...scrollbarVerticalSX,
    ...scrollBarsSX,
  },

  '& .MuiDataGrid-columnHeader': {
    paddingLeft: '0 !important',
  },

  '& .MuiDataGrid-columnHeaderDraggableContainer': {
    cursor: 'default !important',
  },
  '& .MuiDataGrid-filler': {
    '&--pinnedRight': {
      backgroundColor: palette.background.paper,
      borderLeft: 'none',
    },
  },
};
