import React, { useEffect } from 'react';
import { loginRedirect } from './AuthApi/authApi';
import { FallbackProps } from 'react-error-boundary';
import Box from '@mui/material/Box';
import { ReactComponent as CommonErrorImage } from 'assets/images/commonErrorBG.svg';
import NotFoundImage from 'assets/images/notFound.png';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import { ErrorCodes } from 'common/errorCodes';

export const ErrorFallbackWithAuthorizationRedirect: React.FC<FallbackProps> = ({
  error,
  resetErrorBoundary,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (error.message === ErrorCodes.AccessDenied) {
      loginRedirect();
    }
  }, [error]);

  if (error.message === ErrorCodes.AccessDenied) {
    return null;
  }

  return (
    <Box
      component="main"
      sx={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
      role="alert"
    >
      {error.message === ErrorCodes.NotFound ? (
        <>
          <img src={NotFoundImage} alt="" />
          <Typography variant="h1" sx={{ mt: 5 }}>
            {t('common.pageNotFound')}
          </Typography>
          <Button href="/" sx={{ mt: 3 }}>
            {t('common.backToDashboard')}
          </Button>
        </>
      ) : (
        <>
          <CommonErrorImage />
          <Typography variant="h1">{t('auth.somethingWrong')}</Typography>
          <Button onClick={resetErrorBoundary}>{t('auth.tryAgain')}</Button>
        </>
      )}
    </Box>
  );
};
