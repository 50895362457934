import { useKickSimContext } from '../KickRsimProvider';
import { KickSimSwitch } from '../KickSimSwitch';
import Box from '@mui/material/Box';

export type RSimInventorySimulateOutageProps = {
  euicc: string;
  isPrimary: boolean;
  disabled?: boolean;
}

export const RSimInventorySimulateOutage: React.FC<RSimInventorySimulateOutageProps> = ({ euicc, isPrimary, disabled = false }) => {
  const { isPrimaryProfileDisabled, isSecondaryProfileDisabled } = useKickSimContext();
  const primaryProfileDisabled = isPrimaryProfileDisabled(euicc);
  const secondaryProfileDisabled = isSecondaryProfileDisabled(euicc);

  const isDisabled =
    (isPrimary && primaryProfileDisabled) ||
    (!isPrimary && secondaryProfileDisabled);

  return (
    <Box sx={ { minWidth: '180px' } }>
      <KickSimSwitch
        checked={ isDisabled }
        profileId={ isPrimary ? 0 : 1 }
        euicc={ euicc }
        labelPlacement="end"
        disabled={ disabled }
      />
    </Box>
  );
};
