import React, { FC } from 'react';
import { ReactComponent as Active } from 'assets/images/StatusIndicator/Active.svg';
import { ReactComponent as Inactive } from 'assets/images/StatusIndicator/Inactive.svg';
import { ReactComponent as Unknown } from 'assets/images/StatusIndicator/Unknown.svg';
import Box from '@mui/material/Box';
import { i18 } from 'i18n/i18n';
import { ReactComponent as AlertrSIM } from 'assets/images/StatusIndicator/AlertrSIM.svg';
import { Tooltip } from 'common/components/Tooltip/Tooltip';
import { useTranslation } from 'react-i18next';
import { SESSION_STATUS_UI, SessionStatusUi } from '../utils/sessionStatus';

type SessionStatusIndicatorProps = {
  status?: SessionStatusUi;
  paused?: boolean;
};

export const activeSim = i18.t('common.active');
export const inactiveSim = i18.t('common.inactive');
export const unknown = i18.t('common.unknown');

type FCWithChildren = React.FC<React.PropsWithChildren>;

const IconAlertBox: FCWithChildren = ({ children }) => (
  <Box
    sx={{
      position: 'absolute',
      top: '-3px',
      right: '-3px',
    }}
  >
    {children}
  </Box>
);

const IconRootBox: FCWithChildren = React.forwardRef(({ children, ...props }, ref) => (
  <Box ref={ref} sx={{ position: 'relative', width: '24px', height: '24px' }} {...props}>
    {children}
  </Box>
));

const AlertIcon = () => (
  <IconAlertBox>
    <AlertrSIM height="13px" data-testid="Pause icon" />
  </IconAlertBox>
);

export const SessionStatusIndicator: FC<SessionStatusIndicatorProps> = React.memo(
  ({ status, paused = false }) => {
    const { t } = useTranslation();
    const alertDecriptionRows = paused ? `${t('common.simulateOutageRunning')}!` : [];
    if (status === SESSION_STATUS_UI.IN_SESSION) {
      const label = t('common.inSession');
      return (
        <Tooltip title={label} description={alertDecriptionRows}>
          <IconRootBox>
            <Active
              width="24px"
              height="24px"
              aria-label={label}
              role="img"
              data-testid="sessionStatusActive"
            />
            {paused && <AlertIcon />}
          </IconRootBox>
        </Tooltip>
      );
    }

    if (status === SESSION_STATUS_UI.OUT_OF_SESSION) {
      const label = t('common.outSession');
      return (
        <Tooltip title={label} description={alertDecriptionRows}>
          <IconRootBox>
            <Inactive
              width="24px"
              height="24px"
              aria-label={label}
              role="img"
              data-testid="sessionStatusOutOfSession"
            />
            {paused && <AlertIcon />}
          </IconRootBox>
        </Tooltip>
      );
    }

    const label = t('common.unknown');
    return (
      <Tooltip title={label} description={alertDecriptionRows}>
        <IconRootBox>
          <Unknown
            width="24px"
            height="24px"
            aria-label={label}
            role="img"
            data-testid="sessionStatusUnknown"
          />
          {paused && <AlertIcon />}
        </IconRootBox>
      </Tooltip>
    );
  },
);
