import { UsageChartEntryData } from '../SingleUsageGraph';
import { USAGE_BAR_BACKGROUND_COLORS, USAGE_BAR_BORDER_COLORS } from '../graphConfig';
import { ChartProps } from 'react-chartjs-2';
import { isAfterOrEqual, isBeforeOrEqual } from 'common/utils/dateUtils';

type LineChartProps = ChartProps<'line'>;

export type UsageDataset = LineChartProps['data']['datasets'];

type UsageGraphDatasetDataEntry = {
  x: [number, number];
  y: [number, number];
  backgroundColor: string;
  borderColor: string;
};

export const filterValuesNotInRange =
  (selectedStartRange: Date, selectedEndRange: Date) =>
  <T extends { x: [Date, Date] }>({ x }: T) => {
    const [start, end] = x;

    if (isBeforeOrEqual(end, selectedStartRange) || isAfterOrEqual(start, selectedEndRange)) {
      return false;
    }

    return true;
  };

export const mapDataPointsToDatasets = (
  entries: UsageChartEntryData[],
  selectedStartRange?: Date,
  selectedEndRange?: Date,
): { data: UsageGraphDatasetDataEntry[]; maxValue: number } => {
  let maxValue = 0;
  const entriesFromOldest = [...entries].reverse();
  const initialArray: UsageGraphDatasetDataEntry[] = [];

  const parsedSelectedStartRange = selectedStartRange || new Date(0);
  const parsedSelectedEndRange = selectedEndRange || new Date();

  const filterFunction = filterValuesNotInRange(parsedSelectedStartRange, parsedSelectedEndRange);

  const mappedValue = entriesFromOldest.reduce(
    (prevValue: UsageGraphDatasetDataEntry[], currentValue: UsageChartEntryData, index) => {
      const [start, end] = currentValue.x;

      const parsedRange = [start.getTime(), end.getTime()];
      const inputYMax = currentValue.outputUsage + currentValue.inputUsage;

      if (inputYMax > maxValue && filterFunction(currentValue)) {
        maxValue = inputYMax;
      }

      return [
        ...prevValue,
        {
          x: parsedRange,
          y: [0, currentValue.outputUsage],
          backgroundColor: USAGE_BAR_BACKGROUND_COLORS.OUTPUT,
          borderColor: USAGE_BAR_BORDER_COLORS.OUTPUT,
          dataIndex: currentValue.tooltipData.dataIndex,
          label: currentValue.tooltipData.label,
        },
        {
          x: parsedRange,
          y: [currentValue.outputUsage, inputYMax],
          backgroundColor: USAGE_BAR_BACKGROUND_COLORS.INPUT,
          borderColor: USAGE_BAR_BORDER_COLORS.INPUT,
          dataIndex: currentValue.tooltipData.dataIndex,
          label: currentValue.tooltipData.label,
        },
      ] as UsageGraphDatasetDataEntry[];
    },
    initialArray,
  );

  return { data: mappedValue, maxValue };
};
