import { useLazyPaginationQuery } from 'common/useLazyPaginationQuery';
import { setMinimalPromiseTime } from 'common/utils/setMinimalPromiseTime';
import { SimInventoryData } from 'simInventory/SimInventoryApi/simInventoryApi.interface';
import { Sorting } from 'common/hooks/useSorting';
import { SimInventoryFiltersV2 } from 'simInventory/models/SimProfile.model';
import { fetchSimProducts, fetchSimProductsPagination } from '../SimProductsApi/simProductsApi';
import { getSearchIds } from 'simInventory/Components/Inputs/SimIdSearchInput.util';
import { getCustomFieldValuesForQueryKey } from 'common/components/Filters/CustomFieldsFilter/customFields.utils';
import { useHasFeatureFlag } from '../../../featureFlags/useHasFeatureFlag';

export const useSimProductsFetch = (
  filters: SimInventoryFiltersV2,
  sort: { columnName: string; sort: Sorting } | null = null,
  rowsPerPage: number,
  page: number,
) => {
  const { tags, searchText, chosenIdType, customFields, ...otherFilters } = filters;

  const tagsMigratedToProductsView = useHasFeatureFlag('TagsMigratedToProductsView');

  const { queryResult, pagination, queryKey } = useLazyPaginationQuery<SimInventoryData>(
    [
      'SimProductsConfigurable',
      searchText,
      chosenIdType,
      sort,
      ...Object.values(otherFilters),
      getCustomFieldValuesForQueryKey(customFields),
      tags,
      rowsPerPage,
      page,
    ],
    async ({ signal }) => {
      return setMinimalPromiseTime(
        fetchSimProducts(
          page,
          rowsPerPage,
          {
            searchText: getSearchIds(searchText),
            chosenIdType: chosenIdType,
            tags,
            customFields,
            ...otherFilters,
          },
          sort,
          tagsMigratedToProductsView,
          signal,
        ),
      );
    },
    async ({ signal }) => {
      return fetchSimProductsPagination(
        page,
        rowsPerPage,
        {
          searchText: getSearchIds(searchText),
          chosenIdType: chosenIdType,
          tags,
          customFields,
          ...otherFilters,
        },
        signal,
      );
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const simProducts = queryResult.data;
  const isRefetching = queryResult.isRefetching;

  return {
    simProducts,
    pagination,
    queryKey,
    isRefetching,
  };
};
