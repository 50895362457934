import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import { Children } from 'react';

const SessionDetailsGrid = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
  [theme.breakpoints.up('md')]: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridAutoFlow: 'dense'
  },
  [theme.breakpoints.up('lg')]: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)'
  }
}));

const StartColumn = styled(Box)(() => ({
  gridColumn: 1
}));

const MiddleColumn = styled(Box)(({ theme }) => ({
  gridColumn: 1,
  [theme.breakpoints.up('lg')]: {
    gridColumn: 2
  }
}));

const EndColumn = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    gridColumn: 2
  },
  [theme.breakpoints.up('lg')]: {
    gridColumn: 3
  }
}));

export type ThreeColumnLayoutProps = {
  firstColumnChildren: React.ReactNode[];
  secondColumnChildren: React.ReactNode[];
  thirdColumnChildren: React.ReactNode[];
  rowGap?: number;
};

export const ThreeColumnLayout: React.FC<ThreeColumnLayoutProps> = ({
  firstColumnChildren,
  secondColumnChildren,
  thirdColumnChildren,
  rowGap = 0
}) => {
  return (
    <SessionDetailsGrid sx={{ rowGap }}>
      {Children.map(firstColumnChildren, (child) => (
        <StartColumn>{child}</StartColumn>
      ))}
      {Children.map(secondColumnChildren, (child) => (
        <MiddleColumn>{child}</MiddleColumn>
      ))}
      {Children.map(thirdColumnChildren, (child) => (
        <EndColumn>{child}</EndColumn>
      ))}
    </SessionDetailsGrid>
  );
};
