import Typography from "@mui/material/Typography";
import MuiAlertTitle from "@mui/material/AlertTitle";
import { alertTitleSx } from './AlertTitle.style';

type AlertTitleProps = {
  children: string | string[];
};

export const AlertTitle: React.FC<AlertTitleProps> = ({ children }) => {
  return (
    <MuiAlertTitle sx={alertTitleSx}>
      <Typography variant="h4">{children}</Typography>
    </MuiAlertTitle>
  );
};
