import { Bar, getElementAtEvent } from 'react-chartjs-2';
import React, { useMemo, useRef } from 'react';
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  Tooltip,
  TimeScale,
  CategoryScale,
  BarElement,
  TimeSeriesScale,
  Decimation
} from 'chart.js';

import zoom from 'chartjs-plugin-zoom';
import 'chartjs-adapter-luxon';
import Box from '@mui/material/Box';
import {
  GRAPH_DEFAULT_OPTIONS,
  STATUS_BORDER_COLOR_MAP,
  STATUS_COLOR_MAP,
  chartBorderPlugin
} from '../graphConfig';
import { highlightPlugin } from '../utils/graphPlugins';
import { useDispatchEventToChart } from '../common/Graphs/hooks/useDispatchEventToChart';
import { useGraphTooltipConfig } from './hooks/useGraphTooltipConfig';
import { useGraphZoomConfig } from '../common/Graphs/hooks/useGraphZoomConfig';

ChartJS.register(
  TimeScale,
  CategoryScale,
  LinearScale,
  Tooltip,
  PointElement,
  BarElement,
  TimeSeriesScale,
  Decimation
);
ChartJS.register(zoom);

export type ChartEntryData = {
  x: [Date, Date];
  y: string;
  dataIndex: number;
};

export type TimeRangeChartProps = {
  highlightFrom?: Date | null;
  highlightTo?: Date | null;
  entries: ChartEntryData[];
  from: number;
  to: number;
  min: number;
  max: number;
  onZoomComplete: (range: [Date, Date]) => void;
  tooltip: (positionX: number, index: number | null, setName: string | null) => void;
  onClick: (index: number, setName: string | null) => void;
  onPan?: (newMin: Date, newMax: Date) => void;
};

export const SingleSessionGraphRange: React.FC<TimeRangeChartProps> = React.memo(
  ({
    entries,
    highlightFrom,
    highlightTo,
    from,
    to,
    min,
    max,
    tooltip,
    onZoomComplete,
    onClick,
    onPan
  }) => {
    const tooltipConfig = useGraphTooltipConfig(from, to, tooltip);
    const zoomConfig = useGraphZoomConfig(5 * 60_000, min, max, onZoomComplete, onPan);

    const barOptions = useMemo(
      () => ({
        ...GRAPH_DEFAULT_OPTIONS,
        indexAxis: 'y',
        scales: {
          x: {
            type: 'time',
            min: new Date(from),
            max: new Date(to),
            ...GRAPH_DEFAULT_OPTIONS!.scales!.x
          },
          y: {
            stacked: true,
            beginAtZero: false,
            ...GRAPH_DEFAULT_OPTIONS!.scales!.y
          }
        },
        plugins: {
          ...GRAPH_DEFAULT_OPTIONS!.plugins,
          zoom: zoomConfig,
          tooltip: tooltipConfig,
          highlightPlugin: {
            from: highlightFrom,
            to: highlightTo
          }
        }
      }),
      [from, to, entries, highlightFrom, highlightTo, tooltipConfig, zoomConfig]
    );

    const barData = useMemo(() => {
      const notFinished = entries.filter((entry) => entry.y === 'NOT_FINALIZED');
      const normal = entries.filter((entry) => entry.y === 'NORMAL');
      const zeroByteSession = entries.filter((entry) => entry.y === 'ZERO_BYTE_SESSION');

      const notFinishedDatasets: ChartEntryData[] = notFinished.map((entry) => ({
        x: entry.x,
        y: 'Status',
        dataIndex: entry.dataIndex
      }));

      const normalDatasets: ChartEntryData[] = normal.map((entry) => ({
        x: entry.x,
        y: 'Status',
        dataIndex: entry.dataIndex
      }));

      const zeroByteDatasets: ChartEntryData[] = zeroByteSession.map((entry) => ({
        x: entry.x,
        y: 'Status',
        dataIndex: entry.dataIndex
      }));

      return {
        labels: ['Status'],
        datasets: [
          {
            label: 'NORMAL',
            data: normalDatasets,
            backgroundColor: STATUS_COLOR_MAP.NORMAL,
            borderColor: STATUS_BORDER_COLOR_MAP.NORMAL,
            borderWidth: 1,
            borderRadius: 4,
            borderSkipped: false,
            barThickness: 24
          },
          {
            label: 'NOT FINISHED',
            data: notFinishedDatasets,
            backgroundColor: STATUS_COLOR_MAP.NOT_FINALIZED,
            borderColor: STATUS_BORDER_COLOR_MAP.NOT_FINALIZED,
            borderWidth: 1,
            borderRadius: 4,
            borderSkipped: false,
            barThickness: 24
          },

          {
            label: 'ZERO BYTE SESSION',
            data: zeroByteDatasets,
            backgroundColor: STATUS_COLOR_MAP.ZERO_BYTE_SESSION,
            borderColor: STATUS_BORDER_COLOR_MAP.ZERO_BYTE_SESSION,
            borderWidth: 1,
            borderRadius: 4,
            borderSkipped: false,
            barThickness: 24
          }
        ]
      };
    }, [entries]);

    const chartRef = useRef();
    const onChartClick = (event: any) => {
      //@ts-ignore
      const elementsAtEvent = getElementAtEvent(chartRef.current, event);
      if (elementsAtEvent.length === 0) {
        return;
      }

      const [chartElement] = elementsAtEvent;

      //@ts-ignore
      const pointRaw = chartElement.element['$context'].raw;

      //@ts-ignore
      const index = pointRaw.dataIndex;

      //@ts-ignore
      const setName = pointRaw.y;

      onClick(index, setName);
    };

    useDispatchEventToChart(chartRef);

    return (
      <Box sx={{ height: 83 }}>
        <Bar
          //@ts-ignore
          options={barOptions}
          data={barData}
          plugins={[chartBorderPlugin, highlightPlugin]}
          ref={chartRef}
          onClick={onChartClick}
        />
      </Box>
    );
  }
);
