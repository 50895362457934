import { useTranslation } from 'react-i18next';
import {
  MultiOptionInputSearch,
  MultiOptionInputSearchProps,
} from '../MultiOptionInput/MultiOptionInputSearch';

// const parseMultipleIdString = (value: string) => {
//   return value.split(/[^a-zA-Z\d\s:]/).filter((value) => !!value);
// };

export type MultipleIdInputProps = Omit<MultiOptionInputSearchProps, 'onInputValueChange'> & {
  onInputValueChange: (searchText: string) => void;
};

export const ID_SEARCH_REGEX = /[0-9]+/g;

export const MultipleIdInput = ({ onInputValueChange, ...props }: MultipleIdInputProps) => {
  const { t } = useTranslation();
  return (
    <MultiOptionInputSearch
      onInputValueChange={onInputValueChange}
      placeholder={`${t('common.search')} ${props.optionValue}`}
      searchRegex={ID_SEARCH_REGEX}
      {...props}
    />
  );
};
