import { rsimFiltersAtom } from 'atoms/filters';

import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useSetAtom } from 'jotai';
import { mapURLSearchParamsToRsimFilters } from '../../utils/filtersToURLSearcParamsMapper';

export const RSimFiltersURLParser = ({ children }: any) => {
  const [searchParams] = useSearchParams();
  const setFilters = useSetAtom(rsimFiltersAtom);

  useEffect(() => {
    setFilters(mapURLSearchParamsToRsimFilters(searchParams));
  }, []);

  return <>{children}</>;
};
