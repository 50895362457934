import { ChipProps, Components, Palette } from '@mui/material';
import { palette } from 'theme/palette';

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    secondary: false;
    error: false;
    warning: false;
    info: true;
    success: true;
    failed: true;
  }

  interface ChipPropsSizeOverrides {
    large: true;
  }
}

export const CHIPS_HEIGHTS_MAP = {
  SMALL: '18px',
  MEDIUM: '24px',
  LARGE: '32px',
};

export const CHIPS_PADDINGS_HORIZONTAL = {
  SMALL: '8px',
  MEDIUM: '8px',
  LARGE: '10px',
};

const chipPalette: Record<
  Exclude<ChipProps['color'], undefined>,
  Omit<Palette['primary'], 'dark' | 'light'> & {
    iconBackground: string;
    iconBackgroundOutlined: string;
    border: string;
    outlinedContrastText: string;
  }
> = {
  primary: {
    main: '#1976D2',
    contrastText: palette.text.white,
    outlinedContrastText: '#1976D2',
    iconBackground: palette.text.white,
    iconBackgroundOutlined: '#1976D2',
    border: '#1976D2',
  },
  default: {
    main: palette.border,
    contrastText: palette.text.primary,
    outlinedContrastText: palette.text.primary,
    iconBackground: palette.text.secondary,
    iconBackgroundOutlined: palette.text.secondary,
    border: palette.text.secondary,
  },
  info: {
    main: palette.blue['100'],
    contrastText: palette.blue['600'],
    outlinedContrastText: palette.blue['600'],
    iconBackground: palette.blue['600'],
    iconBackgroundOutlined: palette.blue['600'],
    border: palette.blue['600'],
  },
  failed: {
    main: palette.red['100'],
    contrastText: palette.red['600'],
    outlinedContrastText: palette.red['600'],
    iconBackground: palette.red['600'],
    iconBackgroundOutlined: palette.red['600'],
    border: palette.red['600'],
  },
  success: {
    main: palette.green['100'],
    contrastText: palette.green['600'],
    outlinedContrastText: palette.green['600'],
    iconBackground: palette.green['600'],
    iconBackgroundOutlined: palette.green['600'],
    border: palette.green['600'],
  },
};

export const MuiChip: Components['MuiChip'] = {
  defaultProps: {
    color: 'default',
  },
  styleOverrides: {
    root: ({ ownerState }) => {
      const { variant, color } = ownerState;

      const usedColor = chipPalette[color || 'default'];

      if (variant === 'outlined') {
        return {
          backgroundColor: palette.text.white,
          color: usedColor.outlinedContrastText,
          border: `1px solid ${usedColor.border}`,
        };
      }

      return {
        backgroundColor: usedColor.main,
        color: usedColor.contrastText,
      };
    },
    deletable: ({ ownerState, theme }) => {
      const { size } = ownerState;

      if (size === 'small') {
        return {
          paddingRight: 0,
        };
      }

      if (size === 'large') {
        return {
          paddingRight: '6px',
        };
      }

      return {
        paddingRight: '3px',
      };
    },
    label: () => {
      return {
        paddingLeft: 0,
        paddingRight: 0,
      };
    },
    sizeSmall: {
      height: CHIPS_HEIGHTS_MAP.SMALL,
      paddingLeft: CHIPS_PADDINGS_HORIZONTAL.SMALL,
      paddingRight: CHIPS_PADDINGS_HORIZONTAL.SMALL,
    },
    sizeMedium: {
      height: CHIPS_HEIGHTS_MAP.MEDIUM,
      paddingLeft: CHIPS_PADDINGS_HORIZONTAL.MEDIUM,
      paddingRight: CHIPS_PADDINGS_HORIZONTAL.MEDIUM,
    },
    deleteIcon: ({ ownerState }) => {
      const { size, variant, color } = ownerState;
      const usedColor = chipPalette[color || 'default'];

      const shared = {
        height: '16px',
        width: 'auto',
        margin: 0,
        padding: '2px',
        paddingLeft: '2px',
      };

      if (size === 'large') {
        shared.paddingLeft = '4px';
      }

      return {
        ...shared,
        fill: variant === 'filled' ? usedColor.iconBackground : usedColor.iconBackgroundOutlined,
      };
    },
    //@ts-ignore
    sizeLarge: {
      height: CHIPS_HEIGHTS_MAP.LARGE,
      paddingLeft: CHIPS_PADDINGS_HORIZONTAL.LARGE,
      paddingRight: CHIPS_PADDINGS_HORIZONTAL.LARGE,
    },
  },
};
