export const simProductsColumnsMaxWidth: { [key: string]: { combined: number; flat: number } } = {
  iccid: { combined: 170, flat: 170 },
  imsi: { combined: 170, flat: 140 },
  currentIpAddress: { combined: 130, flat: 130 },
  managementIpAddress: { combined: 130, flat: 130 },
  subscriptionStatus: { combined: 175, flat: 150 },
  subscriptionStatusEntryTime: { combined: 175, flat: 195 },
  msisdn: { combined: 150, flat: 150 },
  liveDataUsage: { combined: 120, flat: 120 },
  inMinuteUsage: { combined: 120, flat: 120 },
  inTextUsage: { combined: 150, flat: 150 },
  outTextUsage: { combined: 150, flat: 150 },
  sessionStatus: { combined: 190, flat: 190 },
  sessionStatusLastUpdated: { combined: 190, flat: 190 },
  sessionStartTime: { combined: 200, flat: 200 },
  sessionEndTime: { combined: 200, flat: 200 },
  tags: { combined: 150, flat: 150 },
  simType: { combined: 120, flat: 120 },
};

export const simProductsColumnsDefaultWidth: {
  [key: string]: { combined: number; flat: number };
} = {
  ...simProductsColumnsMaxWidth,
  customerCommsPlan: { combined: 160, flat: 160 },
  commsPlan: { combined: 160, flat: 160 },
  mobileNetworkOperator: { combined: 160, flat: 160 },
  organisation: { combined: 160, flat: 160 },
  accountName: { combined: 160, flat: 160 },
  accountRef: { combined: 160, flat: 160 },
  connectionId: { combined: 120, flat: 120 },
  orderNumber: { combined: 120, flat: 120 },
  label: { combined: 200, flat: 200 },
  activeConfiguration: { combined: 200, flat: 200 },
};

export const dateCellSx = {
  '&.MuiTypography-text1': {
    lineHeight: '17px',
  },
};
