import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import {
  fetchSimInventoryTableConfiguration,
  revertSimInventoryTableConfiguration,
} from 'simInventory/SimInventoryApi/simInventoryTableConfigurationApi';
import {
  TABLE_VARIANT,
  TableVariant,
} from 'simInventory/SimInventoryApi/simInventoryTableConfigurationApi.interface';
import { saveSimInventoryTableConfiguration } from '../../../SimInventoryApi/simInventoryTableConfigurationApi';

const SimInventoryConfigurableKey = ['SimInventoryConfigurable'];

export const useColumnConfiguration = () => {
  const {
    data: configuration,
    refetch,
    status,
    isRefetching,
  } = useSuspenseQuery({
    queryKey: SimInventoryConfigurableKey,
    queryFn: async () => fetchSimInventoryTableConfiguration(true),
    gcTime: 0,
  });

  const queryClient = useQueryClient();
  const { mutate: updateVariant, status: updatingStatus } = useMutation({
    mutationFn: async (variant: TableVariant) => {
      return saveSimInventoryTableConfiguration({
        variant,
        columnConfigurations:
          variant === TABLE_VARIANT.Flat
            ? configuration!.flatColumnConfigurations
            : configuration!.combinedColumnConfigurations,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: SimInventoryConfigurableKey });
    },
  });

  const { mutate: revertToDefault, status: revertUpdatingStatus } = useMutation({
    mutationFn: () => {
      if (configuration?.enabledVariant === undefined) {
        throw new Error('need to fetch configuration first!');
      }

      return revertSimInventoryTableConfiguration(configuration.enabledVariant);
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: SimInventoryConfigurableKey }),
  });

  const currentColumns =
    configuration?.enabledVariant === TABLE_VARIANT.Flat
      ? configuration.flatColumnConfigurations
      : configuration?.combinedColumnConfigurations;

  let mutationStatus = updatingStatus;
  if (revertUpdatingStatus === 'pending') {
    mutationStatus = revertUpdatingStatus;
  }

  return {
    configuration,
    currentColumns,
    updateVariant,
    revertToDefault,
    refetch,
    status,
    updatingStatus: mutationStatus,
    isRefetching,
  };
};
