import { SortingParam } from 'common/hooks/useSorting';
import { atom } from 'jotai';
import { focusAtom } from 'jotai-optics';
import { atomWithReset, atomWithStorage, createJSONStorage } from 'jotai/utils';

import {
  FILTER_SIM_CARD_IDENTIFIERS,
  LAST_ACTIVE_PROFILE_FILTER,
  LAST_UPDATE_TIME_FILTERS,
  RSimFilters,
  SIMULATE_OUTAGE_FILTER,
} from 'rSimInventory/Filters/data/filters.constants';

export const rsimFiltersAtom = atomWithReset<RSimFilters>({
  chosenIdType: FILTER_SIM_CARD_IDENTIFIERS.EID,
  searchText: '',
  lastActiveProfile: LAST_ACTIVE_PROFILE_FILTER.ANY,
  lastUpdateTime: LAST_UPDATE_TIME_FILTERS.NONE,
  tags: [],
  connectionId: '',
  orderNumber: '',
  simulateOutage: SIMULATE_OUTAGE_FILTER.ANY,
  accounts: [],
  eidFrom: '',
  eidTo: '',
});

export const chosenIdTypeFilterAtom = focusAtom(rsimFiltersAtom, (optic) =>
  optic.prop('chosenIdType'),
);

export const searchTextFilterAtom = focusAtom(rsimFiltersAtom, (optic) => optic.prop('searchText'));

export const lastActiveProfileFilterAtom = focusAtom(rsimFiltersAtom, (optic) =>
  optic.prop('lastActiveProfile'),
);

export const lastUpdateTimeFilterAtom = focusAtom(rsimFiltersAtom, (optic) =>
  optic.prop('lastUpdateTime'),
);

export const simulateOutageFilterAtom = focusAtom(rsimFiltersAtom, (optic) =>
  optic.prop('simulateOutage'),
);

export const eidRangeFilterAtom = focusAtom(rsimFiltersAtom, (optic) =>
  optic.pick(['eidFrom', 'eidTo']),
);

export const connectionIdFilterAtom = focusAtom(rsimFiltersAtom, (optic) =>
  optic.prop('connectionId'),
);

export const orderNumberFilterAtom = focusAtom(rsimFiltersAtom, (optic) =>
  optic.prop('orderNumber'),
);

export const accountsFilterAtom = focusAtom(rsimFiltersAtom, (optic) => optic.prop('accounts'));

export const tagsFilterAtom = focusAtom(rsimFiltersAtom, (optic) => optic.prop('tags'));

export const rowsPerPageFilterAtom = atom(25);

export const pageFilterAtom = atom(0);

const atomLocalStorage = createJSONStorage<SortingParam>(() => localStorage);

export const rsimSortingAtomWithStorage = atomWithStorage<SortingParam>(
  'rsim-sortings',
  {
    columnName: 'EID',
    sort: 'asc',
  } as SortingParam,
  atomLocalStorage,
  { getOnInit: true },
);
