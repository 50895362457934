import { addMilliseconds, differenceInMilliseconds, isBefore, subDays } from 'date-fns';
import { UsageChartEntryData } from '../../SingleUsageGraph';
import {
  InterimsTimelineEntry,
  LAST_RADIUS_TYPE,
  LastRadiusType,
} from 'common/components/SessionHistory/services/SessionHistoryApi';

const MS_IN_DAY = 24 * 60 * 60 * 1000;

export const mapInterimTimelineEntryToRadioAcessGraphEntry = (interimsTimelineEntry: any) => ({
  x: [interimsTimelineEntry.start, interimsTimelineEntry.stop],
  y: 'RAT',
  label: interimsTimelineEntry.radioAccess,
});

export const mapInterimsTimelineToRadioAcessGraphData = (interimsTimeline: any[]) => {
  const radioAcessGraphData = interimsTimeline.reduce(
    (radioAccessData, interimTimeLineEntry, index, interimsTimeline) => {
      const prevInterim = index - 1 >= 0 ? interimsTimeline[index - 1] : null;

      if (prevInterim && prevInterim.radioAccess === interimTimeLineEntry.radioAccess) {
        radioAccessData.at(-1).x[0] = interimTimeLineEntry.start;
        return radioAccessData;
      }

      const radioAccessDataEntry =
        mapInterimTimelineEntryToRadioAcessGraphEntry(interimTimeLineEntry);
      radioAccessData.push(radioAccessDataEntry);

      return radioAccessData;
    },
    [],
  );

  return radioAcessGraphData;
};

export const mapInterimTimelineEntryToLocationGraphEntry = (interimsTimelineEntry: any) => ({
  x: [interimsTimelineEntry.start, interimsTimelineEntry.stop],
  y: 'Location',
  label: interimsTimelineEntry.localization,
});

export const mapInterimsTimelineToLocationGraphData = (interimsTimeline: any[]) => {
  return interimsTimeline.reduce((locationData, interimTimeLineEntry, index, interimsTimeline) => {
    const prevInterim = index - 1 >= 0 ? interimsTimeline[index - 1] : null;

    if (prevInterim && prevInterim.localization === interimTimeLineEntry.localization) {
      locationData.at(-1).x[0] = interimTimeLineEntry.start;
      return locationData;
    }

    const locationDataEntry = mapInterimTimelineEntryToLocationGraphEntry(interimTimeLineEntry);
    locationData.push(locationDataEntry);
    return locationData;
  }, []);
};

export const createInterimsAvailableRange = (
  sessionStart: Date,
  sessionEnd: Date,
  daysToDisplay: number,
  currentData: Date = new Date(),
) => {
  const durationInMilliseconds = differenceInMilliseconds(sessionEnd, sessionStart);

  const daysInMS = daysToDisplay * MS_IN_DAY;

  if (durationInMilliseconds < daysInMS) {
    return [sessionStart, addMilliseconds(sessionStart, daysInMS)];
  }

  const sessionStartMin = subDays(currentData, 30);

  if (isBefore(sessionStart, sessionStartMin)) {
    return [sessionStartMin, sessionEnd];
  }

  return [sessionStart, sessionEnd];
};

export const getInitialSelectedRange = (availableRangeMax: Date, zoom: number) => {
  return {
    min: subDays(availableRangeMax, zoom),
    max: availableRangeMax,
  };
};

export const mapInterimsTimelineToUsage = (
  interimsTimeline: InterimsTimelineEntry[],
): UsageChartEntryData[] => {
  return interimsTimeline.map((interimEntry, index) => ({
    x: [interimEntry.start, interimEntry.stop],
    inputUsage: interimEntry.inputUsage,
    outputUsage: interimEntry.outputUsage,
    tooltipData: {
      dataIndex: index,
      label: '',
    },
  }));
};

export const getLastRadiusTypeLabel = (lastRadiusType?: LastRadiusType | null) => {
  const LAST_RADIUS_TYPE_TO_LABEL: Record<LastRadiusType, string> = {
    [LAST_RADIUS_TYPE.START]: 'Start',
    [LAST_RADIUS_TYPE.UPDATE]: 'Update',
    [LAST_RADIUS_TYPE.STOP]: 'Stop',
  };

  if (!lastRadiusType) {
    return '-';
  }

  return LAST_RADIUS_TYPE_TO_LABEL[lastRadiusType];
};
