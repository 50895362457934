import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { useTranslation } from 'react-i18next';

export type CollapseIconButtonProps = Pick<IconButtonProps, 'onClick'> & { opened: boolean };

export const CollapseIconButton: React.FC<CollapseIconButtonProps> = ({ opened, onClick }) => {
  const { t } = useTranslation();
  return (
    <IconButton
      size="small"
      aria-label={t('common.expandRow')}
      sx={{ width: '100%' }}
      onClick={onClick}
    >
      {opened ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
    </IconButton>
  );
};
