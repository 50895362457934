import Typography from '@mui/material/Typography';
import { Link, useLocation } from 'react-router-dom';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material';
import { StickyCopyExtension } from 'common/StickyCopyExtension/StickyCopyExtension';
import React from 'react';

export type StickyLinkCellProps = {
  value: string;
  to: string;
  sx?: SxProps<Theme>;
};

export const StickyLinkCell: React.FC<StickyLinkCellProps> = React.memo(({ value, to, sx }) => {
  const { pathname, search } = useLocation();
  return (
    <StickyCopyExtension value={value}>
      <Typography
        component={Link}
        variant="link"
        to={to}
        sx={sx}
        state={{ previousPath: `${pathname}${search}` }}
      >
        {value}
      </Typography>
    </StickyCopyExtension>
  );
});
