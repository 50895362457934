import Typography from '@mui/material/Typography';
import MuiToggleButtonGroup, {
  ToggleButtonProps as MuiToggleButtonProps,
} from '@mui/material/ToggleButton';

export type ToggleButtonProps = Pick<MuiToggleButtonProps, 'value' | 'sx'> & { children: string };

export const ToggleButton: React.FC<ToggleButtonProps> = ({ children, sx, ...props }) => {
  return (
    <MuiToggleButtonGroup
      {...props}
      sx={{
        textTransform: 'capitalize',
        px: 3,
        pt: 2,
        pb: 2.25,
        ...sx,
        '&.MuiToggleButton-root:first-child': {
          borderTopLeftRadius: '12px',
          borderBottomLeftRadius: '12px',
        },
        '&.MuiToggleButton-root:last-child': {
          borderTopRightRadius: '12px',
          borderBottomRightRadius: '12px',
        },
      }}
    >
      <Typography variant="text1" color="text.secondary">
        {children}
      </Typography>
    </MuiToggleButtonGroup>
  );
};
