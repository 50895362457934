import MuiTooltip, { TooltipProps as MuiTooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import React from 'react';

type GraphTooltipProps = Pick<MuiTooltipProps, 'children'> & {
  title: string;
};

const popperSx = {
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'text.secondary',
    borderColor: 'text.secondary',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: 'text.secondary',
  },
};

export const GraphTooltip: React.FC<GraphTooltipProps> = ({ children, title }) => {
  return (
    <MuiTooltip
      arrow
      placement="top"
      PopperProps={{ sx: popperSx }}
      title={
        <>
          <Typography variant="text5" component="div" color="text.white">
            {title}
          </Typography>
        </>
      }
    >
      {children}
    </MuiTooltip>
  );
};
