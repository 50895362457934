import enGbLocale from 'date-fns/locale/en-GB';
import enUSLocale from 'date-fns/locale/en-US';
import plLocale from 'date-fns/locale/pl';
import humanizeDuration from 'humanize-duration';

const supportedLocale: Record<string, Locale> = {
  'en-GB': enGbLocale,
  pl: plLocale,
  'en-US': enUSLocale,
};
export const getDateLocal = () => {
  const language = window.navigator.language;
  return supportedLocale[language] || enGbLocale;
};

const humanizerStringOptions = { spacer: '', delimiter: ' ', round: true };
const shortEnglishHumanizer = humanizeDuration.humanizer({
  ...humanizerStringOptions,
  language: 'shortEn',
  languages: {
    shortEn: {
      y: () => 'y',
      mo: () => 'mo',
      w: () => 'w',
      d: () => 'd',
      h: () => 'h',
      m: () => 'm',
      s: () => 's',
      ms: () => 'ms',
    },
  },
});

const shortPolishHumanizer = humanizeDuration.humanizer({
  ...humanizerStringOptions,
  language: 'shortPl',
  languages: {
    shortPl: {
      y: () => 'r',
      mo: () => 'm',
      w: () => 'w',
      d: () => 'd',
      h: () => 'g',
      m: () => 'm',
      s: () => 's',
    },
  },
});

const humanizeDurations: Record<string, humanizeDuration.Humanizer> = {
  'en-GB': shortEnglishHumanizer,
  'en-US': shortEnglishHumanizer,
  pl: shortPolishHumanizer,
};

export const getHuminizeDurationFunction = () => {
  const language = window.navigator.language;
  return humanizeDurations[language] || humanizeDurations['en-GB'];
};

export const getHuminizeDurationInSecFunction = () => {
  const humanizeFunction = getHuminizeDurationFunction();
  return (timeInSec: number) => humanizeFunction(timeInSec * 1000);
};
