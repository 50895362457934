import React, { useMemo, useState } from 'react';
import { ReportAlreadyScheduledError } from 'simInventory/SimInventoryApi/simInventoryApi';
import { Trans, useTranslation } from 'react-i18next';
import { AuthError } from 'auth/utils';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { rsimFiltersAtom, rsimSortingAtomWithStorage } from 'atoms/filters';
import { fetchRSimInventoryExtendedFiltersPagination } from '../RSimApi/rSimApi';
import {
  checkIsLargeReport,
  downloadRSimProductsReportWithBackgroundSupport,
  MaxRSimProductsCountError,
} from '../RSimApi/rSimReportsApi';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { LargeExportPrompt } from 'common/components/Export/LargeExportPrompt';
import { ExportCardsSnackbars, ExportError } from 'common/components/Export/ExportCardsSnackbars';
import { useDebounce } from 'usehooks-ts';
import { getSearchIds } from '../Components/Inputs/RSimIdSearchInput.util';

export const RSimDownloadLargeReportButton: React.FC<{ disabled?: boolean }> = ({ disabled }) => {
  const filters = useAtomValue(rsimFiltersAtom);
  const { tags, searchText, chosenIdType, ...otherFilters } = filters;
  const sorting = useAtomValue(rsimSortingAtomWithStorage);

  const debouncedSearchText = useDebounce(searchText || '', 500);
  const minLengthText = debouncedSearchText.length < 3 ? '' : debouncedSearchText;

  const searchIds = useMemo(() => {
    return getSearchIds(minLengthText);
  }, [minLengthText]);

  const [confirmationRequire, setConfirmationRequire] = useState(false);
  const [largeExport, setLargeExport] = useState(false);

  const query = useQuery({
    queryKey: [
      'RSimInventory',
      minLengthText,
      chosenIdType,
      sorting,
      ...Object.values(otherFilters),
      tags,
    ],
    queryFn: async ({ signal }) => {
      const data = await fetchRSimInventoryExtendedFiltersPagination(
        {
          searchIds,
          chosenIdType,
          tags,
          ...otherFilters,
        },
        sorting,
        signal,
      );
      return data.totalNumberOfItems || 0;
    },
  });

  const { mutate, isPending } = useMutation({
    mutationKey: [
      'rsim-products',
      'reports',
      'download',
      {
        searchIds,
        chosenIdType,
        tags,
        ...otherFilters,
      },
      sorting,
    ],
    mutationFn: async () => {
      await downloadRSimProductsReportWithBackgroundSupport(
        {
          searchIds,
          chosenIdType,
          tags,
          ...otherFilters,
        },
        sorting,
      );
    },
    retry: 0,
    onError: (error) => {
      if (error instanceof AuthError) {
        throw error;
      }

      if (error instanceof MaxRSimProductsCountError) {
        setExportError({
          title: t('common.exportFailed'),
          description: [
            t('products.rSim.maxRSimProductsCount', {
              maxRSims: error.maxRSimProductsCount,
            }),
          ],
        });
      } else if (error instanceof ReportAlreadyScheduledError) {
        setExportError({
          title: t('common.exportFailed'),
          description: [
            t('simInventory.anotherBackgroundExportIsStillInProgress'),
            t('simInventory.pleaseTryAgainLater'),
          ],
        });
      } else {
        setExportError({
          title: t('common.requestFailed'),
          description: [t('common.somethingWrong')],
        });
      }
    },
    onSuccess: () => {
      setDownloadingSnackbarOpened(true);
    },
  });

  const exportReport = async () => {
    const largeExport = await checkIsLargeReport({
      searchIds,
      chosenIdType,
      tags,
      ...otherFilters,
    });

    setLargeExport(largeExport);
    setConfirmationRequire(largeExport);
    if (!largeExport) {
      mutate();
    }
  };

  const [downloadingSnackbarOpened, setDownloadingSnackbarOpened] = useState(false);

  const { t } = useTranslation();

  const [exportError, setExportError] = useState<ExportError | null>(null);

  return (
    <Stack>
      <Button
        startIcon={<FileDownloadIcon />}
        disabled={disabled || isPending || query.data === 0}
        color="secondary"
        onClick={exportReport}
      >
        {t('common.export')}
      </Button>
      <LargeExportPrompt
        open={confirmationRequire}
        onConfirm={async () => {
          mutate();
          setConfirmationRequire(false);
        }}
        onClose={() => {
          setConfirmationRequire(false);
          setLargeExport(false);
        }}
        title={t('products.rSim.rSimExport')}
        message={
          <>
            <Trans
              i18nKey={'products.rSim.requestOfNRSimsExport'}
              values={{ numberOfCards: query.data || 0 }}
              components={{ 1: <strong /> }}
            />
            <div>{t('common.emailWithDownloadLink')}</div>
          </>
        }
      />
      <ExportCardsSnackbars
        opened={downloadingSnackbarOpened}
        largeExportSuccessTitle={t('products.rSim.exportingNRSims', {
          numberOfCards: query.data || 0,
        })}
        largeExport={largeExport}
        setExportError={setExportError}
        setDownloadingSnackbarOpened={setDownloadingSnackbarOpened}
        setLargeExport={setLargeExport}
        exportError={exportError}
      />
    </Stack>
  );
};
