import { useTranslation } from 'react-i18next';
import { provisionRsim } from './api/rSimActionsApi';
import { ConfirmActionDialog } from 'common/components/ConfigurableTable/Components/Actions/ConfirmActionDialog';

type ActivationDialogProps = {
  open: boolean;
  onClose: () => void;
  data: any;
};

export const ActivationDialog = ({ data, open, onClose }: ActivationDialogProps) => {
  const { t } = useTranslation();
  return (
    <ConfirmActionDialog
      open={open}
      data={data}
      onClose={onClose}
      submit={(data: any) => provisionRsim([data.connectionId], true)}
      message={`${t('rSimInventory.activationEid')} ${data?.euicc}`}
      title={t('rSimInventory.activation')}
    />
  );
};
