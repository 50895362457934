import MuiTextField from '@mui/material/TextField';
import { TextFieldProps } from '../input.interface';
import { InputWrapper } from '../InputWrapper/InputWrapper';
import { TextFieldFieldsetSx } from './TextField.style';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';

export const TextField: React.FC<TextFieldProps> = ({
  label,
  fullWidth,
  onChange,
  value,
  sx,
  type = 'search',
  showSearchIcon = false,
  ...props
}) => (
  <InputWrapper label={label} fullWidth={fullWidth}>
    <MuiTextField
      value={value}
      onChange={onChange}
      sx={{ ...TextFieldFieldsetSx, ...sx }}
      type={type}
      {...props}
      slotProps={{
        ...(showSearchIcon && {
          input: {
            startAdornment: (
              <InputAdornment position="start" sx={{ ml: 3 }}>
                <SearchIcon fontSize="small" />
              </InputAdornment>
            ),
            //@ts-ignore
            ...props.slotProps?.input?.startAdornment,
          },
          ...props.slotProps?.input,
        }),
        ...props.slotProps,
      }}
    />
  </InputWrapper>
);
