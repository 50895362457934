import { ObjectValues } from 'common/tsHelpers';

export type ColumnConfiguration = {
  name: string;
  position: number;
  width: string;
  children: ColumnConfiguration[];
  enabled: boolean;
  sortable: boolean;
  isCustomField: boolean;
};

export type TableConfiguration = {
  enabledVariant: TableVariant;
  flatColumnConfigurations: ColumnConfiguration[];
  combinedColumnConfigurations: ColumnConfiguration[];
};

export type ColumnWidth = {
  columnName: string;
  width: string;
  isCustomField?: boolean;
};

export const TABLE_VARIANT = {
  Combined: 0,
  Flat: 1,
} as const;

export type TableVariant = ObjectValues<typeof TABLE_VARIANT>;
