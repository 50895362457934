import Big from 'big.js';
import Box from '@mui/material/Box';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { TextField } from '../TextField/TextField';
import { onlyDigits } from 'common/utils/regex';

const IdRangeSearchRoot = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
});

export type IdRangeSearchProps = {
  'data-testid'?: string;
  idName?: string;
  from: string;
  to: string;
  onChange: (from: string, to: string) => void;
};

const validateRangeInput = (from: string, to: string) => {
  if (!from || !to) {
    return false;
  }

  const parsedIdFrom = new Big(from);
  const parsedIdTo = new Big(to);

  return parsedIdFrom.gt(parsedIdTo);
};

export const IdRangeSearch = ({ idName, from, to, onChange, ...props }: IdRangeSearchProps) => {
  const { t } = useTranslation();
  const placeholder = idName ? `${t('common.type')} ${idName}` : '';

  const error = validateRangeInput(from, to);

  return (
    <IdRangeSearchRoot>
      <TextField
        data-testid={`${props['data-testid']} from`}
        placeholder={placeholder}
        label={t('common.from')}
        value={from}
        error={error}
        onChange={(event) => {
          if (onlyDigits.test(event.target.value) || event.target.value === '') {
            onChange(event.target.value, to);
          }
        }}
      />
      <TextField
        data-testid={`${props['data-testid']} to`}
        placeholder={placeholder}
        label={t('common.to')}
        value={to}
        error={error}
        helperText={error ? t('common.toHigherThanFromError') : ''}
        onChange={(event) => {
          if (onlyDigits.test(event.target.value) || event.target.value === '') {
            onChange(from, event.target.value);
          }
        }}
      />
    </IdRangeSearchRoot>
  );
};
