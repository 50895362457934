import Box from '@mui/material/Box';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfigurableTableProps, TableBaseProps } from './TableBase.interface';
import { simProductsColumnsMaxWidth, simProductsColumnsDefaultWidth } from './TableBase.style';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { useColumnConfiguration } from './hooks/useColumnConfiguration';
import { HeaderCell } from './Components/HeaderCell';

import {
  ColumnConfiguration,
  TABLE_VARIANT,
} from 'simInventory/SimInventoryApi/simInventoryTableConfigurationApi.interface';
import { BaseCell } from 'simInventory/Components/ConfigurableTable/tableCells/BaseCell';
import { SimInventoryDataGrid } from 'simInventory/Components/ConfigurableTable/Components/SimInventoryDataGrid';
import { useDataGridDialogs } from 'common/components/ConfigurableTable/hooks/useDataGridDialogs';
import { ActionCell } from 'common/components/ConfigurableTable/Components/Cells/ActionCell';

export const TableBase: React.FC<TableBaseProps> = ({
  items,
  actions,
  flatColumnConfigurations,
  combinedColumnConfigurations,
  enabledVariant,
  sort,
  onSortChange,
}) => {
  const mappedItems = items.map((i) => {
    const mappedCustomFields: { [key: string]: any } = {};
    i.customFields?.forEach((field) => {
      if (
        mappedCustomFields[field.label.toLowerCase()] &&
        mappedCustomFields[field.label.toLowerCase()].dataType === 'Dropdown'
      ) {
        const values = mappedCustomFields[field.label.toLowerCase()].selectionOption.split(', ');
        if (!values.includes(field.selectionOption)) {
          values.push(field.selectionOption);
        }
        mappedCustomFields[field.label.toLowerCase()].selectionOption = values.join(', ');
      } else {
        mappedCustomFields[field.label.toLowerCase()] = field;
      }
    });
    return { ...i, ...mappedCustomFields };
  });

  const { t } = useTranslation();

  const idLabelMapForFlatTable: Record<string, string> = useMemo(
    () => ({
      iccid: t('common.iccid'),
      imsi: t('common.imsi'),
      msisdn: t('common.msisdn'),
      currentIpAddress: t('products.sim.lastKnownIP'),
      managementIpAddress: t('common.managementIp'),
      mnoRatePlan: t('common.plan'),
      organisation: t('common.organisation'),
      label: t('common.label'),
      subscriptionStatus: t('common.subscriptionStatus'),
      subscriptionStatusEntryTime: t('simInventory.subscriptionStatusEntryTime_flat'),
      customerCommsPlan: t('simInventory.customerCommsPlan'),
      commsPlan: t('simInventory.commsPlan'),
      mobileNetworkOperator: t('simInventory.mobileNetworkOperator'),
      accountName: t('common.accountName'),
      accountRef: t('common.accountNumber'),
      liveDataUsage: t('products.sim.dataMB'),
      inMinuteUsage: t('products.sim.voiceMT'),
      outMinuteUsage: t('products.sim.voiceMO'),
      inTextUsage: t('products.sim.smsMT'),
      outTextUsage: t('products.sim.smsMO'),
      sessionStartTime: t('simInventory.sessionStartTime'),
      sessionEndTime: t('simInventory.sessionEndTime'),
      sessionStatus: t('simInventory.sessionStatus'),
      sessionStatusLastUpdated: t('simInventory.sessionStatusLastUpdated_flat'),
      connectionId: t('simInventory.connectionId'),
      orderNumber: t('simInventory.orderNumber'),
      tags: t('common.tag'),
      simType: t('simInventory.simType'),
      activeConfiguration: t('simInventory.activeConfiguration'),
      productCode: t('simInventory.productCode'),
      billingStatus: t('simInventory.billingStatus'),
      productType: t('simInventory.productType'),
    }),
    [],
  );
  const idLabelMapForCombinedTable: Record<string, string> = useMemo(
    () => ({
      ...idLabelMapForFlatTable,
      subscriptionStatusEntryTime: t('simInventory.subscriptionStatusEntryTime_combined'),
      sessionStatusLastUpdated: t('simInventory.sessionStatusLastUpdated_combined'),
    }),
    [],
  );

  const columnConfiguration: ColumnConfiguration[] =
    enabledVariant === TABLE_VARIANT.Combined
      ? combinedColumnConfigurations
      : flatColumnConfigurations;

  const columns = useMemo(() => {
    const columns = columnConfiguration?.reduce(
      (enabledColumns: GridColDef[], column: ColumnConfiguration) => {
        const columnDefinition: GridColDef = {
          field: column.name,
          //@ts-ignore
          width:
            column.width && column.width !== 'auto'
              ? column.width
              : simProductsColumnsDefaultWidth[column.name] &&
                enabledVariant === TABLE_VARIANT.Combined
              ? simProductsColumnsDefaultWidth[column.name]?.combined
              : simProductsColumnsDefaultWidth[column.name] && enabledVariant === TABLE_VARIANT.Flat
              ? simProductsColumnsDefaultWidth[column.name]?.flat
              : 130,
          minWidth: 70,
          maxWidth:
            enabledVariant === TABLE_VARIANT.Combined
              ? simProductsColumnsMaxWidth[column.name]?.combined || 400
              : simProductsColumnsMaxWidth[column.name]?.flat || 400,
          renderHeader: () => (
            <HeaderCell
              column={column}
              primaryText={
                !column.isCustomField
                  ? enabledVariant === TABLE_VARIANT.Combined
                    ? idLabelMapForCombinedTable[column.name]
                    : idLabelMapForFlatTable[column.name]
                  : column.name
              }
              secondaryText={
                !column.isCustomField
                  ? enabledVariant === TABLE_VARIANT.Combined
                    ? idLabelMapForCombinedTable[column.children[0]?.name]
                    : idLabelMapForFlatTable[column.children[0]?.name]
                  : column.children[0]?.name
              }
              enableSortingPrimary={column.sortable}
              enableSortingSecondary={column.children[0]?.sortable || false}
              handleSort={onSortChange}
              sortedColumn={sort}
              verticalAlign="top"
            />
          ),
          renderCell: (params: GridRenderCellParams) => (
            <BaseCell params={params} column={column} variant={enabledVariant} />
          ),
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          resizable: false,
          disableReorder: true,
        };
        enabledColumns.push(columnDefinition);

        return enabledColumns;
      },
      [],
    );

    if (actions) {
      const mappedAction = actions.map((action: any) => {
        if (action.type === 'dialog') {
          return {
            ...action,
            actionCallback: (data: any) => selectDialog(action.id, data),
          };
        }

        return action;
      });

      const actionsColDef: GridColDef = {
        field: 'actions',
        headerName: '',
        resizable: false,
        width: 60,
        minWidth: 60,
        maxWidth: 60,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        disableReorder: true,
        renderHeader: () => <></>,
        renderCell: (props) => {
          return <ActionCell actions={mappedAction} data={props.row} />;
        },
        pinnable: false,
      };

      columns.push(actionsColDef);
    }
    return columns;
  }, [columnConfiguration, enabledVariant, sort]);

  const visibilityModel = useMemo(
    () =>
      columnConfiguration.reduce(
        (visibilityModel: Record<string, boolean>, columnConfiguration: ColumnConfiguration) => {
          return {
            ...visibilityModel,
            [columnConfiguration.name]: columnConfiguration.enabled,
          };
        },
        {} as Record<string, boolean>,
      ),
    [],
  );

  const initialState = useMemo(() => {
    return {
      columns: {
        columnVisibilityModel: { ...visibilityModel, simStatus: false },
      },
    };
  }, [visibilityModel]);

  const { dialogs, selectDialog, unselectDialog, dialogActions } = useDataGridDialogs({
    actions,
  });

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <SimInventoryDataGrid
        rowHeight={enabledVariant === TABLE_VARIANT.Combined ? 52 : 42}
        columns={columns}
        rows={mappedItems}
        initialState={initialState}
        columnConfiguration={columnConfiguration}
      />
      {dialogActions.map((action: any) => {
        return (
          <React.Fragment key={action.id}>
            <action.Component
              open={!!dialogs[action.id]}
              data={dialogs[action.id]}
              onClose={() => {
                unselectDialog(action.id);
              }}
              slotProps={action.slotProps}
            />
          </React.Fragment>
        );
      })}
    </Box>
  );
};

export const ConfigurableTable = (props: ConfigurableTableProps) => {
  const { configuration } = useColumnConfiguration();

  return (
    <TableBase
      {...props}
      flatColumnConfigurations={configuration!.flatColumnConfigurations}
      combinedColumnConfigurations={configuration!.combinedColumnConfigurations}
      enabledVariant={configuration!.enabledVariant}
      key={configuration?.enabledVariant}
    />
  );
};
