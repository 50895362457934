import Tab from '@mui/material/Tab';
import { tabStyle } from 'common/components/AppLayout/AppLayout.styles';
import { usePreviousPath } from 'common/components/AppLayout/AppNavigation/usePreviousPath';
import { TabAppLayout } from 'common/components/AppLayout/TabAppLayout';
import { Link } from 'react-router-dom';
import { Routes } from 'Routes.constants';
import { useTranslation } from 'react-i18next';
import { Actions, Subjects } from '../permissions/ability';
import { useAbility } from '../permissions/hooks/useAbility';

export const AdminTabsLayout = () => {
  const previousPath = usePreviousPath();
  const { t } = useTranslation();
  const ability = useAbility();

  return (
    <TabAppLayout>
      {ability.can(Actions.edit, Subjects.customFields) && (
        <Tab
          value={`${Routes.admin}/custom-fields`}
          label={t('customFields.customFields')}
          component={Link}
          to={`${Routes.admin}/custom-fields`}
          state={{ previousPath }}
          sx={tabStyle}
        />
      )}
      {ability.can(Actions.edit, Subjects.importRsims) && (
        <Tab
          value={`${Routes.admin}/rsim-manufacturing-file`}
          label={t('admin.rSimManufacturingFile.rSimManufacturingFile')}
          component={Link}
          to={`${Routes.admin}/rsim-manufacturing-file`}
          state={{ previousPath }}
          sx={tabStyle}
        />
      )}
    </TabAppLayout>
  );
};
