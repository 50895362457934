import * as React from 'react';
import { useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ReactComponent as CloseIcon } from 'assets/images/inputClose.svg';
import { ReactComponent as PreviousIcon } from 'assets/images/previous.svg';
import { Tooltip } from '../../Tooltip/Tooltip';
import { useTranslation } from 'react-i18next';
import Paper from '@mui/material/Paper';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { Option } from '../AutocompleteSelect/AutocompleteSelect';
import { styled } from '@mui/material';
import { palette } from 'theme/palette';
import { SystemStyleObject } from '@mui/system';
import { Theme } from '@mui/material/styles';

export const filterArray = (options: string[]) => {
  return options.reduce((p: string[], c: string) => {
    if (!p.some((el: string) => el.toLowerCase() === c.toLowerCase())) {
      p.push(c);
    }
    return p;
  }, []);
};

const filterOptions = (options: string[], inputValue: string) => {
  return options.filter((o: string) => !!o && o.toLowerCase().includes(inputValue.toLowerCase()));
};

export const mapOptionsToString = (hints: Option[]) => hints.map((h) => h.id);

export type TextFieldWithHintsProps = {
  value: string | null;
  placeholder?: string;
  onChange: (value: string | null) => void;
  inputValue?: string;
  hints: string[];
  onRemoveHint?: (hint: string) => void;
  'data-testid'?: string;
  isSearchField?: boolean;
  isNumeric?: boolean;
};

const SearchTextFieldMuiInputSX: SystemStyleObject<Theme> = {
  height: '40px',
  p: 0,
};

const OptionBox = styled(Box)({
  padding: '6px 4px 8px 4px',
  height: '36px',
  width: 'inherit',
  '.close-icon': { display: 'none', width: '24px', height: '24px' },
  '.hint-icon': { display: 'block', width: '24px', height: '24px' },
  '&:hover': {
    backgroundColor: palette.grey[100],
    '.close-icon': {
      display: 'flex',
      borderRadius: '27px',
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '.hint-icon': { display: 'none' },
  },
}) as typeof Box;

const OptionTextBox = styled(Box)({
  paddingTop: 0,
  paddingLeft: 0,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textWrap: 'nowrap',
  maxWidth: '100%',
});

const HintBox = styled(Box)({
  height: '24px',
  cursor: 'pointer',
  marginRight: '4px',
});

const PaperStyled = styled(Paper)({
  borderRadius: '16px',
  padding: '8px',
  boxShadow: '0px 0px 12px 0px #0000001F',
  '.MuiAutocomplete-listbox': {
    padding: 0,
    borderRadius: '16px',
  },
  '.MuiAutocomplete-paper:empty': {
    display: 'none',
  },
});

export const TextFieldWithHints: React.FC<TextFieldWithHintsProps> = ({
  hints = [],
  value,
  placeholder,
  inputValue,
  onChange,
  onRemoveHint,
  isSearchField = true,
  isNumeric = false,
  ...otherProps
}) => {
  const testId = otherProps['data-testid'];
  const hintOptions: string[] = [...hints].reverse();

  const [open, setOpen] = useState(false);

  const { t } = useTranslation();

  return (
    <Autocomplete
      data-testid={testId ?? 'select'}
      disableClearable
      freeSolo
      value={typeof value === 'string' ? value : undefined}
      onChange={(_event, newValue) => {
        onChange(newValue || null);
      }}
      inputValue={inputValue}
      onInputChange={(event, newValue, reason) => {
        if (newValue.startsWith(' ')) {
          newValue = newValue.trimStart();
        }
        if (newValue === '') {
          onChange(null);
        } else {
          onChange(newValue);
        }
      }}
      options={hintOptions}
      selectOnFocus
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      open={open}
      PaperComponent={(params) => {
        if (hintOptions.length > 0) {
          return <PaperStyled {...params} data-testid="TextFieldWithHintsPaper" />;
        } else {
          return null;
        }
      }}
      renderOption={({ className, ...props }, option, { selected }) => {
        if (hintOptions.includes(option)) {
          return (
            <OptionBox
              component="li"
              {...props}
              sx={{
                backgroundColor: ({ palette }) =>
                  selected ? palette.grey[200] : palette.background.paper,
              }}
            >
              <Stack direction="row" alignItems="center" alignContent="center" spacing={2}>
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={2}
                  sx={{ width: '100%', ml: '4px !important' }}
                  data-testid="multiselect-option"
                >
                  <OptionTextBox>
                    <Typography variant="text1" color="text.primary">
                      {option}
                    </Typography>
                  </OptionTextBox>
                </Stack>
                <HintBox
                  data-testid="remove-hint-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (option) {
                      onRemoveHint && onRemoveHint(option);
                    }
                  }}
                >
                  <PreviousIcon
                    data-testid="hint-icon"
                    className="hint-icon"
                    width="21px"
                    height="18px"
                  />

                  <Tooltip title={t('common.clear')} showArrow={true} variant="dark">
                    <Box data-testid="remove-hint-icon" className="close-icon">
                      <CloseIcon width="12px" height="12px" role="img" />
                    </Box>
                  </Tooltip>
                </HintBox>
              </Stack>
            </OptionBox>
          );
        }
        return null;
      }}
      filterOptions={(options, params) => {
        const filtered = filterArray([...filterOptions(hintOptions, params.inputValue)]);
        const isExisting = hintOptions.includes(params.inputValue);
        if (params.inputValue !== '' && !isExisting) {
          filtered.push(params.inputValue);
        }
        return filtered;
      }}
      sx={{
        bgcolor: (theme) => theme.palette.background.paper,
        '.MuiAutocomplete-inputRoot': {
          padding: '5px 5px 5px 12px',
          height: 'auto',
          minHeight: '40px',
        },
        '.MuiOutlinedInput-notchedOutline': {
          borderRadius: '12px',
        },
        '.MuiAutocomplete-paper:empty': {
          display: 'none',
        },
        '.MuiAutocomplete-popupIndicator': {
          display: 'none',
        },
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            type={isNumeric ? 'number' : 'text'}
            onKeyDown={(e) => {
              if (isNumeric && /^[e-]+$/.test(e.key)) {
                e.preventDefault();
              }
            }}
            placeholder={placeholder}
            slotProps={{
              htmlInput: {
                ...params.inputProps,
                sx: {
                  p: '0 !important',
                  '::-webkit-inner-spin-button': {
                    appearance: 'none',
                    margin: 0,
                  },
                },
              },
              input: {
                ...params.InputProps,
                sx: SearchTextFieldMuiInputSX,
                startAdornment: (
                  <>
                    {isSearchField && (
                      <InputAdornment position="start" sx={{ ml: 1 }}>
                        <SearchIcon fontSize="small" />
                      </InputAdornment>
                    )}
                  </>
                ),
              },
            }}
          />
        );
      }}
    />
  );
};
