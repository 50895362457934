import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { styled } from '@mui/material';

type HeaderCellProps = {
  primaryText: string;
  secondaryText?: string;
};

const TableCellContent = styled(Box)({
  height: '42px',
  textTransform: 'capitalize',
  pl: 2,
  pt: 1.5,
  pb: 1.5,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  width: '100%',
  '& .dragBox': {
    paddingRight: '18px',
    opacity: 0,
    '& svg': {
      display: 'block',
      margin: 'auto',
    },
  },
  '&:hover .dragBox': {
    opacity: 1,
  },
});

const TableCellDataBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  '& .MuiIconButton-root': {
    padding: '0',
  },
  '& .sort': {
    opacity: 0,
  },
  '&:hover .sort': {
    opacity: 1,
  },
  '& span': {
    overflow: 'hidden',
    '& div': {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  },
});

export const HeaderCell: React.FC<HeaderCellProps> = ({ primaryText, secondaryText }) => {
  return (
    <TableCellContent>
      <TableCellDataBox data-testid="tableHeaderPrimary">
        <Typography variant="text3" color="text.primary">
          <Box data-testid="primaryText">{primaryText}</Box>
        </Typography>
      </TableCellDataBox>
      <Box data-testid="tableHeaderSecondary">
        <Typography variant="text4" color="text.secondary">
          <Box data-testid="secondaryText">{secondaryText}</Box>
        </Typography>
      </Box>
    </TableCellContent>
  );
};
