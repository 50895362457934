declare module '@mui/material/styles' {
  interface Palette {
    tertiary: Palette['primary'];

    white: string;
    border: string;

    green: ColorOptions;
    red: ColorOptions;
    amber: ColorOptions;
    blue: ColorOptions;

    text: TypeText;
  }

  interface PaletteOptions {
    tertiary: PaletteOptions['primary'];

    white: string;
    border: string;

    green: ColorOptions;
    red: ColorOptions;
    amber: ColorOptions;
    blue: ColorOptions;
  }

  interface TypeText {
    primary: string;
    secondary: string;
    tertiary: string;
    white: string;
  }

  interface ColorOptions {
    '100': string;
    '200': string;
    '300': string;
    '400': string;
    '500': string;
    '600': string;
  }
}

const greyColor = {
  '100': '#F6FAFC',
  '200': '#EFF4F5',
  '300': '#DCE4E5',
  '400': '#C0CACC',
  '500': '#8D9B9E',
  '600': '#667C81',
  '700': '#597B82',
};

const typoColor = {
  primary: '#2F3232',
  secondary: '#707676',
  tertiary: '#AAADAD',
  white: '#FFFFFF',
};

const elementsColor = {
  border: '#E5E5E5',
  background: '#F9F9F9',
  white: '#FFFFFF',
};

const greenColor = {
  '100': '#E6F7E9',
  '200': '#96DB9D',
  '300': '#69CC73',
  '400': '#0AA849',
  '500': '#4B9256',
  '600': '#3E7747',
};

const redColor = {
  '100': '#FBE7E8',
  '200': '#F67D85',
  '300': '#DE3C46',
  '400': '#DC0D1A',
  '500': '#D2535C',
  '600': '#B3261E',
};

const amberColor = {
  '100': '#FCF5E6',
  '200': '#F6C86D',
  '300': '#FDC351',
  '400': '#F9B122',
  '500': '#ECAD33',
  '600': '#E9A625',
};

const blueColor = {
  '100': '#E6F1F7',
  '200': '#6FB7E1',
  '300': '#51BEFD',
  '400': '#0D90DC',
  '500': '#3791C5',
  '600': '#407695',
};

export const palette = {
  text: typoColor,

  grey: greyColor,
  green: greenColor,
  red: redColor,
  amber: amberColor,
  blue: blueColor,
  white: elementsColor.white,

  background: {
    paper: elementsColor.white,
    default: elementsColor.background,
  },
  border: elementsColor.border,

  tertiary: {
    main: typoColor.white,
    dark: typoColor.tertiary,
    contrastText: typoColor.primary,
  },
  success: {
    main: greenColor['100'],
    contrastText: greenColor['600'],
    dark: greenColor['500'],
  },
  error: {
    main: redColor['100'],
    contrastText: redColor['600'],
    dark: redColor['500'],
  },
  warning: {
    main: amberColor['100'],
    contrastText: amberColor['600'],
    dark: amberColor['500'],
  },
  info: {
    main: blueColor['100'],
    contrastText: blueColor['600'],
    dark: blueColor['500'],
  },
};
