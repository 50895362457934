import { useLocation } from 'react-router-dom';

type PreviousPathRouterState = {
  previousPath?: string;
};

export const usePreviousPath = () => {
  const location = useLocation();
  const state = location.state as PreviousPathRouterState;

  return state?.previousPath;
};
