import { useContext, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { TagContext } from 'tags/TagProvider';
import { ManageableTag, useTags } from './useTags';
import { useTagsAutocompleteValidator } from './validators/useTagsAutocompleteValidator';
import { v4 as uuidv4 } from 'uuid';

type useTagsProps = {
  id: string;
  onTagsUpdate?: (euicc: string, tags: any) => void;
  enabled?: boolean;
};

export const useTagsAutocompleteSelect = ({ id, onTagsUpdate, enabled = true }: useTagsProps) => {
  const [changed, setChanges] = useState(false);

  const { type, assignTags, fetchAssignedTags, createTag } = useContext(TagContext);
  const { tags, refetchAllTags } = useTags(enabled);

  const {
    data: assignedSimTags,
    refetch,
    isFetching,
    isRefetching,
  } = useQuery({
    queryKey: [type, 'assigned', id],
    queryFn: async () => {
      const tags = (await fetchAssignedTags(id)) || [];
      return tags.map((tag) => tag.id);
    },
    enabled,
    // suspense: false,
    initialData: [],
  });

  const queryClient = useQueryClient();
  const { mutateAsync: save } = useMutation({
    mutationFn: async () => {
      if (!assignedSimTags) {
        return;
      }

      if (assignedSimTags?.length === 0) {
        return assignTags(id, []);
      }

      const tagsToAsigin = [...assignedSimTags];
      const notCreatedTags = tags?.filter((tag) => tag.toSave) || [];

      for (const notCreatedTag of notCreatedTags) {
        const indexOfTagTocreate = tagsToAsigin.indexOf(notCreatedTag.id);
        if (indexOfTagTocreate >= 0) {
          const newTag = await createTag(notCreatedTag.name);
          tagsToAsigin[indexOfTagTocreate] = newTag.id;
        }
      }
      return assignTags(id, tagsToAsigin);
    },
    onSuccess: async () => {
      const tags = await fetchAssignedTags(id);
      if (onTagsUpdate) {
        onTagsUpdate(id, tags);
      }
    },
  });

  const [searchValue, setSearchValue] = useState('');
  const { errors, validateTagLength } = useTagsAutocompleteValidator(assignedSimTags!);

  const selectTags = (tagIds: string[]) => {
    queryClient.setQueryData<string[]>([type, 'assigned', id], tagIds);
  };

  const addNewTags = (names: string[]) => {
    const newTags = names.map((name) => ({
      id: uuidv4(),
      name: name,
      toAdd: false,
      toSave: true,
    }));

    queryClient.setQueryData<ManageableTag[] | undefined>(['tags', type], (currentTags) => {
      if (!currentTags) {
        return [...newTags];
      }

      return [...currentTags, ...newTags];
    });

    queryClient.setQueryData<string[]>([type, 'assigned', id], (currentSelectedTagsId) => {
      const newTagIds = newTags.map(({ id }) => id);

      if (!currentSelectedTagsId) {
        return [...newTagIds];
      }

      return [...currentSelectedTagsId, ...newTagIds];
    });
  };

  const refresh = () => {
    refetch();
    refetchAllTags();
  };

  return {
    tags,
    assignedSimTags,

    addNewTags,
    selectTags,
    save,

    changed,
    setChanges,
    searchValue,
    setSearchValue,
    errors,
    validateTagLength,
    refresh,
    isLoading: isFetching || isRefetching,
  };
};
