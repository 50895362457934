import { useLocation } from 'react-router-dom';
import { activePageAtom, tabsAtom } from '../../../../../atoms/pages';
import { useAtomValue, useSetAtom } from 'jotai';
import { useEffect } from 'react';

type NavigationAtomsInitiatorProps = {
  children: React.ReactNode;
};

export const NavigationAtomsInitiator = ({ children }: NavigationAtomsInitiatorProps) => {
  const location = useLocation();

  const tabs = useAtomValue(tabsAtom);

  const setPage = useSetAtom(activePageAtom);

  useEffect(() => {
    const currentTab = tabs.find((tab) => location.pathname.includes(tab.pathname));
    if (currentTab) {
      setPage(currentTab.page);
    } else {
      setPage('inventory');
    }
  }, [location.pathname]);
  return <>{children}</>;
};
