import { FilterAsyncChip } from 'common/components/FilterChip/FilterAsyncChip';
import { useAtom, WritableAtom } from 'jotai';
import React, { SetStateAction } from 'react';
import { fetchMno } from 'simInventory/SimInventoryApi/mnoApi';

type MnosChipsProps = {
  mnoAtom: WritableAtom<string[], [SetStateAction<string[]>], void>;
};

export const MnosChips = ({ mnoAtom }: MnosChipsProps) => {
  const [mnos, setMnos] = useAtom(mnoAtom);

  const onClear = (id: string) => {
    setMnos((currentAccounts) => currentAccounts.filter((account) => account !== id));
  };

  if (mnos.length === 0) {
    return null;
  }

  return (
    <>
      {mnos.map((mno) => {
        return (
          <React.Fragment key={mno}>
            <FilterAsyncChip
              labelPrefix="Mobile Network Operator"
              onCancel={() => {
                onClear(mno);
              }}
              fieldName="name"
              queryFn={() => {
                return fetchMno(mno);
              }}
              queryKey={['mno', mno]}
              queryEnabled={!!mno}
            />
          </React.Fragment>
        );
      })}
    </>
  );
};
