import Typography from '@mui/material/Typography';
import React from 'react';
import Box from '@mui/material/Box';

type TooltipAdditionalRowsRenderProps = {
  rows?: { color: string; description: string }[];
};

export const TooltipAdditionalRowsRender: React.FC<TooltipAdditionalRowsRenderProps> = ({
  rows,
}) => {
  return (
    <>
      {rows &&
        rows.map((r, i) => (
          <Box key={i} sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
              sx={{ height: '12px', width: '12px', backgroundColor: r.color, borderRadius: '2px' }}
            ></Box>
            <Typography variant="text3" color="text.secondary" ml="6px">
              {r.description}
            </Typography>
          </Box>
        ))}
    </>
  );
};
