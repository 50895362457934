export const downloadInputFileButtonDataTestId = 'downloadInputFileButton';
export const uploadBulkProvisioningButtonDataTestId = 'upload bulk provisioning file button';
export const uploadBulkProvisioningInputDataTestId = 'upload bulk provisioning file input';
export const bulkProvisioningTableDataTestId = 'bulk provisioning table';
export const bulkProvisioningFileNameFilter = 'bulk provisioning file name input';
export const bulkProvisioningUploadDateFromFilterTestId = 'bulk provisioning upload date from';
export const bulkProvisioningUploadDateFromFilterOpenPickerButtonTestId =
  'bulk provisioning upload date from open picker button';
export const bulkProvisioningUploadDateToFilterTestId = 'bulk provisioning upload date to';
export const bulkProvisioningUploadDateToFilterOpenPickerButtonTestId =
  'bulk provisioning upload date to open picker button';
export const uploadFinishedAtTestId = 'upload finished at';
export const statusTestId = 'Status';
export const noOfCardsTestId = 'No. of cards';
export const fileNameTestId = 'File name';
export const successTestId = 'Success';
export const failTestId = 'Fail';
export const uploadDateTestId = 'Upload date';
export const processedDateTestId = 'Processed date';
export const bulkProvisioningDropAreaTestId = 'bulk provisioning drop area';
export const bulkProvisioningFiltersSelectStatus = 'bulk provisioning select status';
