import { connectionIdFilterAtom } from 'atoms/filters/sim';
import { connectionIdHintsAtom } from 'atoms/hints/sim';
import { HintedTextFieldFilters } from 'common/components/Filters/Inputs/HintedTextFieldFilters';
import { useTranslation } from 'react-i18next';

export const ConnectionIdSearchInput = () => {
  const { t } = useTranslation();

  return (
    <HintedTextFieldFilters
      data-testid="connectionId text field"
      placeholder={t('rSimInventory.connectionIdFilterPlaceholder')}
      filterAtom={connectionIdFilterAtom}
      filterHintsAtom={connectionIdHintsAtom}
    />
  );
};
