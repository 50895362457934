import Grid from '@mui/material/Grid2';
import ListItem from '@mui/material/ListItem';
import React from 'react';
import { PropertyListItemProps } from './PropertyListItem.interface';

export const PropertyListItem: React.FC<PropertyListItemProps> = ({
  label,
  children,
  gridProps,
  ...others
}) => {
  return children ? (
    <ListItem
      sx={{ borderLeft: ({ palette }) => `1px solid ${palette.text.tertiary}` }}
      data-testid={others['data-testid']}
    >
      <Grid container alignItems="center" spacing={1} {...gridProps} sx={{ width: '100%' }}>
        <Grid size={4}>{label}</Grid>
        <Grid size={8}>{children}</Grid>
      </Grid>
    </ListItem>
  ) : null;
};
