import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import React, { useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useVirtual } from 'react-virtual';
import { fetchInterims } from 'common/components/SessionHistory/services/SessionHistoryApi';
import { SessionHistoryCellProps } from './SessionHistoryCell';
import { InterimCell } from './InterimCell';
import { toLocaleDateTime } from 'common/utils/dateUtils';
import { useTranslation } from 'react-i18next';
import { getLastRadiusTypeLabel } from 'common/components/Graphs/Interims/utils/interimUtils';
import { styled } from '@mui/material';

export type InterimsSessionProps = {
  imsi: number;
  sessionId: string;
};

const TOP_OFFSET = 89;
const INTERIMS_PER_PAGE = 50;

const InterimGridBox = styled(Box)({
  display: 'grid',
  gridTemplateColumns: '100px 200px 150px 200px 200px minmax(200px, auto)',
});

const FullWidthCell = styled(Box)({
  gridColumn: '1 / 1000',
});

type InterimsHeaderProps = Pick<SessionHistoryCellProps, 'primaryText'>;

const InterimsHeader: React.FC<InterimsHeaderProps> = ({ primaryText }) => {
  return (
    <Box sx={{ verticalAlign: 'center', py: 1.5, textTransform: 'capitalize' }} role="cell">
      <Box sx={{ whiteSpace: 'nowrap', lineHeight: '21px' }} data-testid="tableHeaderPrimary">
        <Typography variant="text1" color="text.primary" data-testid="primaryText">
          {primaryText}
        </Typography>
      </Box>
    </Box>
  );
};

export const InterimsSession: React.FC<InterimsSessionProps> = ({ imsi, sessionId }) => {
  const { data, fetchNextPage, isFetchingNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: ['SessionHistory', 'interims', sessionId, imsi],
    queryFn: async ({ signal, pageParam = 1 }) => {
      const interims = await fetchInterims(
        imsi.toString(),
        sessionId,
        pageParam,
        INTERIMS_PER_PAGE,
        signal,
      );
      return interims;
    },
    initialPageParam: 1,

    getNextPageParam: (lastPage, allPages) => {
      if (lastPage.length >= INTERIMS_PER_PAGE) {
        return allPages.length + 1;
      }
    },
    gcTime: 0,
  });

  const interims =
    data?.pages.reduce((history: any, page: any) => {
      return [...history, ...page];
    }, []) || [];

  const parentRef = useRef<HTMLDivElement>(null);
  const rowVirtualizer = useVirtual({
    //@ts-ignore
    size: interims.length,
    parentRef,
    overscan: 30,
  });

  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  });

  useEffect(() => {
    if (inView && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, isFetchingNextPage]);

  const { t } = useTranslation();

  return (
    <InterimGridBox
      sx={{
        pb: 2,
        pl: 16,
      }}
    >
      <FullWidthCell
        sx={{
          position: 'sticky',
          display: 'grid',
          gridTemplateColumns: 'subgrid',
          top: TOP_OFFSET,
          backgroundColor: (theme) => theme.palette.background.paper,
          pt: 1,
          zIndex: 10,
        }}
      >
        <FullWidthCell>
          <Typography variant="h4" color="text.secondary">
            Session interims
          </Typography>
        </FullWidthCell>
        <FullWidthCell
          data-testid="interimsHeaders"
          role="columnheader"
          sx={{
            position: 'sticky',
            display: 'grid',
            gridTemplateColumns: 'subgrid',
            backgroundColor: (theme) => theme.palette.background.paper,
            zIndex: 1000,
          }}
        >
          <InterimsHeader primaryText={t('common.type')} />
          <InterimsHeader primaryText={'Session Status Last Updated'} />
          <InterimsHeader primaryText={'Usage (KB)'} />
          <InterimsHeader primaryText={'MNO code'} />
          <InterimsHeader primaryText={'Localization'} />
          <InterimsHeader primaryText={'Radio access type'} />
        </FullWidthCell>
      </FullWidthCell>
      <FullWidthCell
        ref={parentRef}
        sx={{
          position: 'relative',
          height: `${rowVirtualizer.totalSize}px`,
          '& div[role]:first-child': {
            borderTop: (theme) => `1px solid ${theme.palette.border}`,
          },
          '& div[role] + div[role]': {
            borderTop: (theme) => `1px solid ${theme.palette.border}`,
          },
        }}
      >
        {rowVirtualizer.virtualItems.map((virtualRow) => {
          const { timestamp, usage, operatorCode, localization, radioAccess, lastRadiusType } =
            //@ts-ignore
            interims[virtualRow.index];
          return (
            <InterimGridBox
              role="row"
              key={virtualRow.index}
              sx={{
                '&:last-child': {
                  borderBottom: 'none',
                },
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: `${virtualRow.size}px`,
                transform: `translateY(${virtualRow.start}px)`,
              }}
            >
              <InterimCell text={getLastRadiusTypeLabel(lastRadiusType)} />
              <InterimCell text={toLocaleDateTime(timestamp)} />
              <InterimCell text={usage?.toFixed(2)} sx={{ textAlign: 'right' }} />
              <InterimCell text={operatorCode} />
              <InterimCell text={localization} />
              <InterimCell text={radioAccess} />
            </InterimGridBox>
          );
        })}
      </FullWidthCell>

      {hasNextPage && (
        <FullWidthCell sx={{ clipPath: 'content-box' }}>
          <div ref={ref}>
            <Box
              sx={{
                position: 'sticky',
                left: '50vw',
                width: 'min-content',
                pt: 4,
              }}
            >
              <CircularProgress color="inherit" />
            </Box>
          </div>
        </FullWidthCell>
      )}
    </InterimGridBox>
  );
};
