import Typography from '@mui/material/Typography';
import { ContentCopyExtension } from 'common/ContentCopyExtension/ContentCopyExtension';
import { Link, useLocation } from 'react-router-dom';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material';

export type CopyableLinkProps = {
  value: string;
  to: string;
  sx?: SxProps<Theme>;
  state?: any;
};

export const CopyableLink: React.FC<CopyableLinkProps> = ({ value, to, sx, state }) => {
  const { pathname, search } = useLocation();

  return (
    <ContentCopyExtension value={value}>
      <Typography
        component={Link}
        variant="link"
        to={to}
        sx={sx}
        state={{ ...state, previousPath: `${pathname}${search}` }}
      >
        {value}
      </Typography>
    </ContentCopyExtension>
  );
};
