import React from 'react';
import Typography from '@mui/material/Typography';
import { SxProps, Theme } from '@mui/material/styles';

type TextCellProps = {
  text: string;
  testId: string;
  sx?: SxProps<Theme>;
};

export const TextCell: React.FC<TextCellProps> = ({ text, testId, sx }) => {
  return (
    <Typography sx={sx} variant="text1" color="text.primary" data-testid={testId}>
      {text}
    </Typography>
  );
};
