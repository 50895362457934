import { EnablingSwitch, LabelPlacement } from 'common/components/Inputs/EnablingSwitch/EnablingSwitch';
import { useTranslation } from 'react-i18next';
import { useKickSimContext } from './KickRsimProvider';

type KickSimSwitchProps = {
  euicc: string;
  profileId: 0 | 1;
  checked: boolean;
  labelPlacement?: LabelPlacement;
  disabled?: boolean;
};

export const KickSimSwitch: React.FC<KickSimSwitchProps> = ({ euicc, profileId, checked, labelPlacement, disabled }) => {
  const { t } = useTranslation();

  const { kickSim } = useKickSimContext();

  return (
    <EnablingSwitch
      checked={checked}
      onChange={(event, checked) => {
        event.stopPropagation();
        kickSim({ enabled: !checked, profileId, euicc });
      }}
      enabledLabel={`${t('common.simulationRunning')}!`}
      labelPlacement={labelPlacement}
      disabled={disabled}
    />
  );
};
