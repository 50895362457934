import React from 'react';
import { Routes } from '../../Routes.constants';

export type SimDetailsContextProps = {
  baseRoute: Routes;
};

type SimDetailsProviderProps = {
  children: React.ReactNode;
  baseRoute: Routes;
};

export const SimDetailsContext = React.createContext<SimDetailsContextProps>({
  baseRoute: Routes.simInventory,
});

export const SimDetailsProvider = ({ children, baseRoute }: SimDetailsProviderProps) => {
  return <SimDetailsContext.Provider value={{ baseRoute }}>{children}</SimDetailsContext.Provider>;
};
