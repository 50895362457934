import { useGraphSessionRange } from '../hooks/useGraphSessionRange';
import { subHours } from 'date-fns';
import { ReactComponent as FastForwardIcon } from 'assets/images/fastforward.svg';
import { Button } from 'common/components/Buttons/Button';

export const LastHourButton = () => {
  const { selectRange } = useGraphSessionRange();

  return (
    <Button
      variant="secondary"
      onClick={() => {
        selectRange(subHours(new Date(), 1), new Date());
      }}
      iconEnd={<FastForwardIcon />}
      size="small"
    >
      Last hour
    </Button>
  );
};
