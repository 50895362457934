import { NoRowsOverlay } from './Components/NoRowsOverlay';
import { CellConfiguration } from './Components/Cells/CellLoader';
import {
  DataGridProProps,
  GridApiPro,
  GridColDef,
  gridDimensionsSelector,
  GridPaginationModel,
  useGridApiContext,
  useGridApiRef,
  useGridSelector,
} from '@mui/x-data-grid-pro';
import { SkeletonLoader } from './Components/Loaders/SkeletonLoader';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import { Configuration, usePrepareConfiguration } from './hooks/usePrepareConfiguration';
import { SortingIndicator } from './Components/Buttons/SortIndicator';
import { SecondaryColumnHeaders } from './Components/Rows/SecondaryColumnHeaders';
import { BaseGrid } from './Components/Base/BaseGrid';
import { useDataGridDialogs } from './hooks/useDataGridDialogs';
import React from 'react';

type ConfigurableTableProps = {
  disablePagination?: boolean;
  disableColumnReorder?: boolean;
  configuration: Configuration[];
  cellConfiguration: CellConfiguration;
  cellProps?: any;
  //TODO: Imporve typings (generic)
  rows?: Record<string, any>[];
  totalNumberOfItems?: number;
  actions?: any;
  'data-testid'?: string;
  page?: number;
  pageSize?: number;
  onPaginationChange?: (pagination: GridPaginationModel) => void;
  slots?: DataGridProProps['slots'];

  loading?: DataGridProProps['loading'];
  getRowId?: DataGridProProps['getRowId'];
  onSortModelChange?: DataGridProProps['onSortModelChange'];
  initialState?: DataGridProProps['initialState'];
};

//To remove when use this component in SimInventory
const CustomizeMuiDataGrid = styled(BaseGrid)(({ theme }) => ({
  '& .MuiDataGrid-skeletonHeaderRow': {
    backgroundColor: theme.palette.background.paper,
  },
  '& .MuiDataGrid-container--top::after': {
    backgroundColor: theme.palette.background.paper,
  },
  '& .MuiDataGrid-columnHeader': {
    borderBottom: 'none',
    backgroundColor: theme.palette.background.paper,
  },
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: theme.palette.background.paper,
    '& > *': {
      backgroundColor: `${theme.palette.background.paper} !important`,
    },
  },
  '& .MuiDataGrid-cell': {
    borderBottom: `1px solid ${theme.palette.border}`,
  },
  '& .MuiDataGrid-row.MuiDataGrid-row--lastVisible': {
    '& .MuiDataGrid-cell': {
      borderBottom: `none`,
    },
  },
  '& .MuiDataGrid-bottomContainer': {
    border: 'none',
    height: 0,
  },
  '& .MuiDataGrid-columnHeader--pinnedRight': {
    backgroundColor: `${theme.palette.background.paper} !important`,
  },
}));

type SubGridAdditionalActionsProps = {
  rows?: Record<string, any>[];
  getRowId?: DataGridProProps['getRowId'];
  subgridApi: React.MutableRefObject<GridApiPro>;
  actionColDef: GridColDef;
};

const SubGridAdditionalActions = ({
  rows = [],
  getRowId,
  subgridApi,
  actionColDef,
}: SubGridAdditionalActionsProps) => {
  const mainGridRef = useGridApiContext();
  const { root: visibleViewport } = useGridSelector(mainGridRef, gridDimensionsSelector);

  const dims =
    subgridApi.current && subgridApi.current.getRootDimensions
      ? subgridApi.current?.getRootDimensions()
      : null;

  const headersTotalHeight = dims?.headersTotalHeight || 0;
  const rowsCount = rows?.length || 0;
  const rowHeight = dims?.rowHeight || 0;

  return (
    <Box
      sx={{
        position: 'absolute',
        left: 30,
        right: 30,
        zIndex: 1000,
        pointerEvents: 'none',
        marginTop: `${headersTotalHeight}px`,
        height: rowsCount * rowHeight,
      }}
    >
      {rows?.map((row: any) => {
        const rowId = getRowId ? getRowId(row) : row.id;
        return (
          <Box
            key={rowId}
            data-id={rowId}
            sx={{
              position: 'sticky',
              left: visibleViewport.width - (actionColDef.width || 50),
              height: `${rowHeight}px`,
              width: actionColDef.width || 50,
              pointerEvents: 'all',
              opacity: 0,
            }}
          >
            <Box
              sx={{
                width: '100%',
                height: `${rowHeight - 1}px`,
                background: ({ palette }) => palette.background.default,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {
                //@ts-ignore
                actionColDef.renderCell && actionColDef.renderCell({ row })
              }
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export const SecondaryConfigurableTable = ({
  slots,
  rows,
  configuration,
  cellConfiguration,
  cellProps,
  actions,
  ...props
}: ConfigurableTableProps) => {
  const apiRef = useGridApiRef();

  const { dialogs, selectDialog, unselectDialog, dialogActions } = useDataGridDialogs({
    actions,
  });

  const columnsConfiguration = usePrepareConfiguration({
    detailPanelEnabled: false,
    configuration,
    cellConfiguration,
    cellProps,
    actions,
    selectDialog,
  });

  const actionColDef = columnsConfiguration.find((col) => col.field === 'actions');
  const actionsSx = actionColDef
    ? rows?.reduce((currentStyle, row) => {
        const rowId = props.getRowId ? props.getRowId(row) : row.id;
        return {
          ...currentStyle,
          [`&:has(div[data-id="${rowId}"]:hover) div[data-id="${rowId}"]`]: {
            opacity: 1,
          },
        };
      }, {})
    : undefined;

  const filterdColumnsConfiguration = actionColDef
    ? columnsConfiguration.filter((col) => col.field !== 'actions')
    : columnsConfiguration;

  return (
    <>
      <Box sx={actionsSx}>
        {actionColDef && (
          <SubGridAdditionalActions
            rows={rows}
            getRowId={props.getRowId}
            subgridApi={apiRef}
            actionColDef={actionColDef}
          />
        )}
        <CustomizeMuiDataGrid
          {...props}
          disablePagination
          apiRef={apiRef}
          columns={filterdColumnsConfiguration}
          slots={{
            columnHeaderSortIcon: SortingIndicator,
            noRowsOverlay: NoRowsOverlay,
            loadingOverlay: () => <SkeletonLoader numberOfRows={2} />,
            row: undefined,
            columnHeaders: SecondaryColumnHeaders,
            ...slots,
          }}
          rows={rows}
        />
      </Box>
      {dialogActions.map((action: any) => {
        return (
          <React.Fragment key={action.id}>
            <action.Component
              open={!!dialogs[action.id]}
              data={dialogs[action.id]}
              onClose={() => {
                unselectDialog(action.id);
              }}
            />
          </React.Fragment>
        );
      })}
    </>
  );
};
