import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import { useLocation, useNavigate } from 'react-router-dom';
import { Routes } from '../../../../Routes.constants';

export type GoToDetailsMenuItemProps = {
  iccid: string;
  onClose?: () => void;
  baseRoute: Routes;
  linkText: string;
};

export const GoToDetailsMenuItem = ({
  iccid,
  onClose,
  baseRoute,
  linkText,
}: GoToDetailsMenuItemProps) => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  return (
    <MenuItem
      onClick={() => {
        navigate(`${baseRoute}/${iccid}`, {
          state: { previousPath: `${pathname}${search}` },
        });
        if (onClose) {
          onClose();
        }
      }}
    >
      <ListItemText>{linkText}</ListItemText>
    </MenuItem>
  );
};
