import { SxProps } from '@mui/material/styles';
import { Theme } from '@mui/system';
import { typography } from 'theme/typography';

export const alertSx: SxProps<Theme> = {
  width: '100%',
  p: 3,
  '& .MuiAlert-action': { py: 0, mt: 0 },
  '& .MuiAlert-action .MuiIconButton-root': { py: 0 },
  '& .MuiAlert-icon': {
    ...typography.h4,
    height: '20px',
    py: 0,
    fontSize: '20px',
    my: 0
  },
  '& .MuiAlert-message': {
    ...typography.h4,
    p: 0,
    my: 0
  }
};
