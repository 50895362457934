import { TextCell } from 'common/components/ConfigurableTable/Components/Cells/TextCell';
import { Configuration } from 'common/components/ConfigurableTable/hooks/usePrepareConfiguration';
import { SecondaryConfigurableTable } from 'common/components/ConfigurableTable/SecondaryConfigurableTable';
import { RsimProfileStatus } from 'rSimInventory/RSimApi/rSimApi.interface';
import styled from '@emotion/styled';
import { RSimSimPollingStatusCell } from '../Cells/RSimSimPollingStatusCell';
import { SimulateOutageCell } from '../Cells/SimulateOutageCell';
import { Routes } from 'Routes.constants';
import { useLocation, useNavigate } from 'react-router-dom';
import { RSimSimIccidLinkCell } from './RSimSimIccidLinkCell';
import { SimInventoryEditDialog } from './SimInventoryEditDialog';
import { DurationCell } from 'common/components/ConfigurableTable/Components/Cells/DurationCell';
import { SimUpdateProvider } from './SimUpdateProvider';
import { CARD_TYPE, TagProvider } from 'tags/TagProvider';
import { useHasFeatureFlag } from '../../../featureFlags/useHasFeatureFlag';
import { useCustomFields } from '../../../admin/customFields/hooks/useCustomFields';
import { useExtendSimWithDerivativeFromRSim } from './hooks/useExtendSimWithDerivativeFromRSim';
import { useColumnConfiguration } from './hooks/useColumnConfiguration';

type RSimSimTableProps = {
  euicc: string;
  primaryIccid: string;
  secondaryIccid: string;
  status: RsimProfileStatus;
};

const RSimSimConfigurableTable = styled(SecondaryConfigurableTable)({
  '& .MuiDataGrid-cell[data-field="status"]': {
    border: 'none',
  },
});

export const RSimSimTable = ({
  euicc,
  primaryIccid,
  secondaryIccid,
  status,
}: RSimSimTableProps) => {
  const simInventoryWithStatus = useExtendSimWithDerivativeFromRSim(
    euicc,
    status,
    primaryIccid,
    secondaryIccid,
  );
  const configuration: Configuration[] = useColumnConfiguration();
  const { customFields } = useCustomFields();

  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const tagsMigratedToProductsView = useHasFeatureFlag('TagsMigratedToProductsView');

  return (
    <div data-testid="sims-inventory-table">
      <TagProvider type={CARD_TYPE.SIM}>
        <SimUpdateProvider queryKey={['RSimSimProfiles', euicc]}>
          <RSimSimConfigurableTable
            data-testid={`sim-inventory-${euicc}`}
            rows={simInventoryWithStatus || []}
            actions={[
              {
                label: 'View Details',
                type: 'custom',
                actionCallback: (data: any) => {
                  navigate(`${Routes.simInventory}/${data.iccid}`, {
                    state: {
                      previousPath: `${pathname}${search}`,
                    },
                  });
                },
              },
              {
                id: 'edit',
                label: 'Edit',
                type: 'dialog',
                Component: SimInventoryEditDialog,
                enabled: () =>
                  !tagsMigratedToProductsView ||
                  (tagsMigratedToProductsView && !!customFields && customFields.length > 0),
                // permission: {
                //   action: Actions.edit,
                //   subject: Subjects.tags,
                // },
              },
            ]}
            configuration={configuration}
            disablePagination
            getRowId={(row) => row.iccid}
            cellConfiguration={{
              default: TextCell,
              iccid: RSimSimIccidLinkCell,
              status: RSimSimPollingStatusCell,
              simulateOutage: SimulateOutageCell,
              inMinuteUsage: DurationCell,
            }}
          />
        </SimUpdateProvider>
      </TagProvider>
    </div>
  );
};
