import { addMilliseconds, subMilliseconds } from 'date-fns';

import { scaleTime } from 'd3-scale';

export type TimeRange = {
  min: number;
  max: number;
};

export type DataRange = {
  min: Date;
  max: Date;
};

export type TimeArray = [number, number];

export const timeRageToDateRange = (timeRange: TimeRange) => {
  return {
    min: new Date(timeRange.min),
    max: new Date(timeRange.max)
  };
};

export const dateRangeToTimeRange = (dateRange: DataRange) => {
  return {
    min: dateRange.min.getTime(),
    max: dateRange.max.getTime()
  };
};

export const timeRageArrayToDateRange = (timeRangeArray: [number, number]) => {
  const [min, max] = timeRangeArray;
  const timeRange = {
    min,
    max
  };
  return timeRageToDateRange(timeRange);
};

export const dateRangeToTimeRangeArray = (dateRange: DataRange): TimeArray => {
  const { min, max } = dateRangeToTimeRange(dateRange);
  return [min, max];
};

export const getMarkLabel = (
  currentMark: Date,
  prevMark: Date | undefined,
  forceMonth: boolean = false
) => {
  if (!prevMark || currentMark.getMonth() !== prevMark.getMonth()) {
    return currentMark.toLocaleString('default', { day: 'numeric', month: 'short' });
  }

  if (prevMark.getDay() !== currentMark.getDay() && forceMonth) {
    return currentMark.toLocaleString('default', { day: 'numeric', month: 'short' });
  }

  if (prevMark.getDay() === currentMark.getDay()) {
    return ' ';
  }

  return currentMark.toLocaleString('default', { day: 'numeric' });
};

export const getRangeAroundTimePoint = (date: Date, rangeOffsetInMS: number) => {
  const min = subMilliseconds(date, rangeOffsetInMS);
  const max = addMilliseconds(date, rangeOffsetInMS);
  return { min, max };
};

export const generateTicks = (ticksCount: number, dateRange: DataRange) => {
  return scaleTime(dateRangeToTimeRangeArray(dateRange))
    .domain(dateRangeToTimeRangeArray(dateRange))
    .ticks(ticksCount);
};

export const createMarksFromTicks = (
  ticks: Date[],
  style: React.CSSProperties,
  diplayMonthForEveryDay: boolean = false
) => {
  return ticks.reduce((currentTicks, date, index, ticks) => {
    const prevTick = index > 0 ? ticks[index - 1] : undefined;
    const label = getMarkLabel(date, prevTick, diplayMonthForEveryDay);
    return {
      ...currentTicks,
      [date.getTime()]: {
        label: label,
        style: style
      }
    };
  }, {});
};

export const moveRange = (updatedTimeRangeArray: TimeArray, previousTimeRangeArray: TimeArray) => {
  if (
    updatedTimeRangeArray[0] !== previousTimeRangeArray[0] &&
    updatedTimeRangeArray[1] !== previousTimeRangeArray[1]
  ) {
    return timeRageArrayToDateRange(updatedTimeRangeArray);
  }

  return timeRageArrayToDateRange(previousTimeRangeArray);
};

export const selectNewDefaultRange = (
  updatedTimeRangeArray: TimeArray,
  previousTimeRangeArray: TimeArray,
  defaultRangeDiffInMS: number
) => {
  const [start, end] = updatedTimeRangeArray;
  if (start === previousTimeRangeArray[0] && end === previousTimeRangeArray[1]) {
    return timeRageArrayToDateRange(previousTimeRangeArray);
  }
  const chnagedValue =
    Math.abs(previousTimeRangeArray[0] - start) > Math.abs(end - previousTimeRangeArray[1])
      ? start
      : end;

  return getRangeAroundTimePoint(new Date(chnagedValue), defaultRangeDiffInMS);
};
