import { parseISO } from 'date-fns';
import { InterimsDTO } from './interimsModels';
import { ValuesType } from 'utility-types';

export const mapInterimsDTO = (interims: InterimsDTO, imsi: string) => {
  return interims.map((interim) => ({
    id: `${interim.acctSessionId}-${interim.statusLastUpdated}-${imsi}`,
    timestamp: parseISO(interim.statusLastUpdated),
    usage: interim.deltaInputUsage + interim.deltaOutputUsage,
    cumulativeUsage: interim.cumulativeInputUsage + interim.cumulativeOutputUsage,
    localization: interim.localization,
    radioAccess: interim.ratType,
    operatorCode: interim.operatorCode,
    lastRadiusType: interim.lastRadiusType,
    inputUsage: interim.deltaInputUsage,
    outputUsage: interim.deltaOutputUsage,
  }));
};

export type Interim = ValuesType<ReturnType<typeof mapInterimsDTO>>;
export type Interims = ReturnType<typeof mapInterimsDTO>;
