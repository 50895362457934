import { useEffect } from 'react';
import { isNil, omitBy } from 'lodash';
import { SignalRContext } from 'signalr/SignalRProvider';
import { UpdateNotification } from 'simInventory/Components/notification.interface';
import { mapSimProfileDTO } from 'simInventory/SimInventoryApi/simInventoryApi.utils';

type IdsProps = Pick<UpdateNotification, 'imsi' | 'euicc' | 'iccid' | 'msisdn'>;

export const useSimSignalRSubscription = (iccids: string[], onUpdate: any) => {
  useEffect(() => {
    if (iccids.length > 0 && SignalRContext.connection?.state === 'Connected') {
      SignalRContext.invoke('SubscribeToIccids', iccids);
      return () => {
        SignalRContext.invoke('UnSubscribeToIccids', iccids);
      };
    }
  }, [iccids, SignalRContext.connection?.state]);

  SignalRContext.useSignalREffect(
    'SimProfileUpdatedNotification',
    async (message: UpdateNotification) => {
      const { properties, ...others } = message;
      const { localization, ...otherProperties } = properties;
      const formatedLocalization = localization ? { localization: { value: localization } } : {};

      const updateOther: IdsProps = {
        iccid: others.iccid,
        ...omitBy(others, isNil),
      };

      onUpdate(
        mapSimProfileDTO({
          ...otherProperties,
          ...updateOther,
          ...formatedLocalization,
        }),
      );
    },
    [onUpdate],
  );
};
