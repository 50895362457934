import React from 'react';
import { createSignalRContext } from 'react-signalr';

export const SignalRContext = createSignalRContext();

export const SignalRProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <SignalRContext.Provider url={process.env.REACT_APP_SIM_INVENTORY_SIGNALR!} dependencies={[]}>
      {children}
    </SignalRContext.Provider>
  );
};
