import { isEmpty, isEqual, uniq } from 'lodash';
import {
  CustomField,
  CustomFieldSelectedOption,
  FIELD_TYPE,
} from '../../../../admin/customFields/entities/customFields.entity';
import {
  AssignedCustomField,
  CustomFieldValue,
} from '../../../../simInventory/SimInventoryApi/simInventoryApi.interface';

export const getIndexOfFieldById = (customFieldsArray: string[], fieldId: string) => {
  return customFieldsArray.findIndex((f) => f.split('_')[0].toString() === fieldId.toString());
};

export const getValuesForFieldById = (customField: string, fieldId: string) => {
  const customFieldsArray = customField.split(';');
  const fieldIndex = getIndexOfFieldById(customFieldsArray, fieldId.toString());

  if (fieldIndex > -1) {
    const splitFilter = customFieldsArray[fieldIndex].split('_');
    const values = splitFilter[2];
    if (values.length > 0) {
      if (splitFilter[1] === 'text') {
        return [values];
      } else if (splitFilter[1] === 'dropdown') {
        return values.split(',');
      }
    }
  }
  return [];
};

export const addTextValueToCustomFieldFilter = (
  customFieldsFilter: string,
  fieldId: string,
  value: string | null,
) => {
  let customFieldsArray = customFieldsFilter.split(';');
  customFieldsArray = customFieldsArray.filter((i) => i !== '');
  const fieldIndex = getIndexOfFieldById(customFieldsArray, fieldId);

  if (value === '' || !value) {
    customFieldsArray.splice(fieldIndex, 1);
    return customFieldsArray.join(';');
  }

  if (fieldIndex > -1) {
    customFieldsArray[fieldIndex] = fieldId.toString() + '_text_' + value;
  } else {
    customFieldsArray.push(fieldId.toString() + '_text_' + value);
  }

  return customFieldsArray.join(';');
};

export const addDropdownValueToCustomFieldFilter = (
  customFieldsFilter: string,
  fieldId: string,
  values: string[],
) => {
  let customFieldsArray = customFieldsFilter.split(';');
  customFieldsArray = customFieldsArray.filter((i) => i !== '');
  const fieldIndex = getIndexOfFieldById(customFieldsArray, fieldId);

  if (values.length > 0) {
    const filteredValues = uniq(values);

    if (fieldIndex > -1) {
      customFieldsArray[fieldIndex] = fieldId.toString() + '_dropdown_' + filteredValues.join(',');
    } else {
      customFieldsArray.push(fieldId.toString() + '_dropdown_' + filteredValues.join(','));
    }
  } else {
    customFieldsArray.splice(fieldIndex, 1);
  }

  return customFieldsArray.join(';');
};

export const removeItemFromCustomFieldFilter = (
  customFieldsFilter: string,
  fieldId: string,
  optionId: string,
) => {
  const currentFilterArray = customFieldsFilter.split(';');
  const fieldIndex = getIndexOfFieldById(currentFilterArray, fieldId);

  if (fieldIndex > -1) {
    const splitFilter = currentFilterArray[fieldIndex].split('_');
    let values: string[] = [splitFilter[2]];
    if (splitFilter[1] === 'dropdown') {
      values = splitFilter[2].split(',');
    }
    if (values.length > 0) {
      values = values.filter((v) => v !== optionId);
      const valuesString = values.join(',');
      if (valuesString === '') {
        currentFilterArray.splice(fieldIndex, 1);
      } else {
        currentFilterArray[fieldIndex] = fieldId + '_' + splitFilter[1] + '_' + valuesString;
      }
    } else {
      currentFilterArray.splice(fieldIndex, 1);
    }
    return currentFilterArray.join(';');
  }
};

export const parseCustomFieldStringToSearchBody = (customFieldsFilter: string) => {
  const currentFilterArray = customFieldsFilter.split(';');
  const searchBody: { id: number; textValue?: string; selectionOptionIds?: string[] }[] = [];
  currentFilterArray.forEach((f) => {
    const splitFilter = f.split('_');
    if (splitFilter[1] === 'text') {
      searchBody.push({ id: +splitFilter[0], textValue: splitFilter[2] });
    } else {
      const options = splitFilter[2].split(',');
      searchBody.push({ id: +splitFilter[0], selectionOptionIds: options });
    }
  });
  return searchBody;
};

export const getCustomFieldIdByLabel = (customFields: CustomField[], label: string) => {
  return customFields.find((f) => f.label === label)?.id;
};

// ------- custom fields assigned to sim

export const getAssignedOption = (
  index: number,
  selectionOptions: CustomFieldSelectedOption[],
  assignedCustomFields: { label: string; value: string }[],
) => {
  let assignedOption: CustomFieldSelectedOption | null = null;
  if (index > -1) {
    assignedOption =
      selectionOptions.find((option) => option.id === +assignedCustomFields[index].value) || null;
  }
  return assignedOption;
};

export const getAssignedFields = (
  simCustomFields: CustomFieldValue[],
  customFieldsData: CustomField[],
) => {
  const assignedCF: AssignedCustomField[] = [];
  simCustomFields?.forEach((field: CustomFieldValue) => {
    const assignedIndex = assignedCF.findIndex((f) => f.label === field.label);
    const data = customFieldsData?.find((item) => field.label === item.label);

    const value =
      data?.dataType === FIELD_TYPE.Text
        ? field.textValue || ''
        : data?.selectionOptions.find((o) => o.label === field.selectionOption)?.id?.toString() ||
          '';

    if (assignedIndex > -1) {
      assignedCF[assignedIndex].value = value;
    } else {
      assignedCF.push({
        label: field.label,
        value: value,
      });
    }
  });
  return assignedCF;
};

export const addAssignedValue = (
  index: number,
  value: string | null,
  field: CustomField,
  prevAssigned: AssignedCustomField[],
) => {
  if (index > -1) {
    const customFields = [...prevAssigned];
    customFields[index] = {
      ...customFields[index],
      value: value || '',
    };
    return customFields;
  } else if (!value) {
    return prevAssigned;
  } else {
    return [
      ...prevAssigned,
      {
        label: field.label,
        value: value,
      },
    ];
  }
};

export const mapAssignedCustomFieldsToCustomField = (
  assignedCustomFields: AssignedCustomField[],
  customFieldsData: any,
  originalCustomFields: AssignedCustomField[],
) => {
  const uniqueFields = assignedCustomFields.filter(
    (item1) =>
      !originalCustomFields.some(
        (item2) => item2.label === item1.label && item2.value === item1.value,
      ),
  );

  return (
    uniqueFields.map((f) => ({
      id: getCustomFieldIdByLabel(customFieldsData!, f.label)!,
      value: f.value,
    })) || []
  );
};

export const getCustomFieldValuesForQueryKey = (
  customFields: Record<string, string | string[]>,
) => {
  if (isEmpty(customFields)) {
    return '';
  }

  const anyCustomValue = Object.values(customFields).some(
    (value) => value !== '' && !isEqual(value, []),
  );

  if (!anyCustomValue) {
    return '';
  }

  return customFields;
};
