import { Counter } from 'common/components/Counter/Counter';

export type CounterCellProps = {
  value: number;
};

export const CounterCell = ({ value }: CounterCellProps) => {
  return <Counter count={value} />;
};

export const CounterCellSuccess = ({ value }: CounterCellProps) => {
  return <Counter count={value} variant="success" />;
};

export const CounterCellFailed = ({ value }: CounterCellProps) => {
  return <Counter count={value} variant="error" />;
};
