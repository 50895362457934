import Box from '@mui/material/Box';
import { ChipsClipper } from './ChipsClipper';
import { ChipsWrapper } from './ChipsWrapper';
import { useTheme } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { DEFAULT_BORDER_RADIUS } from 'theme/constants';
import { useDebounce } from 'common/useDebounce';
import Stack from '@mui/material/Stack';
import { Chip } from './Chip';
import HoverPopover from 'material-ui-popup-state/HoverPopover';
import { bindHover, bindPopover, usePopupState } from 'material-ui-popup-state/hooks';
import { ChipSkeleton } from './ChipSkeleton';

type ChipDisplayProps = {
  previewLabels: string[];
  loadedLabels: string[];
  hasMore: boolean;
  onLoad: () => void;
  shouldWrap?: boolean;
};

export const ChipDisplay: React.FC<ChipDisplayProps> = ({
  previewLabels,
  loadedLabels,
  hasMore,
  onLoad,
  shouldWrap,
}) => {
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'chipsPopover',
  });

  const theme = useTheme();

  useEffect(() => {
    if (popupState.isOpen) {
      onLoad();
    }
  }, [popupState.isOpen]);

  const loading = previewLabels.length > 0 && loadedLabels.length === 0;
  const labels = loading ? previewLabels : loadedLabels;

  const visibilityDebounce = useDebounce(popupState.isOpen, 500);

  return (
    <Box {...bindHover(popupState)} data-testid="chips" sx={{ width: '100%' }}>
      <Box sx={{ cursor: !shouldWrap ? 'pointer' : 'inherit' }} aria-haspopup="true">
        <Box sx={{ pointerEvents: 'none' }}>
          {shouldWrap && <ChipsWrapper labels={previewLabels} />}
          {!shouldWrap && <ChipsClipper labels={previewLabels} hasMore={hasMore} />}
        </Box>
      </Box>
      {!shouldWrap && (
        <HoverPopover
          {...bindPopover(popupState)}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          PaperProps={{
            sx: {
              //@ts-ignore
              border: `1px solid ${theme.palette.border}`,
              borderRadius: DEFAULT_BORDER_RADIUS,
              p: 2,
              maxWidth: '300px',
            },
          }}
          sx={{
            opacity: visibilityDebounce ? 1.0 : 0,
          }}
        >
          <Stack gap={1} direction="row" flexWrap="wrap">
            {labels.map((label) => (
              <React.Fragment key={label}>
                <Chip label={label} variant="outlined" />
              </React.Fragment>
            ))}
            {loading ? <ChipSkeleton width={50} numberOfRepetitions={2} /> : null}
          </Stack>
        </HoverPopover>
      )}
    </Box>
  );
};
