import { useQuery } from '@tanstack/react-query';
import { fetchCustomFields } from 'admin/customFields/api/customFieldsApi';
import { CustomField } from 'admin/customFields/entities/customFields.entity';
import { customFieldsFilterAtom } from 'atoms/filters/sim';
import { useSetAtom } from 'jotai';
import { useEffect } from 'react';

export const useCustomFieldsFiltersInitialization = () => {
  const { isFetched, data } = useQuery({
    queryKey: ['customFields'],
    queryFn: async () => {
      const fields = await fetchCustomFields();

      return fields.filter((f: CustomField) => f.enabled);
    },
    gcTime: 0,
  });

  const setCustomFieldsFilters = useSetAtom(customFieldsFilterAtom);

  useEffect(() => {
    if (isFetched && data?.length && data?.length > 0) {
      setCustomFieldsFilters((currentFilters: any) => {
        const newCustomFilters = data.reduce((filters, customFields) => {
          let currentValue = currentFilters[customFields.id.toString()];
          if (!currentValue) {
            currentValue = customFields.dataType === 'Dropdown' ? [] : '';
          }

          return {
            ...filters,
            [customFields.id.toString()]: currentValue,
          };
        }, {} as Record<string, string | string[]>);

        return newCustomFilters;
      });
    }
  }, [isFetched, data]);
};
