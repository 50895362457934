import { lastActiveProfileFilterAtom } from 'atoms/filters';

import ToggleButton from '@mui/material/ToggleButton';
import { ToggleGroup } from 'common/components/Inputs/ToggleGroup/ToggleGroup';
import { useAtom } from 'jotai';
import { LAST_ACTIVE_PROFILE_FILTER } from '../../data/filters.constants';

export const LastActiveProfileInput = () => {
  const [lastActiveProfile, setLastActiveProfile] = useAtom(lastActiveProfileFilterAtom);

  return (
    <ToggleGroup
      sx={{
        width: '100%',
        justifyContent: 'stretch',
      }}
      value={lastActiveProfile}
      onChange={(_event, value) => {
        if (value === null) {
          return;
        }

        setLastActiveProfile(value);
      }}
    >
      <ToggleButton sx={{ flex: 1 }} value={LAST_ACTIVE_PROFILE_FILTER.PRIMARY}>
        Primary
      </ToggleButton>
      <ToggleButton sx={{ flex: 1 }} value={LAST_ACTIVE_PROFILE_FILTER.ANY}>
        Any
      </ToggleButton>
      <ToggleButton sx={{ flex: 1 }} value={LAST_ACTIVE_PROFILE_FILTER.SECONDARY}>
        Secondary
      </ToggleButton>
    </ToggleGroup>
  );
};
