import { PROVISIONING_STATUS_TO_STATUS_LABEL_MAP } from 'bulkProvisioning/BulkProvisioning.constants';
import { ProvisioningFilters } from 'bulkProvisioning/BulkProvisioning.interface';

import { FiltersChips, FilterChipProps } from 'common/components/Filters/FiltersChips';
import { useTranslation } from 'react-i18next';
import { SourceChips } from './Components/Chips/SourceChips';
import { isEmpty } from 'lodash';

type BulkFiltersChipsProps = Pick<FilterChipProps, 'onFilterClear' | 'onFiltersClear'> & {
  filters: ProvisioningFilters;
};

export const BulkFiltersChips: React.FC<BulkFiltersChipsProps> = ({
  filters,
  onFilterClear,
  onFiltersClear,
}) => {
  const { t } = useTranslation();
  const createValuesForChips = (filters: ProvisioningFilters) => {
    const filtersNames = {
      status: t('common.status'),
      orderNumber: t('common.orderNumber'),
      source: t('bulkProvisioning.source'),
      uploadDate: t('common.uploadDate'),
    };

    const { status, orderNumber } = filters;
    const chipsValues: Partial<Record<keyof typeof filtersNames, string>> = {};

    if (status !== '') {
      //@ts-ignore
      chipsValues.status = `${filtersNames.status}: ${PROVISIONING_STATUS_TO_STATUS_LABEL_MAP[status]}`;
    }

    if (orderNumber !== '') {
      chipsValues.orderNumber = `${filtersNames.orderNumber}: ${orderNumber}`;
    }

    const from = filters.uploadDateFrom
      ? `from ${filters.uploadDateFrom.toLocaleDateString()}, `
      : '';

    const to = filters.uploadDateTo ? `to ${filters.uploadDateTo.toLocaleDateString()}` : '';

    if (from || to) {
      chipsValues.uploadDate = `${filtersNames.uploadDate}: ${from}${to}`;
    }
    return chipsValues;
  };

  const filtersChips = createValuesForChips(filters);

  const externalChips = [];
  if (!isEmpty(filters.source)) {
    externalChips.push(
      <SourceChips
        source={filters.source}
        onDelete={(source) => {
          onFilterClear('source', source);
        }}
      />,
    );
  }

  return (
    <FiltersChips
      externalChips={externalChips}
      filters={filtersChips}
      onFilterClear={onFilterClear}
      onFiltersClear={onFiltersClear}
    />
  );
};
