import { activePageAtom, pagesAtom, tabsAtom } from 'atoms/pages';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

export const NavigationTracker = () => {
  const location = useLocation();
  const tabs = useAtomValue(tabsAtom);
  const [pages, setPages] = useAtom(pagesAtom);

  const setActivePage = useSetAtom(activePageAtom);

  useEffect(() => {
    const currentTab = tabs.find((tab) => location.pathname.includes(tab.pathname));
    const tabPage = currentTab?.page as keyof typeof pages;
    if (currentTab && pages[tabPage]) {
      setPages({
        ...pages,
        [currentTab.page]: {
          ...pages[tabPage],
          activeTab: currentTab.name,
        },
      });
      setActivePage(currentTab.page);
    }
  }, [location.pathname]);

  return (
    <>
      <Outlet />
    </>
  );
};
