import { useEffect, useMemo } from 'react';
import {
  fetchSessionHistory,
  SessionHistoryEntry,
} from 'common/components/SessionHistory/services/SessionHistoryApi';
import { ErrorCodes } from 'common/errorCodes';
import { useInView } from 'react-intersection-observer';
import { useInfiniteQuery } from '@tanstack/react-query';

export const useInfinityRSimSessionHistory = (euicc?: string) => {
  const {
    data: sessionHistoryPages,
    fetchNextPage,
    isFetchingNextPage,
    isFetched,
    hasNextPage,
  } = useInfiniteQuery<SessionHistoryEntry[]>({
    queryKey: ['RSimSessionHistory', euicc],
    queryFn: async ({ signal, pageParam }) => {
      return await fetchSessionHistory(euicc!, pageParam as Date, true, signal);
    },
    initialPageParam: new Date(),
    enabled: !!euicc,
    retry: (failureCount, error) => {
      if (error instanceof Error && error.message === ErrorCodes.NotFound) {
        return false;
      }
      return failureCount < 3;
    },
    getNextPageParam: (lastPage: SessionHistoryEntry[]) => {
      if (lastPage.length < 20) {
        return;
      }
      const toDate = lastPage.at(-1)!.sessionStart;
      toDate.setMilliseconds(toDate.getMilliseconds() + -1);
      return toDate;
    },
  });

  const sessionHistory: SessionHistoryEntry[] = useMemo(
    () =>
      sessionHistoryPages?.pages.reduce((history, page) => {
        return [...history, ...page];
      }, []) || [],
    [sessionHistoryPages],
  );

  const { ref, inView } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (inView && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, isFetchingNextPage]);

  return {
    refToLoader: ref,
    showLoader: hasNextPage,
    sessionHistory,
    fetchingEnded: isFetched,
  };
};
