import { styled } from '@mui/material';
import { KickSimSwitch } from '../KickSimSwitch';

const SimulateOutageCellRoot = styled('div')({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const SimulateOutageCell = ({ row }: any) => {
  const { euicc, profileId, paused } = row;

  return (
    <SimulateOutageCellRoot>
      <KickSimSwitch euicc={euicc} profileId={profileId} checked={paused} />
    </SimulateOutageCellRoot>
  );
};
