import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { useInfiniteQuery } from '@tanstack/react-query';
import { POLLING_RESPONSE, PROFILE, PollingResponse, fetchPollingHistory } from './pollingApi';
import { useParams } from 'react-router-dom';
import { parseISO } from 'date-fns';
import { GraphsBox } from './GraphsBox';
import { GraphLegend } from './GraphLegend';
import { PollingChartAdditionalMenu } from './PollingChartAdditionalMenu';
import { PoolingChartEntryData, PoolingGraph } from 'common/components/Graphs/PoolingGraph';
import { useDebounce } from 'usehooks-ts';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import { useGraphSessionRange } from 'common/components/Graphs/Sessions/hooks/useGraphSessionRange';
import { SessionHistoryContext } from './SessionHistoryProvider';
import { SimInventoryCan } from 'permissions/PermissionProvider';
import { Actions, Subjects } from 'permissions/ability';
import { palette } from 'theme/palette';

const POLLING_RESPONSE_COLORS: Record<PollingResponse, string> = {
  [POLLING_RESPONSE.ACK]: palette.blue['600'],
  [POLLING_RESPONSE.NACK]: palette.amber['600'],
  [POLLING_RESPONSE.NO_RESPONSE]: palette.blue['600'],
} as const;

export const PollingChart = React.memo(() => {
  const { visibleRange, selectVisibleRange, validRange, selectRange } = useGraphSessionRange();
  const { selectSession } = useContext(SessionHistoryContext);

  const { euicc } = useParams();

  const debouncedFrom = useDebounce(visibleRange.from, 500);
  const debouncedTo = useDebounce(visibleRange.to, 500);

  const { data, isFetchingNextPage, fetchNextPage, hasNextPage, isFetching } = useInfiniteQuery({
    queryKey: ['SessionHistory', 'PollingData', euicc!, debouncedFrom, debouncedTo],
    queryFn: async ({ pageParam }) => {
      try {
        const data = await fetchPollingHistory(
          euicc!,
          new Date(visibleRange.from),
          new Date(visibleRange.to),
          pageParam,
          1000,
        );

        return {
          nextPage: data.hasNextPage,
          pageNumber: pageParam || 0,
          items: data.items.map((data) => {
            return {
              x: parseISO(data.timestamp).getTime(),
              y: data.profileId === PROFILE.PROFILE_0 ? 0.5 : -0.5,
              color: POLLING_RESPONSE_COLORS[data.pollingResponse],
            };
          }),
        };
      } catch (err) {
        return [];
      }
    },
    initialPageParam: 0,
    // suspense: false,
    getNextPageParam: (lastPage: any) => {
      if (lastPage.nextPage) {
        return lastPage.pageNumber + 1;
      }
    },
  });

  const [deferredPooling, setDeferredPooling] = useState<PoolingChartEntryData[]>([]);

  useEffect(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, isFetchingNextPage]);

  useEffect(() => {
    if (!hasNextPage && !isFetching) {
      setDeferredPooling(
        data?.pages.reduce((prev: any, current: any) => {
          return [...prev, ...current.items];
        }, []) || [],
      );
    }
  }, [hasNextPage, isFetching]);

  const loading = hasNextPage || isFetching;

  const { t } = useTranslation();

  const tooltipData = useMemo(
    () => [
      {
        color: POLLING_RESPONSE_COLORS[0],
        description: t('rSimInventory.pollingChartAcknowledged'),
      },
      {
        color: POLLING_RESPONSE_COLORS[1],
        description: t('rSimInventory.pollingChartNotAcknowledged'),
      },
    ],
    [],
  );

  const onZoomComplete = useCallback(
    (range: [Date, Date]) => {
      if (selectSession) {
        selectSession({ positionX: null, index: null, setName: null });
      }

      selectVisibleRange(range[0], range[1]);
    },
    [selectVisibleRange],
  );

  return (
    <GraphsBox
      rightOffsetPx={40}
      title="Polling data"
      legend={<GraphLegend topLabel="Primary" bottomLabel="Backup" axisHeight={35} />}
      tooltipTitle={t('rSimInventory.pollingChartTitle')}
      tooltipRows={tooltipData}
    >
      <PoolingGraph
        min={validRange.from.getTime()}
        max={validRange.to.getTime()}
        from={visibleRange.from.getTime()}
        to={visibleRange.to.getTime()}
        entries={deferredPooling}
        onZoomComplete={onZoomComplete}
        onPan={selectRange}
      />
      {loading && (
        <Box
          sx={{
            position: 'absolute',
            top: 'calc(50% - 14px)',
            left: '50%',
            transform: 'translateX(-50%) translateY(-50%)',
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <SimInventoryCan I={Actions.export} a={Subjects.poolingLogs}>
        <Box sx={{ position: 'absolute', top: -8, right: 4 }}>
          <PollingChartAdditionalMenu
            euicc={euicc!}
            from={visibleRange.from}
            to={visibleRange.to}
          />
        </Box>
      </SimInventoryCan>
    </GraphsBox>
  );
});
