import MuiChip, { ChipProps as MuiChipProps } from '@mui/material/Chip';
import CancelIcon from '@mui/icons-material/Cancel';
import { ChipSkeleton } from './ChipSkeleton';
import React from 'react';

export type ChipProps = {
  color?: MuiChipProps['color'];
  size?: MuiChipProps['size'];
  variant?: MuiChipProps['variant'];
  label?: string;
  onDelete?: MuiChipProps['onDelete'];
  icon?: MuiChipProps['icon'];
  isLoading?: boolean;
};

export const Chip: React.FC<ChipProps> = ({
  color = 'default',
  size = 'medium',
  variant = 'outlined',
  label = '',
  onDelete,
  icon,
  isLoading,
}) => {
  return (
    <>
      {isLoading ? (
        <ChipSkeleton />
      ) : (
        <MuiChip
          label={label}
          variant={variant}
          color={color}
          size={size}
          onDelete={onDelete}
          deleteIcon={<CancelIcon />}
          icon={icon}
        />
      )}
    </>
  );
};
