import Dexie, { Table } from 'dexie';
import { LastRadiusType } from './interimsModels';

export interface CachedDataRange {
  sessionId: string;
  start: number;
  end: number;
}

export interface CachedInterims {
  id: string;
  sessionId: string;
  timestamp: Date;
  usage: number;
  cumulativeUsage: number;
  localization: string;
  radioAccess: string;
  operatorCode: string;
  lastRadiusType: LastRadiusType;
  inputUsage: number;
  outputUsage: number;
}

export class InterimsStorage extends Dexie {
  cachedDataRange!: Table<CachedDataRange>;
  cachedInterims!: Table<CachedInterims>;

  constructor() {
    super('InterimsStorage');
    this.version(1).stores({
      cachedDataRange: '++sessionId, start, end', // Primary key and indexed props
      cachedInterims: '++id, sessionId, timestamp'
    });
  }
}

export const interimsDatabase = new InterimsStorage();
