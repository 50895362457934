export const parseNumberOrDefault = (
  value: string | null,
  defaultValue: number | undefined = 0
) => {
  if (!value) {
    return defaultValue;
  }

  const parsedValue = parseInt(value);
  return isNaN(parsedValue) ? defaultValue : parsedValue;
};
