import { subDays } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';

export const useLastMonthRange = () => {
  const [now, setNow] = useState(new Date());
  useEffect(() => {
    const intervalId = setInterval(() => {
      setNow(new Date());
    }, 30_000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const dateRange: [Date, Date] = useMemo(() => {
    return [subDays(now, 30), now];
  }, [now]);

  return dateRange;
};
