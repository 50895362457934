import { Bar, ChartProps } from 'react-chartjs-2';
import React, { useMemo, useRef } from 'react';
import {
  Chart as ChartJS,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip,
  TimeScale,
  CategoryScale,
  BarElement,
  TimeSeriesScale,
  Filler,
  Decimation,
  TooltipModel
} from 'chart.js';

import 'chartjs-adapter-luxon';
import { GRAPH_DEFAULT_OPTIONS, chartBorderPlugin } from '../../graphConfig';
import { mapDataPointsToDatasets } from '../../utils/usageGraphUtils';
import zoom from 'chartjs-plugin-zoom';
import { barRangePlugin, highlightPlugin } from '../../utils/graphPlugins';
import { useDispatchEventToChart } from './hooks/useDispatchEventToChart';
import { useGraphZoomConfig } from './hooks/useGraphZoomConfig';

ChartJS.register(
  TimeScale,
  CategoryScale,
  LinearScale,
  Tooltip,
  PointElement,
  BarElement,
  TimeSeriesScale,
  LineElement,
  Filler,
  zoom,
  Decimation
);

export type UsageChartEntryData = {
  x: [Date, Date];
  inputUsage: number;
  outputUsage: number;
  tooltipData: {
    label: string;
    dataIndex: number;
  };
};

export type UsageGraphProps = {
  entries: UsageChartEntryData[];
  highlightTo: Date | null;
  highlightFrom: Date | null;
  selectedRangeStart: Date;
  selectedRangeStop: Date;
  zoomBoundaryStart: Date;
  zoomBoundaryStop: Date;
  maxZoomInMS: number;
  onZoomComplete: (range: [Date, Date]) => void;
  tooltip: (positionX: number, index: number | null, setName: string | null) => void;
  onClick: () => void;
  plugins?: ChartProps['plugins'];

  onPan?: (newMin: Date, newMax: Date) => void;
};

export const UsageGraph: React.FC<UsageGraphProps> = React.memo(
  ({
    selectedRangeStart,
    selectedRangeStop,
    entries,
    maxZoomInMS,
    highlightFrom,
    highlightTo,
    zoomBoundaryStart,
    zoomBoundaryStop,
    onZoomComplete,
    tooltip,
    onClick,
    onPan,
    plugins = []
  }) => {
    const { datasets, maxValue } = useMemo(() => {
      const { data, maxValue } = mapDataPointsToDatasets(
        entries,
        selectedRangeStart,
        selectedRangeStop
      );

      return {
        datasets: {
          datasets: [
            {
              barThickness: 1,
              data
            }
          ]
        },
        maxValue
      };
    }, [entries, selectedRangeStart, selectedRangeStop]);

    const zoomConfig = useGraphZoomConfig(
      maxZoomInMS,
      zoomBoundaryStart.valueOf(),
      zoomBoundaryStop.valueOf(),
      onZoomComplete,
      onPan
    );
    const options = useMemo(
      () => ({
        ...GRAPH_DEFAULT_OPTIONS,
        indexAxis: 'y',
        scales: {
          x: {
            ...GRAPH_DEFAULT_OPTIONS!.scales!.x,
            //@ts-ignore
            type: 'time',
            min: selectedRangeStart,
            max: selectedRangeStop
          },
          //@ts-ignore
          y: {
            ...GRAPH_DEFAULT_OPTIONS!.scales!.y,
            type: 'linear',
            min: 0,
            max: 1.2 * maxValue,
            display: false,
            beginAtZero: true,
            offset: false,
            grid: {
              ...GRAPH_DEFAULT_OPTIONS!.scales!.y?.grid,
              offset: false
            }
          }
        },
        plugins: {
          ...GRAPH_DEFAULT_OPTIONS!.plugins,
          highlightPlugin: {
            from: highlightFrom,
            to: highlightTo
          },
          zoom: zoomConfig,
          tooltip: {
            enabled: false,
            axis: 'x',
            mode: 'x',
            intersect: false,
            external: (context: { tooltip: TooltipModel<'bar'>; chart: ChartJS<'bar'> }) => {
              if (context.tooltip.opacity === 0) {
                tooltip(0, null, null);
                return;
              }
              const dataPoint = context.tooltip.dataPoints[0];
              const pointRaw = dataPoint.raw;
              //@ts-ignore
              const index = pointRaw.dataIndex;
              //@ts-ignore
              const label = pointRaw.label;
              //@ts-ignore
              const [startX, endX] = pointRaw.x;
              const scaleX = context.chart.boxes[0] as TimeScale;
              const startPositionX = scaleX.getPixelForValue(
                Math.max(startX, selectedRangeStart.valueOf())
              );
              const endPositionX = scaleX.getPixelForValue(
                Math.min(endX, selectedRangeStop.valueOf())
              );
              const tooltipPositionX = (startPositionX + endPositionX) / 2;
              tooltip(tooltipPositionX, index, label);
            }
          }
        }
      }),
      [
        selectedRangeStart,
        selectedRangeStop,
        datasets,
        tooltip,
        onZoomComplete,
        highlightFrom,
        highlightTo
      ]
    );

    const chartRef = useRef<ChartJS>();
    const onChartClick = () => {
      onClick();
    };

    useDispatchEventToChart(chartRef);

    return (
      <Bar
        plugins={[chartBorderPlugin, barRangePlugin, highlightPlugin, ...plugins]}
        //@ts-ignore
        data={datasets}
        //@ts-ignore
        options={options}
        //@ts-ignore
        ref={chartRef}
        onClick={onChartClick}
      />
    );
  }
);
