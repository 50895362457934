import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useMeasure from 'react-use-measure';

type GraphLegendProps = {
  topLabel: string;
  bottomLabel: string;
  axisHeight: number;
};

export const GraphLegend: React.FC<GraphLegendProps> = ({
  topLabel,
  bottomLabel,
  axisHeight = 35,
}) => {
  const [root, bounds] = useMeasure();

  return (
    <Box height={'100%'} ref={root}>
      <Box
        sx={{
          position: 'absolute',
          top: `${(bounds.height - axisHeight) / 4}px`,
          left: '24px',
          transform: 'translateY(-50%)',
        }}
      >
        <Typography variant="text5" color="text.primary">
          {topLabel}
        </Typography>
      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: `${(3 * (bounds.height - axisHeight)) / 4}px`,
          left: '24px',
          transform: 'translateY(-50%)',
        }}
      >
        <Typography variant="text5" color="text.primary">
          {bottomLabel}
        </Typography>
      </Box>
    </Box>
  );
};
