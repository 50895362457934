import { useMemo, useRef } from 'react';
import { Chart } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js';
import { GRAPH_DEFAULT_OPTIONS, chartBorderPlugin } from '../../graphConfig';
import { useTranslation } from 'react-i18next';
import {
  createBackgroundBaseOnValue,
  createBorderColorBaseOnValue,
  getMarkerBorderColor,
  getMarkerColor,
} from './utils/changinInTimeGraphsColorMappers';
import { ChangingInTimeGraphDataset } from './models/changingInTimeGraphModels';
import { useDispatchEventToChart } from './hooks/useDispatchEventToChart';
import { useGraphZoomConfig } from './hooks/useGraphZoomConfig';

export type ChangingInTimeGraphProps = {
  onClick?: () => void;
  startMarkerValue?: string;
  isStartMarkerSameValue?: boolean;
  startMarkerDate?: Date | null;
  dataset: ChangingInTimeGraphDataset;
  zoomBoundaryStart: Date;
  zoomBoundaryStop: Date;
  maxZoomInMS: number;
  selectedRangeStart: Date;
  selectedRangeStop: Date;
  highlightStartMarker: boolean;
  onZoomComplete: (zoom: [Date, Date]) => void;
  onStartMarkerHovered?: (value: boolean) => void;
  onPan?: (newMin: Date, newMax: Date) => void;
};

export const ChangingInTimeGraph: React.FC<ChangingInTimeGraphProps> = ({
  dataset,
  onStartMarkerHovered,
  isStartMarkerSameValue,
  startMarkerDate,
  startMarkerValue,
  zoomBoundaryStart,
  zoomBoundaryStop,
  maxZoomInMS,
  selectedRangeStart,
  selectedRangeStop,
  highlightStartMarker,
  onZoomComplete,
  onClick,
  onPan,
}) => {
  const { t } = useTranslation();

  const zoomConfig = useGraphZoomConfig(
    maxZoomInMS,
    zoomBoundaryStart.valueOf(),
    zoomBoundaryStop.valueOf(),
    onZoomComplete,
    onPan,
  );
  const barOptions = useMemo(() => {
    return {
      ...GRAPH_DEFAULT_OPTIONS,
      indexAxis: 'y',
      scales: {
        x: {
          type: 'time',
          min: selectedRangeStart,
          max: selectedRangeStop,
          ...GRAPH_DEFAULT_OPTIONS!.scales!.x,
        },
        y: {
          stacked: true,
          beginAtZero: false,
          ...GRAPH_DEFAULT_OPTIONS!.scales!.y,
        },
        y1: {
          type: 'linear',
          display: false,
          max: 1,
        },
      },

      plugins: {
        ...GRAPH_DEFAULT_OPTIONS!.plugins,
        tooltip: {
          xAlign: 'left',
          yAlign: 'center',
          external: (context: any) => {
            const tooltipVisible = !!context?.tooltip?.opacity;
            if (!tooltipVisible) {
              onStartMarkerHovered && onStartMarkerHovered(tooltipVisible);
            }
          },
          callbacks: {
            title: (event: any) => {
              if (event.length > 0) {
                if (event[0].datasetIndex === 0 && onStartMarkerHovered) {
                  onStartMarkerHovered(true);
                }

                const [entry] = event;
                const {
                  dataIndex,
                  dataset: { data },
                } = entry;

                const { label } = data[dataIndex];
                return label || t('common.unknown');
              }
            },
            label: () => '',
          },
        },
        zoom: zoomConfig,
      },
    };
  }, [
    selectedRangeStart.getTime(),
    selectedRangeStop.getTime(),
    startMarkerDate,
    onStartMarkerHovered,
  ]);

  const graphData = useMemo(() => {
    const backgroundColors = createBackgroundBaseOnValue(dataset);
    const borderColors = createBorderColorBaseOnValue(dataset);

    const startDataset = [];
    if (startMarkerDate) {
      startDataset.push({
        type: 'line',
        yAxisID: 'y1',
        usePointStyle: true,
        pointStyle: 'rectRot',
        radius: 10,
        hoverRadius: 10,
        backgroundColor: getMarkerColor(startMarkerValue, dataset, backgroundColors),
        borderColor: getMarkerBorderColor(startMarkerValue),
        hoverBorderColor: getMarkerBorderColor(startMarkerValue),
        data: [
          {
            x: startMarkerDate,
            y: 1,
            label: startMarkerValue,
          },
        ],
      });

      if (highlightStartMarker) {
        startDataset.push({
          type: 'line',
          yAxisID: 'y1',
          usePointStyle: true,
          pointStyle: 'rect',
          radius: 15,
          hoverRadius: 15,
          backgroundColor: 'rgba(111, 183, 225, 0.5)',
          borderColor: 'rgba(13, 144, 220, 0.5)',
          data: [
            {
              x: startMarkerDate,
              y: 1,
              label: startMarkerValue,
            },
          ],
        });
      }
    }

    return {
      datasets: [
        ...startDataset,
        {
          type: 'bar',
          hoverBorderColor: borderColors,
          backgroundColor: backgroundColors,
          borderColor: borderColors,
          borderWidth: 1,
          borderRadius: 4,
          borderSkipped: false,
          barThickness: 24,
          data: dataset,
        },
      ],
    };
  }, [dataset, startMarkerDate, isStartMarkerSameValue, startMarkerValue, highlightStartMarker]);

  const chartRef = useRef<ChartJS>();
  useDispatchEventToChart(chartRef);

  return (
    <Chart
      ref={chartRef}
      //@ts-ignore
      options={barOptions}
      //@ts-ignore
      data={graphData}
      plugins={[chartBorderPlugin]}
      onClick={onClick}
    />
  );
};
