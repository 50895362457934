import { authFetch } from 'auth/utils';
import { ErrorCodes } from 'common/errorCodes';
import saveAs from 'file-saver';
import { ReportAlreadyScheduledError } from 'simInventory/SimInventoryApi/simInventoryApi';
import { getFileNameFromContentDispositionHeader } from 'common/utils/fileDownload';
import { RSimInventoryFilters, RSimSort } from '../models/RSimProfile.model';
import { prepareRSimInventorySearchBody } from './rSimApi';

export const downloadRSimProductsReportWithBackgroundSupport = async (
  searchParams: RSimInventoryFilters,
  sort: RSimSort | null = null,
) => {
  const body = prepareRSimInventorySearchBody(searchParams, sort);

  const fileResponse = await authFetch(
    `${process.env.REACT_APP_SIM_INVENTORY_REPORTING_API}/rsim-product`,
    {
      method: 'POST',
      body: JSON.stringify({
        ...body,
        timeZoneIdentifier: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );

  if (!fileResponse.ok && fileResponse.status.toString() === ErrorCodes.BadRequest) {
    const { validationErrors } = await fileResponse.json();
    if ('MaxRsimProductsCount' in validationErrors) {
      const maxRSimProductsCountError = validationErrors
        .MaxRsimProductsCount[0] as MaxRSimProductsValidationError;
      throw new MaxRSimProductsCountError(maxRSimProductsCountError.state.maxRSimProductsCount);
    }
    if ('ReportAlreadyScheduled' in validationErrors) {
      throw new ReportAlreadyScheduledError();
    }
  }

  if (!fileResponse.ok) {
    throw new Error(fileResponse.statusText);
  }

  if (fileResponse.status === 202) {
    return;
  }

  const filename = getFileNameFromContentDispositionHeader(
    fileResponse.headers.get('Content-Disposition'),
  );

  const blob = await fileResponse.blob();

  saveAs(blob, filename);

  return fileResponse;
};

export const checkIsLargeReport = async (
  searchParams: RSimInventoryFilters,
  sort: RSimSort | null = null,
) => {
  const body = prepareRSimInventorySearchBody(searchParams, sort);

  const response = await authFetch(
    `${process.env.REACT_APP_SIM_INVENTORY_REPORTING_API}/rsim-product/is-large-report`,
    {
      method: 'POST',
      body: JSON.stringify({
        ...body,
        timeZoneIdentifier: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  const { largeReport } = await response.json();

  return largeReport;
};

export type MaxRSimProductsValidationError = {
  state: {
    maxRSimProductsCount: number;
  };
};

export class MaxRSimProductsCountError extends Error {
  maxRSimProductsCount: number;

  constructor(maxRsimProductsCount: number) {
    super(ErrorCodes.BadRequest);
    Object.setPrototypeOf(this, MaxRSimProductsCountError.prototype);
    this.maxRSimProductsCount = maxRsimProductsCount;
  }
}
