import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';

type FiltersButtonProps = {
  active: boolean;
  onClick: () => void;
};

export const FiltersButton = ({ active, onClick }: FiltersButtonProps) => {
  const { t } = useTranslation();

  return (
    <Button
      startIcon={<AddIcon />}
      onClick={onClick}
      sx={{
        minWidth: '92px',
      }}
      color={active ? 'active' : 'secondary'}
    >
      {t('common.filters')}
    </Button>
  );
};
