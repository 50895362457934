import { ConfirmationDialog } from 'common/components/Dialog/ConfirmationDialog';
import { useMutation } from '@tanstack/react-query';

type ConfirmActionDialogProps = {
  open: boolean;
  submit: (data: any) => Promise<void>;
  message: string;
  title: string;
  onClose: () => void;
  data: any;
};

export const ConfirmActionDialog = ({
  message,
  title,
  data,
  submit,
  open,
  onClose,
}: ConfirmActionDialogProps) => {
  const { mutate } = useMutation({
    mutationFn: () => submit(data),
    onSettled: onClose,
  });

  if (!data) {
    return null;
  }

  return (
    <ConfirmationDialog
      message={message}
      title={title}
      open={open}
      onConfirm={mutate}
      onCancel={onClose}
    />
  );
};
