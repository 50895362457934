import { ScrollbarSlider } from 'common/components/TimeRangeSlider/ScrollbarSlider';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useInterimsTimeRange } from './hooks/useInterimsTimeRange';

export const InterimScrollbarRangeSlider = () => {
  const {
    selectedRange,
    availableRange,
    moveVisibleRangeLeft,
    moveVisibleRangeRight,
    moveVisibleRangeTo,
  } = useInterimsTimeRange();

  const disableStartArray = selectedRange.from.getTime() === availableRange.from.getTime();
  const disableEndArray = selectedRange.to.getTime() === availableRange.to.getTime();

  return (
    <Stack direction="row" justifyContent="center" alignItems={'center'} spacing={1} mx={-7}>
      <IconButton>
        <ArrowBackIosNewIcon
          sx={{
            fontSize: '12px',
            color: () => {
              if (disableStartArray) {
                return 'border';
              }

              return 'text.tertiary';
            },
          }}
          onClick={() => {
            moveVisibleRangeLeft(0.1);
          }}
        />
      </IconButton>
      <ScrollbarSlider
        min={availableRange.from.getTime()}
        max={availableRange.to.getTime()}
        value={[selectedRange.from.getTime(), selectedRange.to.getTime()]}
        onChange={(values) => {
          const [min, max] = values;
          moveVisibleRangeTo(new Date(min), new Date(max));
        }}
      />
      <IconButton>
        <ArrowForwardIosIcon
          sx={{
            fontSize: '12px',
            fontWeight: 'bold',
            color: () => {
              if (disableEndArray) {
                return 'border';
              }

              return 'text.tertiary';
            },
          }}
          onClick={() => {
            moveVisibleRangeRight(0.1);
          }}
        />
      </IconButton>
    </Stack>
  );
};
