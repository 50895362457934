import { useMemo } from 'react';
import { Chart as ChartJS, TimeScale, TooltipModel } from 'chart.js';
import { isMobile } from 'react-device-detect';

export const useGraphTooltipConfig = (
  from: number,
  to: number,
  callback: (positionX: number, index: number | null, setName: string | null) => void
) => {
  const tooltipConfig = useMemo(
    () =>
      !isMobile
        ? {
            enabled: false,
            external: (context: { tooltip: TooltipModel<'bar'>; chart: ChartJS<'bar'> }) => {
              if (context.tooltip.opacity === 0) {
                callback(0, null, null);
                return;
              }

              const dataPoint = context.tooltip.dataPoints[0];

              const pointRaw = dataPoint.raw;

              //@ts-ignore
              const index = pointRaw.dataIndex;

              //@ts-ignore
              const setName = pointRaw.y;

              //@ts-ignore
              const [startX, endX] = pointRaw.x;

              const scaleX = context.chart.boxes[0] as TimeScale;

              const startPositionX = scaleX.getPixelForValue(Math.max(startX, from));
              const endPositionX = scaleX.getPixelForValue(Math.min(endX, to));
              const tooltipPositionX = (startPositionX + endPositionX) / 2;

              callback(tooltipPositionX, index, setName);
            }
          }
        : {
            enabled: false
          },
    [from, to, callback, callback]
  );

  return tooltipConfig;
};
