import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { en } from './en/en';

i18next.use(initReactI18next).init({
  fallbackLng: 'en',
  defaultNS: 'translation',
  resources: {
    en,
  },
});

export { i18next as i18 };
