import { ObjectValues } from 'common/tsHelpers';

export const SESSION_TYPE = {
  ZERO_BYTE: 'ZERO_BYTE_SESSION',
  NOT_FINALIZED: 'NOT_FINALIZED',
  NORMAL: 'NORMAL'
} as const;

export type SessionType = ObjectValues<typeof SESSION_TYPE>;

export type SessionAnalysisEntryGraphData = {
  y: -1 | 1 | 0;
  x: number;
  date: Date;
  dataOutputVelocity: number;
  dataInputVelocity: number;
};

export type SessionAnalysisEntry = {
  name: string;
  id: string;
  status: SessionType;
  dataOutput: number;
  dataInput: number;
  dataOutputVelocity: number;
  dataInputVelocity: number;
  localization: string;
  data: SessionAnalysisEntryGraphData[];
  sessionStartDate: Date;
  sessionEndDate: Date;
};

export type ChartPosition = {
  index: number | null;
  x: number;
};
