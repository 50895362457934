import { useTranslation } from 'react-i18next';
import { provisionSim } from './api/simActionsApi';
import { ConfirmActionDialog } from 'common/components/ConfigurableTable/Components/Actions/ConfirmActionDialog';

type ActivationDialogProps = {
  open: boolean;
  onClose: () => void;
  data: any;
};

export const ActivationDialog = ({ data, open, onClose }: ActivationDialogProps) => {
  const { t } = useTranslation();

  return (
    <ConfirmActionDialog
      open={open}
      data={data}
      onClose={onClose}
      submit={(data: any) => provisionSim([data.connectionId], true)}
      message={`${t('simInventory.activationIccid')} ${data?.iccid}`}
      title={t('simInventory.activation')}
    />
  );
};
