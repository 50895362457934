import { CustomField } from 'admin/customFields/entities/customFields.entity';

import List from '@mui/material/List';
import { FilterListItem } from 'common/components/Filters/FilterListItem';
import { useQuery } from '@tanstack/react-query';
import { CustomFieldInput } from './CustomFieldInput';

export const CustomFieldInputs = () => {
  const { data: customFieldsData, isFetched } = useQuery<CustomField[]>({
    queryKey: ['customFields'],
    enabled: false, //It will use previous fetched custom fields
    initialData: [],
  });

  return isFetched ? (
    <List>
      {customFieldsData?.map((field: CustomField) => {
        return (
          <FilterListItem key={field.id} label={field.label} htmlFor={field.id.toString()}>
            <CustomFieldInput
              name={field.label}
              type={field.dataType || 'Text'}
              id={field.id}
              selectionOptions={field.selectionOptions}
            />
          </FilterListItem>
        );
      })}
    </List>
  ) : null;
};
