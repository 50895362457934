import { gridDimensionsSelector, useGridApiContext, useGridSelector } from '@mui/x-data-grid-pro';
import { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import useMeasure from 'react-use-measure';
import { StickyBorderBox } from '../Common/StickyBorderBox';

const DetailsPanelRoot = styled('div')(({ theme }) => ({}));

const DetailsPanelContent = styled('div')(({ theme }) => ({
  background: theme.palette.background.paper,
  height: '100%',
  boxSizing: 'border-box',
  padding: '10px 30px',
}));

const BorderRoot = styled('div')({
  position: 'absolute',
  left: 0,
  right: 0,
  zIndex: 99999,
  pointerEvents: 'none',
});

export const DetailsPanel = ({ children }: any) => {
  const apiRef = useGridApiContext();
  const [root, rootBounds] = useMeasure();

  const dimensions = useGridSelector(apiRef, gridDimensionsSelector);

  useEffect(() => {
    if (dimensions.rowWidth < rootBounds.width && dimensions.isReady) {
      apiRef.current.setColumnWidth('filler', rootBounds.width - (dimensions.rowWidth - 100));
    }
    return () => {
      if (apiRef.current.getExpandedDetailPanels().length === 0) {
        apiRef.current.setColumnWidth('filler', 100);
      }
    };
  }, [dimensions, rootBounds]);

  return (
    <DetailsPanelRoot ref={root}>
      <BorderRoot>
        <StickyBorderBox height={rootBounds.height} />
      </BorderRoot>
      <DetailsPanelContent>{children}</DetailsPanelContent>
    </DetailsPanelRoot>
  );
};
