import React, { createContext, PropsWithChildren } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getFeatureFlags } from './FeatureFlagService';
import { FEATURE_FLAGS_MODULES, FeatureFlagsModules } from './FeatureFlags.models';

export type FeatureFlagContextProps = Record<FeatureFlagsModules, Record<string, boolean>>;

export const FeatureFlagContext = createContext<FeatureFlagContextProps>(
  {} as FeatureFlagContextProps,
);

export const FeatureFlagProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { data } = useQuery({
    queryKey: ['feature', 'flags'],
    queryFn: () => {
      return getFeatureFlags();
    },
    retry: 0,
    gcTime: 0,
    // suspense: false,
  });

  return data ? (
    <FeatureFlagContext.Provider
      value={
        data || {
          [FEATURE_FLAGS_MODULES.SIM_INVENTORY]: {},
          [FEATURE_FLAGS_MODULES.BULK_PROVISIONING]: {},
          [FEATURE_FLAGS_MODULES.AUDIT_TRAIL]: {},
        }
      }
    >
      {children}
    </FeatureFlagContext.Provider>
  ) : null;
};
