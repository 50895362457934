import { styled } from '@mui/material/styles';
import {
  GridColumnHeaders,
  GridColumnHeadersProps,
} from '@mui/x-data-grid-pro/components/GridColumnHeaders';
import React from 'react';
import { StickyBorderBox } from '../Common/StickyBorderBox';

const CustomGridColumnHeadersBorderRoot = styled('div')({
  position: 'absolute',
  left: 0,
  width: 'var(--DataGrid-rowWidth)',
});

export const ColumnHeaders = React.forwardRef<HTMLDivElement, GridColumnHeadersProps>(
  (props, ref) => {
    return (
      <>
        <CustomGridColumnHeadersBorderRoot>
          <StickyBorderBox height={42} />
        </CustomGridColumnHeadersBorderRoot>
        <GridColumnHeaders {...props} ref={ref} data-testid="headers" />
      </>
    );
  },
);
