import { lastUpdateTimeFilterAtom } from 'atoms/filters';
import MenuItem from '@mui/material/MenuItem';
import { Select } from 'common/components/Inputs/Select/Select';
import { useAtom } from 'jotai';
import { LAST_UPDATE_TIME_FILTERS, LastUpdateTimeFilters } from '../../data/filters.constants';

export const LastUpdateTimeInput = () => {
  const [lastUpdateTime, setLastUpdateTime] = useAtom(lastUpdateTimeFilterAtom);

  return (
    <Select
      name="noUpdateFor"
      value={lastUpdateTime}
      onChange={(event) => {
        const value = event.target.value as LastUpdateTimeFilters;
        setLastUpdateTime(value);
      }}
    >
      <MenuItem value={LAST_UPDATE_TIME_FILTERS.NONE}>None</MenuItem>
      <MenuItem value={LAST_UPDATE_TIME_FILTERS.MINUTES_15}>15 minutes</MenuItem>
      <MenuItem value={LAST_UPDATE_TIME_FILTERS.MINUTES_30}>30 minutes</MenuItem>
      <MenuItem value={LAST_UPDATE_TIME_FILTERS.HOUR}>1 hour</MenuItem>
      <MenuItem value={LAST_UPDATE_TIME_FILTERS.DAY}>1 day</MenuItem>
      <MenuItem value={LAST_UPDATE_TIME_FILTERS.WEEK}>1 week</MenuItem>
      <MenuItem value={LAST_UPDATE_TIME_FILTERS.MONTH}>1 month</MenuItem>
    </Select>
  );
};
