import CircularProgress from "@mui/material/CircularProgress";
import { Suspense } from 'react';
import { BulkProvisioningJobDetailsPage } from './Components/BulkProvisioningJobDetailsPage';

export const BulkProvisioningJob = () => {
  return (
    <Suspense fallback={<CircularProgress />}>
      <BulkProvisioningJobDetailsPage />
    </Suspense>
  );
};
