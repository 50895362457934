import { SimInventoryDetailsPage } from './SimInventoryDetailsPage';
import { CARD_TYPE, TagProvider } from 'tags/TagProvider';
import { SuspenseLoadingView } from '../../common/components/SuspenseLoadingView';
import Box from '@mui/material/Box';
import { DetailsSkeleton } from '../../common/components/DetailsSkeleton';
import { useIsProduct } from '../../products/sim/hooks/useIsProduct';

export const SimInventoryEntry = () => {
  return (
    <TagProvider type={CARD_TYPE.SIM} isProduct={useIsProduct()}>
      <SuspenseLoadingView
        loadFallback={
          <Box sx={{ pt: 3, pb: 6, px: 16, mt: 11 }}>
            <DetailsSkeleton numberOfRepetitions={8} />
          </Box>
        }
      >
        <SimInventoryDetailsPage />
      </SuspenseLoadingView>
    </TagProvider>
  );
};
