import { CustomFieldsPage } from './CustomFieldsPage';
import { ContentBox } from '../../common/components/AppLayout/ContentBox';
import { TableSkeleton } from '../../common/components/Table/TableSkeleton';
import { SuspenseLoadingView } from '../../common/components/SuspenseLoadingView';

export const CustomFieldsEntry = () => {
  return (
    <SuspenseLoadingView
      loadFallback={
        <ContentBox>
          <TableSkeleton showStartSkeleton showEndSkeleton numberOfRows={10} />
        </ContentBox>
      }
      queryKey={['CustomFields']}
    >
      <CustomFieldsPage />
    </SuspenseLoadingView>
  );
};
