import { alpha, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import MUIButtonBase, { ButtonBaseProps as MUIButtonBaseProps } from '@mui/material/ButtonBase';

import { DEFAULT_BORDER_RADIUS } from 'theme/constants';
import { palette } from 'theme/palette';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

const buttonBase: SxProps = {
  borderRadius: DEFAULT_BORDER_RADIUS,
  px: 4,
  py: 2.5,
  typography: 'h4',
  '&:has(.text + .icon)': {
    pr: 2,
  },
  '&:has(.icon + .text)': {
    pl: 2,
  },
};

const mediumButton: SxProps = {
  py: 2,
};

const smallButton: SxProps = {
  py: 1.75,
};

const buttonPrimarySX: SxProps<Theme> = {
  ...buttonBase,
  backgroundColor: ({ palette }) => palette.grey[600],
  color: ({ palette }) => palette.text.white,
  '&:hover, &:active': {
    backgroundColor: ({ palette }) => palette.grey[500],
    color: ({ palette }) => palette.text.white,
  },
  '&:disabled': {
    color: palette.text.tertiary,
    backgroundColor: palette.border,
  },
};

const buttonSecondarySX: SxProps<Theme> = {
  ...buttonBase,
  backgroundColor: ({ palette }) => palette.background.paper,
  color: ({ palette }) => palette.text.secondary,
  boxShadow: '4',
  '&:hover': {
    boxShadow: 0,
    backgroundColor: ({ palette }) => palette.grey[200],
    color: ({ palette }) => palette.text.primary,
  },
  '&:active': {
    boxShadow: 0,
    backgroundColor: ({ palette }) => palette.grey[300],
    color: ({ palette }) => palette.text.secondary,
  },
};

const buttonSecondaryActiveSX: SxProps<Theme> = {
  ...buttonBase,
  backgroundColor: ({ palette }) => palette.grey[200],
  color: ({ palette }) => palette.text.secondary,
};

const buttonTertiarySX: SxProps<Theme> = {
  ...buttonBase,
  backgroundColor: ({ palette }) => alpha(palette.background.paper, 0),
  color: ({ palette }) => palette.text.secondary,
  '&:hover, &:active': {
    boxShadow: 0,
    backgroundColor: ({ palette }) => palette.grey[200],
  },
};

export const buttonStyles = {
  primary: buttonPrimarySX,
  secondary: buttonSecondarySX,
  secondaryActive: buttonSecondaryActiveSX,
  tertiary: buttonTertiarySX,
};

export type ButtonProps = {
  variant: keyof typeof buttonStyles;
  children: string;
  onClick?: MUIButtonBaseProps['onClick'];
  iconStart?: React.ReactElement;
  iconEnd?: React.ReactElement;
  disabled?: boolean;
  size?: 'small' | 'medium' | 'large';
};

const iconBox: SxProps<Theme> = {
  width: '24px',
  height: '24px',
};

export const Button: React.FC<ButtonProps> = ({
  variant,
  children,
  iconStart,
  iconEnd,
  size = 'large',
  ...otherProps
}) => {
  const buttonSx = {
    ...buttonStyles[variant],
    ...(size === 'small' ? smallButton : {}),
    ...(size === 'medium' ? mediumButton : {}),
  };
  return (
    <MUIButtonBase sx={buttonSx} disableTouchRipple disableRipple {...otherProps}>
      <Stack spacing={1.5} direction={'row'} alignItems="center">
        {iconStart && (
          <Box sx={iconBox} className="icon">
            {iconStart}
          </Box>
        )}
        <Box sx={{ alignSelf: 'flex-end' }} className="text">
          {children}
        </Box>
        {iconEnd && (
          <Box sx={iconBox} className="icon">
            {iconEnd}
          </Box>
        )}
      </Stack>
    </MUIButtonBase>
  );
};
