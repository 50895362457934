import { StatusBadge } from 'common/components/StatusBadge/StatusBadge';
import { PROVISIONING_STATUS_LABEL } from 'bulkProvisioning/BulkProvisioning.constants';

import { ReactComponent as LargeCheckIcon } from 'assets/images/BulkStatus/LargeCheck.svg';
import { ReactComponent as LargeXIcon } from 'assets/images/BulkStatus/LargeX.svg';
import { ReactComponent as PendingIcon } from 'assets/images/BulkStatus/Pending.svg';

export const SuccessBadge = () => {
  return (
    <StatusBadge
      status={'success'}
      type={'filled'}
      icon={<LargeCheckIcon width={'24px'} height="24px" />}
      label={PROVISIONING_STATUS_LABEL.PROCESSING_SUCCEED}
    />
  );
};

export const DataExtractionFailedBadge = () => {
  return (
    <StatusBadge
      status={'error'}
      type={'filled'}
      icon={<LargeXIcon width={'24px'} height="24px" />}
      label={PROVISIONING_STATUS_LABEL.DATA_EXTRACTION_FAILED}
    />
  );
};

export const ProcessingFailedBadge = () => {
  return (
    <StatusBadge
      status={'error'}
      type={'filled'}
      icon={<LargeXIcon width={'24px'} height="24px" />}
      label={PROVISIONING_STATUS_LABEL.PROCESSING_FAILED}
    />
  );
};

export const ProcessingInProgressBadge = () => {
  return (
    <StatusBadge
      status={'pending'}
      type={'filled'}
      icon={<PendingIcon width={'24px'} height="24px" />}
      label={PROVISIONING_STATUS_LABEL.PROCESSING_IN_PROGRESS}
    />
  );
};

export const DataExtractionInProgressBadge = () => {
  return (
    <StatusBadge
      status={'pending'}
      type={'filled'}
      icon={<PendingIcon width={'24px'} height="24px" />}
      label={PROVISIONING_STATUS_LABEL.DATA_EXTRACTION_IN_PROGRESS}
    />
  );
};

export const DataExtractionPendingBadge = () => {
  return (
    <StatusBadge
      status={'pending'}
      type={'filled'}
      icon={<PendingIcon width={'24px'} height="24px" />}
      label={PROVISIONING_STATUS_LABEL.DATA_EXTRACTION_PENDING}
    />
  );
};

export const UnknownBadge = () => {
  return <StatusBadge status={'unknown'} type={'filled'} />;
};

export const STATUS_BADGE_MAP = {
  [PROVISIONING_STATUS_LABEL.PROCESSING_SUCCEED]: SuccessBadge,
  [PROVISIONING_STATUS_LABEL.DATA_EXTRACTION_FAILED]: DataExtractionFailedBadge,
  [PROVISIONING_STATUS_LABEL.PROCESSING_FAILED]: ProcessingFailedBadge,
  [PROVISIONING_STATUS_LABEL.PROCESSING_IN_PROGRESS]: ProcessingInProgressBadge,
  [PROVISIONING_STATUS_LABEL.DATA_EXTRACTION_IN_PROGRESS]: DataExtractionInProgressBadge,
  [PROVISIONING_STATUS_LABEL.DATA_EXTRACTION_PENDING]: DataExtractionPendingBadge,
} as const;
