import {
  SubscriptionStatus,
  SubscriptionStatusLabel
} from './simInventoryApi.interface';

import { i18 } from 'i18n/i18n';
import { SessionStatus } from '../utils/sessionStatus';

export const SUBSCRIPTION_STATUS = {
  UNRECOGNIZED: 0,
  TESTREADY: 1,
  ACTIVEREADY: 2,
  ACTIVE: 3,
  DEACTIVATED: 4,
  RETIRED: 5
} as const;

export const SUBSCRIPTION_STATUS_VALUE_LABEL_MAP: Record<
  SubscriptionStatus,
  SubscriptionStatusLabel
> = {
  [SUBSCRIPTION_STATUS.UNRECOGNIZED]: 'Unrecognized',
  [SUBSCRIPTION_STATUS.TESTREADY]: 'Test Ready',
  [SUBSCRIPTION_STATUS.ACTIVEREADY]: 'Active Ready',
  [SUBSCRIPTION_STATUS.ACTIVE]: 'Active',
  [SUBSCRIPTION_STATUS.DEACTIVATED]: 'Deactivated',
  [SUBSCRIPTION_STATUS.RETIRED]: 'Retired'
};

export const SESSION_STATUS_VALUE_LABEL_MAP: Record<SessionStatus, string> = {
  Unknown: i18.t('simInventory.unknown'),
  InSession: i18.t('simInventory.inSession'),
  Disconnected: i18.t('simInventory.disconnected')
};
