import { labelFilterAtom } from 'atoms/filters/sim';
import { labelHintsAtom } from 'atoms/hints/sim';
import { HintedTextFieldFilters } from 'common/components/Filters/Inputs/HintedTextFieldFilters';
import { useTranslation } from 'react-i18next';

export const LabelSearchInput = () => {
  const { t } = useTranslation();

  return (
    <HintedTextFieldFilters
      data-testid="Label text field"
      placeholder={t('simInventory.labelFilterPlaceholder')}
      filterAtom={labelFilterAtom}
      filterHintsAtom={labelHintsAtom}
    />
  );
};
