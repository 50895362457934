import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';

type SearchStoreContextProps = {
  searchQuery: string;
  updateSearchQuery: (searchQuery: string) => void;
};
export const SearchStoreContext = React.createContext<SearchStoreContextProps>(
  {} as SearchStoreContextProps,
);

export type FiltersProviderProps = {
  children?: React.ReactNode;
};

export const SearchStoreProvider = ({ children }: FiltersProviderProps) => {
  const [searchQuery, setSearchQuery] = useState('');

  return (
    <SearchStoreContext.Provider
      value={{
        searchQuery,
        updateSearchQuery: (searchQuery) => {
          setSearchQuery(searchQuery);
        },
      }}
    >
      {children}
      <Outlet />
    </SearchStoreContext.Provider>
  );
};
