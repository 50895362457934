export const headerHeight = '48px';

export const appBarStyle = {
  paddingRight: '22px',
  paddingLeft: '24px'
};

export const toolbarStyle = {
  '&.MuiToolbar-root': {
    px: '0',
    minHeight: headerHeight,
    height: headerHeight
  }
};
