import { ErrorCodes } from 'common/errorCodes';
import { useSuspenseQuery } from '@tanstack/react-query';
import { fetchSimInventoryEntry } from 'simInventory/SimInventoryApi/simInventoryApi';
import { useSimDetailSubscription } from './useSimDetailSubscription';
import { fetchSimProductEntry } from '../../products/sim/SimProductsApi/simProductsApi';
import { useHasFeatureFlag } from '../../featureFlags/useHasFeatureFlag';
import { useIsProduct } from '../../products/sim/hooks/useIsProduct';

export const useSimDetails = (iccid: string) => {
  const isProduct = useIsProduct();
  const queryKey = isProduct ? 'SimProductEntry' : 'SimInventoryEntry';
  const tagsMigratedToProductsView = useHasFeatureFlag('TagsMigratedToProductsView');

  const { data, refetch } = useSuspenseQuery({
    queryKey: [queryKey, iccid],
    queryFn: () =>
      !!iccid
        ? isProduct && tagsMigratedToProductsView
          ? fetchSimProductEntry(iccid!)
          : fetchSimInventoryEntry(iccid!)
        : null,
    refetchOnWindowFocus: false,
    retry: (failureCount, error) => {
      if (error instanceof Error && error.message === ErrorCodes.NotFound) {
        return false;
      }
      return failureCount < 3;
    },
  });

  useSimDetailSubscription(iccid, [queryKey, iccid]);

  return { data, refetch };
};
