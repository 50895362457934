import { SetStateAction, useMemo, useState } from 'react';
import { useQueries, useQuery } from '@tanstack/react-query';
import {
  AutocompleteSelect,
  filterArray,
} from 'common/components/Inputs/AutocompleteSelect/AutocompleteSelect';
import { useDebounce } from 'common/useDebounce';
import { useTranslation } from 'react-i18next';
import { useAtom, WritableAtom } from 'jotai';
import { fetchMno, fetchMnos } from 'simInventory/SimInventoryApi/mnoApi';

type MNOSearchFilterProps = {
  mnoFilterAtom: WritableAtom<string[], [SetStateAction<string[]>], void>;
  mnoHintsAtom: WritableAtom<any, [any], void>;
};

export const MNOSearchFilter = ({ mnoHintsAtom, mnoFilterAtom }: MNOSearchFilterProps) => {
  const { t } = useTranslation();

  const [mnos, setMnos] = useAtom(mnoFilterAtom);
  const [hints, removeHints] = useAtom(mnoHintsAtom);

  const [mnoSearch, setMnoSearch] = useState('');
  const debounceMnoSearch = useDebounce(mnoSearch, 500);
  const { data: mnoData, isFetched } = useQuery({
    queryKey: ['mno', debounceMnoSearch],
    queryFn: async () => {
      const mnos = await fetchMnos(debounceMnoSearch);

      const mnoOptions = mnos.map((mno) => ({
        id: mno.ccii.toString(),
        name: mno.name,
        toAdd: false,
        showBothIdAndName: false,
      }));

      return mnoOptions;
    },
    initialData: [],
  });

  const { data: selectedMnoData, isFetched: isSelectedMnosFetched } = useQueries({
    queries: mnos.map((id) => ({
      queryKey: ['mno', id],
      queryFn: () => fetchMno(id),
      staleTime: Infinity,
    })),
    combine: (results) => {
      return {
        data: results
          .filter((mno) => mno.isFetched && mno.data)
          .map((result) => {
            const { data } = result;
            return {
              id: data!.ccii.toString(),
              name: data!.name,
              toAdd: false,
              showBothIdAndName: false,
            };
          }),
        isFetched: !results.some((result) => !result.isFetched),
      };
    },
  });

  const optionsWithSelectedValues = useMemo(() => {
    return filterArray([...(mnoData || []), ...(selectedMnoData || [])]);
  }, [selectedMnoData, mnoData]);

  //@ts-ignore
  const mobileNetworkOperatorHint = hints
    //@ts-ignore
    ?.map((mnoId: string) => {
      const account = mnoData?.find((validMno) => validMno.id === mnoId);
      return account;
    })
    .filter((mno: any) => !!mno);

  return (
    <AutocompleteSelect
      testId="mno-multiselect"
      //@ts-ignore
      hints={mobileNetworkOperatorHint || []}
      values={mnos}
      options={optionsWithSelectedValues}
      onChange={(values) => {
        setMnos(values.map((value) => value.id));
      }}
      onRemoveHint={removeHints}
      allowAdding={false}
      inputValue={mnoSearch}
      onTextInputChange={(val) => {
        setMnoSearch(val);
      }}
      allowFilteringById={false}
      placeholder={t('simInventory.mnoFilterPlaceholder')}
      isLoading={!isFetched || !isSelectedMnosFetched}
    />
  );
};
