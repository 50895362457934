import { styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';

type IconTextCellProps = {
  value: string;
  subValue: string;
  icon?: React.ReactNode;
  subIcon?: React.ReactNode;
};

const ValueRow = styled('div')({
  display: 'flex',
});

const Icon = styled('div')({
  flex: 1,
  marginLeft: 4,
});

export const IconTextCell = ({ value, subValue, icon, subIcon }: IconTextCellProps) => {
  return (
    <div>
      <ValueRow>
        <Typography variant="text1" color="text.primary" component="div" data-testid="primaryText">
          {value}
        </Typography>
        <Icon>{icon}</Icon>
      </ValueRow>
      <ValueRow>
        <Typography
          variant="text5"
          color="text.secondary"
          component="div"
          data-testid="secondaryText"
        >
          {subValue}
        </Typography>
        <Icon>{subIcon}</Icon>
      </ValueRow>
    </div>
  );
};
