import { IconTextCell } from 'common/components/ConfigurableTable/Components/Cells/IconTextCell';
import { ReactComponent as ConfigurationIcon } from 'assets/images/configuration.svg';
import { ReactComponent as PendingIcon } from 'assets/images/pending.svg';
import { Tooltip } from 'common/components/Tooltip/Tooltip';
import { useTranslation } from 'react-i18next';

const CustomConfigurationIcon = () => {
  const { t } = useTranslation();
  return (
    <Tooltip title={t('rSimInventory.customConfigurationDetected')}>
      <ConfigurationIcon
        width="16px"
        height="16px"
        role="img"
        data-testid="custom-configuration-icon"
      />
    </Tooltip>
  );
};

const PendingConfigurationIcon = () => {
  const { t } = useTranslation();
  return (
    <Tooltip title={t('rSimInventory.configurationChangePending')}>
      <PendingIcon width="18px" height="18px" role="img" data-testid="change-pending-icon" />
    </Tooltip>
  );
};

const CustomAndPendingConfiguration = () => {
  return (
    <>
      <CustomConfigurationIcon />
      <PendingConfigurationIcon />
    </>
  );
};

export const OtaCell = ({ value, subValue, row }: any) => {
  let icon;
  const { isCustomConfigurationDetected, isPendingConfiguration } = row;
  if (isCustomConfigurationDetected && isPendingConfiguration) {
    icon = <CustomAndPendingConfiguration />;
  } else if (isCustomConfigurationDetected) {
    icon = <CustomConfigurationIcon />;
  } else if (isPendingConfiguration) {
    icon = <PendingConfigurationIcon />;
  }

  return <IconTextCell value={value} subValue={subValue} icon={icon} />;
};
