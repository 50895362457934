import { useKickSimContext } from '../KickRsimProvider';
import { PollingSimStatus, PollingStatus, RSimProfile } from '../PollingSimStatusIcon.interface';
import { PollingSimStatusIcon } from '../PollingSimStatusIcon';
import React from 'react';
import { RsimProfileStatus } from '../../RSimApi/rSimApi.interface';
import { Actions, Subjects } from 'permissions/ability';
import { useAbility } from 'permissions/hooks/useAbility';

export type RSimInventoryPollingSimStatusProps = {
  euicc: string;
  isPrimary: boolean;
  rSimStatus: RsimProfileStatus;
};

export const RSimInventoryPollingSimStatus: React.FC<RSimInventoryPollingSimStatusProps> = ({
  euicc,
  isPrimary,
  rSimStatus,
}) => {
  const { isPrimaryProfileDisabled, isSecondaryProfileDisabled } = useKickSimContext();
  const primaryProfileDisabled = isPrimaryProfileDisabled(euicc);
  const secondaryProfileDisabled = isSecondaryProfileDisabled(euicc);

  const isDisabled =
    (isPrimary && primaryProfileDisabled) || (!isPrimary && secondaryProfileDisabled);

  let primaryProfileStatus: PollingStatus = PollingSimStatus.Unknown;
  let secondaryProfileStatus: PollingStatus = PollingSimStatus.Unknown;
  if (rSimStatus === RsimProfileStatus.PrimaryProfileActive && isPrimary) {
    primaryProfileStatus = PollingSimStatus.Active;
    secondaryProfileStatus = PollingSimStatus.Inactive;
  } else if (rSimStatus === RsimProfileStatus.SecondaryProfileActive && !isPrimary) {
    primaryProfileStatus = PollingSimStatus.Inactive;
    secondaryProfileStatus = PollingSimStatus.Active;
  } else if (rSimStatus === RsimProfileStatus.PrimaryProfileDisconnected && isPrimary) {
    primaryProfileStatus = PollingSimStatus.Disconnected;
    secondaryProfileStatus = PollingSimStatus.Inactive;
  } else if (rSimStatus === RsimProfileStatus.SecondaryProfileDisconnected && !isPrimary) {
    primaryProfileStatus = PollingSimStatus.Inactive;
    secondaryProfileStatus = PollingSimStatus.Disconnected;
  }

  const status = isPrimary ? primaryProfileStatus : secondaryProfileStatus;

  const ability = useAbility();
  return (
    <PollingSimStatusIcon
      status={status}
      paused={isDisabled && ability.can(Actions.simulate, Subjects.outage)}
      profile={isPrimary ? RSimProfile.Primary : RSimProfile.Backup}
      hideTooltip={true}
    />
  );
};
