import { Interims } from 'common/components/SessionHistory/utils/interims';
import { LAST_RADIUS_TYPE } from 'common/components/SessionHistory/utils/interimsModels';
import { useState } from 'react';

export const useInterimSelection = (interims: Interims) => {
  const [selectedInterimId, setSelectedInterimId] = useState<string>('');
  const [defaultInterimIdToDisplay, setDefaultInterimIdToDisplay] = useState<string>('');
  const startInterim =
    interims.find((interim) => interim.lastRadiusType === LAST_RADIUS_TYPE.START) || null;

  const selectInterimById = (interimId: string) => {
    setSelectedInterimId(interimId);
  };

  const selectStartInterim = () => {
    if (startInterim) {
      setSelectedInterimId(startInterim.id);
    }
  };

  const unselectInterim = () => {
    setSelectedInterimId('');
  };

  const markSelectedInterimAsDefault = () => {
    setDefaultInterimIdToDisplay(selectedInterimId);
  };

  let selectedInterim = interims.find((interim) => interim.id === selectedInterimId) || null;
  if (!selectedInterim && defaultInterimIdToDisplay) {
    selectedInterim = interims.find((interim) => interim.id === defaultInterimIdToDisplay) || null;
  }

  return {
    markedInterimId: defaultInterimIdToDisplay,
    selectedInterimId,
    selectedInterim,
    startInterim,

    selectInterimById,
    selectStartInterim,
    markSelectedInterimAsDefault,
    unselectInterim
  };
};
