import Typography from '@mui/material/Typography';

export const TextCell = ({ value, subValue }: any) => {
  return (
    <div>
      <Typography variant="text1" color="text.primary" component="div" data-testid="primaryText">
        {value}
      </Typography>
      <Typography
        variant="text5"
        color="text.secondary"
        component="div"
        data-testid="secondaryText"
      >
        {subValue}
      </Typography>
    </div>
  );
};
