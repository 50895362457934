import { authFetch } from 'auth/utils';
import { AssignedTagToSimDTO, FetchAssignedTagToSimsFilter, Tag } from './tagsApi.types';

export const fetchSimTags = async (signal?: AbortSignal) => {
  const tagsResponse = await authFetch(
    `${process.env.REACT_APP_SIM_INVENTORY_SIMS_PROFILE_API}/tags`,
    { signal },
  );

  const tags: Tag[] = await tagsResponse.json();

  return tags;
};

export const fetchSimTag = async (id: string, signal?: AbortSignal) => {
  const tagsResponse = await authFetch(
    `${process.env.REACT_APP_SIM_INVENTORY_SIMS_PROFILE_API}/tags/${id}`,
    { signal },
  );

  const tag = await tagsResponse.json();
  return tag;
};

export const createSimTag = async (name: string, signal?: AbortSignal) => {
  const urlQuery = new URLSearchParams();
  urlQuery.append('name', name);

  const tagsResponse = await authFetch(
    `${process.env.REACT_APP_SIM_INVENTORY_SIMS_PROFILE_API}/tags?${urlQuery.toString()}`,
    { signal, method: 'POST' },
  );

  const tag: Tag = await tagsResponse.json();

  return tag;
};

export const assignTagsToSim = async (iccid: string, tagIds: string[], signal?: AbortSignal) => {
  await authFetch(`${process.env.REACT_APP_SIM_INVENTORY_SIMS_PROFILE_API}/tags/assign`, {
    signal,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      iccid,
      tagIds,
    }),
  });
};

export const fetchAssignedTagToSim = async (iccid: string, signal?: AbortSignal) => {
  const assignedTags = await fetchAssignedTagToSims({ iccids: [iccid] });
  return assignedTags.length > 0 ? assignedTags[0].tags : [];
};

export const fetchAssignedTagToSims = async (
  filters: FetchAssignedTagToSimsFilter,
  signal?: AbortSignal,
) => {
  const tagsResponse = await authFetch(
    `${process.env.REACT_APP_SIM_INVENTORY_SIMS_PROFILE_API}/tags/assigned`,
    {
      signal,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(filters),
    },
  );

  const assignedTags: AssignedTagToSimDTO[] = await tagsResponse.json();

  return assignedTags;
};

export const fetchSimProductsTags = async (signal?: AbortSignal) => {
  const tagsResponse = await authFetch(`${process.env.REACT_APP_SIM_PRODUCTS_API}/tags`, {
    signal,
  });

  const tags: Tag[] = await tagsResponse.json();

  return tags;
};

export const fetchSimProductsTag = async (id: string, signal?: AbortSignal) => {
  const tagsResponse = await authFetch(`${process.env.REACT_APP_SIM_PRODUCTS_API}/tags/${id}`, {
    signal,
  });

  const tag = await tagsResponse.json();
  return tag;
};

export const createSimProductsTag = async (name: string, signal?: AbortSignal) => {
  const urlQuery = new URLSearchParams();
  urlQuery.append('name', name);

  const tagsResponse = await authFetch(
    `${process.env.REACT_APP_SIM_PRODUCTS_API}/tags?${urlQuery.toString()}`,
    { signal, method: 'POST' },
  );

  const tag: Tag = await tagsResponse.json();

  return tag;
};

export const assignTagsToSimProducts = async (
  iccid: string,
  tagIds: string[],
  signal?: AbortSignal,
) => {
  await authFetch(`${process.env.REACT_APP_SIM_PRODUCTS_API}/tags/assign`, {
    signal,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      iccid,
      tagIds,
    }),
  });
};

export const fetchAssignedTagToSimProduct = async (iccid: string, signal?: AbortSignal) => {
  const assignedTags = await fetchAssignedTagsToSimProducts({ iccids: [iccid] });
  return assignedTags.length > 0 ? assignedTags[0].tags : [];
};

export const fetchAssignedTagsToSimProducts = async (
  filters: FetchAssignedTagToSimsFilter,
  signal?: AbortSignal,
) => {
  const tagsResponse = await authFetch(`${process.env.REACT_APP_SIM_PRODUCTS_API}/tags/assigned`, {
    signal,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(filters),
  });

  const assignedTags: AssignedTagToSimDTO[] = await tagsResponse.json();

  return assignedTags;
};
