import { palette } from './palette';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    h1?: React.CSSProperties;
    h2?: React.CSSProperties;
    h3?: React.CSSProperties;
    h4?: React.CSSProperties;
    h5?: React.CSSProperties;
    text1?: React.CSSProperties;
    text2?: React.CSSProperties;
    text3?: React.CSSProperties;
    text4?: React.CSSProperties;
    text5?: React.CSSProperties;

    button?: React.CSSProperties;
    link?: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    h1?: React.CSSProperties;
    h2?: React.CSSProperties;
    h3?: React.CSSProperties;
    h4?: React.CSSProperties;
    h5?: React.CSSProperties;
    text1?: React.CSSProperties;
    text2?: React.CSSProperties;
    text3?: React.CSSProperties;
    text4?: React.CSSProperties;
    text5?: React.CSSProperties;

    button?: React.CSSProperties;
    link?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h1: true;
    h2: true;
    h3: true;
    h4: true;
    h5: true;
    text1: true;
    text2: true;
    text3: true;
    text4: true;
    text5: true;

    button: true;
    link: true;
  }
}

export const fontFamily = 'Hind, Arial';

export const typographyBase = {
  h1: {
    fontSize: '28px',
    lineHeight: '48px',
    fontWeight: 600,
    fontFamily,
  },
  h2: {
    fontSize: '18px',
    lineHeight: '27px',
    fontWeight: 600,
    fontFamily,
  },
  h3: {
    fontSize: '18px',
    lineHeight: '24px',
    fontWeight: 500,
    fontFamily,
  },
  h4: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 600,
    fontFamily,
  },
  h5: {
    fontSize: '16px',
    lineHeight: '25px',
    fontWeight: 500,
    fontFamily,
  },
  text1: {
    fontSize: '13px',
    lineHeight: '21px',
    fontWeight: 400,
    fontFamily,
  },
  text2: {
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: 500,
    fontFamily,
  },
  text3: {
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 500,
    fontFamily,
  },
  text4: {
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 400,
    fontFamily,
  },
  text5: {
    fontSize: '12px',
    lineHeight: '18px',
    fontWeight: 400,
    fontFamily,
  },
};

export const typography = {
  fontFamily: 'Hind',
  ...typographyBase,
  link: {
    ...typographyBase.text1,
    color: palette.grey[700],
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  button: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 600,
    textTransform: 'none',
    fontFamily,
  },
};
