import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import './RangeSlider.css';
import { Marks } from './utils';

export type RangeSliderProps = {
  min: number;
  max: number;
  marks: Marks;
  value: [number, number];
  onChange: (value: [number, number]) => void;
};

export const RangeSlider: React.FC<RangeSliderProps> = ({ min, max, marks, value, onChange }) => {
  return (
    <Slider
      range
      draggableTrack
      allowCross={false}
      marks={marks}
      min={min}
      max={max}
      value={value}
      onChange={(value) => {
        const range = value as [number, number];
        onChange(range);
      }}
    />
  );
};
