import { useTranslation } from 'react-i18next';
import { FilterChip } from '../FilterChip/FilterChip';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import React from 'react';
import { isEmpty } from 'lodash';

export type FilterChipProps = {
  filters: Record<string, string>;
  externalChips?: React.ReactNode;
  onFilterClear: (filterName: string, value?: any) => void;
  onFiltersClear: () => void;
};

export const FiltersChips: React.FC<FilterChipProps> = ({
  filters,
  externalChips,
  onFiltersClear,
  onFilterClear,
}) => {
  const { t } = useTranslation();

  return Object.values(filters).length > 0 || !isEmpty(externalChips) ? (
    <Box
      data-testid="filters chips"
      sx={{
        display: 'flex',
        flexFlow: 'row wrap',
        alignItems: 'center',
        gap: 2,
      }}
    >
      {Object.values(filters).length > 0
        ? Object.entries(filters).map(([key, value]) => (
            <React.Fragment key={key}>
              <FilterChip text={value} onClose={() => onFilterClear(key, value)} />
            </React.Fragment>
          ))
        : null}
      {externalChips ? externalChips : null}
      <Button variant="text" color="tertiary" onClick={onFiltersClear}>
        {t('common.clearFilters')}
      </Button>
    </Box>
  ) : null;
};
