import Box from '@mui/material/Box';
import { alpha } from '@mui/material';
import React from 'react';

type ErrorOverflowBoxProps = {
  children?: React.ReactNode;
};

export const ErrorOverflowBox = ({ children }: ErrorOverflowBoxProps) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        inset: 0,

        bottom: '34px',
        background: (theme) => alpha(theme.palette.background.default, 0.8),
      }}
    >
      {children}
    </Box>
  );
};
