import { Bar } from 'react-chartjs-2';
import React, { useMemo, useRef } from 'react';
import {
  Chart as ChartJS,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip,
  TimeScale,
  CategoryScale,
  BarElement,
  TimeSeriesScale,
  Filler,
  Decimation,
  TooltipModel
} from 'chart.js';

import 'chartjs-adapter-luxon';
import Box from '@mui/material/Box';
import { GRAPH_DEFAULT_OPTIONS, chartBorderPlugin } from './graphConfig';
import { mapDataPointsToDatasets } from './utils/usageGraphUtils';
import zoom from 'chartjs-plugin-zoom';
import { barRangePlugin, highlightPlugin } from './utils/graphPlugins';
import { useDispatchEventToChart } from './common/Graphs/hooks/useDispatchEventToChart';

ChartJS.register(
  TimeScale,
  CategoryScale,
  LinearScale,
  Tooltip,
  PointElement,
  BarElement,
  TimeSeriesScale,
  LineElement,
  Filler,
  zoom,
  Decimation
);

export type UsageChartEntryData = {
  x: [Date, Date];
  inputUsage: number;
  outputUsage: number;
  tooltipData: {
    label: string;
    dataIndex: number;
  };
};

export type SingleUsageGraphProps = {
  entries: UsageChartEntryData[];
  from: number;
  to: number;
  highlightFrom?: Date | null;
  highlightTo?: Date | null;
  onZoomComplete: (range: [Date, Date]) => void;
  tooltip: (positionX: number, index: number | null, setName: string | null) => void;
  onClick: () => void;
  onPan?: (newMin: Date, newMax: Date) => void;
  min: number;
  max: number;
};

export const SingleUsageGraph: React.FC<SingleUsageGraphProps> = React.memo(
  ({
    entries,
    highlightFrom,
    highlightTo,
    from,
    to,
    min,
    max,
    onZoomComplete,
    tooltip,
    onClick,
    onPan
  }) => {
    const { datasets, maxValue } = useMemo(() => {
      const { data, maxValue } = mapDataPointsToDatasets(entries);

      return {
        datasets: {
          datasets: [
            {
              barThickness: 1,
              data
            }
          ]
        },
        maxValue
      };
    }, [entries]);

    const options = useMemo(
      () => ({
        ...GRAPH_DEFAULT_OPTIONS,
        indexAxis: 'y',
        scales: {
          x: {
            ...GRAPH_DEFAULT_OPTIONS!.scales!.x,
            //@ts-ignore
            type: 'time',
            min: from,
            max: to
          },
          //@ts-ignore
          y: {
            ...GRAPH_DEFAULT_OPTIONS!.scales!.y,
            type: 'linear',
            min: 0,
            max: 1.2 * maxValue,
            display: false,
            beginAtZero: true,
            offset: false,
            grid: {
              ...GRAPH_DEFAULT_OPTIONS!.scales!.y?.grid,
              offset: false
            }
          }
        },
        plugins: {
          ...GRAPH_DEFAULT_OPTIONS!.plugins,
          zoom: {
            ...GRAPH_DEFAULT_OPTIONS!.plugins?.zoom,

            //@ts-ignore
            limits: {
              ...GRAPH_DEFAULT_OPTIONS!.plugins?.zoom?.limits,
              x: {
                ...GRAPH_DEFAULT_OPTIONS!.plugins?.zoom?.limits?.x,
                min: min,
                max: max
              }
            },
            zoom: {
              ...GRAPH_DEFAULT_OPTIONS!.plugins?.zoom?.zoom,
              enabled: false,
              //@ts-ignore
              onZoomComplete(context) {
                onZoomComplete([
                  new Date(context.chart.scales.x.min),
                  new Date(context.chart.scales.x.max)
                ]);
              }
            },
            pan: {
              ...GRAPH_DEFAULT_OPTIONS!.plugins?.zoom?.pan,
              mode: 'x',
              scaleMode: 'x',

              threshold: 200,
              //@ts-ignore
              onPanStart: (event) => {},
              //@ts-ignore
              onPan: (event) => {
                if (onPan) {
                  // //@ts-ignore
                  const min = new Date(event.chart.boxes[0].min);
                  // // console.log(subMilliseconds())
                  //@ts-ignore
                  const max = new Date(event.chart.boxes[0].max);
                  // console.log(min, max);
                  onPan(min, max);
                }
              },
              //@ts-ignore
              onPanComplete: (event) => {
                // if (onPan) {
                //   // //@ts-ignore
                //   const min = new Date(event.chart.boxes[0].min);
                //   // // console.log(subMilliseconds())
                //   //@ts-ignore
                //   const max = new Date(event.chart.boxes[0].max);
                //   // console.log(min, max);
                //   onPan(min, max);
                // }
              }
            }
          },
          tooltip: {
            enabled: false,
            axis: 'x',
            mode: 'x',
            intersect: false,
            external: (context: { tooltip: TooltipModel<'bar'>; chart: ChartJS<'bar'> }) => {
              if (context.tooltip.opacity === 0) {
                tooltip(0, null, null);
                return;
              }
              const dataPoint = context.tooltip.dataPoints[0];
              const pointRaw = dataPoint.raw;
              //@ts-ignore
              const index = pointRaw.dataIndex;
              //@ts-ignore
              const label = pointRaw.label;
              //@ts-ignore
              const [startX, endX] = pointRaw.x;
              const scaleX = context.chart.boxes[0] as TimeScale;
              const startPositionX = scaleX.getPixelForValue(Math.max(startX, from));
              const endPositionX = scaleX.getPixelForValue(Math.min(endX, to));
              const tooltipPositionX = (startPositionX + endPositionX) / 2;
              tooltip(tooltipPositionX, index, label);
            }
          },
          highlightPlugin: {
            from: highlightFrom,
            to: highlightTo
          }
        }
      }),
      [from, to, min, max, datasets, highlightFrom, highlightTo, tooltip, onZoomComplete]
    );

    const chartRef = useRef<ChartJS>();
    const onChartClick = () => {
      onClick();
    };

    useDispatchEventToChart(chartRef);

    return (
      <Box sx={{ height: 185 }}>
        <Bar
          plugins={[chartBorderPlugin, barRangePlugin, highlightPlugin]}
          data={datasets}
          //@ts-ignore
          options={options}
          //@ts-ignore
          ref={chartRef}
          onClick={onChartClick}
        />
      </Box>
    );
  }
);
