import { SuspenseLoadingView } from 'common/components/SuspenseLoadingView';
import { Outlet } from 'react-router-dom';
import { RSimSessionHistoryTable } from './RSimSessionHistoryTable';
import { ContentBox } from '../../../common/components/AppLayout/ContentBox';
import { TableSkeleton } from '../../../common/components/Table/TableSkeleton';
import { SessionHistoryErrorBoundary } from 'simInventory/SessionHistory/SessionHistoryErrorBoundary';

export const RSimSessionHistoryEntry = () => {
  return (
    <SuspenseLoadingView
      loadFallback={
        <ContentBox>
          <TableSkeleton numberOfRows={20} pagination={false} />
        </ContentBox>
      }
      queryKey={['RSimSessionHistory']}
    >
      <SessionHistoryErrorBoundary>
        <RSimSessionHistoryTable />
        <Outlet />
      </SessionHistoryErrorBoundary>
    </SuspenseLoadingView>
  );
};
