import { isEmpty } from 'lodash';
import { useCallback } from 'react';
import { useKickSimContext } from 'rSimInventory/Components/KickRsimProvider';
import {
  PollingSimStatus,
  RSimProfile,
} from 'rSimInventory/Components/PollingSimStatusIcon.interface';
import { useRSimsSimsContext } from 'rSimInventory/hooks/useRSimsSimsContext';
import { RsimProfileStatus } from 'rSimInventory/RSimApi/rSimApi.interface';

const getPoolingStatusForPrimaryProfile = (status: RsimProfileStatus) => {
  switch (status) {
    case RsimProfileStatus.PrimaryProfileActive:
      return PollingSimStatus.Active;
    case RsimProfileStatus.PrimaryProfileDisconnected:
      return PollingSimStatus.Disconnected;
    case RsimProfileStatus.SecondaryProfileActive:
      return PollingSimStatus.Inactive;
    case RsimProfileStatus.SecondaryProfileDisconnected:
      return PollingSimStatus.Inactive;
  }

  return PollingSimStatus.Unknown;
};

const getPoolingStatusForBackupProfile = (status: RsimProfileStatus) => {
  switch (status) {
    case RsimProfileStatus.PrimaryProfileActive:
      return PollingSimStatus.Inactive;
    case RsimProfileStatus.PrimaryProfileDisconnected:
      return PollingSimStatus.Inactive;
    case RsimProfileStatus.SecondaryProfileActive:
      return PollingSimStatus.Active;
    case RsimProfileStatus.SecondaryProfileDisconnected:
      return PollingSimStatus.Disconnected;
  }

  return PollingSimStatus.Unknown;
};

export const useExtendSimWithDerivativeFromRSim = (
  euicc: string,
  status: RsimProfileStatus,
  primaryIccid: string,
  secondaryIccid: string,
) => {
  const { data } = useRSimsSimsContext();
  const { isPrimaryProfileDisabled, isSecondaryProfileDisabled } = useKickSimContext();

  const sims = data?.sims;

  const simsProfiles = sims?.[euicc];

  const concatSimProfilesInOrder = useCallback(
    (sortedSimProfiles: any[], item: any) => {
      if (isEmpty(sortedSimProfiles)) {
        return [item];
      } else if (item?.iccid === primaryIccid) {
        return [item, ...sortedSimProfiles];
      }

      return [...sortedSimProfiles, item];
    },
    [simsProfiles, primaryIccid],
  );

  const extendSimWithDerivativeFromRSim = useCallback(
    (simProfile: any) => {
      if (simProfile.iccid === primaryIccid) {
        return {
          ...simProfile,
          status: getPoolingStatusForPrimaryProfile(status),
          profile: RSimProfile.Primary,
          paused: isPrimaryProfileDisabled(euicc),
          profileId: 0,
          euicc,
        };
      }

      if (simProfile.iccid === secondaryIccid) {
        return {
          ...simProfile,
          status: getPoolingStatusForBackupProfile(status),
          profile: RSimProfile.Backup,
          paused: isSecondaryProfileDisabled(euicc),
          profileId: 1,
          euicc,
        };
      }

      return { ...simProfile, status: PollingSimStatus.Unknown, euicc };
    },
    [status, primaryIccid, secondaryIccid, isPrimaryProfileDisabled, isSecondaryProfileDisabled],
  );

  return simsProfiles?.reduce(concatSimProfilesInOrder, []).map(extendSimWithDerivativeFromRSim);
};
