import { QueryKey, useIsFetching } from '@tanstack/react-query';

import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as RefreshIcon } from 'assets/images/Refetch/RefreshIcon.svg';
import { ReactComponent as RefreshAnimationIcon } from 'assets/images/Refetch/RefreshAnimationIcon.svg';

import { useRefetchQuery } from 'common/useRefetchQuery';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { Button, ButtonProps } from '../Buttons/Button';

export type RefetchQueryButtonProps = {
  queryKey: QueryKey;
  variant?: ButtonProps['variant'];
};

export const RefetchQueryButton: React.FC<RefetchQueryButtonProps> = ({
  queryKey,
  variant = 'tertiary',
}) => {
  const { t } = useTranslation();
  const refetch = useRefetchQuery(queryKey);
  const fetching = useIsFetching({ queryKey });

  return (
    <Button
      iconStart={
        !fetching ? (
          <SvgIcon>
            <RefreshIcon />
          </SvgIcon>
        ) : (
          <Box sx={{ transform: 'scale(0.83)', mt: -0.25, ml: 0.25 }}>
            <SvgIcon
              sx={{
                animation: `spin 1s linear infinite`,
              }}
            >
              <RefreshAnimationIcon />
            </SvgIcon>
          </Box>
        )
      }
      variant={variant}
      onClick={refetch}
      size="medium"
    >
      {t('common.refresh')}
    </Button>
  );
};
