import { TextFieldWithHints } from 'common/components/Inputs/TextFieldWithHints/TextFieldWithHints';
import { SetStateAction, useAtom, WritableAtom } from 'jotai';

type HintedTextFieldFiltersProps = {
  filterAtom: WritableAtom<string, [SetStateAction<string>], void>;
  filterHintsAtom: WritableAtom<any, [any], void>;
  'data-testid'?: string;
  placeholder?: string;
};

export const HintedTextFieldFilters = ({
  filterAtom,
  filterHintsAtom,
  ...otherProps
}: HintedTextFieldFiltersProps) => {
  const [filterValue, setFilterValue] = useAtom(filterAtom);
  const [filterHints, removeHint] = useAtom(filterHintsAtom);

  return (
    <TextFieldWithHints
      {...otherProps}
      hints={filterHints}
      value={filterValue}
      onChange={(value) => {
        setFilterValue(value || '');
      }}
      onRemoveHint={removeHint}
    />
  );
};
