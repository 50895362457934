import Box from '@mui/material/Box';
import { ContentCopyExtension } from 'common/ContentCopyExtension/ContentCopyExtension';
import { Tooltip } from '../components/Tooltip/Tooltip';
import React from 'react';
import { palette } from 'theme/palette';

export type StickyCopyExtensionProps = {
  children: React.ReactElement;
  value: string;
};

const stickyWrapperSx = {
  width: 'calc(100% + 25px)',
  '& button': {
    position: 'sticky',
    right: '-10px',
    background: palette.background.default,
    '&: hover': {
      backgroundColor: '#e6eaeb !important',
    },
    height: '18px',
    width: '18px',
  },
  '& span, a': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
};

export const StickyCopyExtension: React.FC<StickyCopyExtensionProps> = ({ children, value }) => {
  return (
    <Box sx={stickyWrapperSx}>
      <ContentCopyExtension value={value}>
        <Tooltip
          showArrow={true}
          placement={'bottom'}
          enterDelay={1000}
          enterTouchDelay={1000}
          enterNextDelay={1000}
          variant="dark"
          title={value}
        >
          {children}
        </Tooltip>
      </ContentCopyExtension>
    </Box>
  );
};
