export const PollingSimStatus = {
  Active: 'Active',
  Inactive: 'Inactive',
  Unknown: 'Unknown',
  Disconnected: 'Disconnected'
} as const;

export const RSimProfile = {
  Primary: 'Primary',
  Backup: 'Backup'
} as const;

type ObjectValues<T> = T[keyof T];

export type PollingStatus = ObjectValues<typeof PollingSimStatus>;

export type RSimPrfilesValues = ObjectValues<typeof RSimProfile>;

export type SimStatusIconProps = {
  status: PollingStatus;
  paused?: boolean;
  profile: RSimPrfilesValues;
  hideTooltip?: boolean;
};
