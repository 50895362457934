import { Components } from '@mui/material';
import { palette } from 'theme/palette';
import { typographyBase } from 'theme/typography';
import {
  scrollbarHorizontalSX,
  scrollBarsSX,
  scrollbarVerticalSX,
} from 'theme/utility/utility.styles';

export const MuiDataGrid: Components['MuiDataGrid'] = {
  defaultProps: {
    disableRowSelectionOnClick: true,
  },
  styleOverrides: {
    root: ({ ownerState }) => {
      const loading = ownerState?.loading;
      return {
        border: 'none',
        '& .MuiDataGrid-container--top': {
          '&::after': {
            backgroundColor: palette.grey[200],
          },
        },

        '& .MuiDataGrid-filler--pinnedRight': {
          borderLeft: 'none',
        },

        '& .MuiDataGrid-columnSeparator': {
          opacity: 0,
          '&:hover': {
            opacity: 1,
            color: palette.grey[400],
          },
        },
        '& .MuiDataGrid-columnHeader': {
          ...typographyBase.text3,
          height: loading ? '0 !important' : '42px !important',

          borderBottom: '1px solid #dfdfdf',
          '&:focus': {
            outline: 'none',
          },
        },

        '& .MuiDataGrid-columnHeaders': {
          height: loading ? '0 !important' : '42px !important',
          minHeight: loading ? '0 !important' : '42px !important',
          maxHeight: '42px !important',
          lineHeight: loading ? '0 !important' : '42px !important',
          backgroundColor: palette.grey[100],
          position: 'sticky',
          top: 0,
          '& .MuiDataGrid-columnHeader--pinnedRight': {
            backgroundColor: palette.grey[100],
            borderLeft: 'none',
            '& *': {
              opacity: '0 !important',
            },
            '& .MuiDataGrid-columnHeader': {
              opacity: '0 !important',
            },
          },
          '&:hover': {
            '& .MuiDataGrid-columnHeader--pinnedRight': {
              opacity: '1 !important',
              '& *': {
                opacity: '1 !important',
              },
              '& .MuiDataGrid-columnHeader': {
                opacity: '1 !important',
              },
            },
            '& .MuiDataGrid-columnSeparator': {
              opacity: 1,
            },
          },
        },
        '& .MuiDataGrid-row': {
          background: palette.background.paper,
          '&:hover': {
            backgroundColor: palette.background.default,

            '& .MuiDataGrid-cell--pinnedRight': {
              backgroundColor: `${palette.background.default} !important`,
            },
            '& .MuiDataGrid-cell--pinnedRight > *': {
              opacity: 1,
            },
          },

          '& .MuiDataGrid-cell--pinnedRight': {
            borderLeft: 'none !important',

            '&  > *': {
              opacity: 0,
            },
          },
        },
        '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
          outline: 'none !important',
        },
        '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
          outline: 'none',
        },
        '& .MuiDataGrid-row--firstVisible': {
          '& .MuiDataGrid-scrollbarFiller.MuiDataGrid-scrollbarFiller--borderTop': {
            borderTop: 'none',
          },
        },
        '& .MuiDataGrid-columnHeaderTitleContainerContent': {
          width: '100%',
        },
        '& .MuiDataGrid-cell': {
          ...typographyBase.text1,
          display: 'flex',
          alignItems: 'center',
        },
        '& .MuiDataGrid-scrollbar--vertical::-webkit-scrollbar': {
          width: '14px',
        },

        '& .MuiDataGrid-scrollbar--horizontal': {
          ...scrollbarHorizontalSX,
          ...scrollBarsSX,
        },

        '& .MuiDataGrid-scrollbar--vertical': {
          ...scrollbarVerticalSX,
          ...scrollBarsSX,
        },
        '& .MuiDataGrid-virtualScroller': {
          backgroundColor: palette.background.default,
        },
        '& .MuiDataGrid-skeletonHeaderRow': {
          backgroundColor: palette.grey[100],
        },
        '& .MuiDataGrid-skeletonBodyRow': {
          backgroundColor: palette.background.paper,
        },
        '& .MuiDataGrid-detailPanel': {
          width: 'auto',
        },
      };
    },
    footerContainer: {
      '& .MuiTablePagination-root': {
        flex: 1,
      },
    },
  },
};
