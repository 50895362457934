import { TFunction } from 'react-i18next';
import {
  ProvisioningSource,
  ProvisioningSourceFilter,
  ProvisioningStatusFilter,
  provisioningSourceFilter,
  provisioningStatusFilter,
} from './BulkProvisioning.interface';
import { PROVISIONING_SOURCE } from './BulkProvisioning.constants';

export const parseStatusFilter = (status: string | null): ProvisioningStatusFilter => {
  if (!status) {
    return '';
  }

  const statusParams = parseInt(status);

  try {
    const parsedStatus = provisioningStatusFilter.parse(statusParams);
    return parsedStatus;
  } catch (err) {
    return '';
  }
};

export const parseSourcesFilter = (source: string[] | null): ProvisioningSourceFilter => {
  if (!source) {
    return [];
  }

  const sourceParams = source.map((source) => parseInt(source));

  try {
    const parcedSource = provisioningSourceFilter.parse(sourceParams);
    return parcedSource;
  } catch (err) {
    return [];
  }
};

export const mapSourceValueToLabel = (
  source: ProvisioningSource,
  t: TFunction<'translation', undefined>,
) => {
  switch (source) {
    case PROVISIONING_SOURCE.FILE_UPLOAD:
      return t('bulkProvisioning.fileUpload');
    case PROVISIONING_SOURCE.MANUAL_ACTION:
      return t('bulkProvisioning.manualAction');
    case PROVISIONING_SOURCE.ORDER:
      return t('bulkProvisioning.order');
  }
};
