import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import React from 'react';
import { GRAPH_MARGIN } from 'simInventory/SessionHistory/HistoryAnalysis/HistoryAnalysis.consts';
import { ReactComponent as HelpIcon } from 'assets/images/help.svg';
import { TooltipAdditionalRowsRender } from 'common/components/Tooltip/TooltipAdditionalRowsRender/TooltipAdditionalRowsRender';
import { Tooltip } from 'common/components/Tooltip/Tooltip';

type GraphsBoxProps = {
  title?: React.ReactNode;
  legend?: React.ReactNode;
  leftOffsetPx?: number;
  rightOffsetPx?: number;
  children: React.ReactNode;
  errorMessage?: React.ReactNode;
  tooltipTitle?: string;
  tooltipRows?: { color: string; description: string }[];
};

export const GraphsBox: React.FC<GraphsBoxProps> = ({
  title,
  legend,
  leftOffsetPx = 100,
  rightOffsetPx = 80,
  children,
  errorMessage,
  tooltipTitle,
  tooltipRows,
}) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Box sx={{ display: 'flex', marginBottom: '2px' }}>
        {title ? (
          <Typography variant="text3" color="text.secondary" ml={`${leftOffsetPx}px`} mr="5px">
            {title}
          </Typography>
        ) : null }
        { tooltipTitle || tooltipRows ? (
          <Tooltip
            title={ tooltipTitle ? tooltipTitle : '' }
            component={ <TooltipAdditionalRowsRender rows={ tooltipRows }/> }>
            <Box sx={ { fill: (theme) => theme.palette.text.tertiary,
              '&:hover': {
                fill:  (theme) => theme.palette.text.secondary,
              } } }>
              <HelpIcon width="15px" height="15px" role="img"/>
            </Box>
          </Tooltip>
        ) : null }
      </Box>
      <Box sx={ { position: 'relative' }}>
        {legend ? (
          <Box
            sx={{
              position: 'absolute',
              width: `${leftOffsetPx - GRAPH_MARGIN}px`,
              inset: 0
            }}
          >
            {legend}
          </Box>
        ) : null}
        <Box
          sx={{
            ml: `${leftOffsetPx - GRAPH_MARGIN}px`,
            mr: `${rightOffsetPx}px`,
            height: '100%'
          }}
        >
          {children}
          {errorMessage && (
            <Box
              sx={{
                position: 'absolute',
                inset: 0,
                left: `${leftOffsetPx - GRAPH_MARGIN}px`,
                right: `${rightOffsetPx}px`,
                height: '100%'
              }}
            >
              {errorMessage}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};
