import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { TagContext } from 'tags/TagProvider';
import { Tag } from 'tags/services/tagsApi.types';

export type ManageableTag = Tag & {
  toAdd: boolean;
  toSave: boolean;
};

export const useTags = (enabled: boolean = true) => {
  const { type, fetchTags } = useContext(TagContext);
  const { data, refetch: refetchAllTags } = useQuery({
    queryKey: ['tags', type],
    queryFn: async (): Promise<ManageableTag[]> => {
      const tags = await fetchTags();
      return tags.map((tag) => ({
        ...tag,
        toAdd: false,
        toSave: false,
      }));
    },
    enabled,
    // suspense: false,
  });

  return {
    tags: data,
    refetchAllTags,
  };
};
