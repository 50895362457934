import { Theme } from '@mui/material/styles';
import { SystemStyleObject } from '@mui/system';
import { fillParentHeight, paddingVertical2 } from 'theme/utility/utility.styles';
import { SectionGridItemProps } from '../../../common/components/SectionGrid/SectionGridItem';

export const rSimInventoryDetailsScrollArea: SystemStyleObject<Theme> = {
  ...paddingVertical2,
  ...fillParentHeight,
};

export const rSimInventoryDetails: SystemStyleObject<Theme> = {
  pt: 3,
  pb: 6,
  px: 14,
};

export const simGridSizes: Partial<SectionGridItemProps> = {
  mdSize: 6,
  lgSize: 4,
  xlSize: 3,
};

export const tagGridSizes: Partial<SectionGridItemProps> = {
  smSize: 12,
  mdSize: 12,
  lgSize: 12,
  xlSize: 12,
};

export const rSimInventorySimDetailsGridSmallScreen: SystemStyleObject<Theme> = {
  display: 'grid',
  gridTemplateAreas: `"primary1"
                      "secondary1"`,
};

export const rSimInventorySimDetailsGridMediumScreen: SystemStyleObject<Theme> = {
  gridTemplateAreas: `"primary1 divider secondary1"`,
  gridTemplateColumns: '1fr auto 1fr'
};

export const rSimInventorySimDetailsSkeletonSmallScreen: SystemStyleObject<Theme> = {
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridTemplateAreas: `"skeleton1"
                "skeleton2"`,
};

export const rSimInventorySimDetailsSkeletonMediumScreen: SystemStyleObject<Theme> = {
  gridTemplateAreas: `"skeleton1 divider skeleton2"`,
  gridTemplateColumns: '1fr 10px 1fr',
};
