import { ObjectValues } from 'common/tsHelpers';

export type CustomFieldResponse = {
  id: number;
  label: string;
  enabled: boolean;
  selectionOptions: CustomFieldSelectedOption[] | null;
  dataType?: CustomFieldType;
  isNew?: boolean;
};

export type CustomField = {
  id: number;
  label: string;
  enabled: boolean;
  selectionOptions: CustomFieldSelectedOption[];
  dataType?: CustomFieldType;
  isNew?: boolean;
};

export type CustomFieldSelectedOption = {
  id?: number;
  label: string;
  order: number;
};

export type CreateCustomField = {
  label: string;
  enabled: boolean;
  selectionOptions: string[];
  dataType: CustomFieldType;
};

export type EditCustomField = {
  id: number;
  label: string;
  enabled: boolean;
};

export type EditCustomFieldSelectionOptions = {
  id: number;
  selectionOptions: CustomFieldSelectedOption[];
};

export const FIELD_TYPE = {
  Text: 'Text',
  Dropdown: 'Dropdown',
} as const;

export type CustomFieldType = ObjectValues<typeof FIELD_TYPE>;
