import {
  ProvisioningSource,
  ProvisioningSourceFilter,
} from 'bulkProvisioning/BulkProvisioning.interface';
import { useSourceTranslation } from 'bulkProvisioning/Filters/hooks/useSourceTranslation';
import { Chip } from 'common/components/Chips/Chip';
import { useTranslation } from 'react-i18next';

export type SourceChipsProps = {
  source: ProvisioningSourceFilter;
  onDelete: (source: ProvisioningSource) => void;
};

export const SourceChips = ({ source, onDelete }: SourceChipsProps) => {
  const { t } = useTranslation();
  const translateSourceValue = useSourceTranslation();
  return (
    <>
      {source.map((source) => {
        return (
          <Chip
            // @ts-ignore
            label={`${t('bulkProvisioning.source')}: ${translateSourceValue(source)}`}
            size="medium"
            variant="outlined"
            onDelete={() => onDelete(source)}
          />
        );
      })}
    </>
  );
};
