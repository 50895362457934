import { FiltersButton } from 'common/components/Filters/FiltersButton';

import { useSetAtom } from 'jotai';
import { rsimFiltersAtom } from 'atoms/filters';
import { useResetAtom } from 'jotai/utils';
import { RSimFiltersDialog } from './Components/RSimFiltersDialog';
import { useHasActiveFilters } from './hooks/useHasActiveFilters';
import { useCallback, useState } from 'react';
import { syncHintsAtom, updateHintsAtom } from 'atoms/hints/rsim-products';
import { RSimFiltersDialogAtomInitializator } from './Components/Atoms/RSimFiltersDialogAtomInitializator';
import {
  TableLayoutChips,
  TableLayoutFilters,
  TableLayoutSearchInput,
} from 'common/components/Layouts/TableLayout/TableLayout';
import { RSimIdFiltersSearchInput } from './Components/Inputs/RSimIdFiltersSearchInput';
import { RSimFiltersChips } from './Components/Chips/RSimFiltersChips';
import { RSimFilters as RSimFiltersType } from './data/filters.constants';

export const RSimFilters = () => {
  const [open, setOpen] = useState(false);

  const hasChips = useHasActiveFilters();

  const setFilters = useSetAtom(rsimFiltersAtom);
  const resetFilters = useResetAtom(rsimFiltersAtom);
  const filtersActive = useHasActiveFilters();

  const setHints = useSetAtom(updateHintsAtom);
  const syncHints = useSetAtom(syncHintsAtom);

  const onApply = useCallback((filters: RSimFiltersType) => {
    setFilters(filters);
    setHints(filters);
    setOpen(false);
  }, []);

  const onClose = useCallback(() => {
    setOpen(false);
    syncHints();
  }, []);

  return (
    <>
      <TableLayoutFilters>
        <TableLayoutSearchInput>
          <RSimIdFiltersSearchInput />
        </TableLayoutSearchInput>
        <FiltersButton
          onClick={() => {
            setOpen(true);
          }}
          active={filtersActive}
        />
        {open && (
          <RSimFiltersDialogAtomInitializator>
            <RSimFiltersDialog
              onApply={onApply}
              onCancel={onClose}
              onClear={resetFilters}
              onClose={onClose}
            />
          </RSimFiltersDialogAtomInitializator>
        )}
      </TableLayoutFilters>
      <TableLayoutChips hide={!hasChips}>
        <RSimFiltersChips />
      </TableLayoutChips>
    </>
  );
};
