import React, { useCallback, useEffect, useState } from 'react';
import {
  addMilliseconds,
  addMinutes,
  differenceInMilliseconds,
  isAfter,
  isBefore,
  subDays,
  subMilliseconds
} from 'date-fns';

export type InterimsTimeRangeContextProps = {
  selectedRange: { from: Date; to: Date };
  availableRange: { from: Date; to: Date };
  zoomIn: (zoomValue: number) => void;
  zoomOut: (zoomValue: number) => void;
  zoomExpand: () => void;
  moveVisibleRangeLeft: (movePercent: number) => void;
  moveVisibleRangeRight: (movePercent: number) => void;
  moveVisibleRangeTo: (newSelectedFrom: Date, newSelectedTo: Date) => void;
};

export const InterimsTimeRangeContext = React.createContext<InterimsTimeRangeContextProps>(
  {} as InterimsTimeRangeContextProps
);

export type InterimsTimeRangeProviderProps = {
  sessionFrom: Date;
  sessionTo: Date;
  children: React.ReactNode;
};

const calculateInitialSelectedRange = (
  sessionFrom: Date,
  sessionTo: Date,
  maxDays: number = 14,
  minRangeMinutes: number = 5
) => {
  const diffInMs = differenceInMilliseconds(sessionTo, sessionFrom);

  const maxDaysInMs = maxDays * 24 * 3600 * 1000;
  const minRangeInMs = minRangeMinutes * 60_000;

  if (diffInMs > maxDaysInMs) {
    return {
      from: subDays(sessionTo, maxDays),
      to: sessionTo
    };
  }

  if (diffInMs < minRangeInMs) {
    return {
      from: sessionFrom,
      to: addMinutes(sessionFrom, minRangeMinutes)
    };
  }

  return {
    from: sessionFrom,
    to: sessionTo
  };
};

const calculateInitialAvailableRange = (
  sessionFrom: Date,
  sessionTo: Date,
  minRangeMinutes: number = 5
) => {
  const diffInMs = differenceInMilliseconds(sessionTo, sessionFrom);

  const minRangeInMS = minRangeMinutes * 60_000;
  if (diffInMs < minRangeInMS) {
    return {
      from: sessionFrom,
      to: addMilliseconds(sessionFrom, minRangeInMS)
    };
  }

  return {
    from: sessionFrom,
    to: sessionTo
  };
};

const MAX_ZOOM_IN = 5 * 60_000;
const MAX_ZOOM_OUT = 14 * 24 * 60 * 60 * 1000;

export const InterimsTimeRangeProvider: React.FC<InterimsTimeRangeProviderProps> = ({
  sessionFrom,
  sessionTo,
  children
}) => {
  const [availableRange, setAvailableRange] = useState(
    calculateInitialAvailableRange(sessionFrom, sessionTo)
  );

  useEffect(() => {
    setAvailableRange(calculateInitialAvailableRange(sessionFrom, sessionTo));
  }, [sessionTo]);

  const [selectedRange, setSelectedRange] = useState(
    calculateInitialSelectedRange(sessionFrom, sessionTo)
  );

  useEffect(() => {
    setAvailableRange(calculateInitialAvailableRange(sessionFrom, sessionTo));
    setSelectedRange(calculateInitialSelectedRange(sessionFrom, sessionTo));
  }, [sessionFrom]);

  const zoomIn = useCallback(
    (zoomValue: number) => {
      setSelectedRange((currentRange) => {
        const diffInMs = differenceInMilliseconds(currentRange.to, currentRange.from);
        const totalTimeRangeChange = zoomValue * diffInMs;
        let sideTimeRangeChange = totalTimeRangeChange / 2;

        if (diffInMs - totalTimeRangeChange < MAX_ZOOM_IN) {
          const correctedTotalTimeRangeChange = diffInMs - MAX_ZOOM_IN;
          sideTimeRangeChange = correctedTotalTimeRangeChange / 2;
        }

        return {
          from: addMilliseconds(selectedRange.from, sideTimeRangeChange),
          to: subMilliseconds(selectedRange.to, sideTimeRangeChange)
        };
      });
    },
    [selectedRange]
  );

  const zoomOut = useCallback(
    (zoomValue: number) => {
      setSelectedRange((currentRange) => {
        const cuurentDiffInMs = differenceInMilliseconds(currentRange.to, currentRange.from);
        let prevDiffInMS = cuurentDiffInMs / (1 - zoomValue);

        if (prevDiffInMS > MAX_ZOOM_OUT) {
          prevDiffInMS = MAX_ZOOM_OUT;
        }

        const deltaInMS = prevDiffInMS - cuurentDiffInMs;
        const sideTimeRangeChange = deltaInMS / 2;

        let newFrom = subMilliseconds(currentRange.from, sideTimeRangeChange);
        let newTo = addMilliseconds(currentRange.to, sideTimeRangeChange);

        if (isBefore(newFrom, availableRange.from)) {
          newFrom = availableRange.from;
        }

        if (isAfter(newTo, availableRange.to)) {
          newTo = availableRange.to;
        }

        return {
          from: newFrom,
          to: newTo
        };
      });
    },
    [availableRange]
  );

  const zoomExpand = useCallback(() => {
    setSelectedRange((currentRange) => {
      const diffInMs = differenceInMilliseconds(currentRange.to, currentRange.from);
      const totalTimeRangeChange = MAX_ZOOM_OUT - diffInMs;
      const sideTimeRangeChange = totalTimeRangeChange / 2;

      let newFrom = subMilliseconds(currentRange.from, sideTimeRangeChange);
      let newTo = addMilliseconds(currentRange.to, sideTimeRangeChange);

      if (isBefore(newFrom, availableRange.from)) {
        newFrom = availableRange.from;
      }

      if (isAfter(newTo, availableRange.to)) {
        newTo = availableRange.to;
      }

      return {
        from: newFrom,
        to: newTo
      };
    });
  }, [availableRange]);

  const moveVisibleRangeLeft = useCallback(
    (movePercent: number) => {
      setSelectedRange((currentRange) => {
        const diifInMS = differenceInMilliseconds(currentRange.to, currentRange.from);
        const msToMove = diifInMS * movePercent;

        const newFrom = subMilliseconds(currentRange.from, msToMove);
        const newTo = subMilliseconds(currentRange.to, msToMove);

        const outOfRange = isBefore(newFrom, sessionFrom);

        if (outOfRange) {
          const outOfRangeDelta = differenceInMilliseconds(currentRange.from, sessionFrom);

          return {
            from: sessionFrom,
            to: subMilliseconds(currentRange.to, outOfRangeDelta)
          };
        }

        return {
          from: newFrom,
          to: newTo
        };
      });
    },
    [sessionFrom]
  );

  const moveVisibleRangeRight = useCallback(
    (movePercent: number) => {
      setSelectedRange((currentRange) => {
        const diifInMS = differenceInMilliseconds(currentRange.to, currentRange.from);
        const msToMove = diifInMS * movePercent;

        const newFrom = addMilliseconds(currentRange.from, msToMove);
        const newTo = addMilliseconds(currentRange.to, msToMove);
        const outOfRange = isAfter(newTo, sessionTo);

        if (outOfRange) {
          const outOfRangeDelta = differenceInMilliseconds(sessionTo, currentRange.to);

          return {
            from: addMilliseconds(currentRange.from, outOfRangeDelta),
            to: sessionTo
          };
        }

        return {
          from: newFrom,
          to: newTo
        };
      });
    },
    [sessionTo]
  );

  const moveVisibleRangeTo = useCallback((newSelectedFrom: Date, newSelectedTo: Date) => {
    setSelectedRange({
      from: newSelectedFrom,
      to: newSelectedTo
    });
  }, []);

  return (
    <InterimsTimeRangeContext.Provider
      value={{
        selectedRange,
        availableRange,
        zoomIn,
        zoomOut,
        zoomExpand,
        moveVisibleRangeLeft,
        moveVisibleRangeRight,
        moveVisibleRangeTo
      }}
    >
      {children}
    </InterimsTimeRangeContext.Provider>
  );
};
