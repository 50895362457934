import { Tick } from 'chart.js';
import { addMilliseconds, isAfter, isBefore, milliseconds, subMilliseconds } from 'date-fns';

export const changeRangeBaseOnDays = (
  currentRange: { min: Date; max: Date },
  destinationRangeInDays: number,
  rangeBounds?: { min: Date; max: Date },
) => {
  const { min, max } = currentRange;
  const middleDate = new Date((min.getTime() + max.getTime()) / 2);
  const destinationRangeInMS = milliseconds({ days: destinationRangeInDays });

  let newMin = subMilliseconds(middleDate, destinationRangeInMS / 2);
  let newMax = addMilliseconds(middleDate, destinationRangeInMS / 2);

  if (rangeBounds && isAfter(newMax, rangeBounds?.max) && isBefore(newMin, rangeBounds.min)) {
    return rangeBounds;
  }

  if (rangeBounds && isAfter(newMax, rangeBounds?.max) && !isBefore(newMin, rangeBounds.min)) {
    newMax = rangeBounds?.max;
    newMin = subMilliseconds(rangeBounds?.max, destinationRangeInMS);
  }

  if (rangeBounds && isBefore(newMin, rangeBounds.min) && !isAfter(newMax, rangeBounds?.max)) {
    newMin = rangeBounds?.min;
    newMax = addMilliseconds(rangeBounds?.min, destinationRangeInMS);
  }

  return {
    min: newMin,
    max: newMax,
  };
};

export const convertDateToLabels = (value: number, index: number, values: Tick[]) => {
  const currentTickDate = new Date(value);
  const previousTickDate = index > 0 ? new Date(values[index - 1].value) : null;

  const prevTickAndCurrentTickSameDay =
    previousTickDate && previousTickDate.getDay() === currentTickDate.getDay();
  const prevTickAndCurrentTickSameHour =
    previousTickDate && previousTickDate.getHours() === currentTickDate.getHours();
  const prevTickAndCurrentTickSameMinute =
    previousTickDate && previousTickDate.getMinutes() === currentTickDate.getMinutes();

  if (
    prevTickAndCurrentTickSameDay &&
    prevTickAndCurrentTickSameHour &&
    prevTickAndCurrentTickSameMinute
  ) {
    return '';
  }

  const tickDate = new Date(value);
  const displayDay = index === 0 || !prevTickAndCurrentTickSameDay;

  const label = [
    `${new Date(value).toLocaleTimeString('default', { hour: '2-digit', minute: '2-digit' })}`,
  ];

  if (displayDay) {
    label.push(
      tickDate.toLocaleDateString(window.navigator.language, {
        day: 'numeric',
        month: 'short',
      }),
    );
  }

  return label;
};
