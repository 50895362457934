import { atom, WritableAtom } from 'jotai';
import { SyncStorage } from 'jotai/vanilla/utils/atomWithStorage';
import { atomWithStorage, createJSONStorage } from 'jotai/utils';
import { has, isArray, isObject } from 'lodash';
import { addMultipeHintsValue, addPrimitiveHintValue } from './hints.utils';
import { focusAtom } from 'jotai-optics';

export const createUpdateHintsAtom = ({
  key,
  atomWithStorage,
  storage,
  hintedValues,
}: {
  key: string;
  atomWithStorage: WritableAtom<unknown, [unknown], void>;
  storage: SyncStorage<unknown>;
  hintedValues: string[];
}) => {
  return atom(
    () => null,
    (_, set, value: any) => {
      const hintsInStorage = storage.getItem(key, null);
      const entries = Object.entries(value);

      const newHints = entries.reduce((currentHints, [name, value]) => {
        if (!hintedValues.includes(name)) {
          return currentHints;
        }

        let hints;
        if (isArray(value)) {
          //@ts-ignore
          hints = addMultipeHintsValue(value, hintsInStorage?.[name] || []);
        } else {
          //@ts-ignore
          hints = addPrimitiveHintValue(value, hintsInStorage?.[name] || []);
        }
        return {
          ...currentHints,
          [name]: hints,
        };
      }, {} as any);

      return set(atomWithStorage, newHints);
    },
  );
};

export const createGetterAndRemoverHintAtom = (focusAtom: any) =>
  atom(
    (get) => {
      //@ts-ignore
      return get(focusAtom);
    },
    (_, set, valueToRemove: any) => {
      set(focusAtom, (hints: any) =>
        hints.filter((hint: any) => {
          if (isObject(valueToRemove) && has(valueToRemove, 'id')) {
            //@ts-ignore
            return hint !== valueToRemove.id;
          }

          return hint !== valueToRemove;
        }),
      );
      //   return {
      //     ...hints,
      //     [name]: hints[name].filter((hint: any) => {
      //       if (isObject(valueToRemove) && has(valueToRemove, 'id')) {
      //         //@ts-ignore
      //         return hint !== valueToRemove.id;
      //       }

      //       return hint !== valueToRemove;
      //     }),
      //   };
      // });
    },
  );

export const createSyncAtom = ({
  storageKey,
  storage,
  atomWithStorage,
}: {
  storageKey: string;
  atomWithStorage: WritableAtom<unknown, [unknown], void>;
  storage: SyncStorage<unknown>;
}) =>
  atom(
    () => null,
    (_, set) => {
      const hintsInStorage = storage.getItem(storageKey, null);
      if (hintsInStorage) {
        set(atomWithStorage, hintsInStorage);
      }
    },
  );

export const initHints = ({
  storageName,
  storageVersion,
  intialValues,
}: {
  storageName: string;
  storageVersion: string;
  intialValues: Record<string, string[]>;
}) => {
  const STORAGE_KEY = `${storageName}-${storageVersion}`;

  const atomLocalStorage = createJSONStorage(() => localStorage);
  const storageAtom = atomWithStorage(STORAGE_KEY, intialValues, atomLocalStorage, {
    getOnInit: true,
  });

  const syncAtom = createSyncAtom({
    storageKey: STORAGE_KEY,
    storage: atomLocalStorage,
    atomWithStorage: storageAtom,
  });

  const hintedProperty = Object.keys(intialValues);

  const updateAllAtom = createUpdateHintsAtom({
    key: STORAGE_KEY,
    storage: atomLocalStorage,
    atomWithStorage: storageAtom,
    hintedValues: hintedProperty,
  });

  const gettersAndRemovers = hintedProperty.reduce((gettersAndRemovers, hintProp) => {
    return {
      ...gettersAndRemovers,

      [hintProp]: createGetterAndRemoverHintAtom(
        //@ts-ignore
        focusAtom(storageAtom, (optic) => optic.prop(hintProp)),
      ),
    };
  }, {});

  return {
    storageAtom,
    syncAtom,
    updateAllAtom,
    gettersAndRemovers,
  };
};
