import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

type LocationStoreContextProps = {
  locationState?: any;
  saveCurrentLocationState: (state: any) => void;
};
export const LocationStoreContext = React.createContext<LocationStoreContextProps>(
  {} as LocationStoreContextProps,
);

export type LocationStateStoreProviderProps = {
  children?: React.ReactNode;
};

export const LocationStateStoreProvider = ({ children }: LocationStateStoreProviderProps) => {
  const [locationStateStore, setLocationStateStore] = useState<Record<string, any>>({});

  const location = useLocation();

  return (
    <LocationStoreContext.Provider
      value={{
        //@ts-ignore
        locationState: locationStateStore[location.key],
        saveCurrentLocationState: (state: any) => {
          setLocationStateStore((currentState) => ({
            ...currentState,
            [location.key]: state,
          }));
        },
      }}
    >
      {children}
    </LocationStoreContext.Provider>
  );
};
