import { useMemo } from 'react';
import { RangeSlider, RangeSliderProps } from './RangeSlider';
import { subDays } from 'date-fns';
import { generateTicks } from 'common/timelineRangeSlider/timeLineRange.utils';
import { createMarksFromTicks } from './utils';

export type MonthTimeRangeSliderProps = Pick<RangeSliderProps, 'value' | 'onChange'> & {
  currentDate: Date;
};

const TICKS_IN_MONTHS = 30 * 2;
export const MonthTimeRangeSlider: React.FC<MonthTimeRangeSliderProps> = ({
  value,
  onChange,
  currentDate
}) => {
  const dateRange = useMemo(() => {
    return [subDays(currentDate, 30), currentDate];
  }, [currentDate]);

  const marks = useMemo(() => {
    const [min, max] = dateRange;
    const ticks = generateTicks(TICKS_IN_MONTHS, { max: max, min: min });
    return createMarksFromTicks(ticks);
  }, [dateRange]);

  const [min, max] = dateRange;

  return (
    <RangeSlider
      value={value}
      onChange={onChange}
      marks={marks}
      min={min.getTime()}
      max={max.getTime()}
    />
  );
};
