import { ToggleButton } from 'common/components/Inputs/ToggleGroup/ToggleButton';
import { ToggleGroup } from 'common/components/Inputs/ToggleGroup/ToggleGroup';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import { sessionStatusFilterAtom } from 'atoms/filters/sim-products';
import { SESSION_STATUS } from 'simInventory/models/SimProfile.model';

export const SessionStatusInput = () => {
  const { t } = useTranslation();
  const [sessionStatus, setSessionStatus] = useAtom(sessionStatusFilterAtom);
  return (
    <ToggleGroup
      sx={{
        width: '100%',
        justifyContent: 'stretch',
      }}
      value={sessionStatus}
      onChange={(_event, value) => {
        if (value === null) {
          return;
        }

        setSessionStatus(value);
      }}
      data-testid="Select status"
      aria-label="Select status"
    >
      <ToggleButton
        sx={{ flex: 1 }}
        value={SESSION_STATUS.IN_SESSION}
        data-testid={'select-status-inSession'}
      >
        {t('common.inSession')}
      </ToggleButton>
      <ToggleButton
        sx={{ flex: 1 }}
        value={SESSION_STATUS.UNKNOWN}
        data-testid={'select-status-any'}
      >
        Any
      </ToggleButton>
      <ToggleButton
        sx={{ flex: 1 }}
        value={SESSION_STATUS.DISCONNECTED}
        data-testid={'session-status-outSession'}
      >
        {t('common.outSession')}
      </ToggleButton>
    </ToggleGroup>
  );
};
