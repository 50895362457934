import Box from '@mui/material/Box';
import React from 'react';
import { TableWithFiltersLayoutProps } from './TableWithFiltersLayout.interface';

export const FilterBox: React.FC<React.PropsWithChildren> = ({ children }) => (
  <Box sx={{ p: 4 }}>{children}</Box>
);

export const TableWithFiltersLayout: React.FC<TableWithFiltersLayoutProps> = ({
  filters,
  children
}) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', position: 'relative', height: '100%' }}>
      <Box sx={{ p: 0, m: 0 }}>{filters}</Box>
      <Box sx={{ flex: 1, position: 'relative' }}>
        <Box sx={{ position: 'absolute', inset: 0 }}>{children}</Box>
      </Box>
    </Box>
  );
};
