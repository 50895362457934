import { useEffect, useState } from 'react';
import { MonthTimeRangeSlider, MonthTimeRangeSliderProps } from './MonthTimeRangeSlider';

export type LastMonthTimeRangeSliderProps = Pick<MonthTimeRangeSliderProps, 'value' | 'onChange'>;

export const LastMonthTimeRangeSlider: React.FC<LastMonthTimeRangeSliderProps> = ({
  value,
  onChange
}) => {
  const [now, setNow] = useState(new Date());
  useEffect(() => {
    const intervalId = setInterval(() => {
      setNow(new Date());
    }, 30_000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return <MonthTimeRangeSlider value={value} onChange={onChange} currentDate={now} />;
};
