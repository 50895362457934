import { authFetch } from 'auth/utils';
import {
  ColumnConfiguration,
  ColumnWidth,
  TABLE_VARIANT,
  TableConfiguration,
  TableVariant,
} from './simInventoryTableConfigurationApi.interface';
import { compareByPosition } from 'common/components/ConfigurableTable/utils/ColumnConfiguration.util';
import { lowercaseFirstLetter, uppercaseFirstLetter } from 'common/utils/stringUtil';

export const fetchSimInventoryTableConfiguration = async (
  isFeatureEnabled: boolean,
  signal?: AbortSignal,
): Promise<TableConfiguration> => {
  if (!isFeatureEnabled) {
    return {
      enabledVariant: TABLE_VARIANT.Combined,
      flatColumnConfigurations: [],
      combinedColumnConfigurations: [],
    };
  }

  const simInventoryTableConfigurationResponse = await authFetch(
    `${process.env.REACT_APP_SIM_INVENTORY_SIMS_PROFILE_API}/columns-configurations`,
    { signal },
  );
  const configuration: TableConfiguration = await simInventoryTableConfigurationResponse.json();

  const sortedFlatConfiguration = configuration.flatColumnConfigurations.sort(compareByPosition);
  const sortedCombinedConfiguration =
    configuration.combinedColumnConfigurations.sort(compareByPosition);
  return {
    enabledVariant: configuration.enabledVariant,
    flatColumnConfigurations: sortedFlatConfiguration.map((config) =>
      mapConfigurationToLowercase(config),
    ),
    combinedColumnConfigurations: sortedCombinedConfiguration.map((config) =>
      mapConfigurationToLowercase(config),
    ),
  };
};

export const saveSimInventoryTableConfiguration = async (
  config: { columnConfigurations: ColumnConfiguration[]; variant: TableVariant },
  signal?: AbortSignal,
) => {
  const sortedConfiguration = config.columnConfigurations.sort(compareByPosition);
  return await authFetch(
    `${process.env.REACT_APP_SIM_INVENTORY_SIMS_PROFILE_API}/columns-configurations`,
    {
      signal,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        variant: config.variant,
        columnConfigurations: sortedConfiguration.map((config) =>
          mapConfigurationToUppercase(config),
        ),
      }),
    },
  );
};

export const saveSimInventoryColumnWidth = async (
  columnWidths: ColumnWidth,
  signal?: AbortSignal,
) => {
  return await authFetch(
    `${process.env.REACT_APP_SIM_INVENTORY_SIMS_PROFILE_API}/columns-configurations/set-columns-width`,
    {
      signal,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        columnWidthDto: [
          {
            columnName: uppercaseFirstLetter(columnWidths.columnName),
            width: columnWidths.width,
            isCustomField: columnWidths.isCustomField,
          },
        ],
      }),
    },
  );
};

export const revertSimInventoryTableConfiguration = async (
  variant: TableVariant,
  signal?: AbortSignal,
) => {
  return await authFetch(
    `${process.env.REACT_APP_SIM_INVENTORY_SIMS_PROFILE_API}/columns-configurations/revert-to-default`,
    {
      signal,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        variant,
      }),
    },
  );
};

function mapConfigurationToLowercase(column: ColumnConfiguration): ColumnConfiguration {
  return {
    ...column,
    name: lowercaseFirstLetter(column.name),
    children: column.children?.map((child) => mapConfigurationToLowercase(child)) || [],
  };
}

function mapConfigurationToUppercase(column: ColumnConfiguration): ColumnConfiguration {
  return {
    ...column,
    name: uppercaseFirstLetter(column.name),
    children: column.children?.map((child) => mapConfigurationToUppercase(child)) || [],
  };
}
