import { InterimsTimelineEntry } from 'common/components/SessionHistory/services/SessionHistoryApi';

export const useInterimSelectionRange = (
  selectedInterimId: string,
  timeline: InterimsTimelineEntry[]
) => {
  const selectedInterimTimelineEntry =
    timeline.find((interimTimeline) => interimTimeline.id === selectedInterimId) || null;

  return {
    markedInterimStart: selectedInterimTimelineEntry?.start || null,
    markedInterimStop: selectedInterimTimelineEntry?.stop || null
  };
};
