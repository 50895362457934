import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Collapse from '@mui/material/Collapse';
import { DurationBox } from 'common/components/Table/DurationBox';
import React, { Suspense, useEffect, useState } from 'react';
import { SessionHistoryCell } from './SessionHistoryCell';
import { useTranslation } from 'react-i18next';

import { InterimsSession } from './InterimsTable';
import Typography from '@mui/material/Typography';
import { SessionHistoryEntry } from 'common/components/SessionHistory/services/SessionHistoryApi';
import { toLocaleDateTime } from 'common/utils/dateUtils';
import { CollapseIconButton } from 'common/components/Buttons/CollapseIconButton';
import { ReactComponent as PrimarySim } from 'assets/images/StatusIndicator/primarySim.svg';
import { ReactComponent as SecondarySim } from 'assets/images/StatusIndicator/secondarySim.svg';
import { Tooltip } from '../../../common/components/Tooltip/Tooltip';
import { palette } from 'theme/palette';

type SessionHistoryRowProps = SessionHistoryEntry & {
  opened: boolean;
  onToggle: (open: boolean) => void;
  showProfile?: boolean;
  isPrimary?: boolean;
};

const middleCellPaddingSX = {
  pl: 5,
};

const numericCellSX = {
  textAlign: 'right',
};

const profileIconConfig = {
  width: '24px',
  height: '24px',
  role: 'img',
};

export const SessionHistoryRowWithInterim: React.FC<SessionHistoryRowProps> = ({
  imsi,
  sessionStart,
  sessionEnd,
  duration,
  framedIpaddress,
  calledStationId,
  operatorCode,
  roamingCode,
  localization,
  detectedImei,
  acctSessionId,
  ratType,
  username,
  rasClient,
  framedProtocol,
  nasIpAddress,
  serviceType,
  cumulativeUsage,
  opened,
  onToggle,
  showProfile = false,
  isPrimary = true,
}) => {
  const [innerOpen, setInnerOpen] = useState(opened);
  const { t } = useTranslation();
  useEffect(() => {
    if (opened !== innerOpen) {
      setInnerOpen(opened);
    }
  }, [opened]);

  const gridColumnValue = showProfile ? 'span 17' : 'span 16';

  return (
    <Box
      className={innerOpen ? 'opened' : ''}
      sx={{
        display: 'grid',
        gridColumn: gridColumnValue,
        gridTemplateColumns: 'subgrid',
        backgroundColor: palette.background.paper,
        '&.opened > div[role=row]': {
          borderBottom: (theme) => `1px solid ${theme.palette.border}`,
        },
      }}
    >
      <Box
        role="row"
        sx={{
          display: 'grid',
          gridColumn: gridColumnValue,
          gridTemplateColumns: 'subgrid',
          position: 'sticky',
          backgroundColor: palette.background.paper,
          top: 42,
          zIndex: 1000,
        }}
      >
        <Box sx={{ width: '48px', display: 'flex', justifyContent: 'center' }} role="cell">
          <CollapseIconButton
            opened={innerOpen}
            onClick={() => {
              setInnerOpen((current: boolean) => !current);
            }}
          />
        </Box>
        {showProfile && (
          <Box
            role="cell"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {isPrimary ? (
              <Tooltip title={t('common.primary')}>
                <PrimarySim {...profileIconConfig} data-testid="Pause icon" />
              </Tooltip>
            ) : (
              <Tooltip title={t('common.backup')}>
                <SecondarySim {...profileIconConfig} data-testid="Pause icon" />
              </Tooltip>
            )}
          </Box>
        )}
        <SessionHistoryCell
          sx={showProfile ? middleCellPaddingSX : {}}
          primaryText={toLocaleDateTime(sessionStart)}
          secondaryText={toLocaleDateTime(sessionEnd)}
        />
        <Box sx={middleCellPaddingSX} role="cell">
          <DurationBox duration={duration} />
        </Box>
        <Box sx={{ ...middleCellPaddingSX, ...numericCellSX }} role="cell">
          <Typography variant="text1" color="text.primary" data-testid="primaryText">
            {cumulativeUsage?.toFixed(2)}
          </Typography>
        </Box>
        <SessionHistoryCell
          primaryText={framedIpaddress || ''}
          secondaryText={calledStationId || ''}
          sx={middleCellPaddingSX}
        />
        <SessionHistoryCell primaryText={operatorCode || ''} sx={middleCellPaddingSX} />
        <SessionHistoryCell primaryText={roamingCode || ''} sx={middleCellPaddingSX} />
        <SessionHistoryCell primaryText={localization || ''} sx={middleCellPaddingSX} />
        <SessionHistoryCell primaryText={detectedImei || ''} sx={middleCellPaddingSX} />
        <SessionHistoryCell primaryText={acctSessionId || ''} sx={middleCellPaddingSX} />
        <SessionHistoryCell primaryText={ratType || ''} sx={middleCellPaddingSX} />
        <SessionHistoryCell primaryText={rasClient || ''} sx={middleCellPaddingSX} />
        <SessionHistoryCell primaryText={framedProtocol || ''} sx={middleCellPaddingSX} />
        <SessionHistoryCell primaryText={nasIpAddress || ''} sx={middleCellPaddingSX} />
        <SessionHistoryCell primaryText={serviceType || ''} sx={middleCellPaddingSX} />
        <SessionHistoryCell primaryText={username || ''} sx={middleCellPaddingSX} />
      </Box>
      <Box sx={{ gridColumn: 'span 16' }}>
        <Collapse in={innerOpen} addEndListener={() => onToggle(innerOpen)} timeout={0}>
          {innerOpen ? (
            <Suspense
              fallback={
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    height: 70,
                    py: 4,
                  }}
                >
                  <CircularProgress color="inherit" sx={{ position: 'fixed', left: '50%' }} />
                </Box>
              }
            >
              {acctSessionId && <InterimsSession imsi={imsi} sessionId={acctSessionId} />}
            </Suspense>
          ) : null}
        </Collapse>
      </Box>
    </Box>
  );
};
