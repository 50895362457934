import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import './ScrollbarSlider.css';

export type DragSliderProps = {
  min: number;
  max: number;
  value: [number, number];
  onChange: (value: [number, number]) => void;
};

export const ScrollbarSlider: React.FC<DragSliderProps> = ({ min, max, value, onChange }) => {
  return (
    <Slider
      range
      className="drag-slider"
      handleRender={() => <></>}
      draggableTrack
      allowCross={false}
      min={min}
      max={max}
      value={value}
      onChange={(value) => {
        const range = value as [number, number];
        onChange(range);
      }}
    />
  );
};
