import { ObjectValues } from 'common/tsHelpers';

export const SIM_CARD_IDENTIFIERS = {
  EID: 'EID',
  ICCID: 'ICCID',
  IMSI: 'IMSI',
  MSISDN: 'MSISDN'
} as const;

export type SimCardIdentifiers = ObjectValues<typeof SIM_CARD_IDENTIFIERS>;
