import { differenceInMinutes } from 'date-fns';

export const MS_IN_DAY = 24 * 60 * 60 * 1000;

export const ZOOM_VALUES_IN_MINUTES = {
  DAY: 24 * 60,
  THREE_DAYS: 3 * 24 * 60,
  SEVEN_DAYS: 7 * 24 * 60,
};

export const getVisibleInterimsZoomOptions = (start: Date, stop: Date) => {
  const durationInMinutes = differenceInMinutes(stop, start);

  return {
    oneDay: true,
    threeDays: durationInMinutes > ZOOM_VALUES_IN_MINUTES.DAY,
    sevenDays: durationInMinutes > ZOOM_VALUES_IN_MINUTES.THREE_DAYS,
  };
};
