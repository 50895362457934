import { useCallback, useState } from 'react';

import { FilterSection } from 'common/components/Filters/FilterSection';
import { FiltersDialog, FiltersDialogProps } from 'common/components/Filters/FiltersDialog';

import { FilterListItem } from 'common/components/Filters/FilterListItem';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import { useTranslation } from 'react-i18next';
import { RadioSection } from 'common/components/Filters/IdFilterSelector/RadioSection';
import { FilterBox } from 'common/components/Filters/FilterBox';
import { SimInventoryCan } from 'permissions/PermissionProvider';
import { Actions, Subjects } from 'permissions/ability';
import { useAtomValue } from 'jotai';
import { rsimFiltersAtom } from 'atoms/filters';
import { useResetAtom } from 'jotai/utils';
import { LastActiveProfileInput } from './Inputs/LastActiveProfileInput';
import { LastUpdateTimeInput } from './Inputs/LastUpdateTimeInput';
import { SimulateOutageInput } from './Inputs/SimulateOutageInput';
import { EIDRangeSearch } from './Inputs/EIDRangeSearchInput';
import { IdSearchInput } from './Inputs/IdSearchInput';
import { ConnectionIdSearchInput } from './Inputs/ConnectionIdSearchInput';
import { OrderNumberSearchInput } from './Inputs/OrderNumberSearchInput';
import { AccountSearchInput } from './Inputs/AccountSearchInput';
import { TagsSearchInput } from './Inputs/TagsSearchInput';
import { RSimFilters } from '../data/filters.constants';

const RADIO_LABELS = {
  search: 'Search',
  eidRange: 'EID Range',
};

type RSimFiltersDialogProps = Pick<FiltersDialogProps, 'onCancel' | 'onClear' | 'onClose'> & {
  onApply: (filters: RSimFilters) => void;
};

export const RSimFiltersDialog = ({ onApply, onClear, ...props }: RSimFiltersDialogProps) => {
  const { t } = useTranslation();
  const filters = useAtomValue(rsimFiltersAtom);

  const [selectedSearch, setSelectedSearch] = useState<'search' | 'eidRange'>(() => {
    if (filters.eidFrom !== '' || filters.eidTo !== '') {
      return 'eidRange';
    }

    return 'search';
  });

  const resetFilters = useResetAtom(rsimFiltersAtom);

  const onClearClicked = useCallback(() => {
    resetFilters();
    onClear();
  }, []);

  const onApplyClicked = useCallback(() => {
    if (parseInt(filters.eidFrom) > parseInt(filters.eidTo)) {
      onApply({ ...filters, eidFrom: '', eidTo: '' });
    } else {
      onApply(filters);
    }
  }, [filters]);

  return (
    <FiltersDialog {...props} open={true} onApply={onApplyClicked} onClear={onClearClicked}>
      <FilterSection title={t('common.idSearch')}>
        <FilterBox>
          <RadioSection
            options={['search', 'eidRange']}
            value={selectedSearch}
            onValueChange={setSelectedSearch as (value: string) => void}
            labels={RADIO_LABELS}
          >
            {(value) => {
              return (
                <Box>
                  <Box
                    data-selector="search"
                    sx={{ opacity: value === 'search' ? 1 : 0 }}
                    aria-hidden={value !== 'search'}
                  >
                    <IdSearchInput />
                  </Box>
                  <Box
                    data-selector="eidRange"
                    sx={{ opacity: value === 'eidRange' ? 1 : 0 }}
                    aria-hidden={value !== 'eidRange'}
                  >
                    <EIDRangeSearch />
                  </Box>
                </Box>
              );
            }}
          </RadioSection>
        </FilterBox>
      </FilterSection>
      <FilterSection title={'rSim'}>
        <FilterListItem label="Last active profile" htmlFor="lastActiveProfile">
          <LastActiveProfileInput />
        </FilterListItem>
        <FilterListItem label="No update for" htmlFor="noUpdateFor">
          <LastUpdateTimeInput />
        </FilterListItem>
        <FilterListItem label={t('common.simulateOutage')} htmlFor="simulateOutage">
          <Box
            sx={{
              display: 'flex',
            }}
          >
            <SimulateOutageInput />
          </Box>
        </FilterListItem>
        <SimInventoryCan I={Actions.filter} a={Subjects.internalData}>
          <FilterListItem label="Account" htmlFor="account">
            <AccountSearchInput />
          </FilterListItem>
        </SimInventoryCan>
        <FilterListItem label="Tag" htmlFor="tag">
          <TagsSearchInput />
        </FilterListItem>
      </FilterSection>
      <Box>
        <FilterSection title={t('common.order')}>
          <List>
            <FilterListItem label={t('common.connectionId')} htmlFor="connectionId">
              <ConnectionIdSearchInput />
            </FilterListItem>
            <FilterListItem label={t('simInventory.orderNumber')} htmlFor="orderNumber">
              <OrderNumberSearchInput />
            </FilterListItem>
          </List>
        </FilterSection>
      </Box>
    </FiltersDialog>
  );
};
