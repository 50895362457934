import { styled } from '@mui/material';
import { RSimStatusIndicator } from '../RSimStatusIndicator';

const RSimStatusCellRoot = styled('div')({
  display: 'flex',
  flex: '1',
  justifyContent: 'center',
});

export const RSimStatusCell = ({ row }: any) => {
  const { euicc, status } = row;
  return (
    <RSimStatusCellRoot>
      <RSimStatusIndicator status={status} euicc={euicc} />
    </RSimStatusCellRoot>
  );
};
