import { useMounted } from 'common/useMounted';
import { Atom, useAtomValue } from 'jotai';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useFilterToUrlParser = <Value>({
  atom,
  mapperFunction,
}: {
  atom: Atom<Value>;
  mapperFunction: (filters: Awaited<Value>) => URLSearchParams;
}) => {
  const filters = useAtomValue(atom);
  const isMounted = useMounted();

  const [, setSearhParams] = useSearchParams();

  useEffect(() => {
    if (isMounted) {
      setSearhParams(mapperFunction(filters as Awaited<Value>), { replace: true });
    }
  }, [filters]);
};
