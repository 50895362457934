import React from 'react';
import { useInterimsLoaderByDate } from 'common/components/SessionHistory/hooks/useInterimsLoaderByDate';
import { InterimsTimelineEntry } from 'common/components/SessionHistory/services/SessionHistoryApi';
import { Interims, Interim } from 'common/components/SessionHistory/utils/interims';
import { ValuesType } from 'utility-types';
import { useInterimSelection } from './hooks/useInterimSelection';
import { useInterimSelectionRange } from './hooks/useInterimSelectionRange';
import { useInterimsTimeRange } from './hooks/useInterimsTimeRange';

export type InterimsTimelineProviderContextProps = {
  interimsTimeline: InterimsTimelineEntry[];
  fetchingInterims: boolean;

  selectedInterimId: string;
  selectedInterim: ValuesType<Interims> | null;
  startInterim: Interim | null;
  startInterimMarked: boolean;
  markedInterimStart: Date | null;
  markedInterimStop: Date | null;

  selectInterimById: (interimId: string) => void;
  selectStartInterim: () => void;
  markSelectedInterimAsDefault: () => void;
  unselectInterim: () => void;

  sessionStart: Date;
  sessionEnd: Date;
};

export const InterimsTimelineContext = React.createContext<InterimsTimelineProviderContextProps>(
  {} as InterimsTimelineProviderContextProps,
);

export type InterimsTimelineProviderProps = {
  sessionStart: Date;
  sessionEnd: Date;
  children: React.ReactNode;
  imsi?: number;
  sessionId?: string;
};

export const InterimsTimelineProvider: React.FC<InterimsTimelineProviderProps> = ({
  children,
  imsi,
  sessionId,
  sessionStart,
  sessionEnd,
}) => {
  const { selectedRange } = useInterimsTimeRange();

  const {
    timeline: interimsTimeline,
    interims,
    isFetching: fetchingInterims,
  } = useInterimsLoaderByDate(imsi, sessionId, sessionStart, sessionEnd, selectedRange.from);

  const {
    startInterim,
    selectedInterimId,
    markedInterimId,
    selectedInterim,
    selectInterimById,
    selectStartInterim,
    unselectInterim,
    markSelectedInterimAsDefault,
  } = useInterimSelection(interims || []);

  const { markedInterimStart, markedInterimStop } = useInterimSelectionRange(
    markedInterimId,
    interimsTimeline,
  );

  const startInterimMarked = markedInterimId === startInterim?.id;

  return (
    <InterimsTimelineContext.Provider
      value={{
        interimsTimeline,
        fetchingInterims,
        startInterim,
        selectedInterimId,
        selectedInterim,
        startInterimMarked,
        markedInterimStart,
        markedInterimStop,

        selectStartInterim,
        selectInterimById,
        unselectInterim,
        markSelectedInterimAsDefault,

        sessionStart,
        sessionEnd,
      }}
    >
      {children}
    </InterimsTimelineContext.Provider>
  );
};
