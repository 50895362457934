import React from 'react';

import { Tag } from 'tags/services/tagsApi.types';
import { TagDisplay } from 'tags/TagsDisplay';
import Box from '@mui/material/Box';
import { styled } from '@mui/material';
import { CHIPS_HEIGHTS_MAP } from 'theme/components/MuiChip';

type TagsCellProps = {};

const TagsCellRoot = styled(Box, {
  name: 'TagsCell',
  slot: 'root',
})({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  lineHeight: CHIPS_HEIGHTS_MAP.MEDIUM,
});

export const TagsCell: React.FC<TagsCellProps> = React.memo(({ row }: any) => {
  return (
    <TagsCellRoot>
      <TagDisplay
        initialTags={row.tags?.items?.map((tag: Tag) => tag.name) || []}
        id={row.euicc}
        hasMore={row.tags?.hasMore || false}
      />
    </TagsCellRoot>
  );
});
