import { createContext, useContext } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import {
  SimInventoryData,
  SimProfile,
} from 'simInventory/SimInventoryApi/simInventoryApi.interface';

type SimUpdateContextProps = {
  updateTagsForSim: (iccid: string, tags: any) => void;
  updateCustomFieldsForSim: (iccid: string, tags: any) => void;
};

export const simUpdateContext = createContext<SimUpdateContextProps>({} as SimUpdateContextProps);

export const useSimUpdateContext = () => useContext(simUpdateContext);

export const SimUpdateProvider = ({ queryKey, children }: any) => {
  const queryClient = useQueryClient();
  const updateTagsForSim = async (iccid: string, tags: any) => {
    const simProfiles: SimInventoryData | undefined = queryClient.getQueryData(queryKey);

    if (!simProfiles?.items.length) {
      return;
    }

    //@ts-ignore
    queryClient.setQueryData(queryKey, (oldData: SimInventoryData) => {
      if (!oldData) {
        return oldData;
      }

      const newData = {
        ...oldData,
        items: oldData?.items.map((simProfile: SimProfile) => {
          if (simProfile.iccid === iccid) {
            return { ...simProfile, tags: { items: tags, hasMore: false } };
          }

          return simProfile;
        }),
      };

      return newData;
    });
    return;
  };

  const updateCustomFieldsForSim = async (iccid: string, customFields: any) => {
    const simProfiles: SimInventoryData | undefined = queryClient.getQueryData(queryKey);

    if (!simProfiles?.items.length) {
      return;
    }

    //@ts-ignore
    queryClient.setQueryData(queryKey, (oldData: SimInventoryData) => {
      if (!oldData) {
        return oldData;
      }

      const newData = {
        ...oldData,
        items: oldData?.items.map((simProfile: SimProfile) => {
          if (simProfile.iccid === iccid && simProfile.customFields) {
            return {
              ...simProfile,
              customFields: simProfile.customFields.map((customFiled) => {
                const currentCustomField = customFields.find(
                  (currentCustomField: any) => currentCustomField.label === customFiled.label,
                );

                if (currentCustomField && customFiled.dataType === 'Text') {
                  return {
                    ...customFiled,
                    textValue: currentCustomField.value,
                  };
                }

                if (currentCustomField && customFiled.dataType === 'Dropdown') {
                  return {
                    ...customFiled,
                    selectionOption: currentCustomField.value,
                  };
                }

                return customFiled;
              }),
            };
          }

          return simProfile;
        }),
      };

      return newData;
    });
    return;
  };

  return (
    <simUpdateContext.Provider value={{ updateTagsForSim, updateCustomFieldsForSim }}>
      {children}
    </simUpdateContext.Provider>
  );
};
