import { cleanDefaultValuesURLSearchParams } from 'common/utils/urlSearchParamsUtils';
import { RSIM_INITIAL_FILTERS, RSimFilters } from '../data/filters.constants';
import { pick } from 'lodash';

export const mapRSimFiltersToURLSearchParams = (rsimFilters: RSimFilters) => {
  const cleanedFiltersValues = cleanDefaultValuesURLSearchParams(rsimFilters, RSIM_INITIAL_FILTERS);
  return new URLSearchParams(cleanedFiltersValues);
};

export const mapURLSearchParamsToRsimFilters = (urlSearchParams: URLSearchParams) => {
  const filters = Object.fromEntries(urlSearchParams);

  const { tags, accounts, ...cleanedFiltersValues } = pick(
    filters,
    Object.keys(RSIM_INITIAL_FILTERS),
  );

  return {
    ...RSIM_INITIAL_FILTERS,
    ...cleanedFiltersValues,
    tags: tags ? tags.split(',') : RSIM_INITIAL_FILTERS.tags,
    accounts: accounts ? accounts.split(',') : RSIM_INITIAL_FILTERS.accounts,
  };
};
