import { getHuminizeDurationInSecFunction } from 'i18n/dateLocale';
import { TextCell } from './TextCell';

const humanize = getHuminizeDurationInSecFunction();

export const DurationCell = ({ value, subValue }: any) => {
  let valueToDisplay = value;
  if (typeof value === 'number') {
    valueToDisplay = humanize(value);
  }

  let subValueToDisplay = subValue;
  if (typeof value === 'number') {
    subValueToDisplay = humanize(subValue);
  }
  return <TextCell value={valueToDisplay} subValue={subValueToDisplay} />;
};
