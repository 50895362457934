import {
  CHANGE_IN_TIME_BORDER_COLOR,
  CHANGE_IN_TIME_COLORS,
  CHANGE_IN_TIME_UNKNOWN_BORDER_COLOR,
  CHANGE_IN_TIME_UNKNOW_COLOR
} from 'common/components/Graphs/graphConfig';
import { ChangingInTimeGraphDataset } from '../models/changingInTimeGraphModels';

const arrayColorMapper =
  (colorArray: string[], nullColor: string) => (datasetData: ChangingInTimeGraphDataset) => {
    let previousColorsIndex: number = colorArray.length - 1;
    const colorsArray = datasetData.map((entry) => {
      if (entry.label === null) {
        return nullColor;
      }

      previousColorsIndex = (previousColorsIndex + 1) % colorArray.length;
      return colorArray[previousColorsIndex];
    });

    return colorsArray;
  };

export const createBackgroundBaseOnValue = arrayColorMapper(
  CHANGE_IN_TIME_COLORS,
  CHANGE_IN_TIME_UNKNOW_COLOR
);

export const createBorderColorBaseOnValue = (datasetData: ChangingInTimeGraphDataset) => {
  const colorsArray = datasetData.map((entry) => {
    if (entry.label === null) {
      return CHANGE_IN_TIME_UNKNOWN_BORDER_COLOR;
    }

    return CHANGE_IN_TIME_BORDER_COLOR;
  });

  return colorsArray;
};

export const getMarkerColor = (
  startMarkerValue: string | null | undefined,
  dataset: ChangingInTimeGraphDataset,
  datasetColors: string[]
) => {
  if (!startMarkerValue) {
    return CHANGE_IN_TIME_UNKNOW_COLOR;
  }

  if (dataset.length === 0) {
    return CHANGE_IN_TIME_COLORS[0];
  }

  const markerHasTheSameValue = dataset.at(-1)?.label === startMarkerValue;

  if (markerHasTheSameValue) {
    return datasetColors.at(-1);
  }

  const startColorIndex = CHANGE_IN_TIME_COLORS.indexOf(datasetColors.at(-1)!);

  return CHANGE_IN_TIME_COLORS[(startColorIndex + 1) % CHANGE_IN_TIME_COLORS.length];
};

export const getMarkerBorderColor = (startMarkerValue: string | null | undefined) => {
  if (!startMarkerValue) {
    return CHANGE_IN_TIME_UNKNOWN_BORDER_COLOR;
  }

  return CHANGE_IN_TIME_BORDER_COLOR;
};
