import React from 'react';
import { RSimInventoryDetailsPage } from './RSimInventoryDetailsPage';
import { CARD_TYPE, TagProvider } from 'tags/TagProvider';
import Box from '@mui/material/Box';
import { DetailsSkeleton } from 'common/components/DetailsSkeleton';
import { SuspenseLoadingView } from 'common/components/SuspenseLoadingView';
import { Outlet } from 'react-router-dom';
import { PrimarySimGrid } from './components/PrimarySimGrid';
import { SecondarySimGrid } from './components/SecondarySimGrid';
import {
  rSimInventoryDetails,
  rSimInventorySimDetailsSkeletonMediumScreen,
  rSimInventorySimDetailsSkeletonSmallScreen,
} from './RSimInventoryDetailsPage.style';
import { useTheme } from '@mui/material/styles';
import { palette } from 'theme/palette';

export const RSimInventoryEntry = () => {
  const theme = useTheme();
  return (
    <TagProvider type={CARD_TYPE.RSIM}>
      <Box sx={{ height: '100%', border: (theme) => `1px solid ${theme.palette.border}` }}>
        <SuspenseLoadingView
          loadFallback={
            <Box sx={{ ...rSimInventoryDetails, mt: 16 }}>
              <DetailsSkeleton />

              <Box
                sx={{
                  ...rSimInventorySimDetailsSkeletonSmallScreen,
                  [theme.breakpoints.up('md')]: {
                    ...rSimInventorySimDetailsSkeletonMediumScreen,
                  },
                }}
              >
                <PrimarySimGrid sx={{ gridArea: 'skeleton1', pl: 0 }} hideTopBorder={true}>
                  <DetailsSkeleton />
                </PrimarySimGrid>
                <div
                  style={{
                    gridArea: 'divider',
                    borderRight: `1px solid ${palette.border}`,
                  }}
                ></div>
                <SecondarySimGrid sx={{ gridArea: 'skeleton2', pl: 0 }} hideTopBorder={true}>
                  <DetailsSkeleton />
                </SecondarySimGrid>
              </Box>
            </Box>
          }
          queryKey={[
            'fetchRSimDetails',
            'fetchRSimDetailsPrimarySim',
            'fetchRSimDetailsSecondarySim',
          ]}
        >
          <RSimInventoryDetailsPage />
          <Outlet />
        </SuspenseLoadingView>
      </Box>
    </TagProvider>
  );
};
