export const en = {
  translation: {
    auth: {
      somethingWrong: 'Oops! Something went wrong!',
      tryAgain: 'Try again',
    },
    bulkProvisioning: {
      manualAction: 'Manual action',
      fileUpload: 'File upload',
      order: 'Order',
      downloadFailedResults: 'Download failed results',
      downloadInput: 'Download input file',
      dragAndDrop: 'Drag& drop first file or click here to Upload file',
      processedDate: 'Processed date',
      title: 'Activity Log',
      uploadDateFrom: 'Upload date from',
      uploadDateTo: 'Upload date to',
      viewDetails: 'View details',
      source: 'Source',
      processingFinishedTime: 'Processing Finished Time',
      fileUploadingInfo: 'You’re uploading file:',
      downloadTemplate: 'Download Template',
      masterTemplate: 'Master template',
      bulkProvisioning: 'Bulk provisioning',
      unitDataAdjustment: 'Unit data adjustment',
      bulkProvisioningFailed: 'Bulk Provisioning Failed',
    },
    tag: {
      signLimits: 'Tag can’t be longer that 50 characters',
      tagsLimit: 'Tags limit reached',
    },
    common: {
      idSearch: 'ID Search',
      zoom: 'Zoom',
      zoomIn: 'Zoom in',
      zoomOut: 'Zoom out',
      zoomOutMax: 'Zoom out max',
      connectionId: 'Connection ID',
      order: 'Order',
      activeTime: 'Active time',
      accountName: 'Account Name',
      accountNumber: 'Account Number',
      active: 'Active',
      additionalActions: 'additional actions',
      applyFilters: 'Apply filters',
      cancel: 'Cancel',
      clearFilters: 'Clear filters',
      client: 'Client',
      close: 'Close',
      confirm: 'Confirm',
      currentIp: 'Current IP',
      dropFile: 'Drop file here',
      eid: 'EID',
      expandRow: 'expand row',
      fail: 'Fail',
      failed: 'Failed',
      fileName: 'File name',
      filters: 'Filters',
      iccid: 'ICCID',
      id: 'ID',
      imsi: 'IMSI',
      inactive: 'Inactive',
      disconnected: 'Disconnected',
      inventory: 'Inventory',
      label: 'Label',
      logout: 'logout',
      managementIp: 'Management IP',
      minTextLong: 'Minimum {{number}} characters',
      mno: 'MNO',
      msisdn: 'MSISDN',
      noAccess: 'You do not have permission to view this data',
      noRecords: 'No records',
      noResults: 'No results found',
      none: 'None',
      numberOfCards: 'No. of cards',
      organisation: 'Organisation',
      plan: 'Plan',
      search: 'Search',
      searchText: 'Search {{text}}',
      source: 'Source',
      status: 'Status',
      subscriptionStatus: 'Subscription Status',
      success: 'Success',
      uploadDate: 'Upload date',
      uploadTime: 'Upload time',
      uploadFile: 'Upload file',
      usage: 'Usage (MB)',
      data: 'Data (MB)',
      refresh: 'Refresh',
      unknown: 'Unknown',
      pageNotFound: 'Page not found',
      backToDashboard: 'Back to dashboard',
      downloading: 'Downloading',
      export: 'Export',
      exportFailed: 'Export failed',
      requestFailed: 'Request Failed',
      simulationRunning: 'Simulation running',
      simulateOutage: 'Simulate outage',
      somethingWrong: 'Something went wrong!',
      simulateOutageRunning: 'Outage simulation running',
      primary: 'Primary',
      secondary: 'Secondary',
      backup: 'Backup',
      connected: 'Connected',
      inSession: 'In session',
      outSession: 'Out of session',
      rowsPerPage: 'Rows per page',
      sessions: 'Sessions',
      orderNumber: 'Order Number',
      common: 'Upload date',
      to: 'to',
      from: 'from',
      customerPlan: 'Customer Plan',
      minutes: 'minutes',
      hour: 'hour',
      day: 'day',
      week: 'week',
      month: 'month',
      tag: 'Tag',
      tags: 'Tags',
      asc: 'Ascending',
      desc: 'Descending',
      toSort: 'Sort',
      profile: 'Profile',
      any: 'Any',
      on: 'On',
      off: 'Off',
      type: 'type',
      account: 'Account',
      limitReached: 'Limit reached',
      resetColumnWidth: 'Reset column width',
      combinedRows: 'Combined rows',
      revertToDefault: 'Revert to default configuration',
      sortByDescending: 'Sort by descending',
      sortByAscending: 'Sort by ascending',
      clear: 'Clear',
      inMinuteUsage: 'Voice Usage MT',
      outMinuteUsage: 'Voice Usage MO',
      inTextUsage: 'SMS Usage MT (Msg)',
      outTextUsage: 'SMS Usage MO (Msg)',
      inMinuteUsageProduct: 'Voice (MT)',
      outMinuteUsageProduct: 'Voice (MO)',
      inTextUsageProduct: 'SMS MT (Msg)',
      outTextUsageProduct: 'SMS MO (Msg)',
      admin: 'Admin',
      toHigherThanFromError: 'Value must be higher than "From"',
      add: 'Add',
      edit: 'Edit',
      delete: 'Delete',
      selectAll: 'Select all',
      field: 'Field',
      changePassword: 'Change Password',
      userManagement: 'User Management',
      logOut: 'Log Out',
      loading: 'Loading',
      products: 'Products',
      voiceMO: 'Voice MO',
      voiceMT: 'Voice MT',
      smsMO: 'SMS MO (msg)',
      smsMT: 'SMS MT (msg)',
      emailWithDownloadLink: "You'll receive an email with a download link when it's ready.",
    },
    rSimInventory: {
      imsiNotValid: 'rSIM profile IMSI is missing',
      activeProfile: 'Active Profile',
      lastSwitch: 'Last Switch',
      lastIpAddress: 'Last IP address',
      title: 'rSIM',
      rSimInventory: 'rSIM Inventory',
      rSIMDetails: 'rSIM Details',
      uploadFinishedAt: 'Upload finished at',
      rsimStatus: 'rSIM Status',
      lastPolling: 'Last Polling',
      lastPollingTimestamp: 'Last Polling Timestamp',
      active: 'Active',
      inactive: 'Inactive',
      primaryProfileActive: 'Primary connected',
      secondaryProfileActive: 'Backup connected',
      primaryProfileDisconnected: 'Inactive - last active: Primary',
      secondaryProfileDisconnected: 'Inactive - last active: Secondary',
      lastActiveProfile: 'Last active',
      primary: 'primary',
      backup: 'backup',
      RSIMDetails: '',
      configuration: 'Configuration',
      customConfigurationDetected: 'Custom configuration detected',
      configurationChangePending: 'Configuration change pending',
      noConfigurationApplied: 'No configuration applied',
      configurationDetails: 'Configuration details',
      restoreConfiguration: 'Restore configuration',
      restoreConfigurationSend: 'Restore configuration sent',
      restoreConfigurationFailed: 'Restore configuration failed',
      usageChartTitle: 'Average session throughput',
      usageChartInput: 'Input',
      usageChartOutput: 'Output',
      pollingChartTitle: 'Polling data',
      pollingChartAcknowledged: 'Acknowledged',
      pollingChartNotAcknowledged: 'Not acknowledged',
      sessionChartTitle: 'Sessions',
      sessionChartFinalized: 'Finalized session',
      sessionChartNotFinalized: 'Not finalized session',
      sessionChartZeroByte: 'Zero-byte session',
      currentlyAppliedConfiguration: 'Currently applied configuration',
      connectionDelayEventCounter: 'Connection delay event counter',
      requestedPoolInterval: 'Reqested poll interval',
      commsCheckEventCounter: 'Comms check event counter',
      maxConsecutiveFailedPoll: 'Max consecutive failed poll',
      backupCancellationDelayEventCounter: 'Backup cancellation delay event counter',
      maxGlobalSwapCounter: 'Max global swap counter',
      resilienceRestorationDelayEventCounter: 'Resilience restoraton delay event counter',
      maxRandConnectionDelayEventCounter: 'Random connection delay event counter',
      maxRandBackupCancellationDelayEventCounter: 'Random backup cancellation delay event counter',
      rSimProfile: 'rSIM profile',
      otaIccid: 'OTA ICCID',
      version: 'Version',
      connectionIdFilterPlaceholder: 'Search Connection ID',
      orderNumberFilterPlaceholder: 'Search Order Number',
      accountFilterPlaceholder: 'Select Account Name or Account Number',
      tagsFilterPlaceholder: 'Select Tags',
      limitOfNRecordsExceeded: 'Limit of {{n}} records exceeded',
      connectionId: 'Connection ID',
      orderNumber: 'Order Number',
      orderReference: 'Order Reference',
      rsimProducts: 'rSIM Products',
      actionStatusInfo: 'View the action status in the UI Updates',
      activationRequestSent: 'Activation request sent',
      deactivationRequestSent: 'Deactivation request sent',
      activation: 'Activation',
      deactivation: 'Deactivation',
      activationEid: 'Activate rSIM EID:',
      deactivationEid: 'Deactivate rSIM EID:',
    },
    simInventory: {
      billingStatuses: {
        billed: 'Billing',
        nonBilled: 'Non-billing',
      },
      billingStatus: 'Billing Status',
      company: 'Company',
      custom: 'Custom',
      identificationNumber: 'Identification number',
      identificationNumbers: 'Identification numbers',
      mobileNetworkOperator: 'Mobile Network Operator',
      mobileNetworkOperatorSectionLabel: 'Mobile network operator',
      selectStatus: 'Select Status',
      sim: 'SIM',
      simInventory: 'SIM Inventory',
      simStatus: 'SIM status',
      subscriptionStatusEntryTime_flat: 'Subscription Status Entry Time',
      subscriptionStatusEntryTime_combined: 'Entry Time',
      subscriptionPackageName: 'Subscription package name',
      title: 'SIM',
      changeStatusTime: 'Change Status Time',
      organisation: 'Organisation',
      subscriptionName: 'Subscription Name',
      subscriptionStatus: 'Subscription Status',
      subscriptionStatusEntryTime: 'Subscription Status Entry Time',
      mnoRatePlan: 'MNO Rate Plan',
      shippedDate: 'Shipped Date',
      firstActivationDate: 'First Activation Date',
      lastSessionDetails: 'Last session details',
      sessionDetails: 'Session details',
      sessionStatus: 'Session Status',
      sessionStatusLastUpdated_flat: 'Session Status Last Updated',
      sessionStatusLastUpdated_combined: 'Last Updated',
      sessionStartTime: 'Session Start Time',
      sessionEndTime: 'Session End Time',
      accountIdentifier: 'Account Identifier',
      rasClient: 'RAS Client',
      framedProtocol: 'Framed Protocol',
      nasIpAddress: 'NAS IP Address',
      serviceType: 'Service Type',
      userName: 'User Name',
      timeZone: 'Time Zone',
      usage: 'Usage (KB)',
      cumulativeInputUsage: 'Data Input (KB)',
      cumulativeOutputUsage: 'Data Output (KB)',
      cumulativeUsage: 'Cumulative Usage (KB)',
      eventTimestamp: 'Event Timestamp',
      ratType: 'RAT Type',
      radioAccessType: 'Radio Access Type',
      device: 'Device',
      detectedImei: 'Detected IMEI',
      imei: 'IMEI',
      managementIp: 'Management IP ',
      currentIp: 'Current IP',
      apn: 'APN',
      location: 'Last Location',
      client: 'Client',
      simProfile: 'SIM profile',
      profileStatus: 'Profile status',
      lastUpdateTime: 'Last Update Time',
      updatePending: 'Update Pending',
      SIMDetails: 'SIM Details',
      customerCommsPlan: 'Customer Comms Plan',
      commsPlan: 'Comms Plan',
      unknown: 'Unknown',
      inSession: 'In Session',
      disconnected: 'Disconnected',
      maxSimProfilesCountError: 'Limit of {{maxSimProfiles, number}} records per download exceeded',
      connectionId: 'Connection ID',
      orderNumber: 'Order Number',
      averageSessionThroughput: 'Average session throughput',
      customerReference: 'Customer Reference',
      order: 'Order',
      averageSessionChartTitle: 'Average session throughput',
      averageSessionChartInput: 'Input',
      averageSessionChartOutput: 'Output',
      sessionChartTitle: 'Sessions',
      sessionChartFinalized: 'Finalized session',
      sessionChartNotFinalized: 'Not finalized session',
      sessionChartZeroByte: 'Zero-byte session',
      profileUsageInCycle: 'Profile usage in cycle',
      usageInCycle: 'Usage in cycle',
      lastRat: 'Last RAT',
      roamingCode: 'Roaming Code',
      customFields: 'Custom fields',
      simType: 'SIM Type',
      activeConfiguration: 'Active Configuration',
      servicePlan: 'Service Plan',
      firstActivationTime: 'First Activation Time',
      productCode: 'Product Code',
      productType: 'Product Type',
      accountFilterPlaceholder: 'Select Account Name or Account Number',
      mnoFilterPlaceholder: 'Select MNO',
      tagsFilterPlaceholder: 'Select Tags',
      labelFilterPlaceholder: 'Search Label',
      connectionIdFilterPlaceholder: 'Search Connection ID',
      orderNumberFilterPlaceholder: 'Search Order Number',
      select: 'Select',
      search: 'Search',
      limitOfNRecordsExceeded: 'Limit of {{n}} records exceeded',
      anotherBackgroundExportIsStillInProgress: 'Another background export is still in progress.',
      pleaseTryAgainLater: 'Please try again later.',
      mnoStatus: 'MNO Status',
      activation: 'Activation',
      deactivation: 'Deactivation',
      activationIccid: 'Activate SIM',
      deactivationIccid: 'Deactivate SIM',
      simInventoryExport: 'Sim inventory export',
      requestOfNSimsExport:
        'We’ll process your request of <1>{{numberOfCards}}</1> SIMs export in the background.',
      exportingNSims: 'Exporting {{numberOfCards}} SIMs',
    },
    graphs: {
      interimDetails: 'Interim details',
      interimType: 'Interim type',
      timestamp: 'Timestamp',
    },
    customFields: {
      admin: 'Admin',
      customFields: 'Custom fields',
      enabled: 'Enabled',
      fieldName: 'Field Name',
      fieldType: 'Field Type',
      values: 'Values',
      action: 'Action',
      text: 'Text',
      dropdown: 'Drop-down list',
      edit: 'Edit',
      delete: 'Delete',
      save: 'Confirm changes',
      create: 'Create custom field',
      cancel: 'Cancel',
      addCustomField: 'Add custom field',
      selectFieldType: 'Select field type',
      typeFieldName: 'Type field name',
      unavailableWhileEditing: 'Unavailable while editing',
      customFieldsLimitReached: 'Custom fields limit reached',
      fieldNameTaken: 'Field name taken',
      fieldNameEmpty: 'Field name empty',
      saveError: 'Please fill all required fields',
      deleteCustomField: 'Delete custom field',
      deleteCustomFieldDescription:
        'Deleting the custom field {{name}} will remove all values assigned to it.',
      dropdownModal: {
        title: 'Edit drop-down list values',
        removeTitle: 'Delete drop-down list value',
        removeDescription:
          'Deleting the value <strong>{{label}}</strong> will remove it from associated SIM cards.',
        textFieldLabel: 'Create new value',
        valueNameTaken: 'Value name taken',
        confirmNewValue: 'Confirm to add a new value',
      },
      addDropdownListValues: 'Add drop-down list values',
    },
    auditTrail: {
      tableHeaders: {
        field: 'Field',
        changedFrom: 'Changed From',
        changedTo: 'Changed To',
        effectiveTime: 'Effective Time',
        source: 'Source',
        userName: 'User Name',
      },
    },
    admin: {
      rSimManufacturingFile: {
        rSimManufacturingFile: 'rSIM manufacturing file',
        dragAndDropFileOr: 'Drag and drop file or',
        browse: 'Browse',
        clear: 'Clear',
        upload: 'Upload',
        uploadNewFile: 'Upload new file',
        downloadResults: 'Download results',
        rSimManufacturingFilename: 'rSIM manufacturing file.out',
        processingFailedInvalidData: 'Processing failed - invalid data',
        failedToDownloadTheFile: 'Failed to download the file',
        fileProcessedSuccessfully: "File '{{filename}}' processed successfully",
        fileProcessed: "File '{{filename}}' processed",
        fileUploadFailed: "File '{{filename}}' upload failed",
        downloadTheResultsFileToSeeDetails: 'Download the results file to see details',
        wrongFileFormat: 'Wrong file format - only .out files are accepted',
      },
    },
    products: {
      tabs: {
        general: 'General',
        history: 'History',
        historyAnalysis: 'History Analysis',
        auditTrail: 'Audit Trail',
      },
      sim: {
        simProducts: 'SIM Products',
        viewDetails: 'View details',
        dataMB: 'Data (MB)',
        voiceMO: 'Voice (MO)',
        voiceMT: 'Voice (MT)',
        smsMO: 'SMS MO (msg)',
        smsMT: 'SMS MT (msg)',
        lastKnownIP: 'Last Known IP',
      },
      rSim: {
        rSimExport: 'rSim export',
        requestOfNRSimsExport:
          'We’ll process your request of <1>{{numberOfCards}}</1> rSIMs export in the background.',
        exportingNRSims: 'Exporting {{numberOfCards}} rSIMs',
        maxRSimProductsCount: 'Limit of {{maxRSims, number}} records per download exceeded',
      },
    },
  },
};
