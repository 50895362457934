import UnfoldLessRoundedIcon from '@mui/icons-material/UnfoldLessRounded';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';

export type CollapseAllIconButtonProps = Pick<IconButtonProps, 'onClick'>;

export const CollapseAllIconButton: React.FC<CollapseAllIconButtonProps> = ({ onClick }) => {
  return (
    <IconButton size="small" aria-label="collapse all" sx={{ width: '100%' }} onClick={onClick}>
      <UnfoldLessRoundedIcon />
    </IconButton>
  );
};
