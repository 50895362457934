import Box from '@mui/material/Box';
import { InterimsGraphs } from 'common/components/Graphs/Interims/InterimsGraphs';
import { subDays } from 'date-fns';
import { SessionDetails } from './SessionDetails';
import { SessionDetailsBox } from './SessionDetailsBox';
import { SessionHistoryTimeline } from '../services/SessionHistoryApi';
import { ValuesType } from 'utility-types';

export type SessionDetailsWithInterimsGraphsProps = {
  session: ValuesType<SessionHistoryTimeline> | null;
  profile?: string | null;
  iccid?: string;
};

export const SessionDetailsWithInterimsGraphs: React.FC<SessionDetailsWithInterimsGraphsProps> = ({
  session,
  profile,
  iccid
}) => {
  return (
    <Box>
      <SessionDetailsBox>
        <SessionDetails session={session} profile={profile} iccid={iccid} />
      </SessionDetailsBox>
      <InterimsGraphs
        imsi={session?.imsi}
        sessionId={session?.acctSessionId!}
        from={session?.sessionStart || subDays(new Date(), 1)}
        to={session?.sessionEnd || session?.statusLastUpdated || session?.sessionStart || new Date()}
      />
    </Box>
  );
};
