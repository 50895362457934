import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import {
  createCustomField,
  deleteCustomField,
  editCustomField,
  editSimCustomFieldsData,
  fetchCustomFields,
  saveSelectionOptions,
} from '../api/customFieldsApi';
import {
  CreateCustomField,
  EditCustomField,
  EditCustomFieldSelectionOptions,
} from '../entities/customFields.entity';
import { useAbility } from 'permissions/hooks/useAbility';
import { Actions, Subjects } from 'permissions/ability';
import { setMinimalPromiseTime } from 'common/utils/setMinimalPromiseTime';

const CustomFieldsKey = ['CustomFields'];

export const useCustomFields = () => {
  const ability = useAbility();
  const customFieldsEnabled = ability.can(Actions.read, Subjects.customFields);
  const {
    data: customFields,
    refetch,
    status,
    isRefetching,
  } = useSuspenseQuery({
    queryKey: [...CustomFieldsKey, customFieldsEnabled],
    queryFn: async () => (customFieldsEnabled ? setMinimalPromiseTime(fetchCustomFields()) : null),
  });

  const queryClient = useQueryClient();

  const { mutate: createField } = useMutation({
    mutationFn: async (data: CreateCustomField) => {
      return createCustomField(data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: CustomFieldsKey });
    },
  });

  const { mutate: editField } = useMutation({
    mutationFn: async (data: EditCustomField) => {
      return editCustomField(data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: CustomFieldsKey });
    },
  });

  const { mutate: deleteField } = useMutation({
    mutationFn: async (id: number) => {
      return deleteCustomField(id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: CustomFieldsKey });
    },
  });

  const { mutate: editSelectionOptions } = useMutation({
    mutationFn: async (data: EditCustomFieldSelectionOptions) => {
      return saveSelectionOptions(data.id, data.selectionOptions);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: CustomFieldsKey });
    },
  });

  const { mutateAsync: editSimCustomFields } = useMutation({
    mutationFn: async (data: { iccid: string; customFields: { id: number; value: string }[] }) => {
      return editSimCustomFieldsData(data.iccid, data.customFields);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: CustomFieldsKey });
    },
  });

  return {
    customFields,
    createField,
    editField,
    deleteField,
    editSimCustomFields,
    refetch,
    status,
    isRefetching,
    editSelectionOptions,
  };
};
