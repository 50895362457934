import { styled } from '@mui/material/styles';
import { gridDimensionsSelector, useGridApiContext, useGridSelector } from '@mui/x-data-grid-pro';

export const StickyBorder = styled('div')<{
  width: number;
  height: number;
  borderLeft?: boolean;
  borderRight?: boolean;
  borderBottom?: boolean;
  borderTop?: boolean;
}>(({ width, height, theme, borderLeft, borderRight, borderBottom, borderTop }) => ({
  position: 'sticky',
  left: 0,
  width: `${width}px`,
  height: `${height}px`,

  borderLeft: borderLeft ? `1px solid ${theme.palette.border}` : 'none',
  borderRight: borderRight ? `1px solid ${theme.palette.border}` : 'none',
  borderBottom: borderBottom ? `1px solid ${theme.palette.border}` : 'none',
  borderTop: borderTop ? `1px solid ${theme.palette.border}` : 'none',
  pointerEvents: 'none',
  zIndex: 99999,
}));

export type StickyBorderBoxProps = {
  height?: number;
  borderLeft?: boolean;
  borderRight?: boolean;
  borderBottom?: boolean;
  borderTop?: boolean;
};

export const StickyBorderBox = ({
  height = 52,
  borderLeft = true,
  borderRight = true,
  borderBottom = true,
  borderTop = false,
}: StickyBorderBoxProps) => {
  const apiRef = useGridApiContext();

  const { root: visibleViewport } = useGridSelector(apiRef, gridDimensionsSelector);

  return (
    <StickyBorder
      width={visibleViewport.width}
      height={height}
      borderLeft={borderLeft}
      borderRight={borderRight}
      borderBottom={borderBottom}
      borderTop={borderTop}
    />
  );
};
