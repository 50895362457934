import { Theme } from '@mui/material/styles';
import { SystemStyleObject } from '@mui/system';

export const fillParentHeight: SystemStyleObject<Theme> = {
  height: '100%',
};

export const centerContentHorizontalAndVertical: SystemStyleObject<Theme> = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
};

export const removeMarginsAndPaddings: SystemStyleObject<Theme> = {
  p: 0,
  m: 0,
};

export const noBorder: SystemStyleObject<Theme> = {
  border: 'none',
};

export const noBottomBorder: SystemStyleObject<Theme> = {
  borderBottom: 'none',
};

export const noTopBorder: SystemStyleObject<Theme> = {
  borderTop: 'none',
};

export const mainBorder = (theme: Theme) => `1px solid ${theme.palette.border}`;

export const bottomBorder: SystemStyleObject<Theme> = {
  borderBottom: (theme) => `1px solid ${theme.palette.border}`,
};

export const topBorder: SystemStyleObject<Theme> = {
  borderTop: (theme) => `1px solid ${theme.palette.border}`,
};

export const leftBorder: SystemStyleObject<Theme> = {
  borderLeft: (theme) => `1px solid ${theme.palette.border}`,
};

export const rightBorder: SystemStyleObject<Theme> = {
  borderRight: (theme) => `1px solid ${theme.palette.border}`,
};

export const TopAndVerticalBorders: SystemStyleObject<Theme> = {
  border: (theme) => `1px solid ${theme.palette.border}`,
  borderBottom: 'none',
};

export const verticalBorders: SystemStyleObject<Theme> = {
  borderLeft: (theme) => `1px solid ${theme.palette.border} !important`,
  borderRight: (theme) => `1px solid ${theme.palette.border} !important`,
};

export const horizontalBordersSX: SystemStyleObject<Theme> = {
  borderBottom: mainBorder,
  borderTop: mainBorder,
};

export const topDarkBorder: SystemStyleObject<Theme> = {
  borderTop: (theme) => `1px solid ${theme.palette.grey['200']} !important`,
};

export const backgroundDefault: SystemStyleObject<Theme> = {
  background: ({ palette }) => palette.background.default,
};

export const backgroundGrey100: SystemStyleObject<Theme> = {
  background: ({ palette }) => palette.grey[100],
};

export const paddingTop3: SystemStyleObject<Theme> = {
  pt: 3,
};
export const paddingHorizontal3: SystemStyleObject<Theme> = {
  px: 3,
};

export const padding4: SystemStyleObject<Theme> = {
  p: 4,
};

export const paddingRight4: SystemStyleObject<Theme> = {
  pr: 4,
};

export const paddingBottom2: SystemStyleObject<Theme> = {
  pb: 2,
};

export const paddingVertical2: SystemStyleObject<Theme> = {
  py: 2,
};

export const paddingHorizontal2: SystemStyleObject<Theme> = {
  px: 2,
};

export const paddingHorizontal4: SystemStyleObject<Theme> = {
  px: 4,
};

export const paddingHorizontal8: SystemStyleObject<Theme> = {
  px: 8,
};

export const paddingHorizontal12: SystemStyleObject<Theme> = {
  px: 12,
};

export const paddingHorizontal16: SystemStyleObject<Theme> = {
  px: 16,
};

export const paddingTop4: SystemStyleObject<Theme> = {
  pt: 4,
};

export const paddingBottom4: SystemStyleObject<Theme> = {
  pb: 4,
};

export const paddingTop6: SystemStyleObject<Theme> = {
  pt: 6,
};

export const paddingTop8: SystemStyleObject<Theme> = {
  pt: 8,
};

export const paddingTop10: SystemStyleObject<Theme> = {
  pt: 10,
};

export const paddingBottom14: SystemStyleObject<Theme> = {
  pb: 14,
};

export const paddingBottom16: SystemStyleObject<Theme> = {
  pt: 16,
};

export const scrollBarsSX: SystemStyleObject<Theme> = {
  scrollbarGutter: 'auto',
  '&::-webkit-scrollbar-thumb': {
    borderRadius: '12px',
    backgroundColor: 'rgba(180, 184, 184, 0.5)',
    border: '3px solid rgba(0, 0, 0, 0)',
    backgroundClip: 'padding-box',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    borderRadius: '12px',
    backgroundColor: '#b4b8b8',
    border: '1px solid rgba(0, 0, 0, 0)',
    backgroundClip: 'padding-box',
    transition: 'border-width 10s ease-in-out',
  },
};

export const scrollbarHorizontalSX: SystemStyleObject<Theme> = {
  '&::-webkit-scrollbar': {
    height: '14px',
  },
};

export const scrollbarVerticalSX: SystemStyleObject<Theme> = {
  '&::-webkit-scrollbar': {
    width: '14px',
  },
};
