import { z } from 'zod';

import {
  PROVISIONING_STATUS,
  PROVISIONING_SOURCE,
  PROVISIONING_STATUS_LABEL,
  PROVISIONING_SOURCE_LABEL,
} from './BulkProvisioningOLD.constants';
import { ObjectValues } from 'common/tsHelpers';

export const provisioningStatusFilter = z.union([
  z.literal(PROVISIONING_STATUS.DATA_EXTRACTION_FAILED),
  z.literal(PROVISIONING_STATUS.DATA_EXTRACTION_IN_PROGRESS),
  z.literal(PROVISIONING_STATUS.DATA_EXTRACTION_PENDING),
  z.literal(PROVISIONING_STATUS.PROCESSING_FAILED),
  z.literal(PROVISIONING_STATUS.PROCESSING_IN_PROGRESS),
  z.literal(PROVISIONING_STATUS.PROCESSING_SUCCEED),
  z.literal(''),
]);

export const provisioningSourceFilter = z.union([
  z.literal(PROVISIONING_SOURCE.MANUAL),
  z.literal(PROVISIONING_SOURCE.AUTOMATIC),
  z.literal(''),
]);

export const provisioningFilters = z.object({
  status: provisioningStatusFilter,
  orderNumber: z.string(),
  uploadDateFrom: z.nullable(z.date()),
  uploadDateTo: z.nullable(z.date()),
  source: provisioningSourceFilter,
});

export type ProvisioningFilters = z.infer<typeof provisioningFilters>;

export type ProvisioningStatusFilter = z.infer<typeof provisioningStatusFilter>;

export type ProvisioningSourceFilter = z.infer<typeof provisioningSourceFilter>;

export type ProvisioningStatus = ObjectValues<typeof PROVISIONING_STATUS>;

export type ProvisioningStatusLabel = ObjectValues<typeof PROVISIONING_STATUS_LABEL>;

export type ProvisioningSource = ObjectValues<typeof PROVISIONING_SOURCE>;

export type ProvisioningSourceLabel = ObjectValues<typeof PROVISIONING_SOURCE_LABEL>;

export type StatusValue = 0 | 1 | 2 | 3;

export type SimStatusCount = {
  inProgress: number;
  succeed: number;
  failed: number;
};
