import { useEffect, useRef } from 'react';
import { Chart as ChartJS } from 'chart.js';

export const useDispatchEventToChart = (chartRef: React.MutableRefObject<ChartJS | undefined>) => {
  const clicked = useRef(false);
  useEffect(() => {
    const mouseDownEvent = () => {
      clicked.current = true;
    };
    chartRef.current?.canvas.addEventListener('mousedown', mouseDownEvent);

    const mouseUpEvent = () => {
      clicked.current = false;
    };

    document.addEventListener('mouseup', mouseUpEvent);
    const mouseEvent = (event: MouseEvent) => {
      if (clicked.current) {
        chartRef.current?.canvas.dispatchEvent(
          new MouseEvent('mousemove', {
            clientX: event.clientX
          })
        );
      }
    };
    document.addEventListener('mousemove', mouseEvent);
    return () => {
      document.removeEventListener('mousemove', mouseEvent);
      document.removeEventListener('mouseup', mouseUpEvent);
      chartRef.current?.canvas.removeEventListener('mousedown', mouseDownEvent);
    };
  }, []);
};
