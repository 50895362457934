import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import { PropertyListItem } from 'simInventory/Components/PropertyListItem';

export type PropertyLabelProps = {
  children: React.ReactNode;
  htmlFor: string;
};

const PropertyLabel: React.FC<PropertyLabelProps> = ({ children, htmlFor }) => (
  <InputLabel htmlFor={htmlFor}>
    <Typography variant="text2" color="text.secondary">
      {children}
    </Typography>
  </InputLabel>
);

export type FilterListItemProps = {
  label: string;
  htmlFor: string;
  children: React.ReactNode;
};

export const FilterListItem: React.FC<FilterListItemProps> = ({ children, label, htmlFor }) => {
  return (
    <PropertyListItem label={<PropertyLabel htmlFor={htmlFor}>{label}</PropertyLabel>}>
      {children}
    </PropertyListItem>
  );
};
