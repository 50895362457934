import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material';
import { Button } from '../Buttons/Button';

const Title = styled(DialogTitle)({
  paddingTop: '16px',
  paddingLeft: '20px',
});

const TypographyTitle = styled(Typography)({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
});

const Message = styled(DialogContent)({
  paddingLeft: '20px',
  paddingRight: '48px',
});

const TypographyMessage = styled(Typography)({
  whiteSpace: 'pre-line',
});

const Actions = styled(DialogActions)({
  paddingTop: '16px',
});

export type ConfirmationDialogProps = {
  open: boolean;
  title: string;
  titleIcon?: React.ReactNode;
  message: string | React.ReactNode;
  onConfirm: () => void;
  onCancel: () => void;
};

export const ConfirmationDialog = ({
  open,
  title,
  titleIcon,
  message,
  onCancel,
  onConfirm,
}: ConfirmationDialogProps) => {
  return (
    <Dialog open={open} onClose={onCancel}>
      <Title>
        <TypographyTitle variant="h2">
          {titleIcon}
          {title}
        </TypographyTitle>
      </Title>
      <Message>
        <TypographyMessage variant="text1">{message}</TypographyMessage>
      </Message>
      <Actions>
        <Button variant={'secondary'} onClick={onCancel}>
          Cancel
        </Button>
        <Button variant={'primary'} onClick={onConfirm}>
          Confirm
        </Button>
      </Actions>
    </Dialog>
  );
};
