import Collapse from '@mui/material/Collapse';
import { styled } from '@mui/material/styles';

export const TableLayout = styled('div', { name: 'TableLayout' })(({ theme }) => ({
  height: '100%',
  width: '100%',
  display: 'grid',
  backgroundColor: theme.palette.background.paper,
  gridTemplateAreas: `
    "filters actions"
    "chips   chips"
    "table   table"`,
  gridTemplateRows: '56px min-content auto',
}));

export const TableLayoutFilters = styled('div', { name: 'TableLayoutFilters' })(({ theme }) => ({
  gridArea: 'filters',
  display: 'flex',
  paddingTop: '16px',
  paddingLeft: '16px',
  gap: '16px',
  border: `1px solid ${theme.palette.border}`,
  borderBottom: 'none',
  borderRight: 'none',
}));

export const TableLayoutSearchInput = styled('div', { name: 'TableLayoutSearchInput' })({
  flex: 1,
  maxWidth: '360px',
});

export const TableLayoutActions = styled('div', { name: 'TableLayoutActions' })(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gridArea: 'actions',
  paddingTop: '16px',
  paddingRight: '16px',

  gap: '16px',
  border: `1px solid ${theme.palette.border}`,
  borderBottom: 'none',
  borderLeft: 'none',
}));

const TableLayoutChipsRoot = styled('div')<{ hide?: boolean }>(({ hide, theme }) => ({
  display: 'flex',
  boxSizing: 'content-box',
  alignItems: 'center',
  gridArea: 'chips',

  paddingRight: '16px',
  paddingLeft: '16px',
  paddingBottom: '16px',
  border: `1px solid ${theme.palette.border}`,
  borderBottom: 'none',
  borderTop: 'none',
}));

const TableLayoutChipsLoader = styled('div')<{ hide?: boolean }>(({ hide, theme }) => ({
  paddingTop: '8px',
  height: '40px',
}));

export const TableLayoutChips = ({
  children,
  hide,
}: {
  children: React.ReactNode;
  hide: boolean;
}) => {
  return (
    <TableLayoutChipsRoot>
      <Collapse in={!hide}>
        <TableLayoutChipsLoader>{children}</TableLayoutChipsLoader>
      </Collapse>
    </TableLayoutChipsRoot>
  );
};

const TableLayoutTableRoot = styled('div')({
  position: 'relative',
  gridArea: 'table',
});

const TableLayoutTableContent = styled('div')({
  position: 'absolute',
  inset: '0',
});

export const TableLayoutTable = ({ children }: { children: React.ReactNode }) => {
  return (
    <TableLayoutTableRoot>
      <TableLayoutTableContent>{children}</TableLayoutTableContent>
    </TableLayoutTableRoot>
  );
};
