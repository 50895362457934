import { STATUS_BADGE_MAP } from '../StatusBadges/BulkProvisioningStatusBadges';
import { ProvisioningStatusLabel } from 'bulkProvisioning/BulkProvisioning.interface';

export type StatusBadgeCellProps = {
  value: ProvisioningStatusLabel;
};

export const StatusBadgesCell = ({ value }: StatusBadgeCellProps) => {
  const StatusBadge = STATUS_BADGE_MAP[value];

  return <StatusBadge />;
};
