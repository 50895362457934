import { cloneDeep } from 'lodash';
import { ColumnConfiguration } from '../../../../simInventory/SimInventoryApi/simInventoryTableConfigurationApi.interface';

export const reorderColumns = (
  columns: ColumnConfiguration[],
  oldIndex: number,
  targetIndex: number,
) => {
  const newColumnsOrder = cloneDeep(columns);
  const srcColumn = newColumnsOrder[oldIndex];
  newColumnsOrder.splice(oldIndex, 1);
  newColumnsOrder.splice(targetIndex, 0, srcColumn);

  return newColumnsOrder.map((column, index) => ({
    ...column,
    position: index + 1,
  }));
};

export function compareByPosition(a: ColumnConfiguration, b: ColumnConfiguration) {
  return a.position - b.position;
}
