import { parseISO } from 'date-fns';

export const parseResponseDateToIso = (responseDate?: string | null, defaultValue: any = '') =>
  responseDate ? parseISO(responseDate) : defaultValue;

export const parseResponseDate = (responseDate?: string | null, defaultValue: any = '') =>
  responseDate ? parseISO(responseDate).toLocaleString() : defaultValue;

export const parseResponseDateAndTime = (responseDate?: string | null) => {
  if (!responseDate) {
    return '';
  }

  const parsedDate = parseISO(responseDate);
  return `${parsedDate.toLocaleDateString()} ${parsedDate.toLocaleTimeString()}`;
};

export const parseDateIfNotUndefined = (dateString?: string | null) => {
  if (dateString === undefined) {
    return undefined;
  }

  return parseResponseDateAndTime(dateString);
};
