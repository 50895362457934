import React from 'react';
import { useHasFeatureFlag } from './useHasFeatureFlag';
import { FEATURE_FLAGS_MODULES, FeatureFlagsModules } from './FeatureFlags.models';

export type FeatureFlagResolverProps = {
  requriedModule?: FeatureFlagsModules;
  requiredFlag: string;
  children: React.ReactNode;
};

export const FeatureFlagResolver: React.FC<FeatureFlagResolverProps> = ({
  requiredFlag,
  requriedModule = FEATURE_FLAGS_MODULES.SIM_INVENTORY,
  children,
}) => {
  const shouldRender = useHasFeatureFlag(requiredFlag, requriedModule);

  return shouldRender ? <>{children}</> : null;
};
