import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

type SectionTitleProps = {
  children: React.ReactNode;
};

const SectionTitle: React.FC<SectionTitleProps> = ({ children }) => (
  <Typography variant="h4" sx={{ mb: 4, ml: 4 }}>
    {children}
  </Typography>
);

type FilterSectionProps = {
  title?: string;
  children?: React.ReactNode;
};

export const FilterSection: React.FC<FilterSectionProps> = ({ title, children }) => {
  return (
    <Box>
      {title ? <SectionTitle>{title}</SectionTitle> : null}
      {children}
    </Box>
  );
};
