import {
  PROVISIONING_SOURCE_TO_SOURCE_LABEL,
  PROVISIONING_STATUS_TO_STATUS_LABEL_MAP,
} from 'bulkProvisioning/BulkProvisioningOLD.constants';
import { ProvisioningFilters } from 'bulkProvisioning/BulkProvisioningOLD.interface';

import { FiltersChips, FilterChipProps } from 'common/components/Filters/FiltersChips';
import { useTranslation } from 'react-i18next';

type BulkFiltersChipsProps = Pick<FilterChipProps, 'onFilterClear' | 'onFiltersClear'> & {
  filters: ProvisioningFilters;
};

export const BulkFiltersChips: React.FC<BulkFiltersChipsProps> = ({
  filters,
  onFilterClear,
  onFiltersClear,
}) => {
  const { t } = useTranslation();
  const createValuesForChips = (filters: ProvisioningFilters) => {
    const filtersNames = {
      status: t('common.status'),
      orderNumber: t('common.orderNumber'),
      source: t('bulkProvisioning.source'),
      uploadDate: t('common.uploadDate'),
    };

    const { status, orderNumber, source } = filters;
    const chipsValues: Partial<Record<keyof typeof filtersNames, string>> = {};

    if (status !== '') {
      //@ts-ignore
      chipsValues.status = `${filtersNames.status}: ${PROVISIONING_STATUS_TO_STATUS_LABEL_MAP[status]}`;
    }

    if (orderNumber !== '') {
      chipsValues.orderNumber = `${filtersNames.orderNumber}: ${orderNumber}`;
    }

    if (source !== '') {
      chipsValues.source = `${filtersNames.source}: ${PROVISIONING_SOURCE_TO_SOURCE_LABEL[source]}`;
    }

    const from = filters.uploadDateFrom
      ? `from ${filters.uploadDateFrom.toLocaleDateString()}, `
      : '';

    const to = filters.uploadDateTo ? `to ${filters.uploadDateTo.toLocaleDateString()}` : '';

    if (from || to) {
      chipsValues.uploadDate = `${filtersNames.uploadDate}: ${from}${to}`;
    }
    return chipsValues;
  };

  const filtersChips = createValuesForChips(filters);

  return (
    <FiltersChips
      filters={filtersChips}
      onFilterClear={onFilterClear}
      onFiltersClear={onFiltersClear}
    />
  );
};
