import Alert, { AlertColor } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Fade from '@mui/material/Fade';
import { alertSx } from './AutoHideSnackBar.style';

type AutoHideSnackbarProps = {
  children: React.ReactNode;
  open?: boolean;
  severity: AlertColor;
  onClose?: () => void;
};

export const AutoHideSnackbar: React.FC<AutoHideSnackbarProps> = ({
  children,
  severity,
  open = false,
  onClose
}) => {
  return (
    <Snackbar open={open} TransitionComponent={Fade} autoHideDuration={4000} onClose={onClose}>
      <Alert onClose={onClose} severity={severity} variant="filled" sx={alertSx}>
        {children}
      </Alert>
    </Snackbar>
  );
};
