import React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import { SxProps, Theme } from '@mui/material/styles';
import { HeaderSkeleton } from 'common/components/Table/HeaderTableCellSkeleton';
import { TableCellSkeleton } from 'common/components/Table/TableCellSkeleton';
import { SkeletonTableRowLayout } from 'common/components/Table/SkeletonTableRowLayout';
import { SkeletonPagination } from './SkeletonPagination';

type TableSkeletonProps = {
  numberOfRows?: number;
  showStartSkeleton?: boolean;
  showEndSkeleton?: boolean;
  headerSkeleton?: React.ReactElement;
  bodySkeleton?: React.ReactElement;
  headerCellSx?: SxProps<Theme>;
  pagination?: boolean;
};

export const TableSkeleton: React.FC<TableSkeletonProps> = ({
  numberOfRows = 10,
  showStartSkeleton = false,
  showEndSkeleton = false,
  headerSkeleton = <HeaderSkeleton />,
  bodySkeleton = <TableCellSkeleton />,
  headerCellSx,
  pagination = true
}) => {
  return (
    <Box sx={{ height: '100%', position: 'relative' }}>
      <Table data-testid="Table skeleton">
        <TableHead>
          <SkeletonTableRowLayout
            sx={headerCellSx}
            skeleton={headerSkeleton}
            showStartSkeleton={showStartSkeleton}
            showEndSkeleton={showEndSkeleton}
          />
        </TableHead>
        <TableBody>
          {Array(numberOfRows)
            .fill(0)
            .map((_, i) => (
              <React.Fragment key={i}>
                <SkeletonTableRowLayout
                  skeleton={bodySkeleton}
                  showStartSkeleton={showStartSkeleton}
                  showEndSkeleton={showEndSkeleton}
                />
              </React.Fragment>
            ))}
        </TableBody>
      </Table>
      {pagination ? (
        <Box sx={{ position: 'absolute', bottom: 0, left: 0, right: 0 }}>
          <SkeletonPagination />
        </Box>
      ) : null}
    </Box>
  );
};
