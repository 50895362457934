import { ReactNode, UIEventHandler, useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';

import './scrollArea.css';

type ScrollAreaProps = {
  children: ReactNode;
  onScroll?: UIEventHandler<Element>;
  onScrollStart?: () => void;
  onScrollStop?: () => void;
  scrollAreaRef?: React.RefObject<Scrollbars>;
  disableRightBottomOffset?: boolean;
  topOffsetScroll?: number;
};

const SCROLLBAR_SIZE = '14px';

export const style = {
  scrollbarVertical: {
    top: '2px',
    width: SCROLLBAR_SIZE
  },
  scrollbarHorizontal: {
    height: SCROLLBAR_SIZE,
    left: '2px'
  }
};

const useDragDetection = () => {
  const [hovered, setHovered] = useState(false);
  const [drag, setDrag] = useState(false);

  useEffect(() => {
    if (hovered) {
      const mouseDownCallback = () => {
        setDrag(true);
      };

      document.addEventListener('mousedown', mouseDownCallback);

      return () => {
        document.removeEventListener('mousedown', mouseDownCallback);
      };
    }
  }, [hovered]);

  useEffect(() => {
    if (drag) {
      const mouseUpCallback = () => {
        setDrag(false);
      };

      document.addEventListener('mouseup', mouseUpCallback);

      return () => {
        document.removeEventListener('mouseup', mouseUpCallback);
      };
    }
  }, [drag]);

  return { hovered, setHovered, drag, setDrag };
};

export const ScrollArea: React.FC<ScrollAreaProps> = ({
  children,
  onScroll,
  onScrollStop,
  onScrollStart,
  scrollAreaRef,
  topOffsetScroll = 0,
  disableRightBottomOffset
}) => {
  const {
    hovered: hoveredVertical,
    setHovered: setHoveredVertical,
    drag: dragVertical,
    setDrag: setDragVertical
  } = useDragDetection();

  const {
    hovered: hoveredHorizontal,
    setHovered: setHoveredHorizontal,
    drag: dragHorizontal,
    setDrag: setDragHorizontal
  } = useDragDetection();

  return (
    <Scrollbars
      renderTrackVertical={(props) => {
        return (
          <div
            {...props}
            style={{ ...style.scrollbarVertical, paddingTop: `${topOffsetScroll}px` }}
            className={`trackVertical ${disableRightBottomOffset ? '' : 'rightBottomOffset'}`}
            onMouseEnter={() => {
              setHoveredVertical(true);
            }}
            onMouseLeave={() => {
              setHoveredVertical(false);
            }}
          />
        );
      }}
      renderTrackHorizontal={(props) => {
        return (
          <div
            {...props}
            style={style.scrollbarHorizontal}
            className={`trackHorizontal ${disableRightBottomOffset ? '' : 'rightBottomOffset'}`}
            onMouseEnter={() => {
              setHoveredHorizontal(true);
            }}
            onMouseLeave={() => {
              setHoveredHorizontal(false);
            }}
          />
        );
      }}
      renderThumbVertical={(props) => {
        const className = dragVertical ? 'thumbVerticalRoot drag' : 'thumbVerticalRoot';
        return (
          <div {...props} className={className}>
            <div className="thumbVertical" />
          </div>
        );
      }}
      renderThumbHorizontal={(props) => {
        const className = dragHorizontal ? 'thumbHorizontalRoot drag' : 'thumbHorizontalRoot';
        return (
          <div {...props} className={className}>
            <div className="thumbHorizontal"></div>
          </div>
        );
      }}
      hideTracksWhenNotNeeded={true}
      id="scrollArea"
      onScroll={(e) => {
        if (hoveredVertical) {
          setDragVertical(true);
        } else if (hoveredHorizontal) {
          setDragHorizontal(true);
        }
        if (onScroll) onScroll(e);
      }}
      onScrollStart={() => {
        if (onScrollStart) onScrollStart();
      }}
      onScrollStop={() => {
        if (onScrollStop) onScrollStop();
      }}
      autoHide={false}
      ref={scrollAreaRef}
    >
      {children}
    </Scrollbars>
  );
};
