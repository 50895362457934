import { t } from 'i18next';
import { LastActiveProfileFilter, SimulateOutageFilter } from '../data/filters.constants';

export const mapLastActiveProfileFilterToValue = (value: LastActiveProfileFilter) => {
  switch (value) {
    case 'ANY':
      return null;
    case 'PRIMARY':
      return 1;
    case 'SECONDARY':
      return 2;
  }
};

export const mapLastActiveProfileFilterTLabel = (value: LastActiveProfileFilter) => {
  switch (value) {
    case 'PRIMARY':
      return t('rSimInventory.primary');
    case 'SECONDARY':
      return t('rSimInventory.backup');
  }

  return '';
};

export const mapSimulateOutageFilterToValue = (value: SimulateOutageFilter) => {
  switch (value) {
    case 'ANY':
      return null;
    case 'OFF':
      return 'off';
    case 'ON':
      return 'on';
  }
};
