import groupBy from 'lodash/groupBy';
import sum from 'lodash/sum';
import { getHoverColor } from 'chart.js/helpers';
import { palette } from 'theme/palette';

const HEIGHT_OF_ZERO_USAGE_INTERIM = 16;
const BG_COLOR_OF_ZERO_USAGE_INTERIM = palette.red['200'];
const BORDER_COLOR_OF_ZERO_USAGE_INTERIM = palette.red['500'];

export const createZeroUsageInterimRenderer = (datasets: any) => ({
  id: 'zeroUsageInterims',
  beforeEvent(_chart: any, args: any, _options: any) {
    const event = args.event;
    if (event.type === 'mousemove' && event.x) {
      //@ts-ignore
      this.mouseXPosition = event.x;
    }

    if (event.type === 'mouseout') {
      //@ts-ignore
      this.mouseXPosition = null;
    }
  },

  afterDraw(chart: any, args: any, options: any) {
    const {
      ctx,
      chartArea: { bottom },
      scales
    } = chart;

    const groupedUsageByInterim = Object.values(
      groupBy(chart.data.datasets[0].data, (value) => {
        return value.dataIndex;
      })
    );

    const interimsWithZeroUseage = groupedUsageByInterim.filter((value) => {
      const [input, output] = value;
      return sum(input.y) + sum(output.y) === 0;
    });

    const emptyInterimsXRangeToRender = interimsWithZeroUseage.map((value) => value[0].x);

    ctx.save();

    emptyInterimsXRangeToRender.forEach(([from, to]) => {
      const xBegin = scales.x.getPixelForValue(from);
      const xEnd = scales.x.getPixelForValue(to);
      const yBegin = bottom - HEIGHT_OF_ZERO_USAGE_INTERIM;

      const width = xEnd - xBegin;

      ctx.fillStyle = BG_COLOR_OF_ZERO_USAGE_INTERIM;
      ctx.strokeStyle = BORDER_COLOR_OF_ZERO_USAGE_INTERIM;
      //@ts-ignore
      if (this.mouseXPosition > xBegin && this.mouseXPosition < xEnd) {
        ctx.fillStyle = getHoverColor(BG_COLOR_OF_ZERO_USAGE_INTERIM);
      }

      ctx.fillRect(xBegin, yBegin, width, HEIGHT_OF_ZERO_USAGE_INTERIM);
      ctx.strokeRect(xBegin, yBegin, width, HEIGHT_OF_ZERO_USAGE_INTERIM);
    });

    ctx.restore();
  }
});
