import { ObjectValues } from 'common/tsHelpers';
import { z } from 'zod';
import { SimProfile } from '../SimInventoryApi/simInventoryApi.interface';

export const SIM_PROFILE_IDS = {
  ICCID: 'ICCID',
  IMSI: 'IMSI',
  MSISDN: 'MSISDN',
} as const;

export const NUMERIC_SIM_PROFILE_IDS = {
  ICCID: 0,
  IMSI: 1,
  MSISDN: 2,
} as const;

export const SESSION_STATUS = {
  UNKNOWN: 'Unknown',
  IN_SESSION: 'InSession',
  DISCONNECTED: 'Disconnected',
} as const;

export const SESSION_STATUS_NUMERIC = {
  DISCONNECTED: 0,
  IN_SESSION: 1,
} as const;

export const sessionStatusSchema = z.union([
  z.literal(SESSION_STATUS.UNKNOWN),
  z.literal(SESSION_STATUS.DISCONNECTED),
  z.literal(SESSION_STATUS.IN_SESSION),
]);

export const sessionStatusFilterSchema = sessionStatusSchema;

export const simIdSchema = z.union([
  z.literal(NUMERIC_SIM_PROFILE_IDS.ICCID),
  z.literal(NUMERIC_SIM_PROFILE_IDS.IMSI),
  z.literal(NUMERIC_SIM_PROFILE_IDS.MSISDN),
]);

export const sortingSchema = z.union([z.literal('asc'), z.literal('desc'), z.literal(null)]);

export const sortSchema = z.object({ columnName: z.string(), sort: sortingSchema });

export const accountReferenceFilterSchema = z.union([z.number(), z.literal('')]);

export const simInventoryExtededBodyFiltersSchema = z.object({
  pageSize: z.optional(z.number()),
  pageNumber: z.optional(z.number()),
  orderNumber: z.optional(z.string()),
  connectionId: z.optional(z.string()),
  cciis: z.optional(z.array(z.string())),
  label: z.optional(z.string()),
  tags: z.optional(z.array(z.string())),
  simProfileIds: z.optional(
    z.object({
      type: simIdSchema,
      ids: z.array(z.string()),
    }),
  ),
  sessionStatus: z.optional(
    z.array(
      z.union([
        z.literal(SESSION_STATUS_NUMERIC.IN_SESSION),
        z.literal(SESSION_STATUS_NUMERIC.DISCONNECTED),
        z.literal(''),
      ]),
    ),
  ),
  accountName: z.optional(z.string()),
  accountRef: z.optional(z.array(z.number())),
  order: z.optional(z.string()),
  iccid: z.optional(z.string()),
  imsi: z.optional(z.string()),
  msisdn: z.optional(z.string()),
  simProfileIdsRange: z.optional(
    z.object({
      type: simIdSchema,
      idsFrom: z.string(),
      idsTo: z.string(),
    }),
  ),
  customFieldSearch: z.optional(
    z.array(
      z.object({
        id: z.number(),
        textValue: z.optional(z.string()),
        optionIds: z.optional(z.array(z.string())),
      }),
    ),
  ),
});

export const simInventoryFiltersSchema = z.object({
  accounts: z.array(z.string()),
  sessionStatus: sessionStatusFilterSchema,
  orderNumber: z.string(),
  connectionId: z.string(),
  mobileNetworkOperator: z.array(z.string()),
  label: z.string(),
  tags: z.array(z.string()),
  iccidFrom: z.string(),
  iccidTo: z.string(),
  customFields: z.any(), //(z.string()), //  'customFieldId1-type-value1,value2,value3;customFieldId2-type-value1,value2' //type: text/dropdown
});

export type SimProfileId = ObjectValues<typeof SIM_PROFILE_IDS>;

export type SessionStatusFilter = z.infer<typeof sessionStatusFilterSchema>;

export type AccountReferenceFilter = z.infer<typeof accountReferenceFilterSchema>;

export type SimInventoryExtededFilterBody = z.infer<typeof simInventoryExtededBodyFiltersSchema>;

export type SimInventoryFilters = z.infer<typeof simInventoryFiltersSchema>;

export type SimInventorySort = z.infer<typeof sortSchema>;

export type SimInventoryExtededFilterBodyIdsProps = keyof Pick<
  SimInventoryExtededFilterBody,
  'iccid' | 'imsi' | 'msisdn'
>;

export type SimInventoryColumnsNames = keyof SimProfile;

export const simIdSchemaV2 = z.union([
  z.literal(SIM_PROFILE_IDS.ICCID),
  z.literal(SIM_PROFILE_IDS.IMSI),
  z.literal(SIM_PROFILE_IDS.MSISDN),
]);

export const MnoFilterSchemaV2 = z.object({
  ccii: z.number(),
  name: z.string(),
});

export const simInventoryFiltersSchemav2 = z.object({
  searchText: z.string(),
  chosenIdType: simIdSchemaV2,
  accounts: z.array(z.string()),
  sessionStatus: sessionStatusFilterSchema,
  orderNumber: z.string(),
  connectionId: z.string(),
  mobileNetworkOperator: z.array(z.string()),
  label: z.string(),
  tags: z.array(z.string()),
  iccidFrom: z.string(),
  iccidTo: z.string(),
  customFields: z.any(), //(z.string()), //  'customFieldId1-type-value1,value2,value3;customFieldId2-type-value1,value2' //type: text/dropdown
});

export type SimInventoryFiltersV2 = z.infer<typeof simInventoryFiltersSchemav2>;

export const simInventorySearchParamsSchemav2 = z.object({
  searchText: z.array(z.string()),
  chosenIdType: simIdSchemaV2,
  accounts: z.array(z.string()),
  sessionStatus: sessionStatusFilterSchema,
  orderNumber: z.string(),
  connectionId: z.string(),
  mobileNetworkOperator: z.array(z.string()),
  label: z.string(),
  tags: z.array(z.string()),
  iccidFrom: z.string(),
  iccidTo: z.string(),
  customFields: z.any(), //(z.string()), //  'customFieldId1-type-value1,value2,value3;customFieldId2-type-value1,value2' //type: text/dropdown
});

export type SimInventorySearchParamsV2 = z.infer<typeof simInventorySearchParamsSchemav2>;
