import { ErrorCodes } from 'common/errorCodes';
import { useSuspenseQuery } from '@tanstack/react-query';
import { fetchSimInventoryEntry } from 'simInventory/SimInventoryApi/simInventoryApi';

export const useSessionsEntry = (iccid: string) => {
  const queryData = useSuspenseQuery({
    queryKey: ['SimInventoryEntry', iccid],
    queryFn: () => (!!iccid ? fetchSimInventoryEntry(iccid!) : null),
    retry: (failureCount, error) => {
      if (error instanceof Error && error.message === ErrorCodes.NotFound) {
        return false;
      }
      return failureCount < 5;
    },
  });

  return queryData;
};
