import Box from "@mui/material/Box";
import { ContentBoxProps } from './ContentBox.interfaces';

export const ContentBox: React.FC<ContentBoxProps> = ({ children, dataTestId }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
      data-testid={dataTestId}
    >
      {children}
    </Box>
  );
};
