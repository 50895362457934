import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

type CheckboxStyledProps = {
  isChecked?: boolean;
  defaultChecked?: boolean;
  handleCheckboxClick: (isChecked: boolean) => void;
  testId: string;
};

export const CheckboxStyled: React.FC<CheckboxStyledProps> = ({
  isChecked,
  defaultChecked,
  handleCheckboxClick,
  testId,
}) => {
  const checkboxIcon = (
    <CheckBoxOutlineBlankIcon
      fontSize="small"
      sx={{ color: (theme) => theme.palette.text.secondary }}
    />
  );

  const checkboxCheckedIcon = (
    <CheckBoxIcon fontSize="small" sx={{ color: (theme) => theme.palette.text.secondary }} />
  );

  return (
    <Checkbox
      sx={{
        '& .MuiTouchRipple-root': {
          opacity: '0',
        },
      }}
      defaultChecked={defaultChecked}
      icon={checkboxIcon}
      checkedIcon={checkboxCheckedIcon}
      checked={isChecked}
      onChange={(e) => handleCheckboxClick(e.target.checked)}
      data-testid={testId || 'checkbox'}
    />
  );
};
