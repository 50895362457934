import { ChipDisplay } from 'common/components/Chips/ChipDisplay';
import { useAssignedTags } from './useAssignedTags';

export type TagDisplayProps = {
  initialTags: string[];
  id: string;
  hasMore: boolean;
  shouldWrap?: boolean;
  preventRefetch?: boolean;
};

export const TagDisplay: React.FC<TagDisplayProps> = ({
  initialTags,
  id,
  hasMore,
  shouldWrap,
  preventRefetch = false,
}) => {
  const { data, refetch } = useAssignedTags(id);

  return (
    <ChipDisplay
      previewLabels={initialTags}
      loadedLabels={data || []}
      onLoad={() => {
        if (!preventRefetch) {
          refetch();
        }
      }}
      hasMore={hasMore}
      shouldWrap={shouldWrap}
    />
  );
};
