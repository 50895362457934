import { ConfirmationDialog } from 'common/components/Dialog/ConfirmationDialog';
import DownloadIcon from '@mui/icons-material/Download';

export type LargeExportPromptProps = {
  open: boolean;
  title: string;
  message: React.ReactNode;
  onConfirm: () => void;
  onClose: () => void;
};

export const LargeExportPrompt = ({
  open,
  title,
  message,
  onConfirm,
  onClose,
}: LargeExportPromptProps) => {
  return (
    <ConfirmationDialog
      open={open}
      titleIcon={<DownloadIcon />}
      title={title}
      message={<div data-testid="export-warning">{message}</div>}
      onConfirm={onConfirm}
      onCancel={onClose}
    />
  );
};
