import React from 'react';
import { GridEditInputCellProps, useGridApiContext } from '@mui/x-data-grid-pro';
import Switch from '@mui/material/Switch';

export const EnabledSwitchCell = (props: GridEditInputCellProps) => {
  const apiRef = useGridApiContext();

  return (
    <Switch
      data-testid="customFieldSwitch"
      checked={props.row.enabled}
      onChange={(e) => {
        apiRef.current.setEditCellValue({
          id: props.row.id,
          field: 'enabled',
          value: !props.row.enabled,
        });
      }}
    />
  );
};
