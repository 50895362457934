import { authFetch } from 'auth/utils';
import { FEATURE_FLAGS_MODULES, FeatureFlagsModules } from './FeatureFlags.models';

type FeatureFlagsDTO = {
  featureFlags: Record<string, boolean>;
};

export const getFeatureFlags = async (signal?: AbortSignal) => {
  const simInventoryFeatureFlagsResponse = await authFetch(
    `${process.env.REACT_APP_SIM_INVENTORY_FEATURE_FLAGS_API}`,
    {
      signal,
    },
  );

  const featureFlags: Record<FeatureFlagsModules, Record<string, boolean>> = {
    [FEATURE_FLAGS_MODULES.SIM_INVENTORY]: {},
    [FEATURE_FLAGS_MODULES.BULK_PROVISIONING]: {},
    [FEATURE_FLAGS_MODULES.AUDIT_TRAIL]: {},
  };

  try {
    const simInventoryFeatureFlags: FeatureFlagsDTO = await simInventoryFeatureFlagsResponse.json();
    featureFlags[FEATURE_FLAGS_MODULES.SIM_INVENTORY] = simInventoryFeatureFlags.featureFlags;
  } catch (err) {
    featureFlags[FEATURE_FLAGS_MODULES.SIM_INVENTORY] = {};
  }

  try {
    const bulkFeatureFlagsResponse = await authFetch(
      `${process.env.REACT_APP_BULK_PROVISIONING_PROVISIONING_FEATURE_FLAGS_API}`,
      {
        signal,
      },
    );
    const bulkFeatureFlags: FeatureFlagsDTO = await bulkFeatureFlagsResponse.json();

    featureFlags[FEATURE_FLAGS_MODULES.BULK_PROVISIONING] = bulkFeatureFlags.featureFlags;
  } catch (err) {
    featureFlags[FEATURE_FLAGS_MODULES.BULK_PROVISIONING] = {};
  }

  try {
    const auditTrailFeatureFlagsResponse = await authFetch(
      `${process.env.REACT_APP_AUDIT_TRAIL_FEATURE_FLAGS_API}`,
      {
        signal,
      },
    );

    const auditTrailFlags = await auditTrailFeatureFlagsResponse.json();

    featureFlags[FEATURE_FLAGS_MODULES.AUDIT_TRAIL] = auditTrailFlags.featureFlags;
  } catch (err) {
    featureFlags[FEATURE_FLAGS_MODULES.AUDIT_TRAIL] = {};
  }

  return featureFlags;
};
