import React from 'react';
import { AutoHideSnackbar } from '../../Snackbar/AutoHideSnackbar';
import { AlertTitle } from '../../Snackbar/AlertTitle';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

export type ExportError = {
  title: string;
  description: string[];
};

export type ExportCardsSnackbarsProps = {
  opened: boolean;
  largeExport: boolean;
  largeExportSuccessTitle: string;
  setExportError: (error: ExportError | null) => void;
  setDownloadingSnackbarOpened: (opened: boolean) => void;
  setLargeExport: (isLargeExport: boolean) => void;
  exportError: ExportError | null;
};

export const ExportCardsSnackbars: React.FC<ExportCardsSnackbarsProps> = ({
  opened,
  largeExport,
  setExportError,
  setDownloadingSnackbarOpened,
  exportError,
  largeExportSuccessTitle,
  setLargeExport,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <AutoHideSnackbar
        severity="success"
        open={opened && !largeExport}
        onClose={() => {
          setDownloadingSnackbarOpened(false);
        }}
      >
        {''} {/* Without this component throw a error and crash app */}
        <AlertTitle> {t('common.downloading')}!</AlertTitle>
      </AutoHideSnackbar>
      <AutoHideSnackbar
        severity="success"
        open={opened && largeExport}
        onClose={() => {
          setDownloadingSnackbarOpened(false);
          setLargeExport(false);
        }}
      >
        {''} {/* Without this component throw a error and crash app */}
        <AlertTitle>{largeExportSuccessTitle}</AlertTitle>
        <Typography variant="text1" color="text.white">
          {t('common.emailWithDownloadLink')}
        </Typography>
      </AutoHideSnackbar>
      <AutoHideSnackbar open={!!exportError} severity="error" onClose={() => setExportError(null)}>
        {''} {/* Without this component throw a error and crash app */}
        <AlertTitle>{exportError?.title || ''}!</AlertTitle>
        {exportError?.description.map((d, index) => (
          <div key={index}>
            <Typography variant="text1" color="text.white">
              {d}
            </Typography>
          </div>
        ))}
      </AutoHideSnackbar>
    </>
  );
};
