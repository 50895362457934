import { useContext } from 'react';
import { FeatureFlagContext } from './FeatureFlagProvider';
import { FEATURE_FLAGS_MODULES, FeatureFlagsModules } from './FeatureFlags.models';

export const useHasFeatureFlag = (
  requiredFlag: string,
  requriedModule: FeatureFlagsModules = FEATURE_FLAGS_MODULES.SIM_INVENTORY,
) => {
  const featureFlags = useContext(FeatureFlagContext);
  return !!featureFlags[requriedModule][requiredFlag];
};
