import React from 'react';
import Typography from '@mui/material/Typography';

type CounterProps = {
  count: number;
  variant?: 'default' | 'success' | 'error';
};

export const Counter: React.FC<CounterProps> = ({ count, variant = 'default' }) => {
  let color = 'text.primary';
  if (variant === 'success') {
    color = 'statusColor.success';
  } else if (variant === 'error') {
    color = 'statusColor.error';
  }

  return (
    <Typography variant={'text1'} color={color}>
      {count === 0 ? '-' : count.toString()}
    </Typography>
  );
};
