import {
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GridColDef,
  GridRenderCellParams,
} from '@mui/x-data-grid-pro';
import { useAbility } from 'permissions/hooks/useAbility';
import { useMemo } from 'react';
import { CellConfiguration, CellLoader } from '../Components/Cells/CellLoader';
import { CollapseAllButton } from '../Components/Buttons/CollapseAll';
import { ActionCell } from '../Components/Cells/ActionCell';
import { HeaderCell } from '../Components/Header/HeaderCell';
import { Action } from '../models/configurableTable';

export type Configuration = Pick<
  GridColDef,
  | 'field'
  | 'resizable'
  | 'sortable'
  | 'headerName'
  | 'pinnable'
  | 'flex'
  | 'width'
  | 'maxWidth'
  | 'minWidth'
> & {
  subField?: { field: string; headerName: string; valueGetter?: (value: any) => string };
  valueGetter?: (value: any) => string;
  permission?: {
    action: string;
    subject: string;
  };
};

type UsePrepareConfigurationProps = {
  cellConfiguration?: CellConfiguration;
  detailPanelEnabled: boolean;
  configuration: Configuration[];
  actions?: Action[];
  cellProps: any;
  selectDialog: (dialogId: string, data: any) => void;
};

export const configurableCellRender =
  (cellConfiguration: any, cellProps?: any) => (params: GridRenderCellParams) => {
    return <CellLoader {...params} cellConfiguration={cellConfiguration} cellProps={cellProps} />;
  };

export const usePrepareConfiguration = ({
  configuration,
  actions,
  cellProps,
  cellConfiguration,
  selectDialog,
  detailPanelEnabled,
}: UsePrepareConfigurationProps) => {
  const ability = useAbility();

  const columnsConfiguration = useMemo(() => {
    const definedcolumns = configuration
      .filter((column) => {
        if (column.permission) {
          return ability.can(column.permission.action, column.permission.subject);
        }

        return true;
      })
      .map((column) => {
        const columnDef: GridColDef = {
          ...column,
          renderCell: configurableCellRender(cellConfiguration, cellProps),
          renderHeader: (props) => {
            return (
              <HeaderCell
                primaryText={props.colDef.headerName || ''}
                //@ts-ignore
                secondaryText={props.colDef.subField?.headerName}
              />
            );
          },
        };

        return columnDef;
      });

    let columns = [];

    if (detailPanelEnabled) {
      columns.push({
        ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
        width: 54,
        renderHeader: () => {
          return <CollapseAllButton />;
        },
      });
    }

    columns = [...columns, ...definedcolumns];

    if (detailPanelEnabled) {
      const filler: GridColDef = {
        field: 'filler',
        headerName: '',
        display: 'flex',
        sortable: false,
      };
      columns.push(filler);
    }

    if (actions) {
      //Add callback for type component
      const mappedAction = actions.map((action: any) => {
        if (action.type === 'dialog') {
          return {
            ...action,
            actionCallback: (data: any) => selectDialog(action.id, data),
          };
        }

        return action;
      });

      const actionsColDef: GridColDef = {
        field: 'actions',
        headerName: '',
        resizable: false,
        width: 60,
        minWidth: 60,
        maxWidth: 60,

        renderCell: (props) => {
          return <ActionCell actions={mappedAction} data={props.row} />;
        },

        sortable: false,
        pinnable: false,
      };
      columns.push(actionsColDef);
    }

    return columns;
  }, [configuration, actions, detailPanelEnabled]);

  return columnsConfiguration;
};
