import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const MAX_TAG_NAME_LENGTH = 50;
const MAX_ASSIGNED_TAGS = 20;

export const useTagsAutocompleteValidator = (selectedTags: string[]) => {
  const [errors, setErrors] = useState<string[]>([]);
  const { t } = useTranslation();

  const addErrorMessageIfNotExist = (errorMessage: string) => {
    setErrors((errors) => {
      const hasErrorMessage = errors.some((value) => value === errorMessage);

      if (hasErrorMessage) {
        return errors;
      }

      return [...errors, errorMessage];
    });
  };

  const removeErrorMessage = (errorMessage: string) => {
    setErrors((errors) => errors.filter((value) => value !== errorMessage));
  };

  const validateTagLength = (tag: string) => {
    const errorMessage = t('tag.signLimits');

    if (tag.length >= MAX_TAG_NAME_LENGTH) {
      addErrorMessageIfNotExist(errorMessage);
      return false;
    }

    removeErrorMessage(errorMessage);
    return true;
  };

  useEffect(() => {
    const errorMessage = t('tag.tagsLimit');
    if (selectedTags.length >= MAX_ASSIGNED_TAGS) {
      addErrorMessageIfNotExist(t('tag.tagsLimit'));
    } else {
      removeErrorMessage(errorMessage);
    }
  }, [selectedTags]);

  return {
    errors,
    validateTagLength,
  };
};
