import { Components } from '@mui/material';
import { palette } from '../palette';

export const MuiRadio: Components['MuiRadio'] = {
  styleOverrides: {
    root: {
      color: `${palette.text.secondary} !important`,
      '&.Mui-checked': {
        color: `${palette.blue['400']} !important`,
      },
    },
  },
};
