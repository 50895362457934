import { SortingParam } from 'common/hooks/useSorting';
import { useLazyPaginationQuery } from 'common/useLazyPaginationQuery';
import { useRefetchQuery } from 'common/useRefetchQuery';
import { setMinimalPromiseTime } from 'common/utils/setMinimalPromiseTime';
import {
  LastActiveProfileFilter,
  LastUpdateTimeFilters,
  SimulateOutageFilter,
} from 'rSimInventory/Filters/data/filters.constants';
import {
  fetchRSimInventoryExtendedFilters,
  fetchRSimProductExtendedFilters,
  fetchRSimInventoryExtendedFiltersPagination,
} from 'rSimInventory/RSimApi/rSimApi';
import { RSimInventoryData } from 'rSimInventory/RSimApi/rSimApi.interface';
import { RSimProfileId } from 'rSimInventory/models/RSimProfile.model';
import { useDebounce } from 'usehooks-ts';
import { useMemo } from 'react';
import { getSearchIds } from '../Components/Inputs/RSimIdSearchInput.util';
import { useHasFeatureFlag } from 'featureFlags/useHasFeatureFlag';

type UseRSimInventoryProps = {
  searchText: string;
  chosenIdType: RSimProfileId;
  lastActiveProfile: LastActiveProfileFilter;
  lastUpdateTime: LastUpdateTimeFilters;
  tags: string[];
  connectionId: string;
  orderNumber: string;
  simulateOutage: SimulateOutageFilter;
  accounts: string[];
  eidFrom: string;
  eidTo: string;
  sorting: SortingParam;
  rowsPerPage: number;
  page: number;
};

export const useRSimInventory = ({
  searchText,
  chosenIdType,
  lastActiveProfile,
  lastUpdateTime,
  tags,
  connectionId,
  orderNumber,
  simulateOutage,
  accounts,
  eidFrom,
  eidTo,
  sorting,
  rowsPerPage,
  page,
}: UseRSimInventoryProps) => {
  const tagsMigratedToProductsView = useHasFeatureFlag('TagsMigratedToProductsView');
  const debouncedSearchText = useDebounce(searchText || '', 500);
  const minLengthText = debouncedSearchText.length < 3 ? '' : debouncedSearchText;

  const searchIds = useMemo(() => {
    return getSearchIds(minLengthText);
  }, [minLengthText]);

  const iccidToSearch = chosenIdType === 'ICCID' ? minLengthText : '';
  const euiccToSearch = chosenIdType === 'EID' ? minLengthText : '';

  const refetchSimInventory = useRefetchQuery(['RSimSimProfiles']);

  return useLazyPaginationQuery<RSimInventoryData>(
    [
      'RSimInventory',
      minLengthText,
      chosenIdType,
      iccidToSearch,
      euiccToSearch,
      lastActiveProfile,
      lastUpdateTime,
      tags,
      connectionId,
      orderNumber,
      simulateOutage,
      accounts,
      sorting,
      eidFrom,
      eidTo,
      rowsPerPage,
      page,
    ],
    async ({ signal }) => {
      const result = await setMinimalPromiseTime(
        !tagsMigratedToProductsView
          ? fetchRSimInventoryExtendedFilters(
              page,
              rowsPerPage,
              {
                searchIds,
                chosenIdType,
                connectionId,
                orderNumber,
                simulateOutage,
                lastActiveProfile,
                tags,
                accounts,
                lastUpdateTime,
                eidFrom,
                eidTo,
              },
              sorting,
            )
          : fetchRSimProductExtendedFilters(
              page,
              rowsPerPage,
              {
                searchIds,
                chosenIdType,
                connectionId,
                orderNumber,
                simulateOutage,
                lastActiveProfile,
                tags,
                accounts,
                lastUpdateTime,
                eidFrom,
                eidTo,
              },
              sorting,
            ),
      );

      refetchSimInventory();
      return result;
    },
    ({ signal }) =>
      fetchRSimInventoryExtendedFiltersPagination(
        {
          searchIds,
          chosenIdType,
          connectionId,
          orderNumber,
          simulateOutage,
          lastActiveProfile,
          tags,
          accounts,
          lastUpdateTime,
          eidFrom,
          eidTo,
        },
        sorting,
      ),
    {
      refetchOnWindowFocus: false,
    },
  );
};
