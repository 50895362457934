import { Outlet } from 'react-router-dom';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { NoAccessBoundary } from '../NoAccessBoundary';

import { mainPaddingBottom, paperStyle, menuWidthSmall, borderStyle } from './AppLayout.styles';

export const SingleTabLayout = () => {
  return (
    <>
      <Box
        component="main"
        sx={{
          marginLeft: menuWidthSmall,
          paddingBottom: mainPaddingBottom,
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            marginLeft: '20px',
            marginRight: '22px',
            height: '100%',
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <NoAccessBoundary>
            <Paper
              sx={{
                ...paperStyle,

                borderTopLeftRadius: '8px',
              }}
              elevation={0}
            >
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  ...borderStyle,
                  borderTopLeftRadius: '8px',
                }}
              >
                <Outlet />
              </div>
            </Paper>
          </NoAccessBoundary>
        </Box>
      </Box>
    </>
  );
};
