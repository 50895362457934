import { MuiAutocomplete } from './components/MuiAutocomplete';
import { MuiChip } from './components/MuiChip';
import { MuiDataGrid } from './components/MuiDataGrid';
import { MuiDialog } from './components/MuiDialog';
import { MuiDialogActions } from './components/MuiDialogActions';
import { MuiDialogContent } from './components/MuiDialogContent';
import { MuiDialogTitle } from './components/MuiDialogTitle';
import { MuiRadio } from './components/MuiRadio';
import { MuiSelect } from './components/MuiSelect';
import { MuiTextField } from './components/MuiTextField';
import { palette } from './palette';
import { fontFamily, typography } from './typography';

//@ts-ignore
import {} from '@mui/x-data-grid-pro/themeAugmentation';

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    tertiary: true;
    active: true;
  }
}

declare module '@mui/material/Checkbox' {
  interface CheckboxPropsColorOverrides {
    tertiary: true;
  }
}

const borderRadius = '12px';
const buttonBorderRadius = borderRadius;

const primaryButtonStyle = {
  color: palette.text.white,
  backgroundColor: palette.grey[600],
  boxShadow: 'none',
  '&:hover': {
    background: palette.grey[500],
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.12);',
  },
};

const secondaryButtonStyle = {
  color: palette.text.secondary,
  backgroundColor: palette.text.white,
  boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.12)',
  '&:hover': {
    background: palette.grey[200],
    color: palette.text.primary,
    boxShadow: 'none',
  },
};

const tertiaryButtonStyle = {
  color: palette.grey[600],
  backgroundColor: palette.text.white,
  '&:hover': {
    background: palette.grey[200],
  },
};

const activeButtonStyle = {
  color: palette.text.secondary,
  background: palette.grey[200],
  boxShadow: 'none',
  '&:hover': {
    background: palette.grey[200],
    color: palette.text.secondary,
    boxShadow: 'none',
  },
};

const alertSuccessStyle = {
  color: palette.text.white,
  backgroundColor: palette.green['600'],
};

const alertErrorStyle = {
  color: palette.text.white,
  backgroundColor: palette.red['300'],
};

const alertInfoStyle = {
  color: palette.text.white,
  backgroundColor: palette.grey['600'],
};

export const components = {
  MuiCssBaseline: {
    '@global': {
      body: {
        margin: 0,
        fontFamily,
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: ({ ownerState }: any) => {
        let colorStyle = {};
        if (ownerState.color === 'primary') {
          colorStyle = primaryButtonStyle;
        }

        return {
          ...colorStyle,
          backgroundColor: 'none',
          '&:hover': { background: 'none', boxShadow: 'none' },
        };
      },
    },
  },
  MuiButton: {
    defaultProps: {
      variant: 'contained',
    },
    styleOverrides: {
      root: ({ ownerState, theme }: any) => {
        let colorStyle = {};
        if (ownerState.color === 'primary') {
          colorStyle = primaryButtonStyle;
        } else if (ownerState.color === 'secondary') {
          colorStyle = secondaryButtonStyle;
        } else if (ownerState.color === 'tertiary') {
          colorStyle = tertiaryButtonStyle;
        } else if (ownerState.color === 'active') {
          colorStyle = activeButtonStyle;
        }

        return {
          borderRadius: buttonBorderRadius,
          ...typography.button,
          minHeight: '40px',
          padding: '8px 16px 8px 16px',
          ...colorStyle,
          '&.Mui-disabled': {
            color: palette.text.tertiary,
            backgroundColor: palette.border,
          },
        };
      },
    },
  },
  MuiTableRow: {
    defaultProps: {
      hover: true,
    },
    styleOverrides: {
      root: {
        fontFamily,
        '&.MuiTableRow-hover': {
          '&:hover': {
            backgroundColor: palette.background.default,
          },
        },
      },
      head: {
        fontFamily,
        whiteSpace: 'nowrap',
        backgroundColor: palette.grey['100'],
      },
    },
  },
  MuiTable: {
    styleOverrides: {
      root: {
        fontFamily,
        borderCollapse: 'separate',
        borderSpacing: 0,
        overflow: 'hidden',
        backgroundColor: palette.background.paper,
      },
    },
  },
  MuiTableHead: {
    styleOverrides: {
      root: {
        fontFamily,
        borderRadius: 0,
        background: palette.grey['100'],
        padding: '8px',
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      head: {
        borderRadius: 0,
        background: palette.grey['100'],
        borderBottom: `1px solid ${palette.grey['200']}`,
      },
      body: {
        borderBottom: `1px solid ${palette.border}`,
      },
      root: {
        fontFamily,
        paddingLeft: '24px',
        paddingRight: '24px',
        whiteSpace: 'nowrap',
        borderColor: palette.background.default,
      },
    },
  },
  MuiTablePagination: {
    defaultProps: {
      showFirstButton: true,
      showLastButton: true,
    },
    styleOverrides: {
      root: {
        fontFamily,
        background: palette.grey['100'],
        borderRadius: '0px 0px 8px 8px',
        borderTop: `1px solid ${palette.grey['200']}`,
      },
      toolbar: {
        display: 'grid',
        gridTemplateColumns: '120px 100px 1fr 220px',
        paddingLeft: '12px',
        paddinRight: '12px',
        margin: 0,
      },
      selectLabel: {
        ...typography.text1,
        margin: 0,
        marginLeft: '12px',
        color: palette.text.secondary,
      },
      spacer: {
        display: 'none',
      },
      displayedRows: {
        ...typography.text1,
        color: palette.text.primary,
        textAlign: 'center',
        margin: 0,
      },
      actions: {
        textAlign: 'right',
      },
    },
  },
  MuiMenu: {
    styleOverrides: {
      root: {
        fontFamily,
      },
      list: {
        backgroundColor: palette.text.white,
        padding: 0,
        borderRadius: '12px !important',
        overflow: 'hidden',
      },
      paper: {
        borderRadius: '12px !important',
        boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.12)',
        padding: '8px',
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      root: {
        minHeight: 32,
        fontFamily,
      },
      indicator: {
        display: 'none',
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        ...typography.h5,
        fontFamily,
        color: palette.text.secondary,
        textTransform: 'none',
        '&.Mui-selected': {
          ...typography.h4,
          color: palette.text.primary,
          backgroundColor: palette.text.white,
          textDecoration: 'none',

          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          borderColor: palette.grey['300'],
          borderWidth: '1px 1px 0 1px',
          borderStyle: 'solid',
        },
      },
    },
  },
  MuiListItemButton: {
    styleOverrides: {
      root: {
        fontFamily,
        '&.Mui-selected': {
          backgroundColor: palette.grey['200'],
          '&:hover': {
            backgroundColor: palette.grey['200'],
          },
        },
        '&:hover': {
          backgroundColor: palette.grey['200'],
        },
      },
    },
  },
  MuiDialog,
  MuiDialogTitle,
  MuiDialogContent,
  MuiDialogActions,
  MuiChip,

  // {
  //   styleOverrides: {
  //     sizeSmall: {
  //       height: '18px',
  //       px: 2,
  //     },
  //     labelSmall: {
  //       paddingLeft: '8px',
  //       paddingRight: '8px',
  //     },
  //     sizeMedium: {
  //       height: '24px',
  //       px: 2,
  //     },
  //     labelMedium: {
  //       paddingLeft: '8px',
  //       paddingRight: '8px',
  //     },
  //     sizeLarge: {
  //       height: '32px',
  //     },
  //     labelLarge: {
  //       paddingLeft: '10px',
  //       paddingRight: '10px',
  //     },
  //     root: {
  //       ...typography.text1,
  //     },
  //     outlined: {
  //       borderColor: palette.text.tertiary,
  //     },
  //   },
  // },

  MuiFormLabel: {
    styleOverrides: {
      root: {
        '&.Mui-focused': {
          color: palette.grey[600],
        },
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        fontFamily,
        '&.Mui-selected': {
          background: palette.grey[200],
        },
        '&.Mui-selected:hover': {
          background: palette.grey[300],
        },
        '&:hover': {
          background: palette.grey[100],
        },
      },
    },
  },

  MuiTextField: MuiTextField,
  MuiInputBase: {
    styleOverrides: {
      root: {
        ...typography.text1,
        color: palette.text.primary,
        height: '40px',
        borderRadius: buttonBorderRadius,
      },
      input: {
        ...typography.text1,
        color: palette.text.primary,
        '&::placeholder': {
          ...typography.text1,
          color: palette.text.secondary,
          opacity: 1,
        },
      },
    },
  },
  MuiSelect: MuiSelect,
  MuiFormControl: {
    styleOverrides: {
      root: {
        fontFamily,
      },
    },
  },
  MuiAppBar: {
    styleOverrides: {
      root: {
        fontFamily,
      },
    },
  },
  MuiBox: {
    styleOverrides: {
      root: {
        fontFamily,
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        fontFamily,
        '& .MuiMultiSectionDigitalClockSection-root::after': {
          height: 0,
        },
      },
    },
  },

  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        backgroundColor: palette.text.white,
        color: palette.text.secondary,
        borderRadius: '12px',
        border: `1px solid  #E5E5E5`,
        padding: '1px 8px 0px',
        ...typography.text5,
      },
      tooltipPlacementTop: {
        marginBottom: '4px !important',
      },
    },
  },
  MuiListItemText: {
    defaultProps: {},
    styleOverrides: {
      fontFamily,
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 400,
    },
  },
  MuiAlert: {
    styleOverrides: {
      root: ({ ownerState }: any) => {
        let colorStyle = {};
        if (ownerState.severity === 'success') {
          colorStyle = alertSuccessStyle;
        } else if (ownerState.severity === 'error') {
          colorStyle = alertErrorStyle;
        } else if (ownerState.severity === 'info') {
          colorStyle = alertInfoStyle;
        }

        return {
          borderRadius: '12px',
          ...ownerState,
          ...colorStyle,
        };
      },
    },
  },
  MuiDataGrid,
  MuiAutocomplete,
  MuiRadio,
};
