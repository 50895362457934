import { LinkTextCell } from 'common/components/ConfigurableTable/Components/Cells/LinkTextCell';
import { Routes } from 'Routes.constants';
import { useHasFeatureFlag } from 'featureFlags/useHasFeatureFlag';

export const RSimEIDLinkCell = ({ value }: any) => {
  const productsViewEnabled = useHasFeatureFlag('ProductsView');

  return (
    <LinkTextCell
      value={value}
      to={`${productsViewEnabled ? Routes.productsRsim : Routes.rSimInventory}/${value}`}
    />
  );
};
