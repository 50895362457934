import { authFetch } from 'auth/utils';
import { ObjectValues } from 'common/tsHelpers';
import saveAs from 'file-saver';

export const POLLING_RESPONSE = {
  ACK: 0,
  NACK: 1,
  NO_RESPONSE: 2,
} as const;

export type PollingResponse = ObjectValues<typeof POLLING_RESPONSE>;

export const PROFILE = {
  PROFILE_0: 0,
  PROFILE_1: 1,
} as const;

type Profile = ObjectValues<typeof PROFILE>;

type PollingMessageDTO = {
  serverId: number;
  profileId: Profile;
  pollingResponse: PollingResponse;
  timestamp: string;
};

type PollingMessagesDTO = {
  items: PollingMessageDTO[];
  pageNumber: number;
  totalPages: number;
  totalCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
};

export const fetchPollingHistory = async (
  euicc: string,
  from: Date,
  to: Date,
  page: number = 0,
  pageSize: number = 50,
  signal?: AbortSignal,
) => {
  const urlQuery = new URLSearchParams({
    PageNumber: (page + 1).toString(),
    PageSize: pageSize.toString(),
    DateFrom: from.toISOString(),
    DateTo: to.toISOString(),
  });
  const pollingResponse = await authFetch(
    `${
      process.env.REACT_APP_SIM_INVENTORY_RSIMS_PROFILE_API
    }/${euicc}/polling-history?${urlQuery.toString()}`,
    { signal },
  );

  const pollingData: PollingMessagesDTO = await pollingResponse.json();

  return pollingData;
};

const downloadFile = async (filePath: string) => {
  const getFileNameFromContentDispositionHeader = (headerValue: string | null) =>
    headerValue
      ?.split(';')
      ?.find((n) => n.includes('filename='))
      ?.replace('filename=', '')
      .trim()
      .replace(/"/g, '');

  const fileResponse = await authFetch(filePath);

  const filename = getFileNameFromContentDispositionHeader(
    fileResponse.headers.get('Content-Disposition'),
  );

  const blob = await fileResponse.blob();

  saveAs(blob, filename);

  return fileResponse;
};

export const downloadPollingHistory = async (euicc: string, from: Date, to: Date) => {
  const urlQuery = new URLSearchParams({
    DateFrom: from.toISOString(),
    DateTo: to.toISOString(),
    TimeZoneIdentifier: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });

  const response = await downloadFile(
    `${
      process.env.REACT_APP_SIM_INVENTORY_REPORTING_API
    }/rsim-profiles/${euicc}/polling-history?${urlQuery.toString()}`,
  );

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  return response;
};
