import { Plugin } from 'chart.js';
import { getHoverColor, clipArea, unclipArea } from 'chart.js/helpers';

export const middleLine = {
  id: 'middleLine',
  afterDraw(chart: any, args: any, options: any) {
    const {
      ctx,
      chartArea: { left, top, width, height }
    } = chart;

    ctx.save();
    ctx.fillStyle = options.borderColor;
    ctx.fillRect(left, top + height / 2, width, 1);

    ctx.restore();
  }
};

export const barRangePlugin: Plugin<'bar'> = {
  id: 'barRangePlugin',
  beforeDatasetsDraw: function (chart) {
    clipArea(chart.ctx, chart.chartArea);
  },
  beforeEvent(chart, args, options) {
    const event = args.event;
    if (event.type === 'mousemove' && event.x) {
      //@ts-ignore
      this.mouseXPosition = event.x;
    }

    if (event.type === 'mouseout') {
      //@ts-ignore
      this.mouseXPosition = null;
    }
  },

  afterDatasetsDraw(chart, args, pluginOptions) {
    const { ctx, data, scales } = chart;

    ctx.save();
    data.datasets.forEach((dataset) => {
      dataset.data.forEach((bar: any) => {
        if (!bar) {
          return;
        }

        if (bar.x.length !== 2 || bar.y.length !== 2) {
          return;
        }
        const [from, to] = bar.x;
        const [min, max] = bar.y;

        const leftEdge = scales.x.left;
        const rightEdge = scales.x.left + scales.x.width;

        const fromPosition = scales.x.getPixelForValue(from);
        const toPosition = scales.x.getPixelForValue(to);
        const xBegin = fromPosition < leftEdge ? leftEdge : fromPosition;
        const xEnd = toPosition > rightEdge ? rightEdge : toPosition;
        const yBegin = scales.y.getPixelForValue(min);
        const yEnd = scales.y.getPixelForValue(max);

        ctx.beginPath();
        ctx.fillStyle = bar.backgroundColor;

        //@ts-ignore
        if (this.mouseXPosition > xBegin && this.mouseXPosition < xEnd) {
          ctx.fillStyle = getHoverColor(bar.backgroundColor);
        }
        ctx.strokeStyle = bar.borderColor;
        ctx.fillRect(xBegin, yBegin, xEnd - xBegin, yEnd - yBegin);
        ctx.strokeRect(xBegin, yBegin, xEnd - xBegin, yEnd - yBegin);
        ctx.closePath();
      });
    });

    ctx.restore();
    unclipArea(chart.ctx);
  }
};

export const highlightPlugin = {
  id: 'highlightPlugin',

  //@ts-ignore
  afterDraw(chart, _args, pluginOptions) {
    const {
      ctx,
      scales: { x }
    } = chart;

    if (!pluginOptions?.from || !pluginOptions?.to) {
      return;
    }

    ctx.save();

    ctx.globalCompositeOperation = 'multiply';
    ctx.fillStyle = 'rgba(111, 183, 225, 0.5)'; //'#6FB7E1';
    ctx.strokeStyle = 'rgba(13, 144, 220, 0.5)';
    const xStart = x.getPixelForValue(pluginOptions.from);
    const xEnd = x.getPixelForValue(pluginOptions.to);
    ctx.fillRect(xStart, 0, xEnd - xStart, chart.height - 35);
    ctx.strokeRect(xStart, 0, xEnd - xStart, chart.height - 35);
    ctx.restore();
  }
};
