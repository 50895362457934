import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { MaxSimProfilesCountError } from 'simInventory/SimInventoryApi/simInventoryApi';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useTranslation } from 'react-i18next';
import { AuthError } from 'auth/utils';
import { AutoHideSnackbar } from 'common/Snackbar/AutoHideSnackbar';
import { AlertTitle } from 'common/Snackbar/AlertTitle';
import { useMutation } from '@tanstack/react-query';
import { downloadSimProfilesReport } from './api/simProfileReportsApi';
import { useAtomValue } from 'jotai';
import { simFiltersAtom, simSortingAtomWithStorage } from 'atoms/filters/sim';
import { useDebounce } from 'usehooks-ts';
import { getSearchIds } from '../Components/Inputs/SimIdSearchInput.util';

type SimProfileDownloadReportsButtonProps = {
  disabled?: boolean;
};

type ExportError = {
  title: string;
  description: string;
};

export const SimProfileDownloadReportsExtendedFiltersButton: React.FC<
  SimProfileDownloadReportsButtonProps
> = ({ disabled }) => {
  const filters = useAtomValue(simFiltersAtom);
  const { tags, searchText, chosenIdType, ...otherFilters } = filters;

  const debouncedSearchText = useDebounce(searchText || '', 500);
  const minLengthText = debouncedSearchText.length < 3 ? '' : debouncedSearchText;

  const sorting = useAtomValue(simSortingAtomWithStorage);

  const filterKeys = { ...filters, minLengthText };
  //@ts-ignore
  delete filterKeys.searchText;

  const { mutate, isPending } = useMutation({
    mutationKey: ['sim-inventory', 'reports', 'download', filterKeys],
    mutationFn: async () => {
      await downloadSimProfilesReport(
        {
          searchText: getSearchIds(minLengthText),
          chosenIdType: chosenIdType,
          tags,
          ...otherFilters,
        },
        sorting,
      );
    },
    retry: 0,

    onError: (error) => {
      if (error instanceof AuthError) {
        throw error;
      }

      if (error instanceof MaxSimProfilesCountError) {
        setExportError({
          title: t('common.exportFailed'),
          description: t('simInventory.maxSimProfilesCountError', {
            maxSimProfiles: error.maxSimProfilesCount,
          }),
        });
      } else {
        setExportError({
          title: t('common.requestFailed'),
          description: t('common.somethingWrong'),
        });
      }
    },
  });

  const [downloadingSnackbarOpened, setDownloadingSnackbarOpened] = useState(false);
  useEffect(() => {
    if (isPending) {
      setDownloadingSnackbarOpened(true);
    }
  }, [isPending]);
  const { t } = useTranslation();

  const [exportError, setExportError] = useState<ExportError | null>(null);

  return (
    <>
      <Stack>
        <Button
          startIcon={<FileDownloadIcon />}
          disabled={disabled || isPending}
          color="secondary"
          onClick={() => mutate()}
        >
          {t('common.export')}
        </Button>
        <AutoHideSnackbar
          severity="success"
          open={downloadingSnackbarOpened}
          onClose={() => {
            setDownloadingSnackbarOpened(false);
          }}
        >
          {''} {/* Without this component throw a error and crash app */}
          <AlertTitle> {t('common.downloading')}!</AlertTitle>
        </AutoHideSnackbar>
        <AutoHideSnackbar
          open={!!exportError}
          severity="error"
          onClose={() => setExportError(null)}
        >
          {''} {/* Without this component throw a error and crash app */}
          <AlertTitle>{exportError?.title || ''}!</AlertTitle>
          <Typography variant="text1" color="text.white">
            {exportError?.description}
          </Typography>
        </AutoHideSnackbar>
      </Stack>
    </>
  );
};
