import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

const LoaderBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
});

export const DialogLoader = () => {
  return (
    <LoaderBox>
      <CircularProgress />
    </LoaderBox>
  );
};
