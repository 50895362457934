import { useMemo } from 'react';
import { GRAPH_DEFAULT_OPTIONS } from '../../../graphConfig';

export const useGraphZoomConfig = (
  minRange: number,
  min: number,
  max: number,
  onZoom: (range: [Date, Date]) => void,
  onPan?: (newMin: Date, newMax: Date) => void
) => {
  const tooltipConfig = useMemo(
    () => ({
      ...GRAPH_DEFAULT_OPTIONS!.plugins?.zoom,
      limits: {
        ...GRAPH_DEFAULT_OPTIONS!.plugins?.zoom?.limits,
        x: {
          ...GRAPH_DEFAULT_OPTIONS!.plugins?.zoom?.limits?.x,
          minRange,
          min: min,
          max: max
        }
      },
      zoom: {
        ...GRAPH_DEFAULT_OPTIONS!.plugins?.zoom?.zoom,
        //@ts-ignore
        onZoomComplete(context) {
          onZoom([new Date(context.chart.scales.x.min), new Date(context.chart.scales.x.max)]);
        }
      },
      pan: {
        ...GRAPH_DEFAULT_OPTIONS!.plugins?.zoom?.pan,
        mode: 'x',
        scaleMode: 'x',
        threshold: 200,
        //@ts-ignore
        onPan: (event) => {
          if (onPan) {
            // //@ts-ignore
            const min = new Date(event.chart.boxes[0].min);
            //@ts-ignore
            const max = new Date(event.chart.boxes[0].max);

            onPan(min, max);
          }
        }
      }
    }),
    [min, max, onZoom, onPan]
  );

  return tooltipConfig;
};
