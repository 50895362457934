import { useInfiniteQuery } from '@tanstack/react-query';
import { ValuesType } from 'utility-types';
import {
  fetchSessionHistoryInterval,
  getSessionHistoryTimeline,
} from 'common/components/SessionHistory/services/SessionHistoryApi';
import { useEffect, useMemo } from 'react';

type PageParams = Partial<ValuesType<Awaited<ReturnType<typeof fetchSessionHistoryInterval>>>>;
type HistorySessionGraphDataOptions = {
  imsi: string;
  from: Date;
  to: Date;
  entryPerPage: number;
};

export const useHistorySessionGraphData = (options: HistorySessionGraphDataOptions) => {
  const { imsi, from, to, entryPerPage } = options;
  const query = useInfiniteQuery({
    queryKey: ['SessionHistory', imsi, from, to],
    queryFn: async ({ signal, pageParam }) => {
      const toDate = to.getTime() > Date.now() ? new Date() : to;
      const prevePageStartTime =
        pageParam && pageParam?.sessionStart ? new Date(pageParam.sessionStart) : null;
      const data = await fetchSessionHistoryInterval(
        imsi,
        from,
        prevePageStartTime || toDate,
        entryPerPage,
        signal,
      );
      return data;
    },
    initialPageParam: {} as PageParams,
    retry: false,
    enabled: !!imsi,
    getNextPageParam: (lastPage) => {
      if (lastPage.length < entryPerPage) {
        return;
      }

      return lastPage.at(-1);
    },
  });

  const { hasNextPage, isFetchingNextPage, fetchNextPage } = query;
  useEffect(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, isFetchingNextPage]);

  const sessionHistory = useMemo(() => {
    const history =
      query.data?.pages.reduce((history, page) => {
        return [...history, ...page];
      }, []) || [];

    return getSessionHistoryTimeline(history, to);
  }, [query.data, to]);

  return {
    ...query,
    data: sessionHistory,
  };
};
