import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { AutoHideSnackbar } from '../../common/Snackbar/AutoHideSnackbar';
import { AlertTitle } from '../../common/Snackbar/AlertTitle';
import { Error } from './useBulkProvisioningUpload';

export type BulkProvisioningUploadSnackbarProps = {
  importError: Error | null;
  setImportError: (importError: Error | null) => void;
  selectFile: (file: null) => void;
};

export const BulkProvisioningUploadSnackbar: React.FC<BulkProvisioningUploadSnackbarProps> = ({
  importError,
  setImportError,
  selectFile,
}) => {
  return (
    <Stack>
      <AutoHideSnackbar
        open={!!importError}
        severity="error"
        onClose={() => {
          setImportError(null);
          selectFile(null);
        }}
      >
        {''} {/* Without this component throw a error and crash app */}
        <AlertTitle>{importError?.title || ''}!</AlertTitle>
        {importError?.description.length === 1 && (
          <Typography key={importError.description[0]} variant="text1" color="text.white">
            {importError.description[0]}
          </Typography>
        )}
        {importError?.description.length &&
          importError?.description.length > 1 &&
          importError?.description.map((message, index) => (
            <>
              <li>
                <Typography key={message} variant="text1" color="text.white">
                  {message}
                </Typography>
                {index + 1 < importError?.description.length && <br />}
              </li>
            </>
          ))}
      </AutoHideSnackbar>
    </Stack>
  );
};
