import { ObjectValues } from 'common/tsHelpers';

export const LAST_RADIUS_TYPE = {
  STOP: 'Stop',
  UPDATE: 'Interim-Update',
  START: 'Start',
} as const;

export type LastRadiusType = ObjectValues<typeof LAST_RADIUS_TYPE>;

export type InterimDTO = {
  statusLastUpdated: string;
  acctSessionId: string;
  start: string;
  stop: string;
  lastRadiusType: LastRadiusType;
  currentStatus: string;
  framedIpAddress: string;
  calledStationId: string;
  detectedImei: string;
  username: string;
  cumulativeOutputUsage: number;
  cumulativeInputUsage: number;
  localization: string;
  operatorCode: string;
  roamingCode: string;
  timeZone: string;
  rasClient: string;
  framedProtocol: string;
  nasIpAddress: string;
  serviceType: string;
  eventTimestamp: string;
  ratType: string;
  processedDate: string;
  deltaInputUsage: number;
  deltaOutputUsage: number;
};

export type InterimsDTO = InterimDTO[];
