export const setMinimalPromiseTime: { <Type>(arg: Type, minimalTime?: number): any } = async (
  promise,
  minimalTime = 250,
) => {
  const response = await Promise.all([
    promise,
    new Promise((resolve) => setTimeout(resolve, minimalTime)),
  ]);
  return response[0];
};
