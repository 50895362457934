import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { SxProps, Theme } from '@mui/material/styles';

type SkeletonTableRowLayoutProps = {
  skeleton: React.ReactElement;
  showStartSkeleton?: boolean;
  showEndSkeleton?: boolean;
  sx?: SxProps<Theme>;
};

export const SkeletonTableRowLayout: React.FC<SkeletonTableRowLayoutProps> = ({
  skeleton,
  showStartSkeleton = false,
  showEndSkeleton = false,
  sx
}) => (
  <TableRow hover={false}>
    {showStartSkeleton && (
      <TableCell padding="checkbox" sx={sx}>
        {React.cloneElement(skeleton)}
      </TableCell>
    )}
    <TableCell sx={sx}>{skeleton}</TableCell>
    {showEndSkeleton && (
      <TableCell sx={{ width: '48px', pl: 0, ...sx }}>{React.cloneElement(skeleton)}</TableCell>
    )}
  </TableRow>
);
