import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CSS from 'csstype';
import { SortingIndicator } from 'common/components/Table/SortingIndicator';
import { ReactComponent as DragIcon } from 'assets/images/drag.svg';
import { palette } from 'theme/palette';
import { ColumnConfiguration } from '../../../SimInventoryApi/simInventoryTableConfigurationApi.interface';
import { Sorting } from 'common/hooks/useSorting';
import { styled } from '@mui/material';

type HeaderCellProps = {
  primaryText?: string;
  secondaryText?: string;
  verticalAlign?: CSS.Property.VerticalAlign;
  enableSortingPrimary?: boolean;
  enableSortingSecondary?: boolean;
  column: ColumnConfiguration;
  sortedColumn: { columnName: string; sort: Sorting } | null;
  handleSort: (columnName: string, sort: Sorting) => void;
};

const HeaderBox = styled(Box)({
  '& .dragBox': {
    opacity: 0,
    '& svg': {
      display: 'block',
      margin: 'auto',
    },
  },
  '&:hover .dragBox': {
    opacity: 1,
  },
  height: '42px',
  textTransform: 'capitalize',
  paddingLeft: '8px',
  paddingTop: '6px',
  paddingBottom: '6px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  width: '100%',
});

const HeaderTitleBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  '& .MuiIconButton-root': {
    padding: '0',
  },
  '& .sort': {
    opacity: 0,
  },
  '&:hover .sort': {
    opacity: 1,
  },
  '& span': {
    overflow: 'hidden',
    '& div': {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  },
});

const DragBox = styled(Box)({
  height: '40px',
  backgroundColor: `${palette.grey[100]}`,
  position: 'absolute',
  right: 0,
  top: '1px',
  paddingTop: '11px',
  width: '30px',
  cursor: 'move',
  marginRight: '8px',
});

const onSort = (sort: Sorting): Sorting => {
  if (!sort || sort === 'desc') {
    return 'asc';
  } else {
    return 'desc';
  }
};

const getPrimarySortState = (
  columnName: string,
  sortedColumn: { columnName: string; sort: Sorting } | null,
) => {
  return sortedColumn && sortedColumn.columnName === columnName ? sortedColumn.sort : null;
};

const getSecondarySortState = (
  column: ColumnConfiguration,
  sortedColumn: { columnName: string; sort: Sorting } | null,
) => {
  return sortedColumn &&
    column.children.length > 0 &&
    sortedColumn.columnName === column.children[0].name
    ? sortedColumn.sort
    : null;
};

export const HeaderCell: React.FC<HeaderCellProps> = ({
  primaryText,
  secondaryText,
  verticalAlign,
  enableSortingPrimary,
  enableSortingSecondary,
  sortedColumn,
  column,
  handleSort,
}) => {
  const [primarySort, setPrimarySort] = useState<Sorting>(
    getPrimarySortState(column.name, sortedColumn),
  );
  const [secondarySort, setSecondarySort] = useState<Sorting>(
    getSecondarySortState(column, sortedColumn),
  );

  useEffect(() => {
    setPrimarySort(getPrimarySortState(column.name, sortedColumn));
    setSecondarySort(getSecondarySortState(column, sortedColumn));
  }, [sortedColumn]);

  const onPrimarySort = () => {
    const sort = onSort(primarySort);
    setPrimarySort(sort);
    setSecondarySort(null);
    handleSort(column.name, sort);
  };
  const onSecondarySort = () => {
    const sort = onSort(secondarySort);
    setPrimarySort(null);
    setSecondarySort(sort);
    handleSort(column.children[0]?.name, sort);
  };

  return (
    <React.Fragment key={column.name}>
      <HeaderBox sx={{ verticalAlign }}>
        <HeaderTitleBox data-testid="tableHeaderPrimary">
          {enableSortingPrimary && (
            <SortingIndicator
              className={!!primarySort ? '' : 'sort'}
              sorting={onPrimarySort}
              direction={primarySort}
              testId={'primarySortingIndicator'}
            ></SortingIndicator>
          )}
          <Typography
            style={!enableSortingPrimary ? { paddingLeft: '16px' } : {}}
            variant="text3"
            color="text.primary"
          >
            <Box data-testid="primaryText">{primaryText}</Box>
          </Typography>
        </HeaderTitleBox>
        <HeaderTitleBox data-testid="tableHeaderSecondary">
          {enableSortingSecondary && (
            <SortingIndicator
              className={!!secondarySort ? '' : 'sort'}
              sorting={onSecondarySort}
              direction={secondarySort}
              testId={'secondarySortingIndicator'}
            ></SortingIndicator>
          )}
          <Typography
            style={!enableSortingSecondary ? { paddingLeft: '16px' } : {}}
            variant="text4"
            color="text.secondary"
          >
            <Box data-testid="secondaryText">{secondaryText}</Box>
          </Typography>
        </HeaderTitleBox>

        <DragBox className="dragBox">
          <DragIcon width="10px" height="16px" role="img" data-testid="drag-icon" />
        </DragBox>
      </HeaderBox>
    </React.Fragment>
  );
};
