import { useContext } from 'react';
import { InterimsTimelineContext } from './InterimsTimelineProvider';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { toLocaleDateTime } from 'common/utils/dateUtils';
import { useTranslation } from 'react-i18next';
import { getLastRadiusTypeLabel } from './utils/interimUtils';
import { ThreeColumnLayout } from 'common/components/AppLayout/ThreeColumnLayout';

const PropertyBox = ({ label, value, 'data-testid': dataTestId }: any) => {
  return (
    <Stack direction="row" alignItems="center" data-testid={dataTestId}>
      <Typography
        variant="text5"
        color="text.secondary"
        sx={{ minWidth: '140px' }}
        data-testid={`${dataTestId} label`}
      >
        {label}
      </Typography>
      <Typography variant="text3" color="text.secondary" data-testid={`${dataTestId} value`}>
        {value}
      </Typography>
    </Stack>
  );
};

export const InterimsDetails = () => {
  const { selectedInterim: interim } = useContext(InterimsTimelineContext);
  const { t } = useTranslation();

  return (
    <Box>
      <Typography variant="h4" color="text.secondary">
        {t('graphs.interimDetails')}
      </Typography>
      <Box mt={2}>
        <ThreeColumnLayout
          rowGap={2}
          firstColumnChildren={[
            <PropertyBox
              data-testid="Interim Type"
              label={t('graphs.interimType')}
              value={getLastRadiusTypeLabel(interim?.lastRadiusType)}
            />,
            <PropertyBox
              data-testid="Timestamp"
              label={t('graphs.timestamp')}
              value={toLocaleDateTime(interim?.timestamp, '-')}
            />,
            <PropertyBox
              data-testid="Cumulative Usage"
              label={t('simInventory.cumulativeUsage')}
              value={interim?.cumulativeUsage.toFixed(2) || '-'}
            />,
          ]}
          secondColumnChildren={[
            <PropertyBox
              data-testid="Usage"
              label={t('simInventory.usage')}
              value={interim?.usage.toFixed(2) || '-'}
            />,
            <PropertyBox
              data-testid="Input Usage"
              label={t('simInventory.cumulativeInputUsage')}
              value={interim?.inputUsage.toFixed(2) || '-'}
            />,
            <PropertyBox
              data-testid="Output Usage"
              label={t('simInventory.cumulativeOutputUsage')}
              value={interim?.outputUsage.toFixed(2) || '-'}
            />,
          ]}
          thirdColumnChildren={[
            <PropertyBox
              data-testid="Location"
              label={t('simInventory.location')}
              value={interim?.localization || '-'}
            />,
            <PropertyBox
              data-testid="Radio Access Type"
              label={t('simInventory.radioAccessType')}
              value={interim?.radioAccess || '-'}
            />,
          ]}
        />
      </Box>
    </Box>
  );
};
