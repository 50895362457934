import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface NoAccessViewProps {
  resetErrorBoundary: any;
}

export const NoAccessView: React.FC<NoAccessViewProps> = ({ resetErrorBoundary }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const errorLocation = useRef(location.pathname);

  useEffect(() => {
    if (location.pathname !== errorLocation.current) {
      resetErrorBoundary();
    }
  }, [location.pathname, resetErrorBoundary]);

  return (
    <div style={{ height: '100%' }}>
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        <Box>
          <Typography variant="h2">No Access</Typography>
        </Box>
        <Box>
          <Typography>{t('common.noAccess')}</Typography>
        </Box>
      </Container>
    </div>
  );
};
