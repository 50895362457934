import Box from '@mui/material/Box';
import { BoxHeaderCell, BoxHeaderCellProps } from 'common/components/Table/BoxHeaderCell';

type SessionHistoryHeaderProps = Pick<BoxHeaderCellProps, 'primaryText' | 'secondaryText'>;

export const SessionHistoryHeader: React.FC<SessionHistoryHeaderProps> = ({
  primaryText,
  secondaryText
}) => {
  return (
    <Box
      sx={{
        py: 1.5,
        height: '42px'
      }}
    >
      <BoxHeaderCell primaryText={primaryText} secondaryText={secondaryText} />
    </Box>
  );
};
