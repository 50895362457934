import { ContentBox } from 'common/components/AppLayout/ContentBox';
import { SuspenseLoadingView } from 'common/components/SuspenseLoadingView';
import { TableSkeleton } from 'common/components/Table/TableSkeleton';
import { useParams } from 'react-router-dom';
import { SessionHistoryTableWithInterims } from './SessionHistoryTableWithInterims';
import { SessionHistoryErrorBoundary } from './SessionHistoryErrorBoundary';

export const SessionHistoryWithInterims = () => {
  const { iccid } = useParams();

  return (
    <SessionHistoryErrorBoundary>
      <SuspenseLoadingView
        queryKey={['SimInventory', iccid]}
        loadFallback={
          <ContentBox>
            <TableSkeleton numberOfRows={20} pagination={false} />
          </ContentBox>
        }
      >
        <SessionHistoryTableWithInterims />
      </SuspenseLoadingView>
    </SessionHistoryErrorBoundary>
  );
};
