import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MuiDrawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import InventoryIcon from '@mui/icons-material/Inventory';
import { ReactComponent as BPUploadIcon } from 'assets/images/BPUploadIcon.svg';
import { ReactComponent as CSLLogo } from 'assets/images/CSLlogo.svg';
import { ReactComponent as RSimLogo } from 'assets/images/rSimLogo.svg';
import { ReactComponent as SimCardIcon } from 'assets/images/simprofile.svg';
import LogoutIcon from '@mui/icons-material/Logout';
import AppBar from '@mui/material/AppBar';
import { fetchAccount, logoutRedirect } from 'auth/AuthApi/authApi';
import { useAuth } from 'auth/AuthProvider';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Grid from '@mui/material/Grid2';
import { appBarStyle, toolbarStyle } from './AppNavigation.styles';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Routes } from '../../../../Routes.constants';
import { ReactComponent as ArrowBack } from 'assets/images/arrowBack.svg';
import { palette } from 'theme/palette';
import { menuWidth, menuWidthSmall } from '../AppLayout.styles';
import { usePreviousPath } from './usePreviousPath';
import { useParentPath } from './useParentPath';
import SettingsIcon from '@mui/icons-material/Settings';
import { useHasFeatureFlag } from '../../../../featureFlags/useHasFeatureFlag';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import LaunchIcon from '@mui/icons-material/Launch';

import React, { useEffect, useState } from 'react';
import { SimInventoryCan } from 'permissions/PermissionProvider';
import { Actions, Subjects } from 'permissions/ability';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Divider from '@mui/material/Divider';
import { useQuery } from '@tanstack/react-query';
import { NavigationLink } from './Components/NavigationLink';
import { useAbility } from '../../../../permissions/hooks/useAbility';

type RoutingParams = {
  iccid?: string;
  euicc?: string;
};

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ open }) => ({
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  overflowX: 'hidden',
  transition: 'width 0.2s ease-in-out',
  '& .MuiListItemText-root': {
    transition: 'opacity 0.2s ease-in-out',
    opacity: '0%',
  },
  width: menuWidthSmall,
  '& .MuiDrawer-paper': {
    zIndex: '1200',
    transition: 'width 0.2s ease-in-out',
    width: menuWidthSmall,
  },
  ...(open && {
    '@media (hover: hover)': {
      '& .MuiListItemText-root': {
        opacity: '100%',
      },
      width: menuWidth,
      '& .MuiDrawer-paper': {
        width: menuWidth,
      },
    },
  }),
  a: {
    color: 'inherit',
  },
}));

const UserButton = styled(Button)(({ theme }) => {
  return {
    fontSize: '12px',
    padding: '10px 16px',
    backgroundColor: 'transparent',
    color: theme.palette.text.secondary,
    '&:hover': {
      backgroundColor: palette.grey[200],
      boxShadow: 'none',
    },
    '&:active': {
      backgroundColor: palette.grey[300],
      boxShadow: 'none',
    },
  };
});

const MenuItemStyled = styled(MenuItem)({
  height: '40px',
  width: '100%',
  marginTop: '6px',
  padding: 0,
  '& a': {
    width: '100%',
    height: '100%',
    textDecoration: 'none',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '6px 16px',
    span: {
      color: palette.grey[700],
    },
    '& svg': {
      color: palette.text.secondary,
    },
  },
  '&:hover': { backgroundColor: palette.grey[200], borderRadius: '12px' },
});

const LogoutMenuItemStyled = styled(MenuItem)({
  height: '40px',
  width: '99%',
  margin: '12px 1px 1px 1px',
  boxShadow: '0px 0px 5px 0px #00000014, 0px 1px 2px 0px #00000014',
  borderRadius: '12px',
  display: 'flex',
  justifyContent: 'center',
  '& span': { lineHeight: '15px' },
  '& svg': {
    color: palette.text.secondary,
    marginRight: '12px',
  },
  '&:hover': {
    backgroundColor: palette.grey[200],
    borderRadius: '12px',
    boxShadow: '2px 2px 2px 0px #00000005 inset',
  },
});

type DetailsTitleProps = { route: Routes; basePageTitle: string };

const SimDetailsTitle: React.FC<DetailsTitleProps> = ({ route, basePageTitle }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { iccid } = useParams<RoutingParams>();
  const previousPath = usePreviousPath();
  const parentPath = useParentPath();

  const goBack = () => {
    navigate(parentPath, { replace: true });
  };

  return (
    <Grid container>
      <Grid>
        {!previousPath && (
          <IconButton onClick={goBack} data-testid="backButton">
            <ArrowBack />
          </IconButton>
        )}
        {previousPath && (
          <IconButton data-testid="backButton" onClick={() => navigate(-1)}>
            <ArrowBack />
          </IconButton>
        )}
      </Grid>
      <Grid sx={{ mt: '9px' }}>
        <Box data-testid="title">{`${t('simInventory.sim')} ${iccid}`}</Box>
        <Breadcrumbs
          separator="›"
          aria-label="breadcrumb"
          sx={{
            mt: '-10px',
            '& .MuiBreadcrumbs-separator': {
              margin: '4px',
            },
          }}
        >
          <Typography variant="text5" color="text.secondary">
            <Link to={route} style={{ color: palette.text.secondary, textDecoration: 'none' }}>
              {basePageTitle}
            </Link>
          </Typography>
          ,
          <Typography variant="text5" color="text.secondary">
            {t('simInventory.SIMDetails')}
          </Typography>
        </Breadcrumbs>
      </Grid>
    </Grid>
  );
};

const RSimInventoryDetailsTitle = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { euicc } = useParams<RoutingParams>();
  const previousPath = usePreviousPath();
  const parentPath = useParentPath();

  const goBack = () => {
    navigate(parentPath, { replace: true });
  };
  const productsViewEnabled = useHasFeatureFlag('ProductsView');

  return (
    <Grid container>
      <Grid>
        {!previousPath && (
          <IconButton onClick={goBack} data-testid="backButton">
            <ArrowBack />
          </IconButton>
        )}
        {previousPath && (
          <IconButton data-testid="backButton" onClick={() => navigate(-1)}>
            <ArrowBack />
          </IconButton>
        )}
      </Grid>
      <Grid sx={{ mt: '9px' }}>
        <Box data-testid="title">{`${t('rSimInventory.title')} ${euicc}`}</Box>
        <Breadcrumbs
          separator="›"
          aria-label="breadcrumb"
          sx={{
            mt: '-10px',
            '& .MuiBreadcrumbs-separator': {
              margin: '4px',
            },
          }}
        >
          <Typography variant="text5" color="text.secondary">
            {!productsViewEnabled ? (
              <Link
                to={Routes.rSimInventory}
                style={{ color: palette.text.secondary, textDecoration: 'none' }}
              >
                {t('rSimInventory.rSimInventory')}
              </Link>
            ) : (
              <Link
                to={Routes.productsRsim}
                style={{ color: palette.text.secondary, textDecoration: 'none' }}
              >
                {t('rSimInventory.rsimProducts')}
              </Link>
            )}
          </Typography>
          ,
          <Typography variant="text5" color="text.secondary">
            {t('rSimInventory.rSIMDetails')}
          </Typography>
        </Breadcrumbs>
      </Grid>
    </Grid>
  );
};

export const AppNavigation = () => {
  const ability = useAbility();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { user } = useAuth();
  const { t } = useTranslation();
  const location = useLocation();

  const adminPanelEnabled =
    ability.can(Actions.edit, Subjects.customFields) ||
    ability.can(Actions.edit, Subjects.importRsims);

  const adminDefaultActiveTab = ability.can(Actions.edit, Subjects.customFields)
    ? 'Custom fields'
    : 'RSim Manufacturing File';

  const productsViewEnabled = useHasFeatureFlag('ProductsView');
  const simProductsViewEnabled = useHasFeatureFlag('SimProductsView');
  const previousPath = usePreviousPath();

  const isCustomSimInventoryRedirect =
    previousPath?.localeCompare(Routes.rSimInventory) === 0 ||
    previousPath?.localeCompare(Routes.provisioningRequest) === 0;

  const { iccid, euicc } = useParams<RoutingParams>();

  const getTitle = () => {
    if (location.pathname.includes(Routes.simInventory) && iccid) {
      return (
        <SimDetailsTitle
          route={Routes.simInventory}
          basePageTitle={t('simInventory.simInventory')}
        />
      );
    }

    if (location.pathname.includes(Routes.productsSim) && iccid) {
      return (
        <SimDetailsTitle route={Routes.productsSim} basePageTitle={t('products.sim.simProducts')} />
      );
    }

    if (
      (location.pathname.includes(Routes.rSimInventory) ||
        location.pathname.includes(Routes.productsRsim)) &&
      euicc
    ) {
      return <RSimInventoryDetailsTitle />;
    }

    if (location.pathname.includes(Routes.admin)) {
      return t('customFields.admin');
    }

    if (location.pathname.includes(Routes.provisioningRequest)) {
      return t('bulkProvisioning.title');
    }

    if (location.pathname.includes(Routes.products)) {
      return t('common.products');
    }

    return t('common.inventory');
  };

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { data: account } = useQuery({
    queryKey: ['Account', user.referenceId],
    queryFn: () => fetchAccount(user.referenceId),
    enabled: !!user.referenceId,
  });

  const accountsToDisplayRsimLogo = ['1216424'];
  const rSimFavicon = require('assets/images/rsim-favicon.png');

  useEffect(() => {
    let link = document.querySelector("link[rel='icon']");
    if (!link) {
      link = document.createElement('link');
      link.id = 'favicon';
      // @ts-ignore
      link.rel = 'shortcut icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    if (!!user.referenceId && accountsToDisplayRsimLogo.includes(user.referenceId.toString())) {
      // @ts-ignore
      link.href = rSimFavicon;
    }
  }, [user]);

  return (
    <Box
      sx={{
        marginBottom: 3,
        px: 0,
        ml: menuWidthSmall,
      }}
    >
      <AppBar elevation={0} color="transparent" sx={appBarStyle} position="static">
        <Toolbar sx={toolbarStyle}>
          <Typography variant="h2" component="div" sx={{ flexGrow: 1 }}>
            {getTitle()}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <UserButton
              //@ts-ignore
              component="span"
              data-testid="account dropdown button"
              variant="contained"
              onClick={handleClick}
              endIcon={<ArrowDropDownIcon />}
            >
              {`${user.givenName} ${user.surname}`}
            </UserButton>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              slotProps={{
                paper: {
                  elevation: 0,
                  sx: {
                    width: '250px',
                    boxShadow: '0px 0px 12px 0px #0000001F',
                    overflow: 'visible',
                    mt: 0.5,
                    padding: 3,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <Box sx={{ padding: '0 12px' }} data-testid="account data">
                <Box>
                  <Typography variant="text2" color="text.primary" data-testid="account name">
                    {account?.accountName}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="text5" color="text.primary" data-testid="account role">
                    {user.role.replace('Customer ', '')}
                  </Typography>
                </Box>
              </Box>
              <Divider sx={{ pt: 1, mb: 3 }} />
              <MenuItemStyled data-testid="account change password" onClick={handleClose}>
                <a
                  href="https://id.csl-group.com/auth/realms/CSLPortal/account/password?kc_locale=en-GB"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Typography variant="text1" color="text.primary">
                    {t('common.changePassword')}
                  </Typography>
                  <LaunchIcon width="18px" height="18px" />
                </a>
              </MenuItemStyled>
              <MenuItemStyled data-testid="account user management" onClick={handleClose}>
                <a
                  href="https://portal.csl-group.com/user-management"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Typography variant="text1" color="text.primary">
                    {t('common.userManagement')}
                  </Typography>
                  <LaunchIcon width="18px" height="18px" />
                </a>
              </MenuItemStyled>

              <LogoutMenuItemStyled
                aria-label={t('common.logOut')}
                onClick={logoutRedirect}
                data-testid="account logout"
              >
                <LogoutIcon width="13px" height="18px" />
                <Typography variant="button" color="text.secondary">
                  {t('common.logOut')}
                </Typography>
              </LogoutMenuItemStyled>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        elevation={24}
        PaperProps={{ elevation: 8, sx: { borderRight: 0, overflowX: 'hidden' } }}
        open={isDrawerOpen}
        onMouseOver={handleDrawerOpen}
        onMouseOut={handleDrawerClose}
      >
        <Box sx={{ mb: 8, ml: 2.5, mt: 3 }}>
          {!!user.referenceId && accountsToDisplayRsimLogo.includes(user.referenceId.toString()) ? (
            <RSimLogo width={'32px'} height={'32px'} />
          ) : (
            <CSLLogo width={'28px'} height={'26px'} />
          )}
        </Box>
        <List sx={{ mt: '77px', '* + *': { mt: 0.5 } }}>
          {productsViewEnabled || simProductsViewEnabled ? (
            <>
              <NavigationLink
                page="products"
                data-testid="products-link"
                to={simProductsViewEnabled ? Routes.productsSim : Routes.productsRsim}
                label={t('common.products')}
                icon={<InventoryIcon />}
              />
              <NavigationLink
                page="inventory"
                data-testid="sim-inventory-link"
                to={isCustomSimInventoryRedirect ? (previousPath as string) : Routes.simInventory}
                label={t('common.inventory')}
                icon={<SimCardIcon />}
              />
            </>
          ) : (
            <NavigationLink
              page="inventory"
              data-testid="sim-inventory-link"
              to={isCustomSimInventoryRedirect ? (previousPath as string) : Routes.simInventory}
              label={t('common.inventory')}
              icon={<InventoryIcon />}
            />
          )}
          <SimInventoryCan I={Actions.read} a={Subjects.bulkProvisioning}>
            <NavigationLink
              page="provisioning"
              data-testid="provisioning-link"
              to={Routes.provisioningRequest}
              label={t('bulkProvisioning.title')}
              icon={<BPUploadIcon width={'24px'} height={'24px'} />}
            />
          </SimInventoryCan>
          {adminPanelEnabled && (
            <NavigationLink
              page="admin"
              data-testid="admin-link"
              to={Routes.admin}
              label={t('common.admin')}
              icon={<SettingsIcon />}
              defaultActiveTabName={adminDefaultActiveTab}
            />
          )}
        </List>
      </Drawer>
    </Box>
  );
};
