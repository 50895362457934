import { LineController } from 'chart.js';

export class PeakChart extends LineController {
  draw() {
    const meta = this.getMeta();
    if (!meta.data.length) {
      return;
    }

    const ctx = this.chart.ctx;

    if (meta._parsed.length !== meta.data.length) {
      return;
    }

    meta.data.forEach((point, index) => {
      ctx.save();
      ctx.beginPath();

      if (point.options?.borderColor === 'rgba(0,0,0,0.1)') {
        //@ts-ignore
        ctx.strokeStyle = meta._parsed[index].color as string;
      } else {
        ctx.strokeStyle = point.options?.borderColor;
      }

      ctx.lineWidth = point.options?.borderWidth || 2;
      ctx.moveTo(point.x, this.chart.chartArea.height / 2);
      //@ts-ignore
      ctx.lineTo(point.x, this.chart.chartArea.height / 2 - 50 * meta._parsed[index].y);
      ctx.stroke();
      ctx.restore();
    });
  }
}

PeakChart.id = 'peak';
