import { Chip } from '../Chips/Chip';
import { QueryFunction, useQuery } from '@tanstack/react-query';
import React from 'react';

type FilterAsyncChipProps = {
  onCancel: () => void;
  labelPrefix: string;
  fieldName: string;
  queryFn: QueryFunction;
  queryKey: string[];
  queryEnabled: boolean;
};

export const FilterAsyncChip = ({
  onCancel,
  labelPrefix,
  fieldName,
  queryFn,
  queryKey,
  queryEnabled,
}: FilterAsyncChipProps) => {
  const { data, isLoading } = useQuery({
    queryKey: [...queryKey],
    queryFn: queryFn,
    enabled: queryEnabled,
  });

  return (
    <Chip
      // @ts-ignore
      label={`${labelPrefix}: ${data?.[fieldName]}`}
      size="medium"
      variant="outlined"
      onDelete={onCancel}
      isLoading={isLoading}
    />
  );
};
