import { useLastMonthRange } from 'common/hooks/useLastMonthRange';
import {
  addMilliseconds,
  differenceInMilliseconds,
  isAfter,
  subDays,
  subMilliseconds,
} from 'date-fns';
import React, { useMemo, useState, useCallback } from 'react';

export type SessionContextProps = {
  validRange: {
    from: Date;
    to: Date;
  };
  selectedRange: {
    from: Date;
    to: Date;
  };
  selectRange: (newFrom: Date, newTo: Date) => void;

  visibleRange: {
    from: Date;
    to: Date;
  };
  selectVisibleRange: (newFrom: Date, newTo: Date) => void;
};

export const GraphSessionRangeContext = React.createContext<SessionContextProps>(
  {} as SessionContextProps,
);

export type GraphSessionRangeProviderProps = {
  children: React.ReactNode;
};

export const GraphSessionRangeProvider: React.FC<GraphSessionRangeProviderProps> = ({
  children,
}) => {
  const [validFrom, validTo] = useLastMonthRange();

  const validRange = useMemo(
    () => ({
      from: validFrom,
      to: validTo,
    }),
    [validFrom, validTo],
  );

  const [selectedRange, setSelectedRange] = useState({
    from: subDays(new Date(), 1),
    to: new Date(),
  });

  const [visibleRange, setVisibleRange] = useState(selectedRange);

  const selectRange = useCallback(
    (newFrom: Date, newTo: Date) => {
      const minimalDiffInMS = 5 * 60_000;
      const diffInMS = differenceInMilliseconds(newTo, newFrom);
      const newRange = {
        from: newFrom,
        to: newTo,
      };

      if (diffInMS < minimalDiffInMS) {
        newRange.from = newFrom;
        newRange.to = addMilliseconds(newFrom, 5 * 60_000);
      }

      if (diffInMS < minimalDiffInMS && isAfter(newRange.to, validRange.to)) {
        newRange.from = subMilliseconds(newTo, 5 * 60_000);
        newRange.to = newTo;
      }

      setSelectedRange(newRange);
      setVisibleRange(newRange);
    },
    [validRange],
  );

  const selectVisibleRange = useCallback((newFrom: Date, newTo: Date) => {
    setVisibleRange({
      from: newFrom,
      to: newTo,
    });
  }, []);

  return (
    <GraphSessionRangeContext.Provider
      value={{ validRange, selectedRange, visibleRange, selectRange, selectVisibleRange }}
    >
      {children}
    </GraphSessionRangeContext.Provider>
  );
};
