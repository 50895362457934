import { subDays, subHours, subMinutes, subMonths, subWeeks } from 'date-fns';
import { LastUpdateTime } from '../data/filters.constants';
import { t } from 'i18next';

export const mapLastUpdateTimeToDateTime = (value: LastUpdateTime) => {
  switch (value) {
    case 'MINUTES_15':
      return subMinutes(new Date(), 15);
    case 'MINUTES_30':
      return subMinutes(new Date(), 30);
    case 'HOUR':
      return subHours(new Date(), 1);
    case 'DAY':
      return subDays(new Date(), 1);
    case 'WEEK':
      return subWeeks(new Date(), 1);
    case 'MONTH':
      return subMonths(new Date(), 1);
  }
};

export const mapLastUpdateTimeToLabels = (value: LastUpdateTime) => {
  switch (value) {
    case 'MINUTES_15':
      return `15 ${t('common.minutes')}`;
    case 'MINUTES_30':
      return `30 ${t('common.minutes')}`;
    case 'HOUR':
      return `${t('common.hour')}`;
    case 'DAY':
      return `${t('common.day')}`;
    case 'WEEK':
      return `${t('common.week')}`;
    case 'MONTH':
      return `${t('common.month')}`;
  }

  return '';
};
