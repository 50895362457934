import { ProvisioningFilters } from './BulkProvisioningOLD.interface';

export const INITIAL_FILTERS: ProvisioningFilters = {
  status: '',
  orderNumber: '',
  source: '',
  uploadDateFrom: null,
  uploadDateTo: null,
};

export const PROVISIONING_STATUS_LABEL = {
  PROCESSING_IN_PROGRESS: 'Processing in progress',
  PROCESSING_SUCCEED: 'Processing success',
  PROCESSING_FAILED: 'Processing fail',
  DATA_EXTRACTION_PENDING: 'Data extraction pending',
  DATA_EXTRACTION_IN_PROGRESS: 'Data extraction in progress',
  DATA_EXTRACTION_FAILED: 'Data extraction failed',
} as const;

export const PROVISIONING_STATUS = {
  PROCESSING_IN_PROGRESS: 0,
  PROCESSING_SUCCEED: 1,
  PROCESSING_FAILED: 2,
  DATA_EXTRACTION_PENDING: 3,
  DATA_EXTRACTION_IN_PROGRESS: 4,
  DATA_EXTRACTION_FAILED: 5,
} as const;

export const PROVISIONING_SOURCE = {
  MANUAL: 0,
  AUTOMATIC: 1,
} as const;

export const PROVISIONING_STATUS_TO_STATUS_LABEL_MAP = {
  [PROVISIONING_STATUS.PROCESSING_IN_PROGRESS]: PROVISIONING_STATUS_LABEL.PROCESSING_IN_PROGRESS,
  [PROVISIONING_STATUS.PROCESSING_SUCCEED]: PROVISIONING_STATUS_LABEL.PROCESSING_SUCCEED,
  [PROVISIONING_STATUS.PROCESSING_FAILED]: PROVISIONING_STATUS_LABEL.PROCESSING_FAILED,
  [PROVISIONING_STATUS.DATA_EXTRACTION_PENDING]: PROVISIONING_STATUS_LABEL.DATA_EXTRACTION_PENDING,
  [PROVISIONING_STATUS.DATA_EXTRACTION_IN_PROGRESS]:
    PROVISIONING_STATUS_LABEL.DATA_EXTRACTION_IN_PROGRESS,
  [PROVISIONING_STATUS.DATA_EXTRACTION_FAILED]: PROVISIONING_STATUS_LABEL.DATA_EXTRACTION_FAILED,
};

export const PROVISIONING_SOURCE_LABEL = {
  MANUAL: 'Manual',
  AUTOMATIC: 'Automatic',
} as const;

export const PROVISIONING_SOURCE_TO_SOURCE_LABEL = {
  [PROVISIONING_SOURCE.MANUAL]: PROVISIONING_SOURCE_LABEL.MANUAL,
  [PROVISIONING_SOURCE.AUTOMATIC]: PROVISIONING_SOURCE_LABEL.AUTOMATIC,
};
