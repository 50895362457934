import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { useContext } from 'react';
import { SessionHistoryContext } from './SessionHistoryProvider';
import { toLocaleDateTime } from 'common/utils/dateUtils';

type SessionTooltipProps = {
  containerWidth: number;
  offsetX?: number;
};

export const SessionTooltip: React.FC<SessionTooltipProps> = ({
  containerWidth,
  offsetX = 100,
}) => {
  const { selectedSession, sessionSelection } = useContext(SessionHistoryContext);

  return selectedSession ? (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        zIndex: 1000,
        left: `${sessionSelection.positionX}px`,
        boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.08), 0px 0px 5px rgba(0, 0, 0, 0.08)',
        bgcolor: (theme) => theme.palette.background.paper,
        px: 2,
        py: 1,
        borderRadius: 3,
      }}
    >
      <Typography variant="text3" color="text.secondary" component="div">
        {`start: ${toLocaleDateTime(selectedSession?.sessionStart)}`}
      </Typography>
      <Typography variant="text3" color="text.secondary" component="div">
        {`end: ${toLocaleDateTime(selectedSession?.sessionEnd)}`}
      </Typography>
      {!selectedSession?.sessionEnd && (
        <Typography variant="text3" color="text.secondary" component="div">
          {`Last timestamp: ${toLocaleDateTime(selectedSession?.statusLastUpdated)}`}
        </Typography>
      )}
      <Typography
        variant="text3"
        color="text.secondary"
        component="div"
      >{`location: ${selectedSession?.localization}`}</Typography>
      <Typography
        variant="text3"
        color="text.secondary"
        component="div"
      >{`Data input: ${selectedSession?.cumulativeInputUsage?.toFixed(2)} KB`}</Typography>
      <Typography
        variant="text3"
        color="text.secondary"
        component="div"
      >{`Data output: ${selectedSession?.cumulativeOutputUsage?.toFixed(2)} KB`}</Typography>
      <Typography
        variant="text3"
        color="text.secondary"
        component="div"
      >{`Input throughput: ${selectedSession?.inputThroughtput.toFixed(2)} KB/s`}</Typography>
      <Typography
        variant="text3"
        color="text.secondary"
        component="div"
      >{`Output throughput: ${selectedSession?.outputThroughtput.toFixed(2)} KB/s`}</Typography>
    </Box>
  ) : null;
};
