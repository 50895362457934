import { Theme } from '@mui/material/styles';
import { SystemStyleObject } from '@mui/system';
import { fillParentHeight, noBottomBorder, topBorder } from './utility.styles';

export const tableRowsWithTopBorder: SystemStyleObject<Theme> = {
  '& .MuiTableCell-root': {
    ...noBottomBorder,
    ...topBorder
  }
};

export const dialogPaper: SystemStyleObject<Theme> = {
  ...fillParentHeight
};
