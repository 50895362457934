import { palette } from 'theme/palette';
import { Theme } from '@mui/material/styles';
import { SystemStyleObject } from '@mui/system';
import {
  paddingBottom2,
  paddingBottom4,
  paddingHorizontal12,
  paddingTop3,
  paddingTop4,
  paddingTop6,
  removeMarginsAndPaddings,
} from 'theme/utility/utility.styles';

export const standardButton = {
  '&:hover': {
    backgroundColor: palette.grey[200],
    color: palette.text.primary,
  },
};

export const buttonStyle = {
  fontSize: '14px',
  padding: '10px 20px',
};

export const modalStyle = {
  width: '100%',
  maxWidth: '1246px',
  border: '1px solid #e5e5e5',
  backgroundColor: 'white',
  borderRadius: '12px',
  padding: '36px 16px 16px 64px'
};

export const closeBtnStyle = {
  position: 'absolute',
  top: '12px',
  right: '16px',
  padding: '0'
};

export const sectionWrapper = {
  display: 'flex',
  flexDirection: 'row',
  gap: '64px',
  marginRight: '63px',
  marginTop: '36px'
};

export const columnStyle = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%'
};

export const searchRow = {
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'end',
  alignItems: 'center',
  marginBottom: '16px',
  '&:last-child': {
    marginBottom: '0'
  }
};

export const searchRowSelect = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center'
};

export const searchBox = {
  marginBottom: '32px',
  '&:last-child': {
    marginBottom: '0'
  }
};

export const searchColumn = {
  borderLeft: `1px solid ${palette.text.secondary}`,
  marginLeft: '-10px',
};

export const buttonsWrapper = {
  gap: '30px',
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: '64px'
};

export const dialogTitle: SystemStyleObject<Theme> = {
  ...paddingTop4,
  ...paddingBottom2,
  ...paddingHorizontal12,
};

export const dialogContent: SystemStyleObject<Theme> = {
  ...removeMarginsAndPaddings,
};

export const detailsGrid: SystemStyleObject<Theme> = {
  ...paddingHorizontal12,
  ...paddingTop6,
};

export const dialogActions: SystemStyleObject<Theme> = {
  ...paddingBottom4,
  ...paddingTop3,
};
