import { authFetch } from 'auth/utils';
import { normilizeAssignedTagsToSim } from 'tags/utils';
import { fetchAssignedTagsToSimProducts, fetchAssignedTagToSims } from 'tags/services/tagsSimApi';

import { SimInventorySearchParamsV2, SimInventorySort } from 'simInventory/models/SimProfile.model';
import { preparePaginatedSimInventorySearchBody } from 'simInventory/utils/prepareSimInventorySearchBody';
import {
  ErrorResponse,
  SimInventoryResponsePagination,
  SimProfileDTO,
} from 'simInventory/SimInventoryApi/simInventoryApi.interface';
import { mapSimProfileDTO } from 'simInventory/SimInventoryApi/simInventoryApi.utils';
import { ErrorCodes } from '../../../common/errorCodes';
import { emptySimProfile } from '../../../simInventory/SimInventoryApi/simInventoryApi';
import { AssignedTagToSimDTO } from '../../../tags/services/tagsApi.types';

export const fetchSimProducts = async (
  page: number,
  entryPerPage: number,
  searchParams: SimInventorySearchParamsV2,
  sort: SimInventorySort | null = null,
  tagsMigratedToProductsView: boolean,
  signal?: AbortSignal,
) => {
  const body = preparePaginatedSimInventorySearchBody(page, entryPerPage, searchParams, sort);

  const simProductsResponse = await authFetch(`${process.env.REACT_APP_SIM_PRODUCTS_API}`, {
    signal,
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const simProducts: SimProfileDTO[] = await simProductsResponse.json();

  let tags: AssignedTagToSimDTO[];
  if (!tagsMigratedToProductsView) {
    tags = await fetchAssignedTagToSims({
      iccids: simProducts.map((sim) => sim.iccid),
    });
  } else {
    tags = await fetchAssignedTagsToSimProducts({
      iccids: simProducts.map((sim) => sim.iccid),
    });
  }
  const normalizedTagsData = normilizeAssignedTagsToSim(tags);

  return {
    items: simProducts.map((item) => mapSimProfileDTO(item, { normalizedTagsData })),
  };
};

export const fetchSimProductsPagination = async (
  page: number,
  entryPerPage: number,
  searchParams: SimInventorySearchParamsV2,
  signal?: AbortSignal,
) => {
  const body = preparePaginatedSimInventorySearchBody(page, entryPerPage, searchParams);

  const simProductsPaginationResponse = await authFetch(
    `${process.env.REACT_APP_SIM_PRODUCTS_API}/pagination`,
    {
      signal,
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );

  const simInventoryPaginationResponseData: SimInventoryResponsePagination =
    await simProductsPaginationResponse.json();

  return {
    totalNumberOfItems:
      simInventoryPaginationResponseData.totalCount !== undefined
        ? simInventoryPaginationResponseData.totalCount
        : -1,
  };
};

export const fetchSimProductEntry = async (
  iccid: string,
  returnEmptyOnError?: boolean,
  signal?: AbortSignal,
) => {
  const simInventoryEntryResponse = await authFetch(
    `${process.env.REACT_APP_SIM_INVENTORY_SIMS_PROFILE_API}/${iccid}`,
    { signal },
  );

  if (!simInventoryEntryResponse.ok) {
    const status = simInventoryEntryResponse.status.toString();

    if (returnEmptyOnError) {
      return emptySimProfile();
    }

    let errorMessage: ErrorResponse;
    try {
      errorMessage = await simInventoryEntryResponse.json();
    } catch (_err) {
      throw new Error(status);
    }

    const iccidNotFoundErrors = errorMessage.validationErrors.Iccid?.find(
      (error) => error.code === 'IccidValidator',
    );

    if (status === ErrorCodes.BadRequest && iccidNotFoundErrors) {
      throw new Error(ErrorCodes.NotFound);
    }

    throw new Error(status);
  }

  const simInventoryItem: SimProfileDTO = await simInventoryEntryResponse.json();

  const tags = await fetchAssignedTagsToSimProducts({ iccids: [iccid] });
  const normalizedTagsData = normilizeAssignedTagsToSim(tags);
  return mapSimProfileDTO(simInventoryItem, { normalizedTagsData });
};
