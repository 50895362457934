import Tab from '@mui/material/Tab';
import { tabStyle } from 'common/components/AppLayout/AppLayout.styles';
import { usePreviousPath } from 'common/components/AppLayout/AppNavigation/usePreviousPath';
import { TabAppLayout } from 'common/components/AppLayout/TabAppLayout';

import { useHasFeatureFlag } from 'featureFlags/useHasFeatureFlag';
import { Link, useParams } from 'react-router-dom';
import { Routes } from 'Routes.constants';

import { Actions, Subjects } from 'permissions/ability';
import { useAbility } from 'permissions/hooks/useAbility';
import { FEATURE_FLAGS_MODULES } from '../../../featureFlags/FeatureFlags.models';
import { useTranslation } from 'react-i18next';

export const SimProductsTabsLayout = () => {
  const { iccid } = useParams();
  const { t } = useTranslation();
  const previousPath = usePreviousPath();
  const sessionHistoryEnabled = useHasFeatureFlag('SimCardSessionsHistory');

  const ability = useAbility();

  const auditTrailEnabled =
    useHasFeatureFlag('AuditTrailsApiEndpoints', FEATURE_FLAGS_MODULES.AUDIT_TRAIL) &&
    ability.can(Actions.read, Subjects.auditTrails);

  return (
    <TabAppLayout>
      <Tab
        value={`${Routes.productsSim}/${iccid}`}
        label={t('products.tabs.general')}
        component={Link}
        to={`${Routes.productsSim}/${iccid}`}
        state={{ previousPath }}
        sx={tabStyle}
        replace
      />

      {sessionHistoryEnabled ? (
        <Tab
          value={`${Routes.productsSim}/${iccid}/history`}
          label={t('products.tabs.history')}
          component={Link}
          to={`${Routes.productsSim}/${iccid}/history`}
          state={{ previousPath }}
          sx={tabStyle}
          replace
        />
      ) : null}
      {sessionHistoryEnabled ? (
        <Tab
          value={`${Routes.productsSim}/${iccid}/history-analysis`}
          label={t('products.tabs.historyAnalysis')}
          component={Link}
          to={`${Routes.productsSim}/${iccid}/history-analysis`}
          state={{ previousPath }}
          sx={tabStyle}
          replace
        />
      ) : null}
      {auditTrailEnabled ? (
        <Tab
          value={`${Routes.productsSim}/${iccid}/audit-trail`}
          label={t('products.tabs.auditTrail')}
          component={Link}
          to={`${Routes.productsSim}/${iccid}/audit-trail`}
          state={{ previousPath }}
          sx={tabStyle}
          replace
        />
      ) : null}
    </TabAppLayout>
  );
};
