import React, { useEffect, useState } from 'react';
import { useRSimHistorySessionGraphData } from './useHistorySessionGraphData';
import { SessionHistoryTimeline } from 'common/components/SessionHistory/services/SessionHistoryApi';
import { GetElementType } from 'common/tsHelpers';
import { useGraphSessionRange } from 'common/components/Graphs/Sessions/hooks/useGraphSessionRange';

type SessionHistoryProviderProps = {
  primaryIccid: string;
  secondaryIccid: string;
  primaryImsi: string;
  secondaryImsi: string;
  children: React.ReactNode;
};

const ENTRY_PER_PAGE = 25;

type SessionSelection = {
  index: number | null;
  positionX: number | null;
  setName: 'Primary' | 'Backup' | null;
};

type SessionToDisplaySelection = {
  index: number | null;
  setName: 'Primary' | 'Backup' | null;
};

const DEFAULT_SESSION_SELECTION: SessionSelection = {
  index: null,
  positionX: 0,
  setName: 'Primary'
};

export const SessionHistoryContext = React.createContext<{
  error: boolean;
  loading: boolean;
  primaryIccid: string;
  secondaryIccid: string;
  primarySessionHistory: SessionHistoryTimeline;
  backupSessionHistory: SessionHistoryTimeline;
  selectedSession: GetElementType<SessionHistoryTimeline> | null;
  sessionSelection: SessionSelection;
  selectSession?: (sessionSelection: SessionSelection) => void;
  sessionToDisplaySelection: SessionToDisplaySelection;
  sessionToDisplay: GetElementType<SessionHistoryTimeline> | null;
  displaySession?: (sessionSelection: SessionToDisplaySelection) => void;
  refetch: () => void;
}>({
  error: false,
  loading: true,
  primarySessionHistory: [],
  backupSessionHistory: [],
  selectedSession: null,
  sessionSelection: DEFAULT_SESSION_SELECTION,
  refetch: () => {},
  sessionToDisplaySelection: { ...DEFAULT_SESSION_SELECTION },
  sessionToDisplay: null,
  primaryIccid: '',
  secondaryIccid: ''
});

export const SessionHistoryProvider: React.FC<SessionHistoryProviderProps> = ({
  children,
  primaryIccid,
  secondaryIccid,
  primaryImsi,
  secondaryImsi
}) => {
  const { selectedRange } = useGraphSessionRange();

  const {
    primary,
    hasPrimaryNextPage,
    backup,
    hasBackupNextPage,
    isFetchingBackup,
    isFetchingPrimary,
    error,
    refetch
  } = useRSimHistorySessionGraphData({
    primaryImsi,
    secondaryImsi,
    from: selectedRange.from,
    to: selectedRange.to,
    entryPerPage: ENTRY_PER_PAGE
  });

  const [praimaryDeferredSessionHistory, setPraimaryDeferredSessionHistory] = useState(primary);

  const [backupDeferredSessionHistory, setBackupDeferredSessionHistory] = useState(backup);

  useEffect(() => {
    if (!hasPrimaryNextPage && !isFetchingPrimary) {
      setPraimaryDeferredSessionHistory(primary);
    }
  }, [hasPrimaryNextPage, isFetchingPrimary]);

  useEffect(() => {
    if (!hasBackupNextPage && !isFetchingBackup) {
      setBackupDeferredSessionHistory(backup);
    }
  }, [hasBackupNextPage, isFetchingBackup]);

  const [sessionSelection, setSessionSelection] =
    useState<SessionSelection>(DEFAULT_SESSION_SELECTION);

  const [sessionToDisplaySelection, setSessionToDisplaySelection] = useState<
    SessionToDisplaySelection & { id: string }
  >({ ...DEFAULT_SESSION_SELECTION, id: '' });

  let session = null;
  if (sessionSelection.index !== null) {
    const sessions =
      sessionSelection.setName === 'Primary'
        ? praimaryDeferredSessionHistory
        : backupDeferredSessionHistory;

    session = sessions[sessionSelection.index];
  }

  let sessionToDisplay = null;
  if (sessionToDisplaySelection.id !== '') {
    const sessions =
      sessionToDisplaySelection.setName === 'Primary'
        ? praimaryDeferredSessionHistory
        : backupDeferredSessionHistory;

    sessionToDisplay =
      sessions.find((entry) => entry.acctSessionId === sessionToDisplaySelection.id) || null;
  }

  const displaySession = (sessionSelectData: SessionToDisplaySelection) => {
    if (sessionSelectData.index !== null) {
      const sessions =
        sessionSelectData.setName === 'Primary'
          ? praimaryDeferredSessionHistory
          : backupDeferredSessionHistory;

      const sessionToDisplay = sessions[sessionSelectData.index];

      setSessionToDisplaySelection({
        ...sessionSelectData,
        id: sessionToDisplay.acctSessionId || ''
      });
    }
  };

  return (
    <SessionHistoryContext.Provider
      value={{
        loading: hasPrimaryNextPage || hasBackupNextPage || isFetchingBackup || isFetchingPrimary,
        primarySessionHistory: praimaryDeferredSessionHistory,
        backupSessionHistory: backupDeferredSessionHistory,
        selectedSession: session,
        sessionSelection,
        selectSession: setSessionSelection,
        error: error,
        refetch,
        displaySession: displaySession,
        sessionToDisplay,
        sessionToDisplaySelection: sessionToDisplaySelection,
        primaryIccid,
        secondaryIccid
      }}
    >
      {children}
    </SessionHistoryContext.Provider>
  );
};
