import { useFilterToUrlParser } from 'common/components/Filters/hooks/useFilterToUrlParser';
import { simProductsFiltersAtom } from 'atoms/filters/sim-products';
import { ContentBox } from 'common/components/AppLayout/ContentBox';
import { TableSkeleton } from 'common/components/Table/TableSkeleton';
import { SuspenseLoadingView } from 'common/components/SuspenseLoadingView';
import { TagProvider } from 'tags/TagProvider';
import { TableWithFiltersLayout } from 'common/components/AppLayout/TableWithFiltersLayout';
import { SimProductsTable } from './Components/SimProductsTable';
import { SimProductsFilters } from './Filters/SimProductsFilters';
import { SimProductsConfigurableKey } from './utils/simProducts';
import { useCustomFieldsFiltersInitialization } from './Filters/hooks/useCustomFieldsFiltersInit';

import { mapSimFiltersToURLSearchParams } from 'simInventory/Filters/utils/filtersToURLSearcParamsMapper';

export const SimProductsTab = () => {
  useCustomFieldsFiltersInitialization();
  useFilterToUrlParser({
    atom: simProductsFiltersAtom,
    mapperFunction: mapSimFiltersToURLSearchParams,
  });

  return (
    <TagProvider type="sim" isProduct={true}>
      <TableWithFiltersLayout filters={<SimProductsFilters />}>
        <SuspenseLoadingView
          loadFallback={
            <ContentBox>
              <TableSkeleton showStartSkeleton showEndSkeleton numberOfRows={20} />
            </ContentBox>
          }
          queryKey={[SimProductsConfigurableKey]}
        >
          <SimProductsTable />
        </SuspenseLoadingView>
      </TableWithFiltersLayout>
    </TagProvider>
  );
};
