import { useContext, useMemo } from 'react';
import Box from '@mui/material/Box';
import { mapInterimsTimelineToLocationGraphData } from './utils/interimUtils';
import { ZOOM_VALUES_IN_MINUTES } from './utils/range';
import { ChangingInTimeGraph } from '../common/Graphs/ChangingInTimeGraph';
import { InterimsTimelineContext } from './InterimsTimelineProvider';
import { FloatingGraphBox } from '../common/Graphs/components/FloatingGraphBox';
import CircularProgress from '@mui/material/CircularProgress';
import { useInterimsTimeRange } from './hooks/useInterimsTimeRange';

export const InterimLocationGraph = () => {
  const { selectedRange, availableRange, moveVisibleRangeTo } = useInterimsTimeRange();

  const {
    fetchingInterims,
    selectStartInterim,
    unselectInterim,
    markSelectedInterimAsDefault,
    startInterim,
    interimsTimeline,
    startInterimMarked
  } = useContext(InterimsTimelineContext);

  const locationDataset = useMemo(() => {
    return mapInterimsTimelineToLocationGraphData(interimsTimeline);
  }, [interimsTimeline]);

  const isStartMarkerSameValue =
    !!startInterim && startInterim?.localization === locationDataset?.at(-1)?.label;

  return (
    <Box sx={{ position: 'relative', height: 83 }}>
      <ChangingInTimeGraph
        highlightStartMarker={startInterimMarked}
        onStartMarkerHovered={(value) => {
          if (value) {
            selectStartInterim();
          } else {
            unselectInterim();
          }
        }}
        onClick={() => {
          markSelectedInterimAsDefault();
        }}
        startMarkerValue={startInterim?.localization}
        isStartMarkerSameValue={isStartMarkerSameValue}
        startMarkerDate={startInterim?.timestamp}
        dataset={locationDataset}
        zoomBoundaryStart={availableRange.from}
        zoomBoundaryStop={availableRange.to}
        maxZoomInMS={ZOOM_VALUES_IN_MINUTES.DAY * 60 * 1000}
        selectedRangeStart={selectedRange.from}
        selectedRangeStop={selectedRange.to}
        onZoomComplete={([min, max]) => {
          moveVisibleRangeTo(min, max);
        }}
        onPan={moveVisibleRangeTo}
      />
      {fetchingInterims ? (
        <FloatingGraphBox>
          <CircularProgress />
        </FloatingGraphBox>
      ) : null}
    </Box>
  );
};
