import Link from '@mui/material/Link';
import { TypographyProps } from '@mui/material/Typography';
import { Link as RouterLink, LinkProps } from 'react-router-dom';

export type TypographyLinkProps = {
  to: LinkProps['to'];
  children: string;
  variant: TypographyProps['variant'];
};

export const TypographyLink: React.FC<TypographyLinkProps> = ({ to, children, variant }) => {
  return (
    <Link
      component={RouterLink}
      to={to}
      variant={variant}
      sx={{ textDecoration: 'none' }}
      color={'grey.700'}
    >
      {children}
    </Link>
  );
};
