import MuiDialog, { DialogProps as MuiDialogProps } from '@mui/material/Dialog';

import React from 'react';
import { DialogTitle } from './DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export type DialogProps = Pick<MuiDialogProps, 'open' | 'children'> & {
  title: string;
  subtitle?: string;
  size?: 'small' | 'medium' | 'large';
  actions?: React.ReactNode;
  onClose: () => void;
};

export const Dialog: React.FC<DialogProps> = ({
  title,
  subtitle,
  size = 'large',
  children,
  actions,
  open,
  onClose,
  ...props
}) => {
  return (
    <MuiDialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth={size === 'large' ? 'lg' : size === 'medium' ? 'md' : 'sm'}
      {...props}
    >
      <DialogTitle title={title} subtitle={subtitle} />
      <IconButton
        aria-label="close"
        onClick={() => {
          onClose();
        }}
        sx={{
          position: 'absolute',
          right: 4.5,
          top: 4.5,
          color: (theme) => theme.palette.text.secondary,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ mt: 4, py: 2.5, pl: 5, pr: 4 }}>{children}</DialogContent>
      {actions ? <DialogActions>{actions}</DialogActions> : null}
    </MuiDialog>
  );
};
