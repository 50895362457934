import { DataGridPro } from '@mui/x-data-grid-pro/DataGridPro';
import { DataGridProProps } from '@mui/x-data-grid-pro';
import { styled } from '@mui/material/styles';

type BaseGridProps = Omit<
  DataGridProProps,
  | 'hideFooter'
  | 'pagination'
  | 'disableColumnMenu'
  | 'columnBufferPx'
  | 'rowBufferPx'
  | 'sortingMode'
  | 'sortingOrder'
  | 'columnHeaderHeight'
  | 'paginationMode'
  | 'pinnedColumns'
> & {
  disablePagination?: boolean;
};

const CustomizeMuiDataGrid = styled(DataGridPro)(({ theme }) => ({
  '& .MuiDataGrid-cell': {
    borderTop: 'none',
  },

  '& .MuiDataGrid-columnHeader': {
    '&:focus': {
      outline: 'none !important',
    },
    '&:focus-within': {
      outline: 'none !important',
    },
  },
  '& .MuiDataGrid-filler--pinnedRight': {
    borderTop: 'none !important',
  },
  '& .MuiDataGrid-scrollbarFiller--borderTop': {
    borderTop: 'none !important',
  },
  '& .MuiDataGrid-detailPanel': {
    borderBottom: `1px solid ${theme.palette.border}`,
  },
  '& .MuiDataGrid-columnHeaderTitleContainerContent': {
    order: 2,
  },
  '& .MuiDataGrid-filler': {
    display: 'none',
  },
}));

export const BaseGrid = ({ disablePagination, ...props }: BaseGridProps) => {
  return (
    <CustomizeMuiDataGrid
      {...props}
      columnBufferPx={999999}
      rowBufferPx={420}
      sortingMode="server"
      sortingOrder={['desc', 'asc']}
      columnHeaderHeight={props.loading ? 0 : 42}
      hideFooter={disablePagination}
      pagination={!disablePagination}
      disableColumnMenu
      paginationMode={disablePagination ? undefined : 'server'}
      pinnedColumns={{
        right: ['actions'],
      }}
    />
  );
};
