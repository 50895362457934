import { styled } from '@mui/material';
import Box from '@mui/material/Box';

export const TabBar = styled(Box)({
  display: 'flex',
  padding: '16px',
  flexWrap: 'wrap',
});

export const TabBarFilters = styled(Box)({
  display: 'flex',
  flex: 1,
});

export const TabBarActions = styled(Box)({
  display: 'flex',
  flex: 1,
  alignItems: 'flex-end',
  justifyContent: 'flex-end',
  columnGap: '16px',
});

export const TabBarChips = styled(Box)({
  display: 'flex',
  flex: '0 0 100%',
  marginTop: '8px',
  '&:empty': {
    display: 'none',
  },
});
