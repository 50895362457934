import { useSuspenseQuery } from '@tanstack/react-query';
import { fetchSimProductsTableConfiguration } from '../../../SimProductsApi/simProductsTableConfigurationApi';

import { TABLE_VARIANT } from 'simInventory/SimInventoryApi/simInventoryTableConfigurationApi.interface';
import { SimProductsConfigurableKey } from '../../../utils/simProducts';

export const useColumnConfiguration = () => {
  const {
    data: configuration,
    refetch,
    status,
    isRefetching,
  } = useSuspenseQuery({
    queryKey: [SimProductsConfigurableKey],
    queryFn: async () => fetchSimProductsTableConfiguration(true),
    gcTime: 0,
  });

  const currentColumns =
    configuration?.enabledVariant === TABLE_VARIANT.Flat
      ? configuration.flatColumnConfigurations
      : configuration?.combinedColumnConfigurations;

  return {
    configuration,
    currentColumns,
    refetch,
    status,
    isRefetching,
  };
};
