import { Theme } from '@mui/material/styles';
import { SystemStyleObject } from '@mui/system';
import {
  fillParentHeight,
  paddingBottom14,
  paddingHorizontal16,
  paddingTop10,
  paddingVertical2,
  paddingRight4,
} from 'theme/utility/utility.styles';

export const simInventoryDetailsScrollArea: SystemStyleObject<Theme> = {
  ...paddingVertical2,
  ...fillParentHeight,
};

export const simInventoryDetails: SystemStyleObject<Theme> = {
  ...paddingHorizontal16,
  ...paddingTop10,
  ...paddingBottom14,
};

export const simInventoryDetailsRSimView: SystemStyleObject<Theme> = {
  px: 0,
  ...paddingTop10,
  ...paddingBottom14,
};

export const statusLabelIcon: SystemStyleObject<Theme> = {
  ...paddingRight4,
};
