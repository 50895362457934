import { useTranslation } from 'react-i18next';
import { provisionSim } from './api/simActionsApi';
import { ConfirmActionDialog } from 'common/components/ConfigurableTable/Components/Actions/ConfirmActionDialog';

type DeactivationDialogProps = {
  data: any;
  open: boolean;
  onClose: () => void;
};

export const DeactivationDialog = ({ data, open, onClose }: DeactivationDialogProps) => {
  const { t } = useTranslation();

  return (
    <ConfirmActionDialog
      open={open}
      data={data}
      onClose={onClose}
      submit={(data: any) => provisionSim([data.connectionId], false)}
      message={`${t('simInventory.deactivationIccid')} ${data?.iccid}`}
      title={t('simInventory.deactivation')}
    />
  );
};
