import { authFetch } from 'auth/utils';

export const provisionRsim = async (connectionIds: string[], activate: boolean) => {
  const body = {
    connectionIds,
    activate,
  };

  await authFetch(`${process.env.REACT_APP_RSIM_PRODUCTS_API}/provisioning`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
