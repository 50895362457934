import { authFetch } from 'auth/utils';
import { compareByPosition } from 'common/components/ConfigurableTable/utils/ColumnConfiguration.util';
import { lowercaseFirstLetter } from 'common/utils/stringUtil';

import {
  ColumnConfiguration,
  TABLE_VARIANT,
  TableConfiguration,
} from 'simInventory/SimInventoryApi/simInventoryTableConfigurationApi.interface';

export const fetchSimProductsTableConfiguration = async (
  isFeatureEnabled: boolean,
  signal?: AbortSignal,
): Promise<TableConfiguration> => {
  if (!isFeatureEnabled) {
    return {
      enabledVariant: TABLE_VARIANT.Combined,
      flatColumnConfigurations: [],
      combinedColumnConfigurations: [],
    };
  }

  const simInventoryTableConfigurationResponse = await authFetch(
    `${process.env.REACT_APP_SIM_PRODUCTS_API}/columns-configurations`,
    { signal },
  );
  const configuration: TableConfiguration = await simInventoryTableConfigurationResponse.json();

  const sortedFlatConfiguration = configuration.flatColumnConfigurations.sort(compareByPosition);
  const sortedCombinedConfiguration =
    configuration.combinedColumnConfigurations.sort(compareByPosition);

  return {
    enabledVariant: configuration.enabledVariant,
    flatColumnConfigurations: sortedFlatConfiguration.map((config) =>
      mapConfigurationToLowercase(config),
    ),
    combinedColumnConfigurations: sortedCombinedConfiguration.map((config) =>
      mapConfigurationToLowercase(config),
    ),
  };
};

function mapConfigurationToLowercase(column: ColumnConfiguration): ColumnConfiguration {
  return {
    ...column,
    name: lowercaseFirstLetter(column.name),
    children: column.children?.map((child) => mapConfigurationToLowercase(child)) || [],
  };
}
