export enum Routes {
  provisioningRequest = '/provisioning-requests',
  simInventory = '/sim-inventory',
  rSimInventory = '/rsim-inventory',
  admin = '/admin',
  customFields = '/admin/custom-fields',
  rsimManufacturingFile = '/admin/rsim-manufacturing-file',
  auditTrail = '/audit-trail',
  products = '/products',
  productsSim = '/products/sim',
  productsRsim = '/products/rsim',
}
