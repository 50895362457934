import { iccidRangeFilterAtom, searchTextFilterAtom } from 'atoms/filters/sim';
import { IdRangeSearch } from 'common/components/Inputs/IdRangeSearch/IdRangeSearch';
import { useAtom, useSetAtom } from 'jotai';
import { useCallback } from 'react';

export const IccidRangeSearch = () => {
  const [iccidRange, setIccidRange] = useAtom(iccidRangeFilterAtom);

  const setSearchText = useSetAtom(searchTextFilterAtom);

  const onChange = useCallback((from: string, to: string) => {
    setIccidRange({ iccidFrom: from, iccidTo: to });
    if (from !== '' || to !== '') {
      setSearchText('');
    }
  }, []);

  return (
    <IdRangeSearch
      data-testid="iccid range search"
      from={iccidRange.iccidFrom}
      to={iccidRange.iccidTo}
      onChange={onChange}
    />
  );
};
