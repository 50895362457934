import { differenceInMilliseconds, parseISO } from 'date-fns';
import { authFetch } from 'auth/utils';
import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';
import { getFinalizationDate, getSessionType, mapSessionDTO } from '../utils/sessions';
import { ValuesType } from 'utility-types';
import { ObjectValues } from 'common/tsHelpers';
import { SessionHistoryDTO, SessionHistorySchemaDTO } from '../models/Session';

type SessionHistoryQueryParams = {
  toDate?: Date;
  fromDate?: Date;
  pageSize?: number;
};

export const getSessionHistory = async (
  imsi: string,
  queryParams: SessionHistoryQueryParams,
  isRsim: boolean = false,
  signal?: AbortSignal,
) => {
  const urlQuery = new URLSearchParams({
    ...(isRsim ? { Euicc: imsi } : {}),
    ...(!isRsim ? { Imsi: imsi } : {}),
  });

  const { fromDate, toDate, pageSize } = queryParams;
  if (fromDate) {
    urlQuery.append('FromDate', fromDate.toISOString());
  }

  if (toDate) {
    urlQuery.append('ToDate', toDate.toISOString());
  }

  if (pageSize) {
    urlQuery.append('PageSize', pageSize.toString());
  }

  const sessionHistoryResponse = await authFetch(
    `${
      isRsim
        ? process.env.REACT_APP_SIM_INVENTORY_RSIMS_PROFILE_API
        : process.env.REACT_APP_SIM_INVENTORY_SIMS_PROFILE_API
    }/sessions/history?${urlQuery.toString()}`,
    { signal },
  );

  if (!sessionHistoryResponse.ok) {
    throw new Error(sessionHistoryResponse.status.toString());
  }

  try {
    const sessionHistoryResponseData: SessionHistoryDTO = SessionHistorySchemaDTO.parse(
      await sessionHistoryResponse.json(),
    );

    return mapSessionDTO(sessionHistoryResponseData);
  } catch (err) {
    if (err instanceof z.ZodError) {
      console.warn(err.issues);
      return [];
    }

    return [];
  }
};

export type SessionHistoryEntry = ValuesType<Awaited<ReturnType<typeof getSessionHistory>>>;

export const fetchSessionHistory = async (
  imsi: string,
  toDate?: Date,
  isRsim: boolean = false,
  signal?: AbortSignal,
) => {
  return getSessionHistory(
    imsi,
    {
      toDate: toDate || new Date(),
      pageSize: 20,
    },
    isRsim,
    signal,
  );
};

export const fetchSessionHistoryInterval = async (
  imsi: string,
  fromDate: Date,
  toDate: Date,
  pageSize: number,
  signal?: AbortSignal,
) => {
  return getSessionHistory(
    imsi,
    {
      toDate: toDate || new Date(),
      fromDate: fromDate || Date.now(),
      pageSize: pageSize,
    },
    false,
    signal,
  );
};

export const getSessionHistoryTimeline = (sessions: SessionHistoryEntry[], maxEndDate: Date) => {
  return sessions.map((session, index, sessions) => {
    const nextSession = index > 0 ? sessions[index - 1] : null;
    const finalizationDate = getFinalizationDate(session, nextSession, maxEndDate);
    const durationInSeconds = differenceInMilliseconds(finalizationDate, session.sessionStart);

    return {
      ...session,
      index,
      finalizationDate,
      sessionType: getSessionType(session),
      inputThroughtput: session?.cumulativeInputUsage / durationInSeconds,
      outputThroughtput: session?.cumulativeOutputUsage / durationInSeconds,
    };
  });
};

export type SessionHistoryTimeline = ReturnType<typeof getSessionHistoryTimeline>;

export const LAST_RADIUS_TYPE = {
  STOP: 'Stop',
  UPDATE: 'Interim-Update',
  START: 'Start',
} as const;

export type LastRadiusType = ObjectValues<typeof LAST_RADIUS_TYPE>;

type InterimDTO = {
  statusLastUpdated: string;
  acctSessionId: string;
  startTime: string;
  stopTime: string;
  lastRadiusType: LastRadiusType;
  currentStatus: string;
  framedIpAddress: string;
  calledStationId: string;
  detectedImei: string;
  username: string;
  cumulativeOutputUsage: number;
  cumulativeInputUsage: number;
  localization: string;
  operatorCode: string;
  roamingCode: string;
  timeZone: string;
  rasClient: string;
  framedProtocol: string;
  nasIpAddress: string;
  serviceType: string;
  eventTimestamp: string;
  ratType: string;
  processedDate: string;
  deltaInputUsage: number;
  deltaOutputUsage: number;
};

type IterimsDTO = InterimDTO[];

export const fetchInterims = async (
  imsi: string,
  sessionId: string,
  page: number,
  pageSize: number,
  signal?: AbortSignal,
) => {
  const urlQuery = new URLSearchParams({
    Imsi: imsi,
    SessionId: sessionId,
    PageNumber: page.toString(),
    PageSize: pageSize.toString(),
  });

  const interimsResponse = await authFetch(
    `${
      process.env.REACT_APP_SIM_INVENTORY_SIMS_PROFILE_API
    }/sessions/interims?${urlQuery.toString()}`,
    { signal },
  );

  const intermis: IterimsDTO = await interimsResponse.json();

  return intermis.map((interim) => ({
    id: uuidv4(),
    timestamp: parseISO(interim.statusLastUpdated),
    cumulativeUsage: interim.cumulativeInputUsage + interim.cumulativeOutputUsage,
    usage: interim.deltaInputUsage + interim.deltaOutputUsage,
    localization: interim.localization,
    radioAccess: interim.ratType,
    operatorCode: interim.operatorCode,
    inputUsage: interim.deltaInputUsage,
    outputUsage: interim.deltaOutputUsage,
    lastRadiusType: interim.lastRadiusType,
  }));
};

export const fetchInterimsToDate = async (
  imsi: string,
  sessionId: string,
  toDate: Date,
  page: number,
  pageSize: number,
  signal?: AbortSignal,
) => {
  const urlQuery = new URLSearchParams({
    Imsi: imsi,
    SessionId: sessionId,
    PageNumber: page.toString(),
    PageSize: pageSize.toString(),
  });

  const interimsResponse = await authFetch(
    `${
      process.env.REACT_APP_SIM_INVENTORY_SIMS_PROFILE_API
    }/sessions/interims?${urlQuery.toString()}`,
    { signal },
  );

  const intermis: IterimsDTO = await interimsResponse.json();

  return intermis.map((interim) => ({
    id: uuidv4(),
    timestamp: parseISO(interim.statusLastUpdated),
    usage: interim.deltaInputUsage + interim.deltaOutputUsage,
    cumulativeUsage: interim.cumulativeInputUsage + interim.cumulativeOutputUsage,
    localization: interim.localization,
    radioAccess: interim.ratType,
    operatorCode: interim.operatorCode,
    lastRadiusType: interim.lastRadiusType,
    inputUsage: interim.deltaInputUsage,
    outputUsage: interim.deltaOutputUsage,
  }));
};

export type Interims = Awaited<ReturnType<typeof fetchInterimsToDate>>;

export type Interim = ValuesType<Interims>;

export type InterimsTimelineEntry = Interim & { start: Date; stop: Date };

export const getInterimsTimeline = (interims: Interims, sessionStart: Date) => {
  const interimsCount = interims.length;

  const interimsTimeline = interims.reduce((timeline, interim, index, interims) => {
    const previousInterim = index + 1 < interimsCount ? interims[index + 1] : null;

    if (!previousInterim && interim.lastRadiusType === LAST_RADIUS_TYPE.START) {
      return timeline;
    }

    timeline.push({
      ...interim,
      start: previousInterim?.timestamp || sessionStart,
      stop: interim.timestamp,
    });
    return timeline;
  }, [] as InterimsTimelineEntry[]);

  return interimsTimeline;
};
