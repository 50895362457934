import { ObjectValues } from 'common/tsHelpers';
import { z } from 'zod';

export const ACTIVATION_ACTIONS = {
  ACTIVATE: 'activate',
  DEACTIVATE: 'deactivate',
  NONE: '',
};

export const activationActionSchema = z.union([
  z.literal(ACTIVATION_ACTIONS.ACTIVATE),
  z.literal(ACTIVATION_ACTIONS.DEACTIVATE),
  z.literal(ACTIVATION_ACTIONS.NONE),
]);

export type ActivationAction = z.infer<typeof activationActionSchema> | null;

export const SIMULATED_OUTAGE_STATUS = {
  NONE: 0,
  PRIMARY: 1,
  SECONDARY: 2,
  BOTH: 3,
} as const;

export const RSIM_PROFILE_IDS = {
  ICCID: 'ICCID',
  EID: 'EID',
} as const;

export const NUMERIC_RSIM_PROFILE_IDS = {
  ICCID: 0,
  EID: 1,
} as const;

export const rsimIdSchema = z.union([
  z.literal(RSIM_PROFILE_IDS.ICCID),
  z.literal(RSIM_PROFILE_IDS.EID),
]);

export const rsimNumericIdSchema = z.union([
  z.literal(NUMERIC_RSIM_PROFILE_IDS.ICCID),
  z.literal(NUMERIC_RSIM_PROFILE_IDS.EID),
]);

export const simulatedOutageSchema = z.union([
  z.literal(SIMULATED_OUTAGE_STATUS.NONE),
  z.literal(SIMULATED_OUTAGE_STATUS.PRIMARY),
  z.literal(SIMULATED_OUTAGE_STATUS.SECONDARY),
  z.literal(SIMULATED_OUTAGE_STATUS.BOTH),
]);

export const SIMULATED_OUTAGE_STATE = {
  ANY: 'ANY',
  ON: 'ON',
  OFF: 'OFF',
} as const;

export const simulatedOutageStateSchema = z.union([
  z.literal(SIMULATED_OUTAGE_STATE.ANY),
  z.literal(SIMULATED_OUTAGE_STATE.ON),
  z.literal(SIMULATED_OUTAGE_STATE.OFF),
]);

export const ACTIVE_PROFILE = {
  UNKNOWN: 0,
  PRIMARY: 1,
  SECONDARY: 2,
};

export const activeProfileSchema = z.union([
  z.literal(ACTIVE_PROFILE.UNKNOWN),
  z.literal(ACTIVE_PROFILE.PRIMARY),
  z.literal(ACTIVE_PROFILE.SECONDARY),
]);

export const ACTIVE_PROFILE_FILTER = {
  PRIMARY: 'PRIMARY',
  SECONDARY: 'SECONDARY',
  ANY: 'ANY',
} as const;

export const activeProfileFilterSchema = z.union([
  z.literal(ACTIVE_PROFILE_FILTER.ANY),
  z.literal(ACTIVE_PROFILE_FILTER.PRIMARY),
  z.literal(ACTIVE_PROFILE_FILTER.SECONDARY),
]);

export const LAST_UPDATE_TIME = {
  MINUTES_15: 'MINUTES_15',
  MINUTES_30: 'MINUTES_30',
  HOUR: 'HOUR',
  DAY: 'DAY',
  WEEK: 'WEEK',
  MONTH: 'MONTH',
} as const;

export const LAST_UPDATE_TIME_FILTERS = {
  ...LAST_UPDATE_TIME,
  NONE: 'NONE',
} as const;

export const lastUpdateFilterSchema = z.union([
  z.literal(LAST_UPDATE_TIME_FILTERS.MINUTES_15),
  z.literal(LAST_UPDATE_TIME_FILTERS.MINUTES_30),
  z.literal(LAST_UPDATE_TIME_FILTERS.HOUR),
  z.literal(LAST_UPDATE_TIME_FILTERS.DAY),
  z.literal(LAST_UPDATE_TIME_FILTERS.WEEK),
  z.literal(LAST_UPDATE_TIME_FILTERS.MONTH),
  z.literal(LAST_UPDATE_TIME_FILTERS.NONE),
]);

export const rsimInventoryExtededBodyFiltersSchema = z.object({
  pageSize: z.optional(z.number()),
  pageNumber: z.optional(z.number()),
  orderNumber: z.optional(z.string()),
  connectionId: z.optional(z.string()),
  tags: z.optional(z.array(z.string())),
  rsimProfileIdsFilter: z.optional(
    z.object({
      type: rsimNumericIdSchema,
      ids: z.array(z.string()),
    }),
  ),
  iccid: z.optional(z.string()),
  euicc: z.optional(z.string()),
  simulatedOutageStatuses: z.optional(z.array(simulatedOutageSchema)),
  lastActiveProfile: z.optional(activeProfileSchema),
  noPollingFrom: z.optional(z.string()),
  accountRef: z.optional(z.array(z.number())),
  eidFrom: z.optional(z.string()),
  eidTo: z.optional(z.string()),
  rsimProfileIdsRange: z.optional(
    z.object({
      type: rsimNumericIdSchema,
      idsFrom: z.string(),
      idsTo: z.string(),
    }),
  ),
  order: z.optional(z.string()),
});

export const rsimInventoryFiltersSchema = z.object({
  searchIds: z.array(z.string()),
  chosenIdType: rsimIdSchema,
  orderNumber: z.string(),
  connectionId: z.string(),
  simulateOutage: simulatedOutageStateSchema,
  lastActiveProfile: activeProfileFilterSchema,
  tags: z.array(z.string()),
  accounts: z.array(z.string()),
  lastUpdateTime: z.optional(lastUpdateFilterSchema),
  eidFrom: z.optional(z.string()),
  eidTo: z.optional(z.string()),
});

export type RSimInventoryFilters = z.infer<typeof rsimInventoryFiltersSchema>;

export type RSimInventoryExtededFilterBody = z.infer<typeof rsimInventoryExtededBodyFiltersSchema>;

export type RSimProfileId = ObjectValues<typeof RSIM_PROFILE_IDS>;

export type RSimInventoryExtededFilterBodyIdsProps = keyof Pick<
  RSimInventoryExtededFilterBody,
  'euicc' | 'iccid'
>;

export type RSimSort = z.infer<typeof sortSchema>;

export const sortingSchema = z.union([z.literal('asc'), z.literal('desc'), z.literal(null)]);

export const sortSchema = z.object({ columnName: z.string(), sort: sortingSchema });
