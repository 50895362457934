export enum ClaimType {
  AuthTime = 'auth_time',
  Jti = 'jti',
  NameIdentifier = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier',
  Id = 'typ',
  SessionState = 'session_state',
  AuthnClassReference = 'http://schemas.microsoft.com/claims/authnclassreference',
  Sid = 'sid',
  Role = 'cmp_roles',
  PreferredUsername = 'preferred_username',
  GivenName = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname',
  Surname = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname',
  EmailAddress = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress',
  AccountReferenceId = 'AccountReferenceId',
}

export const userRoles = {
  admin: 'admin',
  user: 'user',
} as const;

export const newUserRoles = [
  'CSL Admin',
  'CSL Tech',
  'CSL User',
  'Customer Admin',
  'Customer Tech',
  'Customer User',
];
