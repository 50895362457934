import { addMinutes, differenceInMilliseconds, isBefore } from 'date-fns';
import { SessionHistoryEntry } from '../services/SessionHistoryApi';
import { SESSION_TYPE, SessionType } from '../hooks/HistoryAnalysis.types';
import { SessionHistoryDTO } from '../models/Session';
import { v4 as uuidv4 } from 'uuid';
import { isNil } from 'lodash';

type SessionHistoryEntryDates = Pick<
  SessionHistoryEntry,
  'sessionStart' | 'sessionEnd' | 'statusLastUpdated'
>;

export const getFinalizationDate = (
  currentSession: SessionHistoryEntryDates,
  nextSession: SessionHistoryEntryDates | null = null,
  maxEndDate: Date | null = null,
): Date => {
  if (currentSession.sessionEnd) {
    return currentSession.sessionEnd;
  }

  let finalizationDate = null;

  if (!currentSession.sessionEnd && !currentSession.statusLastUpdated) {
    finalizationDate = addMinutes(currentSession.sessionStart, 15);
  } else if (!currentSession.sessionEnd) {
    finalizationDate = addMinutes(currentSession.statusLastUpdated!, 15);
  }

  if (nextSession && isBefore(nextSession.sessionStart, finalizationDate!)) {
    return nextSession.sessionStart;
  }

  if (maxEndDate) {
    finalizationDate = new Date(Math.min(finalizationDate!.getTime(), maxEndDate.getTime()));
  }

  return finalizationDate!;
};

export const getSessionType = (
  session: Pick<
    SessionHistoryEntry,
    'sessionEnd' | 'cumulativeInputUsage' | 'cumulativeOutputUsage'
  >,
) => {
  let type: SessionType = SESSION_TYPE.NORMAL;
  const sessionEnd = session.sessionEnd;

  if (sessionEnd === null) {
    type = SESSION_TYPE.NOT_FINALIZED;
  }

  if (session.cumulativeInputUsage === 0 && session.cumulativeOutputUsage === 0) {
    type = SESSION_TYPE.ZERO_BYTE;
  }

  return type;
};

export const calculateCumulativeUsage = (
  cumulativeInputUsage: number | null | undefined,
  cumulativeOutputUsage: number | null | undefined,
) => {
  if (isNil(cumulativeInputUsage) || isNil(cumulativeOutputUsage)) {
    return null;
  }

  return cumulativeInputUsage + cumulativeOutputUsage;
};

export const mapSessionDTO = (sessionDTO: SessionHistoryDTO) => {
  return sessionDTO.entries.map((sessionHistoryResponseDataEntry) => {
    const { startTime, endTime, ...other } = sessionHistoryResponseDataEntry;

    const cumulativeUsage = calculateCumulativeUsage(
      sessionHistoryResponseDataEntry.cumulativeInputUsage,
      sessionHistoryResponseDataEntry.cumulativeOutputUsage,
    );

    const duration =
      sessionHistoryResponseDataEntry.startTime && sessionHistoryResponseDataEntry.endTime
        ? differenceInMilliseconds(
            sessionHistoryResponseDataEntry.endTime,
            sessionHistoryResponseDataEntry.startTime,
          )
        : 0;

    return {
      ...other,
      id: uuidv4(),
      sessionStart: sessionHistoryResponseDataEntry.startTime,
      sessionEnd: sessionHistoryResponseDataEntry.endTime,
      cumulativeInputUsage: sessionHistoryResponseDataEntry.cumulativeInputUsage || 0,
      cumulativeOutputUsage: sessionHistoryResponseDataEntry.cumulativeOutputUsage || 0,
      cumulativeUsage,
      duration,
    };
  });
};
