import Box from '@mui/material/Box';

export const LABELS_HEIGHT = 35;

export const FloatingGraphBox: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        inset: 0,
        bottom: LABELS_HEIGHT,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      {children}
    </Box>
  );
};
