import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { ReactNode } from 'react';
import { ValuesType } from 'utility-types';

type RadioSectionRootProps = {
  value: string;
  options: string[];
};

const TRANSITION_TIME_MARGIN = 700;
const TRANSITION_TIME_OPACITY = 300;
const EXPAND_MARGIN = 10;

const RadioSectionRoot = styled(Box)<RadioSectionRootProps>((props) => {
  const { options, value } = props;

  const style = options.reduce((currentStyle, option) => {
    return {
      ...currentStyle,
      height: 160,
      //when input with option hover
      [`&:has(input[value="${option}"]:hover)`]: {
        //coresponding option opacity to 1
        [`div[data-selector="${option}"]`]: {
          opacity: 1,
          transition: `opacity ${TRANSITION_TIME_OPACITY}ms`,
          pointerEvents: 'auto',
        },
        //others option opacity to 0
        [`*[data-selector]:not([data-selector="${option}"])`]: {
          opacity: 0,
        },
      },
    };
  }, {});

  return {
    display: 'flex',
    [`& *[data-selector]:not([data-selector="${value}"])`]: {
      pointerEvents: 'none',
    },
    '& [role="radiogroup"]': {
      //defualt negative margin and opacity for label
      label: {
        marginTop: `-${EXPAND_MARGIN}px`,
        paddingTop: `0px`,
        transition: `padding ${TRANSITION_TIME_MARGIN}ms`,
        opacity: 0.7,
      },
      //move on hover down (remove negative margin and add space on bottom)
      // and checkge opacity to 1
      'label:hover': {
        marginTop: `-${EXPAND_MARGIN}px`,
        paddingTop: `${EXPAND_MARGIN}px`,
        paddingBottom: `${EXPAND_MARGIN}px`,
        transition: `padding ${TRANSITION_TIME_MARGIN}ms`,
        opacity: 1,
      },
      //move down when checked (remove negative margin and add space on bottom)
      // and checkge opacity to 1
      'label:has(.Mui-checked)': {
        marginTop: `-${EXPAND_MARGIN}px`,
        paddingTop: `${EXPAND_MARGIN}px`,
        paddingBottom: `${EXPAND_MARGIN}px`,
        transition: `padding ${TRANSITION_TIME_MARGIN}ms`,
        opacity: 1,
        pointerEvents: 'none',
      },
      // move up when sibling hovered and change opacity
      'label:has(~ label:hover)': {
        marginTop: `-${EXPAND_MARGIN}px`,
        paddingTop: `0px`,
        paddingBottom: '0px',
        transition: `padding ${TRANSITION_TIME_MARGIN}ms`,
        opacity: 0.7,
      },
      // move up when sibling hovered and change opacity
      'label:hover ~ label': {
        marginTop: `-${EXPAND_MARGIN}px`,
        paddingTop: `0px`,
        paddingBottom: '0px',
        transition: `padding ${TRANSITION_TIME_MARGIN}ms`,
        opacity: 0.7,
      },
    },
    '@keyframes hide': {
      '0%': {
        opacity: 1,
      },
      '100%': {
        opacity: 0,
      },
    },

    '@keyframes show': {
      from: {
        opacity: 0,
      },
      to: {
        opacity: 1,
      },
    },
    ...style,
  };
});

const RadioSectionRadioGroup = styled(RadioGroup)({
  flexBasis: 'calc(100%/3)',
  flexGrow: 0,
  flexShrink: 0,
});

const RadioSectionChildrenBox = styled(Box)({
  flexGrow: 1,
  flexShrink: 1,
});

export type RadioSectionProps<T extends string[]> = {
  options: T;
  value: ValuesType<T>;
  onValueChange: (newValue: ValuesType<T>) => void;
  labels: Record<ValuesType<T>, string>;
  children: (option: ValuesType<T>) => ReactNode;
};

export const RadioSection = <T extends string[]>({
  labels,
  onValueChange,
  value,
  options,
  children,
}: RadioSectionProps<T>) => {
  return (
    <RadioSectionRoot options={options} value={value}>
      <RadioSectionRadioGroup
        value={value}
        onChange={(_, value) => onValueChange(value as ValuesType<T>)}
      >
        {options.map((option) => {
          return (
            <FormControlLabel
              componentsProps={{
                typography: {
                  variant: 'text2',
                  color: 'text.secondary',
                },
              }}
              value={option}
              control={<Radio />}
              label={labels[option as ValuesType<T>]}
              key={option}
            />
          );
        })}
      </RadioSectionRadioGroup>
      <RadioSectionChildrenBox>{children(value)}</RadioSectionChildrenBox>
    </RadioSectionRoot>
  );
};
