import { useHistorySessionGraphData } from 'common/components/SessionHistory/hooks/useHistorySessionGraphData';

type HistorySessionGraphDataOptions = {
  primaryImsi?: string | null;
  secondaryImsi?: string | null;
  from: Date;
  to: Date;
  entryPerPage: number;
};

export const useRSimHistorySessionGraphData = (options: HistorySessionGraphDataOptions) => {
  const { primaryImsi, secondaryImsi, from, to } = options;

  const {
    data: primarySessionHistory,
    hasNextPage: hasPrimaryNextPage,
    isFetching: isFetchingPrimary,
    isError: isErrorPrimary,
    refetch: refetchPrimary
  } = useHistorySessionGraphData({
    imsi: primaryImsi!,
    from: from,
    to: to,
    entryPerPage: 25
  });

  const {
    data: backupSessionHistory,
    hasNextPage: hasBackupNextPage,
    isFetching: isFetchingBackup,
    isError: isErrorSecodary,
    refetch: refetchSecondary
  } = useHistorySessionGraphData({
    imsi: secondaryImsi!,
    from: from,
    to: to,
    entryPerPage: 25
  });

  const refetch = () => {
    refetchPrimary();
    refetchSecondary();
  };

  return {
    primary: primarySessionHistory,
    backup: backupSessionHistory,
    hasPrimaryNextPage,
    isFetchingPrimary,
    hasBackupNextPage,
    isFetchingBackup,
    error: isErrorPrimary && isErrorSecodary,
    refetch
  };
};
