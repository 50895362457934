import { authFetch } from 'auth/utils';
import { addMilliseconds, isBefore, parseISO, subMilliseconds } from 'date-fns';
import {
  getInterimsBySessionId,
  getRangeToFetch,
  saveInterimsCahce,
} from 'common/components/SessionHistory/utils/cache';
import { InterimsDTO } from '../utils/interimsModels';
import { Interims, mapInterimsDTO } from '../utils/interims';
import { isAfterOrEqual } from 'common/utils/dateUtils';

export const fetchInterimsToDate = async (
  imsi: string,
  sessionId: string,
  toDate: Date,
  pageSize: number,
  initialPage: number = 1,
  signal?: AbortSignal,
) => {
  let interims: InterimsDTO = [];
  for (let i = initialPage; i < Infinity; i++) {
    const urlQuery = new URLSearchParams({
      Imsi: imsi,
      SessionId: sessionId,
      PageNumber: i.toString(),
      PageSize: pageSize.toString(),
    });

    const interimsResponse = await authFetch(
      `${
        process.env.REACT_APP_SIM_INVENTORY_SIMS_PROFILE_API
      }/sessions/interims?${urlQuery.toString()}`,
      { signal },
    );

    let intermisParsedResponse: InterimsDTO = await interimsResponse.json();

    if (intermisParsedResponse.length === 0) {
      break;
    }

    const lastInterim = intermisParsedResponse.at(-1);
    if (isBefore(parseISO(lastInterim!.statusLastUpdated), toDate)) {
      intermisParsedResponse = intermisParsedResponse.filter((interim) => {
        const statusTimestamp = parseISO(interim.statusLastUpdated);
        return isAfterOrEqual(statusTimestamp, toDate);
      });
    }

    interims = [...interims, ...intermisParsedResponse];

    if (intermisParsedResponse.length < pageSize) {
      break;
    }
  }

  return mapInterimsDTO(interims, imsi);
};

export const fetchInterimsWithCache = async (
  imsi: string,
  sessionId: string,
  fromDate: Date,
  endDate: Date,
  pageSize: number,
  signal?: AbortSignal,
) => {
  const cachedRange = await getRangeToFetch(fromDate, endDate, sessionId);

  if (!cachedRange) {
    const allInterims = await fetchInterimsToDate(imsi, sessionId, fromDate, pageSize, 1, signal);
    await saveInterimsCahce(sessionId, allInterims, fromDate, endDate);
    return allInterims;
  }

  const { after, before } = cachedRange;
  let afterInterims: Interims = [];
  if (after) {
    afterInterims = await fetchInterimsToDate(
      imsi,
      sessionId,
      addMilliseconds(after.start, 1),
      pageSize,
      1,
      signal,
    );
  }

  const cachedInterims = await getInterimsBySessionId(sessionId);
  let beforeInterims: Interims = [];
  if (before) {
    const interimsCount = cachedInterims.length + afterInterims.length;

    const initialPage = Math.floor(interimsCount / pageSize) + 1;

    beforeInterims = await fetchInterimsToDate(
      imsi,
      sessionId,
      subMilliseconds(before.start, 1),
      pageSize,
      initialPage,
      signal,
    );

    beforeInterims = beforeInterims.filter((interim) => isBefore(interim.timestamp, before.end));
  }

  const newInterims = [...afterInterims, ...cachedInterims, ...beforeInterims];

  await saveInterimsCahce(sessionId, newInterims, fromDate, endDate);

  return newInterims;
};
