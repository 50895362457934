import React, { useMemo, useContext, useCallback } from 'react';

import { SessionHistoryContext } from './SessionHistoryProvider';
import { GraphsBox } from './GraphsBox';
import { SingleUsageGraph } from 'common/components/Graphs/SingleUsageGraph';
import { convertSessionHistoryToGraphUsageEntries } from 'simInventory/SessionHistory/HistoryAnalysis/utils';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { ErrorOverflowBoxWithRefreshAction } from 'common/components/Graphs/ErrorOverflowBoxWithRefreshAction';
import { useTranslation } from 'react-i18next';
import { USAGE_BAR_BACKGROUND_COLORS } from '../../common/components/Graphs/graphConfig';
import { useGraphSessionRange } from 'common/components/Graphs/Sessions/hooks/useGraphSessionRange';

export const UsageChart = React.memo(() => {
  const { validRange, visibleRange, selectVisibleRange, selectRange } = useGraphSessionRange();
  const {
    selectSession,
    sessionSelection,
    sessionToDisplay,
    primarySessionHistory,
    backupSessionHistory,
    loading,
    error,
    displaySession
  } = useContext(SessionHistoryContext);

  const onZoomComplete = useCallback(
    (range: [Date, Date]) => {
      if (selectSession) {
        selectSession({ positionX: null, index: null, setName: null });
      }

      selectVisibleRange(range[0], range[1]);
    },
    [selectVisibleRange]
  );

  const primaryUsageGraphEntries = useMemo(
    () => convertSessionHistoryToGraphUsageEntries(primarySessionHistory, 'Primary') || [],
    [primarySessionHistory]
  );

  const backupUsageGraphEntries = useMemo(
    () => convertSessionHistoryToGraphUsageEntries(backupSessionHistory, 'Backup') || [],
    [backupSessionHistory]
  );

  const totalUsage = [...primaryUsageGraphEntries, ...backupUsageGraphEntries].sort(
    (a, b) => b.x[0].getTime() - a.x[0].getTime()
  );

  const tooltipBody = useCallback(
    (positionX: number, index: number | null, setName: string | null) => {
      if (sessionSelection?.setName === setName && sessionSelection?.index === index) {
        return;
      }

      if (selectSession && (setName === 'Primary' || setName === 'Backup' || setName === null)) {
        selectSession({
          positionX: positionX,
          index: index,
          setName: setName
        });
      }
    },
    [sessionSelection]
  );

  const { t } = useTranslation();

  const tooltipData = useMemo(
    () => [
      { color: USAGE_BAR_BACKGROUND_COLORS.INPUT, description: t('rSimInventory.usageChartInput') },
      {
        color: USAGE_BAR_BACKGROUND_COLORS.OUTPUT,
        description: t('rSimInventory.usageChartOutput')
      }
    ],
    []
  );

  return (
    <GraphsBox
      title="Average session throughput"
      errorMessage={error && <ErrorOverflowBoxWithRefreshAction />}
      rightOffsetPx={40}
      tooltipTitle={t('rSimInventory.usageChartTitle')}
      tooltipRows={tooltipData}
    >
      <SingleUsageGraph
        highlightFrom={sessionToDisplay?.sessionStart}
        highlightTo={sessionToDisplay?.finalizationDate}
        from={visibleRange.from.getTime()}
        to={visibleRange.to.getTime()}
        min={validRange.from.getTime()}
        max={validRange.to.getTime()}
        entries={totalUsage}
        tooltip={tooltipBody}
        onZoomComplete={onZoomComplete}
        //@ts-ignore
        onClick={() => {
          if (displaySession && sessionSelection.index !== null) {
            displaySession({
              index: sessionSelection.index,
              setName: sessionSelection.setName
            });
          }
        }}
        onPan={selectRange}
      />
      {loading && (
        <Box
          sx={{
            position: 'absolute',
            top: 'calc(50% - 14px)',
            left: '50%',
            transform: 'translateX(-50%) translateY(-50%)'
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </GraphsBox>
  );
});
