import Skeleton from '@mui/material/Skeleton';
import { DEFAULT_BORDER_RADIUS } from 'theme/constants';
import React from 'react';

export type ChipSkeletonProps = {
  width?: number;
  height?: number;
  numberOfRepetitions?: number;
};

export const ChipSkeleton: React.FC<ChipSkeletonProps> = ({
  width = 120,
  height = 24,
  numberOfRepetitions = 1,
}) => {
  return (
    <>
      {[...Array(numberOfRepetitions)].map((e, i) => (
        <Skeleton
          key={i}
          data-testid="skeleton chip"
          variant="rectangular"
          width={width}
          height={height}
          sx={{ borderRadius: DEFAULT_BORDER_RADIUS, gap: '10px' }}
        />
      ))}
    </>
  );
};
