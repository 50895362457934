import { HydrateAtoms } from 'atoms/components/HydrateAtoms';
import { simFiltersAtom } from 'atoms/filters/sim';
import { Provider, useAtomValue } from 'jotai';
import { ReactNode } from 'react';

export const SimFiltersDialogAtomInitializator = ({ children }: { children: ReactNode }) => {
  const filters = useAtomValue(simFiltersAtom);

  return (
    <Provider>
      <HydrateAtoms initialValues={[[simFiltersAtom, filters]]}>{children}</HydrateAtoms>
    </Provider>
  );
};
