import { isAfter, isBefore } from 'date-fns';
import { InterimsStorage, interimsDatabase } from './interimsDb';
import { Interims } from '../services/SessionHistoryApi';
import { isAfterOrEqual, isBeforeOrEqual } from 'common/utils/dateUtils';

type RequirdRanges = {
  before: {
    start: Date;
    end: Date;
  } | null;
  after: {
    start: Date;
    end: Date;
  } | null;
};

export const getRangeToFetch = async (
  start: Date,
  end: Date,
  sessionId: string,
  db: InterimsStorage = interimsDatabase
) => {
  const dataRangeCacheArray = await db.cachedDataRange.where({ sessionId }).toArray();
  if (dataRangeCacheArray.length === 0) {
    return null;
  }

  const [dataRangeCacheJSON] = dataRangeCacheArray;
  const cachedStartDate = new Date(dataRangeCacheJSON.start);
  const cachedEndDate = new Date(dataRangeCacheJSON.end);

  const rangeToFetch: RequirdRanges = { before: null, after: null };
  if (isAfterOrEqual(start, cachedStartDate) && isBeforeOrEqual(end, cachedEndDate)) {
    return rangeToFetch;
  }

  if (isBefore(start, cachedStartDate)) {
    rangeToFetch.before = {
      start,
      end: cachedStartDate
    };
  }

  if (isAfter(end, cachedEndDate)) {
    rangeToFetch.after = {
      start: cachedEndDate,
      end
    };
  }

  return rangeToFetch;
};

export const getInterimsBySessionId = async (
  sessionId: string,
  db: InterimsStorage = interimsDatabase
): Promise<Interims> => {
  const cachedInterims = await db.cachedInterims.where({ sessionId }).toArray();
  const interims = cachedInterims.map(({ sessionId, ...interim }) => ({
    ...interim,
    timestamp: new Date(interim.timestamp)
  }));

  interims.sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime());

  return interims;
};

export const saveInterimsCahce = async (
  sessionId: string,
  interims: Interims,
  startDate: Date,
  endDate: Date
) => {
  const currentSavedRanges = await interimsDatabase.cachedDataRange.where({ sessionId }).toArray();

  if (currentSavedRanges.length === 0) {
    await interimsDatabase.cachedDataRange.put({
      sessionId,
      start: startDate.getTime(),
      end: endDate.getTime()
    });
  } else {
    const [currentRange] = currentSavedRanges;

    const newStartRange = isBefore(startDate, new Date(currentRange.start))
      ? startDate
      : new Date(currentRange.start);
    const newEndRange = isAfter(endDate, new Date(currentRange.end))
      ? endDate
      : new Date(currentRange.end);
    await interimsDatabase.cachedDataRange.put({
      sessionId,
      start: newStartRange.getTime(),
      end: newEndRange.getTime()
    });
  }

  await interimsDatabase.cachedInterims.bulkPut(
    interims.map((interim) => ({ ...interim, sessionId }))
  );
};
