import { Plugin } from 'chart.js';
import { ChartProps } from 'react-chartjs-2';
import { convertDateToLabels } from './utils/utils';
import { SessionType } from 'simInventory/SessionHistory/HistoryAnalysis/HistoryAnalysis.types';
import { palette } from 'theme/palette';

type BarChartProps = ChartProps<'bar'>;

export const chartBorderPlugin: Plugin<'bar'> = {
  id: 'chartBorder',
  afterDraw(chart, args, options) {
    const {
      ctx,
      chartArea: { left, top, width, height }
    } = chart;
    ctx.save();
    ctx.strokeStyle = options.borderColor;
    ctx.lineWidth = options.borderWidth;

    ctx.strokeRect(left, top, width, height);
    ctx.restore();
  }
};

export const zoomConfig = {
  pan: {
    enabled: true,
    mode: 'x',
    modifierKey: 'ctrl'
  },
  zoom: {
    mode: 'x',
    speed: 0.01,
    drag: {
      enabled: true,
      borderColor: 'rgb(54, 162, 235)',
      borderWidth: 1,
      backgroundColor: 'rgba(54, 162, 235, 0.3)',
      threshold: 3,
      drawTime: 'afterDraw'
    }
  },
  limits: {
    x: { minRange: 5 * 60 * 1000 }
  }
} as const;

export const GRAPH_DEFAULT_OPTIONS: BarChartProps['options'] = {
  normalized: true,
  maintainAspectRatio: false,
  animation: false,
  layout: {
    padding: 0,
    autoPadding: false
  },
  scales: {
    x: {
      type: 'time',
      clip: true,

      title: {
        display: false
      },
      grid: {
        display: true,
        drawTicks: false
      },
      border: {
        display: false
      },
      ticks: {
        autoSkip: true,
        maxRotation: 0,
        minRotation: 0,
        sampleSize: 1,
        major: {
          enabled: true
        },
        callback: function (value, index, values) {
          if (typeof value === 'string') {
            return '';
          }

          return convertDateToLabels(value, index, values);
        }
      }
    },
    y: {
      display: false,
      stacked: true,
      beginAtZero: true
    }
  },
  resizeDelay: 200,
  plugins: {
    zoom: zoomConfig,
    legend: {
      display: false,
    },
    chartBorder: {
      borderColor: palette.border,
      borderWidth: 1,
    },
  },
} as const;

export const GRAPH_USAGE_XY_DATA_SETTINGS = {
  borderWidth: 1,
  fill: true,
  stepped: true,
};

export const GRAPH_USAGE_OPTIONS = {
  ...GRAPH_DEFAULT_OPTIONS,
  scales: {
    x: {
      ...GRAPH_DEFAULT_OPTIONS?.scales?.x
    },
    y: {
      ...GRAPH_DEFAULT_OPTIONS?.scales?.y,
      display: false,
      type: 'linear',
      stacked: true,
      beginAtZero: true,
    },
  },
};

export const STATUS_COLOR_MAP: Record<SessionType, string> = {
  ZERO_BYTE_SESSION: palette.red['200'],
  NOT_FINALIZED: palette.amber['200'],
  NORMAL: palette.blue['200'],
};

export const STATUS_BORDER_COLOR_MAP: Record<SessionType, string> = {
  ZERO_BYTE_SESSION: palette.red['500'],
  NOT_FINALIZED: palette.amber['500'],
  NORMAL: palette.blue['500'],
};

export const USAGE_BAR_BACKGROUND_COLORS = {
  INPUT: palette.blue['200'],
  OUTPUT: palette.green['200'],
};

export const USAGE_BAR_BORDER_COLORS = {
  INPUT: palette.blue['500'],
  OUTPUT: palette.green['500'],
};

export const CHANGE_IN_TIME_COLORS = [palette.blue['500'], palette.blue['200']];

export const CHANGE_IN_TIME_BORDER_COLOR = palette.blue['500'];

export const CHANGE_IN_TIME_UNKNOW_COLOR = palette.grey['300'];

export const CHANGE_IN_TIME_UNKNOWN_BORDER_COLOR = palette.grey['400'];
