import {
  CustomFieldSelectedOption,
  CustomFieldType,
} from 'admin/customFields/entities/customFields.entity';
import { customFieldsFilterAtom } from 'atoms/filters/sim';
import { AutocompleteSelect } from 'common/components/Inputs/AutocompleteSelect/AutocompleteSelect';
import { TextFieldWithHints } from 'common/components/Inputs/TextFieldWithHints/TextFieldWithHints';
import { useAtom } from 'jotai';
import { focusAtom } from 'jotai-optics';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type CustomFieldInputProps = {
  id: number;
  name: string;
  type: CustomFieldType;

  selectionOptions?: CustomFieldSelectedOption[];
};

export const CustomFieldInput = ({
  id,
  name,
  type,
  selectionOptions = [],
}: CustomFieldInputProps) => {
  const { t } = useTranslation();
  const customFiledfilterAtom = useMemo(
    () => focusAtom(customFieldsFilterAtom, (optic) => optic.prop(id)),
    [id],
  );

  const [customFieldFilter, setCustomFieldFilter] = useAtom(customFiledfilterAtom);

  if (type === 'Text') {
    return (
      <TextFieldWithHints
        data-testid={`customField-text-${id}`}
        placeholder={`${t('simInventory.search')} ${name}`}
        value={customFieldFilter}
        onChange={(value) => {
          setCustomFieldFilter(value || '');
        }}
        hints={[]}
        onRemoveHint={(hint: string) => {}}
      />
    );
  }

  return (
    <AutocompleteSelect
      testId={`customField-dropdown-${id}`}
      hints={[]}
      // hints={hints && hints[field.id.toString()] ? hints[field.id.toString()] : []}
      values={customFieldFilter}
      options={selectionOptions.map((o) => ({
        id: o.id?.toString() || '',
        name: o.label || '',
      }))}
      onChange={(values) => setCustomFieldFilter(values.map((value) => value.id))}
      allowAdding={false}
      onRemoveHint={(hint) => {}}
      placeholder={`${t('simInventory.select')} ${name}`}
    />
  );
};
