import { authFetch } from 'auth/utils';
import {
  CurrentAppliedConfigurationDetails,
  PossibleConfigurationToRevert,
} from './rSimConfigurationApi.types';

export const fetchRSimPossibleOTAConfigurationsToRevert = async (
  euicc: string,
  signal?: AbortSignal,
) => {
  const possibleConfigurationsToRevertResponse = await authFetch(
    `${process.env.REACT_APP_SIM_INVENTORY_OTA_API}/configurations/configurations-possible-to-revert?Euicc=${euicc}`,
    { signal },
  );

  const configurations: PossibleConfigurationToRevert[] =
    await possibleConfigurationsToRevertResponse.json();
  return configurations;
};

export const revertConfigurationVersion = async (
  configuration: PossibleConfigurationToRevert,
  signal?: AbortSignal,
) => {
  return await authFetch(`${process.env.REACT_APP_SIM_INVENTORY_OTA_API}/versions/revert`, {
    signal,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      euicc: configuration.euicc,
      rsimOtaProfileIdentifier: configuration.rsimOtaProfileIdentifier,
      rsimOtaProfileVersion: configuration.rsimOtaProfileVersion,
    }),
  });
};

export const fetchRSimCurrentAppliedConfigurationDetails = async (
  euicc: string,
  signal?: AbortSignal,
) => {
  const currentAppliedConfigurationDetails = await authFetch(
    `${process.env.REACT_APP_SIM_INVENTORY_OTA_API}/configurations/current-applied-configuration-details?Euicc=${euicc}`,
    { signal },
  );

  const appliedConfigurationDetails: CurrentAppliedConfigurationDetails =
    await currentAppliedConfigurationDetails.json();
  return appliedConfigurationDetails;
};
