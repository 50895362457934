import { FiltersChips } from 'common/components/Filters/FiltersChips';
import { useTranslation } from 'react-i18next';
import { SESSION_STATUS } from '../../../utils/sessionStatus';
import { SimInventoryFilters, SimInventoryFiltersV2 } from 'simInventory/models/SimProfile.model';

import { useAtom } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import {
  accountsFilterAtom,
  customFieldsFilterAtom,
  mobileNetworkOperatorFilterAtom,
  SIM_FILTERS_INITIAL_VALUES,
  simFiltersAtom,
  tagsFilterAtom,
} from 'atoms/filters/sim';
import { TagsChips } from 'tags/Components/TagsChips';
import { AccountChips } from 'common/components/FilterChip/AccountChips';
import { MnosChips } from 'common/components/FilterChip/MnosChips';
import { CustomFieldsChips } from 'common/components/FilterChip/CustomFieldsChips';

export const SimInventoryFilterChips = () => {
  const { t } = useTranslation();

  const [filters, setFilters] = useAtom(simFiltersAtom);
  const { tags, mobileNetworkOperator, accounts, customFields, ...otherFilters } = filters;

  const clearAll = useResetAtom(simFiltersAtom);

  const onFilterClear = (filterName: any | 'iccidRange') => {
    if (filterName === 'iccidRange') {
      //@ts-ignore
      setFilters((currentFilters: SimInventoryFiltersV2) => ({
        ...currentFilters,
        iccidFrom: '',
        iccidTo: '',
      }));
      return;
    }

    //@ts-ignore
    setFilters((currentFilters: SimInventoryFiltersV2) => ({
      ...currentFilters,
      //@ts-ignore
      [filterName]: SIM_FILTERS_INITIAL_VALUES[filterName],
    }));
  };

  const createValuesForChips = (
    filters: Omit<
      SimInventoryFilters,
      'tags' | 'mobileNetworkOperator' | 'accounts' | 'customFields'
    >,
  ) => {
    const filtersNames = {
      sessionStatus: t('simInventory.sessionStatus'),
      accounts: t('common.account'),
      mobileNetworkOperator: t('simInventory.mobileNetworkOperator'),
      label: t('common.label'),
      orderNumber: t('common.orderNumber'),
      connectionId: t('common.connectionId'),
      tags: 'tags',
      iccidRange: '',
    };

    const { sessionStatus, iccidFrom, iccidTo, ...othersFilters } = filters;
    const chipsValues: Partial<Record<keyof typeof filtersNames, string>> = {};

    if (sessionStatus !== SESSION_STATUS.UNKNOWN) {
      //@ts-ignore
      chipsValues.sessionStatus = `${filtersNames.sessionStatus}: ${
        sessionStatus === SESSION_STATUS.IN_SESSION ? t('common.inSession') : t('common.outSession')
      }`;
    }

    if (iccidFrom !== '' || iccidTo !== '') {
      chipsValues.iccidRange = `ICCID`;
      if (iccidFrom) {
        chipsValues.iccidRange += ` ${t('common.from')}: ${iccidFrom}`;
      }

      if (iccidTo) {
        chipsValues.iccidRange += ` ${t('common.to')}: ${iccidTo}`;
      }
    }

    Object.entries(othersFilters).forEach(([key, value]) => {
      if (key === 'chosenIdType' || key === 'searchText') {
        return;
      }

      if (value !== '') {
        chipsValues[key as keyof typeof filtersNames] = `${
          filtersNames[key as keyof typeof filtersNames]
        }: ${value}`;
      }
    });

    return chipsValues;
  };

  const filtersChips = createValuesForChips(otherFilters);

  return (
    <FiltersChips
      externalChips={
        <>
          <TagsChips tagsAtom={tagsFilterAtom} />
          <AccountChips accountAtom={accountsFilterAtom} />
          <MnosChips mnoAtom={mobileNetworkOperatorFilterAtom} />
          <CustomFieldsChips customFieldsAtom={customFieldsFilterAtom} />
        </>
      }
      filters={filtersChips}
      onFilterClear={onFilterClear}
      onFiltersClear={clearAll}
    />
  );
};
