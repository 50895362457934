import { useTranslation } from 'react-i18next';
import { provisionRsim } from './api/rSimActionsApi';
import { ConfirmActionDialog } from 'common/components/ConfigurableTable/Components/Actions/ConfirmActionDialog';

type DeactivationDialogProps = {
  data: any;
  open: boolean;
  onClose: () => void;
};

export const DeactivationDialog = ({ data, open, onClose }: DeactivationDialogProps) => {
  const { t } = useTranslation();

  return (
    <ConfirmActionDialog
      open={open}
      data={data}
      onClose={onClose}
      submit={(data: any) => provisionRsim([data.connectionId], false)}
      message={`${t('rSimInventory.deactivationEid')} ${data?.euicc}`}
      title={t('rSimInventory.deactivation')}
    />
  );
};
