import { LinkTextCell } from 'common/components/ConfigurableTable/Components/Cells/LinkTextCell';
import { useLocation } from 'react-router-dom';
import { Routes } from 'Routes.constants';

export const RSimSimIccidLinkCell = ({ value }: any) => {
  const { pathname, search } = useLocation();

  return (
    <LinkTextCell
      value={value}
      to={`${Routes.simInventory}/${value}`}
      state={{ previousPath: `${pathname}${search}` }}
    />
  );
};
