import {
  SimInventoryData,
  SimProfile,
} from 'simInventory/SimInventoryApi/simInventoryApi.interface';
import { QueryKey, useQueryClient } from '@tanstack/react-query';
import { useSimSignalRSubscription } from './useSimSignalRSubscription';

export const useSimInventorySubscription = (iccids: string[], queryKey: QueryKey) => {
  const queryClient = useQueryClient();

  const updateFunction = async (entryToUpdate: SimProfile) => {
    const simProfiles: SimInventoryData | undefined = queryClient.getQueryData(queryKey);

    if (!entryToUpdate || !simProfiles?.items.length) {
      return;
    }

    queryClient.setQueryData<any>(queryKey, (oldData: SimInventoryData) => {
      if (!oldData) {
        return oldData;
      }

      const newData = {
        ...oldData,
        items: oldData?.items.map((simProfile: SimProfile) => {
          const tags = entryToUpdate.tags ? entryToUpdate.tags : simProfile.tags;
          if (simProfile.iccid === entryToUpdate.iccid) {
            return { ...simProfile, ...entryToUpdate, tags };
          }

          return simProfile;
        }),
      };

      return newData;
    });
  };

  useSimSignalRSubscription(iccids, updateFunction);
};
