import Stack from '@mui/material/Stack';
import { Chip } from './Chip';
import Box from '@mui/material/Box';

export type ChipsWrapperProps = {
  labels: string[];
};

export const ChipsWrapper = ({ labels }: ChipsWrapperProps) => {
  return (
    <Stack width={ '100%' } direction="row" gap={ 1 }>
      <Box sx={ { overflow: 'hidden' } }>
        <Stack
          direction="row"
          width="100%"
          flexWrap="wrap"
          gap={ 1 }>
          { labels.map((label, index) => (
            <Chip label={ label } key={ index } variant="outlined"/>
          )) }
        </Stack>
      </Box>
    </Stack>
  );
};
