import Typography from '@mui/material/Typography';
import { ErrorOverflowBox } from './ErrorOverflowBox';

import Stack from '@mui/material/Stack';
import { RefetchQueryButton } from '../Queries/RefetchQueryButton';

export const ErrorOverflowBoxWithRefreshAction = () => {
  return (
    <ErrorOverflowBox>
      <Stack spacing={1} direction="row" justifyContent="center" alignItems="center" height="100%">
        <Typography variant="text1" color="text.secondary">
          Something went wrong! Select time range again or
        </Typography>

        <RefetchQueryButton variant="primary" queryKey={['SessionHistory']} />
      </Stack>
    </ErrorOverflowBox>
  );
};
