import { AuthError } from 'auth/utils';
import { downloadPollingHistory } from './pollingApi';
import { useState } from 'react';
import { AdditionalMenu } from 'common/components/AdditionalMenu';
import { AdditionalMenuItem } from 'common/components/AdditonalMenuItem';
import Stack from '@mui/material/Stack';
import { AutoHideSnackbar } from 'common/Snackbar/AutoHideSnackbar';
import { AlertTitle } from 'common/Snackbar/AlertTitle';
import { useTranslation } from 'react-i18next';

export type PollingChartAdditionalMenuProps = {
  euicc: string;
  from: Date;
  to: Date;
};
export const PollingChartAdditionalMenu: React.FC<PollingChartAdditionalMenuProps> = ({
  euicc,
  from,
  to,
}) => {
  const { t } = useTranslation();
  const [downloading, setDownloading] = useState(false);

  const [exportError, setExportError] = useState<boolean>(false);

  return (
    <>
      <AdditionalMenu>
        {({ onClose }) => (
          <AdditionalMenuItem
            disabled={downloading}
            onClick={async () => {
              try {
                await downloadPollingHistory(euicc, from, to);
                setDownloading(true);
              } catch (error: unknown) {
                if (error instanceof AuthError) {
                  throw error;
                }

                setExportError(true);
              }
              onClose();
            }}
          >
            {t('common.export')}
          </AdditionalMenuItem>
        )}
      </AdditionalMenu>
      <Stack>
        <AutoHideSnackbar
          severity="success"
          open={downloading}
          onClose={() => setDownloading(false)}
        >
          {''} {/* Without this component throw a error and crash app */}
          <AlertTitle> {t('common.downloading')}!</AlertTitle>
        </AutoHideSnackbar>
        <AutoHideSnackbar
          open={!!exportError}
          severity="error"
          onClose={() => setExportError(false)}
        >
          {''} {/* Without this component throw a error and crash app */}
          <AlertTitle>{t('common.exportFailed')}!</AlertTitle>
        </AutoHideSnackbar>
      </Stack>
    </>
  );
};
