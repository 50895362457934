import React, { useCallback, useContext, useMemo } from 'react';

import { SessionHistoryContext } from './SessionHistoryProvider';
import { GraphsBox } from './GraphsBox';
import { GraphLegend } from './GraphLegend';
import { RSimSessionGraphRange } from 'common/components/Graphs/Sessions/RSimSessionGraphRange';
import { convertSessionHistoryToGraphEntries } from 'simInventory/SessionHistory/HistoryAnalysis/utils';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { ErrorOverflowBox } from 'common/components/Graphs/ErrorOverflowBox';
import { useTranslation } from 'react-i18next';
import { STATUS_COLOR_MAP } from '../../common/components/Graphs/graphConfig';
import { useGraphSessionRange } from 'common/components/Graphs/Sessions/hooks/useGraphSessionRange';

export const SessionChart = React.memo(() => {
  const { visibleRange, selectVisibleRange, validRange, selectRange } = useGraphSessionRange();
  const {
    sessionToDisplay,
    selectSession,
    sessionSelection,
    primarySessionHistory,
    backupSessionHistory,
    loading,
    error,
    displaySession
  } = useContext(SessionHistoryContext);

  const graphEntries = useMemo(
    () => convertSessionHistoryToGraphEntries(primarySessionHistory) || [],
    [primarySessionHistory]
  );

  const backupGraphEntries = useMemo(
    () => convertSessionHistoryToGraphEntries(backupSessionHistory) || [],
    [backupSessionHistory]
  );

  const tooltipBody = useCallback(
    (positionX: number, index: number | null, setName: string | null) => {
      if (sessionSelection?.setName === setName && sessionSelection?.index === index) {
        return;
      }

      if (selectSession && (setName === 'Primary' || setName === 'Backup' || setName === null)) {
        selectSession({
          positionX: positionX,
          index: index,
          setName: setName
        });
      }
    },
    [sessionSelection]
  );

  const selectSessionToDisplay = useCallback(
    (index: number, setName: 'Primary' | 'Backup' | null) => {
      if (displaySession) {
        displaySession({
          index,
          setName
        });
      }
    },
    [displaySession]
  );

  const { t } = useTranslation();

  const tooltipData = useMemo(
    () => [
      { color: STATUS_COLOR_MAP.NORMAL, description: t('rSimInventory.sessionChartFinalized') },
      {
        color: STATUS_COLOR_MAP.NOT_FINALIZED,
        description: t('rSimInventory.sessionChartNotFinalized')
      },
      {
        color: STATUS_COLOR_MAP.ZERO_BYTE_SESSION,
        description: t('rSimInventory.sessionChartZeroByte')
      }
    ],
    []
  );

  const onZoomComplete = useCallback(
    (range: [Date, Date]) => {
      if (selectSession) {
        selectSession({ positionX: null, index: null, setName: null });
      }

      selectVisibleRange(range[0], range[1]);
    },
    [selectVisibleRange]
  );

  return (
    <GraphsBox
      title={'Sessions'}
      legend={<GraphLegend topLabel="Primary" bottomLabel="Backup" axisHeight={35} />}
      errorMessage={error && <ErrorOverflowBox />}
      rightOffsetPx={40}
      tooltipTitle={t('rSimInventory.sessionChartTitle')}
      tooltipRows={tooltipData}
    >
      <RSimSessionGraphRange
        highlightFrom={sessionToDisplay?.sessionStart}
        highlightTo={sessionToDisplay?.finalizationDate}
        primaryEntries={graphEntries}
        backupEntries={backupGraphEntries}
        from={visibleRange.from.getTime()}
        to={visibleRange.to.getTime()}
        min={validRange.from.getTime()}
        max={validRange.to.getTime()}
        onZoomComplete={onZoomComplete}
        tooltip={tooltipBody}
        onClick={selectSessionToDisplay}
        onPan={selectRange}
      />
      {loading && (
        <Box
          sx={{
            position: 'absolute',
            top: 'calc(50% - 14px)',
            left: '50%',
            transform: 'translateX(-50%) translateY(-50%)'
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </GraphsBox>
  );
});
