import { useSuspenseQuery } from '@tanstack/react-query';
import { ErrorCodes } from 'common/errorCodes';
import { useHasFeatureFlag } from 'featureFlags/useHasFeatureFlag';
import { fetchRSim, fetchRSimInventory } from 'rSimInventory/RSimApi/rSimApi';

export const useRSimSuspenseQuery = (euicc?: string) => {
  const tagsMigratedToProductsView = useHasFeatureFlag('TagsMigratedToProductsView');

  return useSuspenseQuery({
    queryKey: ['rSimInventory', euicc],
    queryFn: () => {
      if (!euicc) {
        return null;
      }

      if (!tagsMigratedToProductsView) {
        return fetchRSimInventory(0, 1, '', euicc);
      }

      return fetchRSim(euicc);
    },
    refetchOnWindowFocus: true,
    retry: (failureCount, error) => {
      if (error instanceof Error && error.message === ErrorCodes.NotFound) {
        return false;
      }
      return failureCount < 3;
    },
  });
};
