import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { EditDialog } from 'common/components/Dialog/EditDialog';
import { Actions, Subjects } from 'permissions/ability';
import { useAbility } from 'permissions/hooks/useAbility';
import { SimInventoryCan } from 'permissions/PermissionProvider';

import { useTagsAutocompleteSelect } from 'tags/hooks/useTagsAutocompleteSelect';
import { AutocompleteSelect } from 'common/components/Inputs/AutocompleteSelect/AutocompleteSelect';
import { useRSimUpdateContext } from '../Providers/RSimUpdateProvider';

const RSimEditDialogContent = ({ open, data, onClose }: any) => {
  const { updateTagsForRSim } = useRSimUpdateContext();
  const ability = useAbility();

  const {
    isLoading,
    tags,
    assignedSimTags,

    addNewTags,
    selectTags,
    save,
    refresh,

    changed,

    setChanges,
    searchValue,
    setSearchValue,
    errors,
    validateTagLength,
  } = useTagsAutocompleteSelect({
    id: data.euicc,
    onTagsUpdate: updateTagsForRSim,
    enabled: ability.can(Actions.edit, Subjects.tags),
  });
  return (
    <EditDialog
      open={open}
      isLoading={isLoading}
      subtitle={`rSIM ${data?.euicc}`}
      unsavedChanges={changed}
      onApplyChangesClick={async () => {
        if (ability.can(Actions.edit, Subjects.tags)) {
          await save();
        }
        onClose();
      }}
      onCancelClick={onClose}
      onClearChangesClick={async () => {
        await refresh();
        setChanges(false);
      }}
    >
      <Stack
        direction="row"
        gap={12}
        alignItems="center"
        sx={{ borderLeft: (theme) => `1px solid ${theme.palette.text.tertiary}` }}
      >
        <SimInventoryCan I={Actions.edit} a={Subjects.tags}>
          <Typography variant="text2" color="text.secondary" sx={{ ml: 4 }}>
            Tags
          </Typography>
          <Box flex={1}>
            <AutocompleteSelect
              errors={errors}
              values={assignedSimTags!}
              options={tags || []}
              onChange={(values) => {
                if (values.length <= 20) {
                  selectTags(values.map((value) => value.id));
                  setChanges(true);
                }
              }}
              onAdd={(newValues) => {
                addNewTags(newValues);
              }}
              inputValue={searchValue}
              onTextInputChange={(value) => {
                if (validateTagLength(value)) {
                  setSearchValue(value);
                }
              }}
              testId="tags-multiselect"
            />
          </Box>
        </SimInventoryCan>
      </Stack>
    </EditDialog>
  );
};

export const RSimEditDialog = (props: any) => {
  if (!props.open) {
    return null;
  }

  return <RSimEditDialogContent {...props} />;
};
