import React, { useCallback } from 'react';
import { useSimInventory } from './useSimInventory';
import { NoDataMessage } from 'common/components/Table/NoDataMessage';

import { ConfigurableTable } from './ConfigurableTable/TableBase';
import { Sorting } from '../../common/hooks/useSorting';
import { TableLayoutWithPaginationWithoutScrollArea } from 'common/components/Table/TableLayoutWithPaginationWithoutScrollArea';
import { useAtom, useAtomValue } from 'jotai';
import {
  pageFilterAtom,
  rowsPerPageFilterAtom,
  simFiltersAtom,
  simSortingAtomWithStorage,
} from 'atoms/filters/sim';

export const SimInventoryTable = React.memo(() => {
  const filters = useAtomValue(simFiltersAtom);
  const [rowsPerPage, setRowsPerPage] = useAtom(rowsPerPageFilterAtom);
  const [page, setPage] = useAtom(pageFilterAtom);

  const [sorting, setSorting] = useAtom(simSortingAtomWithStorage);

  const {
    simInventory,
    isRefetching,
    pagination: { totalNumberOfItems },
    updateTagsForSim,
  } = useSimInventory(filters, sorting, rowsPerPage, page);

  const onSortModelChange = useCallback((columnName: string, sort: Sorting) => {
    //temporary solution
    setSorting({
      columnName,
      //@ts-ignore
      sort: sort,
    });
  }, []);

  return (
    <TableLayoutWithPaginationWithoutScrollArea
      noItemsMessage={<NoDataMessage searchText={''} prevSearchText={''} />}
      fetching={isRefetching}
      page={page}
      onPageChange={setPage}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={(page) => setRowsPerPage(page)}
      totalNumberOfItems={totalNumberOfItems}
      table={
        <ConfigurableTable
          items={simInventory?.items || []}
          onTagsUpdate={updateTagsForSim}
          sort={sorting}
          onSortChange={onSortModelChange}
        />
      }
    />
  );
});
