import { HydrateAtoms } from 'atoms/components/HydrateAtoms';
import { Provider, useAtomValue } from 'jotai';
import { ReactNode } from 'react';
import { simProductsFiltersAtom } from 'atoms/filters/sim-products';

export const SimProductsFiltersDialogAtomInitializer = ({ children }: { children: ReactNode }) => {
  const filters = useAtomValue(simProductsFiltersAtom);

  return (
    <Provider>
      <HydrateAtoms initialValues={[[simProductsFiltersAtom, filters]]}>{children}</HydrateAtoms>
    </Provider>
  );
};
