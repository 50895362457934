import React, { FC } from 'react';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import { FilterChipProps } from './FilterChip.interface';
import { ReactComponent as FilterChipClose } from 'assets/images/filterChipClose.svg';

export const FilterChip: FC<FilterChipProps> = ({ text, onClose }) => {
  return (
    <Chip
      label={text}
      sx={{ textTransform: 'capitalize' }}
      onDelete={onClose}
      variant="filled"
      deleteIcon={
        <IconButton
          sx={{
            padding: '0',
            paddingBottom: '2px',
            '&.MuiChip-deleteIcon': {
              marginRight: '6px',
            },
          }}
          onClick={onClose}
        >
          <FilterChipClose style={{ width: '12px', height: '12px' }} />
        </IconButton>
      }
    />
  );
};
