import { createContext } from 'react';
import { useRSimsSims } from 'rSimInventory/hooks/useRSimsSims';
import { RSimInventoryItem } from 'rSimInventory/RSimApi/rSimApi.interface';

type RSimsSimsMap = ReturnType<typeof useRSimsSims>;
export const rSimsSimsContext = createContext<{ data: RSimsSimsMap }>({ data: {} as RSimsSimsMap });

type RSimsSimsProviderProps = {
  children: React.ReactNode;
  rsims?: RSimInventoryItem[];
};

export const RSimsSimsProvider = ({ rsims, children }: RSimsSimsProviderProps) => {
  const data = useRSimsSims(rsims);

  return <rSimsSimsContext.Provider value={{ data }}>{children}</rSimsSimsContext.Provider>;
};
