import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import {
  ColumnConfiguration,
  TABLE_VARIANT,
  TableVariant,
} from '../../../SimInventoryApi/simInventoryTableConfigurationApi.interface';
import { dateCellSx } from '../TableBase.style';
import { TextCell } from './TextCell';
import { TagsCell } from 'common/components/ConfigurableTable/Components/Cells/TagsCell';
import { StickyLinkCell } from 'common/components/ConfigurableTable/Components/Cells/StickyLinkCell';
import { getSessionStatusTranslatedLabel } from '../../../utils/sessionStatus';
import { FIELD_TYPE } from '../../../../admin/customFields/entities/customFields.entity';

import { getHuminizeDurationInSecFunction } from 'i18n/dateLocale';
import { styled } from '@mui/material';
import { useAbility } from 'permissions/hooks/useAbility';
import { BillingStatusCell } from 'common/components/ConfigurableTable/Components/Cells/BillingStatusCell';
import { IconCell } from 'common/components/ConfigurableTable/Components/Cells/IconCell';
import { SessionStatusIndicator } from 'simInventory/Components/SessionStatusIndicator';
import { SimDetailsContext } from '../../../SimInventoryDetails/SimDetailsProvider';

type CellRendererProps = {
  column: ColumnConfiguration;
  params: GridRenderCellParams;
  variant: TableVariant;
};

const humanize = getHuminizeDurationInSecFunction();

const Cell = (
  props: GridRenderCellParams & {
    column: ColumnConfiguration;
    isChild: boolean;
    baseRoute: string;
  },
) => {
  const ability = useAbility();

  if (props.row == null || !props.column) {
    return null;
  }

  if (props.column.name === 'iccid') {
    return ability.can('read', 'simDetails') ? (
      <StickyLinkCell
        value={props.row[props.column.name]?.toString()}
        to={`${props.baseRoute}/${props.row.iccid?.toString()}`}
      />
    ) : (
      <TextCell text={props.row[props.column.name]?.toString()} isChild={props.isChild} />
    );
  }

  if (props.column.isCustomField && props.row[props.column.name.toLowerCase()]) {
    if (props.row[props.column.name.toLowerCase()].dataType === FIELD_TYPE.Dropdown) {
      const text = props.row[props.column.name.toLowerCase()].selectionOption;
      return <TextCell text={text} isChild={props.isChild} />;
    }
    const text = props.row[props.column.name.toLowerCase()].textValue;
    return <TextCell text={text} isChild={props.isChild} />;
  }

  if (['sessionStartTime', 'sessionEndTime'].includes(props.column.name)) {
    const text = props.row[props.column.name];
    return <TextCell text={text} isChild={props.isChild} sx={dateCellSx} />;
  }

  if (props.column.name === 'inMinuteUsage' || props.column.name === 'outMinuteUsage') {
    const text = props.row[props.column.name];

    return <TextCell text={humanize(text)} isChild={props.isChild} />;
  }

  if (props.column.name === 'billingStatus') {
    const billingStatus = props.row[props.column.name];
    return <BillingStatusCell billingStatus={billingStatus} />;
  }

  const text = props.row[props.column.name];
  if (!text) {
    return <></>;
  }
  return <TextCell text={text} isChild={props.isChild} />;
};

type BaseCellRootProps = {
  variant: TableVariant;
};

const BaseCellRoot = styled('div', {
  name: 'BaseCell',
  slot: 'root',
})<BaseCellRootProps>(({ variant }) => ({
  verticalAlign: 'center',
  padding: '8px 0 8px 14px',
  height: variant === TABLE_VARIANT.Combined ? '52px' : '42px',
  display: 'flex',
  width: '100%',
}));

export const BaseCell: React.FC<CellRendererProps> = React.memo(({ column, params, variant }) => {
  const { baseRoute } = useContext(SimDetailsContext);

  return (
    <BaseCellRoot variant={variant} key={column.name}>
      {column.name === 'sessionStatus' && (
        <IconCell
          icon={<SessionStatusIndicator status={params.row.sessionStatus} />}
          primaryText={getSessionStatusTranslatedLabel(params.row.sessionStatus)}
          secondaryText={
            column.children[0]?.name ? params.row[column.children[0]?.name] : undefined
          }
        />
      )}
      {column.name === 'tags' && <TagsCell column={column} item={params.row} />}
      {column.name !== 'tags' && column.name !== 'sessionStatus' && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: column.name !== 'sessionStatus' ? '100%' : 'calc(100% - 28px)',
          }}
        >
          <Box sx={{ whiteSpace: 'nowrap' }} data-testid="primaryText">
            <Cell {...params} column={column} isChild={false} baseRoute={baseRoute} />
          </Box>
          {column.children && (
            <Box sx={{ whiteSpace: 'nowrap' }} data-testid="secondaryText">
              <Cell {...params} column={column.children[0]} isChild={true} baseRoute={baseRoute} />
            </Box>
          )}
        </Box>
      )}
    </BaseCellRoot>
  );
});
