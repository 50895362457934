export type Marks = Record<number, string>;

export const getMinMaxValueFromMarks = (marks: Marks) => {
  const values = Object.keys(marks).map((value) => Number(value));

  const min = Math.min(...values);
  const max = Math.max(...values);

  return { min, max };
};

export const getMarkLabel = (
  currentMark: Date,
  prevMark: Date | undefined,
  forceMonth: boolean = false
) => {
  if (!prevMark || currentMark.getMonth() !== prevMark.getMonth()) {
    return currentMark.toLocaleString('default', { day: 'numeric', month: 'short' });
  }

  if (prevMark.getDay() !== currentMark.getDay() && forceMonth) {
    return currentMark.toLocaleString('default', { day: 'numeric', month: 'short' });
  }

  if (prevMark.getDay() === currentMark.getDay()) {
    return ' ';
  }

  return currentMark.toLocaleString('default', { day: 'numeric' });
};

export const createMarksFromTicks = (ticks: Date[]) => {
  return ticks.reduce((currentTicks, date, index, ticks) => {
    const prevTick = index > 0 ? ticks[index - 1] : undefined;
    const label = getMarkLabel(date, prevTick, false);
    return {
      ...currentTicks,
      [date.getTime()]: label
    };
  }, {});
};
