import { z } from 'zod';

export const SessionHistoryEntryDTOSchema = z.object({
  acctSessionId: z.nullable(z.string()),
  calledStationId: z.nullable(z.string()),
  cumulativeOutputUsage: z.nullable(z.number()).optional(),
  cumulativeInputUsage: z.nullable(z.number()).optional(),
  detectedImei: z.nullable(z.string()),
  eventTimestamp: z.nullable(z.coerce.date()),
  imsi: z.number(),
  framedIpaddress: z.nullable(z.string()),
  framedProtocol: z.nullable(z.string()),
  lastRadiusType: z.nullable(z.string()),
  localization: z.nullable(z.string()),
  nasIpAddress: z.nullable(z.string()),
  operatorCode: z.nullable(z.string()),
  processedDate: z.nullable(z.coerce.date()),
  rasClient: z.nullable(z.string()),
  ratType: z.nullable(z.string()),
  roamingCode: z.nullable(z.string()),
  serviceType: z.nullable(z.string()),
  startTime: z.coerce.date(),
  statusLastUpdated: z.nullable(z.coerce.date()),
  endTime: z.nullable(z.coerce.date()),
  timeZone: z.nullable(z.string()),
  username: z.nullable(z.string()),
});

export const SessionHistorySchemaDTO = z.object({
  entries: z.array(SessionHistoryEntryDTOSchema),
});

export type SessionHistoryEntryDTO = z.infer<typeof SessionHistoryEntryDTOSchema>;

export type SessionHistoryDTO = z.infer<typeof SessionHistorySchemaDTO>;
