import { authFetch } from 'auth/utils';
import { Mno, MnoData } from './mnoApi.types';

export const fetchMnos = async (name?: string, signal?: AbortSignal) => {
  const urlQuery = new URLSearchParams({
    ...(name ? { Search: name } : {}),
    PageSize: '100',
  });
  const mnosResponse = await authFetch(
    `${process.env.REACT_APP_SIM_INVENTORY_MOBILE_NETWORK_OPERATORS_API}?${urlQuery.toString()}`,
    { signal },
  );

  const mnos: MnoData = await mnosResponse.json();
  return mnos.items;
};

export const fetchMno = async (ccii?: string, signal?: AbortSignal) => {
  const mnoResponse = await authFetch(
    `${process.env.REACT_APP_SIM_INVENTORY_MOBILE_NETWORK_OPERATORS_API}/${ccii}`,
    { signal },
  );

  const mno: Mno = await mnoResponse.json();
  return mno;
};
